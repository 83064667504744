export const HEADINGS = [
  ["invoice_date", "Invoice Date"],
  ["create_date", "Discrepancy Date"],
  ["discrepancy_total_amount", "Amount"],
  ["biller_name", "Biller Name"],
  ["buyer_name", "Buyer Name"],
  ["discrepancy_note", "Discrepancy Notes"],
  ["status", "Status"],
];

export const BUYER_HEADINGS = [
  ["invoice_date", "Invoice Date"],
  ["create_date", "Discrepancy Date"],
  ["discrepancy_total_amount", "Amount"],
  ["biller_name", "Biller Name"],
  ["discrepancy_note", "Discrepancy Notes"],
  ["status", "Status"],
  ["accept_adjusted", "Adjust Status"],
];

export const BILLER_HEADINGS = [
  ["invoice_date", "Invoice Date"],
  ["create_date", "Discrepancy Date"],
  ["discrepancy_total_amount", "Amount"],
  ["buyer_name", "Buyer Name"],
  ["discrepancy_note", "Discrepancy Notes"],
  ["status", "Status"],
];

export const filtersMap = [
  {
    label: "Select Date",
    name: "invoice_date",
    placeholder: "Date",
  },
  {
    label: "Select Amount",
    name: "amount",
    placeholder: "Amount",
  },
  {
    label: "Select Invoice",
    name: "invoice_number",
    placeholder: "Invoice",
  },
];
