import React from "react";
import { useSelector } from "react-redux";
import {
  RecentReportsWidget,
  CRSNewsWidget,
} from "../../_metronic/_partials/widgets";

export function DashboardPage() {
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <h6 className="mb-4">
            Hello {user.fullname}, from {user.default_organisation_name}
          </h6>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-8 mb-6">
          <CRSNewsWidget />
        </div>
        <div className="col-xs-12 col-md-4">
          <RecentReportsWidget />
        </div>
      </div>
    </div>
  );
}
