import config from "react-global-configuration";

export const importBankStatement = (tenant_id, filename, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions/import`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        file_name: filename,
        account_id: account_id,
        force: true,
      }),
      mode: "cors",
      credentials: "include",
    }
  );

export const getLastUploadedBankDataResult = (tenant_id, account_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/bank-transactions?${
      account_id ? "account_id=" + account_id : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTransactionsHistory = (
  tenant_id,
  date,
  page = 0,
  page_size = 10
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions?status=matched&gte-last_modified=${date}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getRuleById = (rule_id, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/rules/${rule_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const deleteRule = (rule_id, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/rules/${rule_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyRule = (data, rule_id, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/rules/${rule_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const createRule = (data, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/rules`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const getRules = (
  tenant_id,
  archived,
  field_name = [],
  field_value = [],
  operator = [],
  name = []
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/rules?${(archived &&
      "&status=all") ||
      ""}${
      field_name.length
        ? field_name.map((item) => "&field_name=" + item).join("")
        : ""
    }${
      field_value.length
        ? field_value.map((item) => "&field_value=" + item).join("")
        : ""
    }${
      operator.length
        ? operator.map((item) => "&operator=" + item).join("")
        : ""
    }${name.length ? name.map((item) => "&name=" + item).join("") : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createBankTransaction = (
  tenant_id,
  bank_transaction_id,
  transaction_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions/${bank_transaction_id}/match`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(transaction_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const revertBankTransaction = (tenant_id, bank_transaction_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions/${bank_transaction_id}/revert`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    }
  );

export const matchBankTransactionById = (tenant_id, bank_transaction_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions/${bank_transaction_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const splitBankTransaction = (data, tenant_id, bank_transaction_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/bank-transactions/${bank_transaction_id}/split`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );
