import React from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { MappedInput } from "../../../ui/components/Input";
import { DisputeTransactionSchema } from "../../../helpers/schemas";
import { MappedSelect } from "../../../ui/components/Select";

export const DisputeTransaction = ({ handleSubmit, handleClose }) => {
  const initialValues = {
    reason: "",
    notes: ""
  };

  const mockedReasonOptions = [
    { value: "Reason 1", label: "Reason 1" },
    { value: "Reason 2", label: "Reason 2" },
    { value: "Reason 3", label: "Reason 3" },
  ];

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={DisputeTransactionSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="py-10">
          <h4 className="mb-10 text-center">
            <strong>Discrepancy Details</strong>
          </h4>
          <div className="row mt-15 mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted mb-0">Select Reason for Dispute</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedSelect name="reason" options={mockedReasonOptions} data-testid="reason" />
            </div>
          </div>
          <div className="row mb-15">
            <div className="col-2 my-auto">
              <p className="text-right text-muted mb-0">Notes</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput name="notes" wrapperClassName="w-100" data-testid="notes" />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Submit Dispute
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
