import config from "react-global-configuration";

export const getShareRegisterList = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/shareregister`, {
    mode: "cors",
    credentials: "include",
  });

export const getShareRegisterListByOrgId = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/shares`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addShareRegister = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/shareregister`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const getShareRegisterById = (tenant_id, share_register_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/shareregister/${share_register_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const updateShareRegister = (tenant_id, share_register_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/shareregister/${share_register_id}`,
    {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
  );

export const deleteShareRegister = (tenant_id, share_register_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/shareregister/${share_register_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const exportShareCertificate = (ids, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/shareregister/${
      ids[0]
    }/certificate`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
