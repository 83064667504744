import React, { useState, useEffect } from "react";

export function IntegrationItem({ icon, title, action, isActive, setActive }) {
  const [color, setColor] = useState("#3699FF");

  useEffect(() => {
    isActive ? setColor("#151c47") : setColor("#3699FF");
  }, [isActive]);

  const handleActive = () => {
    setActive(title);
  };
  return (
    <div
      className="px-5 py-5 text-white rounded"
      onClick={handleActive}
      style={{ minHeight: 144, cursor: "pointer", backgroundColor: color }}
    >
      <div>{icon}</div>
      <div className="mt-7" style={{ fontWeight: "500" }}>
        {title}
      </div>
      <div>{action}</div>
    </div>
  );
}

export const IntegrationItemWithFooter = ({
  icon,
  secondIcon,
  title,
  action,
  isActive,
  setActive,
}) => {
  const [color, setColor] = useState("#3699FF");

  useEffect(() => {
    isActive ? setColor("#151c47") : setColor("#3699FF");
  }, [isActive]);

  const handleActive = () => {
    setActive(title);
  };
  return (
    <div
      className="px-5 py-5 text-white rounded"
      onClick={handleActive}
      style={{ minHeight: 144, cursor: "pointer", backgroundColor: color }}
    >
      <div className="d-flex">
        <div>{icon}</div>
        <h1 className="ml-5 mt-7">{title}</h1>
      </div>
      <div className="d-flex mt-6 justify-content-between align-items-center">
        <div>{action} </div>
        <div>{secondIcon}</div>
      </div>
    </div>
  );
};
