import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { InputFile } from "../../../ui/components/InputFile";
import File from "../../../ui/structures/File";
import { getLogoUploadUrl, modifyOrganisation } from "./_api";

export const logoMapUrl = {
  "localhost.nonp.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
  "integration-1.portal.crs-nonp.c0.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
};

export const LogoUpload = ({
  organisation_id,
  data = {},
  tenant_id,
  setCardData,
}) => {
  const { request } = useFetch();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [logoKey, setLogoKey] = useState("");

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const saveUrlFieldsValues = (value) => {
    setLogoKey(value);
  };

  const updateFiles = () => "updated files...";

  const submitLogo = () => {
    if (logoKey) {
      request(
        modifyOrganisation,
        tenant_id,
        { logo_image_url: logoKey },
        organisation_id
      )
        .then((data) => {
          if (!data) return;
          setCardData((state) => ({
            ...state,
            logo_image_url: data.logo_image_url,
          }));
          setUploadedFiles([]);
        })
        .finally(() => {
          setLogoKey("");
        });
    }
  };

  useEffect(() => {
    submitLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoKey]);

  return (
    <>
      <div className="bg-white p-10 pb-20 mb-10">
        <h3 className="mb-10">
          <strong>Logo</strong>
        </h3>
        <div className="text-center">
          {data.logo_image_url ? (
            <img
              src={`${logoMapUrl[window.location.hostname]}/${
                data.logo_image_url
              }`}
              alt="Brand Logo"
              className="img-fluid w-25"
            />
          ) : (
            <h4>Logo not found</h4>
          )}
        </div>
      </div>
      <div className="bg-white p-10">
        <InputFile
          className="rounded"
          onDrop={handleUploadFiles}
          description="You can upload only one file at a time"
          title="Upload User Signature"
          maxFilesQuantity={1}
        >
          {uploadedFiles.map((file) => (
            <File
              key={file.lastModified}
              senderId={organisation_id}
              senderType={tenant_id}
              file={file}
              saveUrlFieldsValues={saveUrlFieldsValues}
              updateFiles={updateFiles}
              getUrl={getLogoUploadUrl}
            />
          ))}
        </InputFile>
      </div>
    </>
  );
};
