/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { formatDateWithoutYear } from "../../../ui/helpers/helpers";
import { downloadFile } from "../../modules/AWS-S3/download";
import { useFetch } from "../../../hooks/fetch.hook";
import { CreditServiceMemosForm } from "../../../ui/structures/CreditServiceMemosForm";
import { Container, Button, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import { getCreditServiceMemos, deleteCreditServiceMemo } from "./_api";
import Can from "../../../ui/helpers/Can";

const ScrollableContainer = styled(Container)({
  flex: "1 1 auto",
  minHeight: "1px",
  padding: "0 1.25rem 0 1.75rem",
  height: 354,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    //   outline: "1px solid slategrey",
  },
  scrollbarColor: "rgba(0,0,0,.1) transparent",
  scrollbarWidth: "thin",
  "& .memo-buttons": {
    display: "none",
    transition: "display 0.5s cubic-bezier(0, 1, 0, 1)",
  },
  "& .memo_container:hover .memo-buttons": { display: "flex" },
});

export function CreditServiceMemos({ tenantId }) {
  const displayedMemos = 5;
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(true);
  const [numberOfMemos, setNumberOfMemos] = useState(displayedMemos);
  const [selectedMemoData, setSelectedMemoData] = useState([]);

  function fetchCreditServiceMemos(refreshList = false) {
    setLoading(true);
    request(
      getCreditServiceMemos,
      tenantId,
      refreshList ? numberOfMemos : displayedMemos,
      refreshList ? 0 : numberOfMemos / displayedMemos
    ).then((response) => {
      if (response) {
        const overwriteResponse = response.map((item) => ({
          date: formatDateWithoutYear(new Date(item.last_modified)),
          ...item,
        }));
        if (refreshList) {
          setData(overwriteResponse);
        } else setData((prev) => [...prev, ...overwriteResponse]);
        setIsLoadMore(displayedMemos <= response.length);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchCreditServiceMemos(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  useEffect(() => {
    if (open === false) {
      setIsLoadMore(true);
      fetchCreditServiceMemos(true);
      setSelectedMemoData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setOpen]);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };
  function handleDeleteMessage(id) {
    request(deleteCreditServiceMemo, tenantId, id).then(() => {
      setData(
        data.filter(function(message) {
          return message.id !== id;
        })
      );
    });
  }

  return (
    <>
      <CreditServiceMemosForm
        open={open}
        setOpen={setOpen}
        tenantId={tenantId}
        selectedMemoData={selectedMemoData}
      />
      <div className="card card-custom pr-3">
        {/* Head */}
        <div
          className="card-header border-0"
          style={{ padding: "0 0 0 1.75rem" }}
        >
          <h3 className="card-title font-weight-bolder text-dark">
            Credit Service Memos
          </h3>
          <Can
            perform="memos:add"
            yes={() => (
              <Button
                color="default"
                style={{ margin: "19px 0" }}
                startIcon={<AddIcon />}
                onClick={handleClickOpenDialog}
              >
                Add New
              </Button>
            )}
          />
        </div>
        {/* Body */}
        <ScrollableContainer>
          {data.length > 0 &&
            data.slice(0, numberOfMemos).map((item, i, arr) => {
              const prevDate =
                (arr[i - 1] &&
                  new Date(arr[i - 1].last_modified).getFullYear()) ||
                null;
              const itemDate = new Date(item.last_modified).getFullYear();
              const yearChange = !prevDate || itemDate !== prevDate;
              return (
                <div key={item.id}>
                  {yearChange && (
                    <div
                      className="flex-row mb-6"
                      style={{ fontSize: 14, fontWeight: "600" }}
                    >
                      {itemDate}
                    </div>
                  )}
                  <div
                    className="memo_container d-flex align-items-start"
                    style={{ height: 60, overflow: "hidden" }}
                  >
                    <div className="col-2 text-center">
                      {item.date}

                      <div className="ml-auto memo-buttons pt-2">
                        <IconButton
                          color="secondary"
                          aria-label="edit"
                          style={{ padding: "0 2px" }}
                          onClick={(e) => {
                            setSelectedMemoData(item);
                            handleClickOpenDialog();
                          }}
                        >
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          style={{
                            padding: "0 2px",
                            color: "#e14352",
                          }}
                          onClick={() => handleDeleteMessage(item.id)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    </div>
                    <div className="col-1" style={{ height: "100%" }}>
                      <span
                        style={{
                          display: "block",
                          width: 10,
                          height: 10,
                          borderRadius: "50%",
                          background: "#fff",
                          border: "2px solid #c4c4c4",
                          position: "absolute",
                          left: 9,
                          top: 4,
                        }}
                      ></span>
                      {arr.length - 1 !== i && (
                        <span
                          style={{
                            display: "block",
                            width: 0,
                            height: 40,
                            border: "1px solid #c4c4c4",
                            position: "absolute",
                            top: 18,
                          }}
                        ></span>
                      )}
                    </div>
                    <div className="col-9">
                      {item.type === "link" ? (
                        <a
                          href={item.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {item.title}
                        </a>
                      ) : (
                        <a
                          href="#"
                          onClick={() =>
                            downloadFile(item.attachment.file_name)
                          }
                        >
                          {item.title}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}

          {isLoadMore ? (
            <div className="text-center m-4">
              <button
                className="btn btn-outline-secondary px-10 py-2 btn-sm"
                style={{ minWidth: 225 }}
                onClick={() => {
                  setNumberOfMemos((prev) => prev + displayedMemos);
                  fetchCreditServiceMemos();
                }}
              >
                {loading ? "loading..." : "Load more"}
              </button>
            </div>
          ) : data.length ? (
            <div className="text-center m-4">
              <button
                className="btn btn-outline-secondary px-10 py-2 btn-sm"
                style={{ minWidth: 225 }}
                disabled
              >
                No more message
              </button>
            </div>
          ) : (
            <div className="text-center">
              There is no available Memo to display
            </div>
          )}
        </ScrollableContainer>
      </div>
    </>
  );
}
