import React from "react";
import {
  capitalizeWord,
  renderShareDetailsField,
} from "../../../helpers/helpers";
import { ReactComponent as CancelIcon } from "../static/Cancel.svg";
import { warningStyle, fieldNameStyle } from "../constants";

export function ShareDetails({ data = {}, tradingName, legalName }) {
  const isDetailsField = (field) =>
    [
      "share_number",
      "share_certificate_number",
      "shareholder",
      "held_beneficially",
      "beneficiary",
      "admitted_date",
      "admitted_folio",
      "date_issued",
      "remarks",
      // "billing_entity",
      "issued_folio",
      "date_cancelled",
      "cancelled_folio",
    ].includes(field);

  return (
    <>
      <h3 className="px-10 my-6">
        <strong>
          Share Details for {tradingName} ({legalName})
        </strong>
      </h3>
      {data.date_cancelled && (
        <div className="text-white px-5 py-2" style={warningStyle}>
          <CancelIcon /> <span className="ml-7">This Share is Cancelled</span>
        </div>
      )}
      <div className="px-10">
        <div className="py-5 w-50">
          {Object.keys(data).map((field) => {
            return (
              isDetailsField(field) && (
                <div className="row mb-2" key={field}>
                  <div className="col-4">
                    <p style={fieldNameStyle}>{capitalizeWord(field, "_")}</p>
                  </div>
                  {renderShareDetailsField(data, field)}
                </div>
              )
            );
          })}
        </div>
      </div>
    </>
  );
}
