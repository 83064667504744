import React from "react";
import { makeStyles } from "@material-ui/core";
import { List } from "@material-ui/core";
import { ListItem } from "@material-ui/core";
import { ListItemText } from "@material-ui/core";
import { ListItemAvatar } from "@material-ui/core";
import { Avatar } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import { formatDate, formatFileName, sortByDate } from "../helpers/helpers";

const useStyles = makeStyles({
  arrow: {
    width: "10px",
    height: "10px",
    left: "-5px",
    top: "36%",
    transform: "rotate(45deg)",
  },
  vLine: {
    top: "25px",
    bottom: "25px",
    left: "35px",
    width: "2px",
  },
});

export function Files({ data, files = [], className, onDownload }) {
  const classes = useStyles();

  return (
    <div className={`bg-white position-relative w-100 ${className}`}>
      <div
        className={`position-absolute border-x border-secondary ${classes.vLine}`}
      />
      <List>
        {files.length ? (
          files.sort(sortByDate).map(({ file_name, last_modified, user }) => (
            <ListItem key={file_name}>
              <ListItemAvatar>
                <Avatar className="bg-white border-secondary border-x border-y text-success">
                  <DescriptionIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText className="bg-secondary py-3 px-8 position-relative">
                <div className="d-flex flex-column">
                  <div
                    className={`${classes.arrow} bg-secondary position-absolute`}
                  />
                  <div className="row">
                    <div
                      onClick={() => onDownload(file_name)}
                      className="col-4 text-primary font-weight-bold"
                      style={{ cursor: "pointer" }}
                    >
                      {formatFileName(file_name)}
                    </div>
                    <div className="col-8">{formatDate(last_modified)}</div>
                  </div>
                  <div>Uploaded by {user.name}.</div>
                </div>
              </ListItemText>
            </ListItem>
          ))
        ) : (
          <div className="text-center mt-16 mb-16">
            <h6 className="mb-5">No uploaded Files</h6>
          </div>
        )}
      </List>
    </div>
  );
}
