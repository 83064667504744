/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { ReconciliationActions } from "./ReconciliationActions";
import { createBankTransaction, revertBankTransaction } from "../../_api";
import {
  Check as CheckIcon,
  CallSplitRounded as CallSplitRoundedIcon,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles/";
import { formatAmount, formatDate } from "../../../../../ui/helpers/helpers";
import { Button } from "@material-ui/core";

const useStyles = makeStyles({
  container: {
    minHeight: 100,
    // borderBottom: "1px rgba(0,0,0,0.2) solid",
    backgroundColor: "#F8FAFF",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "0 1px 3px rgba(0,0,0,0.09), 0 1px 2px rgba(0,0,0,0.1)",
    },
  },

  containerClosed: {
    minHeight: 36,
    // borderBottom: "1px rgba(0,0,0,0.2) solid",
    backgroundColor: "#F8FAFF",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    "&:hover": {
      backgroundColor: "#ffffff",
      boxShadow: "0 1px 3px rgba(0,0,0,0.09), 0 1px 2px rgba(0,0,0,0.1)",
    },
  },

  date: {
    display: "inline-block",
    background: "#3783e7",
    color: "#fff",
    // color: "#5E6973",
    // background: "#EEF0F8",
    padding: "1px 5px",
    borderRadius: "0.22rem",
  },

  actionSentLabel: {
    backgroundColor: "rgb(40, 167, 69)",
    color: "#fff",
    padding: "1px 10px",
    borderRadius: "0.22rem",
    marginRight: 20,
  },
  actionRevert: {
    // backgroundColor: "rgb(40, 167, 69)",
    color: "rgb(40, 167, 69)",
    padding: "1px 10px",
    borderRadius: "0.22rem",
    marginRight: 20,
    marginLeft: "auto",
  },

  splitIcon: {
    color: "#fff;",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    cursor: "pointer",

    // "&:hover": {
    //   color: "#2951a7",
    // },
  },

  transactionValue: {
    // color: "#c8d4ed;",
    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
    cursor: "pointer",
    marginTop: "-15px",

    "&:hover": {
      color: "#2951a7",
      "& $splitIcon": {
        color: "#2951a7",
      },
    },
  },
  // "& .MuiTabScrollButton-root": {
  //   background: "black",
  //   "&$disabled": {
  //     opacity: 0,
  //     width: 0,
  //   },
  // },
  // "& .MuiTabScrollButton-root.Mui-disabled": {
  //   background: "black",
  //   // "&$disabled": {
  //   // opacity: 0,
  //   // width: 0,
  //   // },
  // },
});

export function MatchItem({
  data,
  organisationsList,
  TENANT_ID,
  accountsList,
  taxesList,
  handleModalSplitOpen,
}) {
  const classes = useStyles();
  const { request } = useFetch();
  const [selectedAction, setSelectedAction] = useState("Transfer");
  const [validationAction, setValidationAction] = useState();
  const [sentTransaction, setSentTransaction] = useState(false);
  const [sentTransactionType, setSentTransactionType] = useState("");

  useEffect(() => {
    setValidationAction();
  }, [selectedAction, setSelectedAction]);

  const setValidationActionWrapper = (action) => {
    if (!validationAction) {
      // Weird - read this:
      // https://stackoverflow.com/questions/55621212/is-it-possible-to-react-usestate-in-react
      setValidationAction(() => () => action());
    }
  };

  const runValidationAction = () => validationAction();

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    let newData = {
      action: selectedAction.toLowerCase(),
      other_account_id: values.account.id,
    };

    request(createBankTransaction, TENANT_ID, data.id, newData).then(
      (response) => {
        if (response) {
          setSubmitting(false);
        }
      }
    );
    setSentTransaction(true);
  };

  const handleRevert = (setSubmitting) => {
    request(revertBankTransaction, TENANT_ID, data.id).then((response) => {
      if (response) {
        if (response) setSentTransaction(false);
      }
    });
  };

  return (
    <>
      {sentTransaction ? (
        <div className={`row my-3 rounded ${classes.containerClosed}`}>
          <div className="col-12 d-flex align-items-center justify-content-between">
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              {data.transaction_description}
            </div>
            {selectedAction === "Match" && sentTransactionType !== "rule" && (
              <Button className={classes.actionRevert} onClick={handleRevert}>
                Revert Transaction
              </Button>
            )}
            <div className={classes.actionSentLabel}>{selectedAction}</div>
          </div>
        </div>
      ) : (
        <div className={`row my-3 rounded ${classes.container}`}>
          <div
            className="col-5 d-flex justify-content-between"
            style={{ paddingTop: 15 }}
          >
            <div>
              <div className={`mb-4 ${classes.date}`}>
                {formatDate(data.transaction_date)}
              </div>
              <div style={{ fontSize: 14, fontWeight: 500, paddingRight: 20 }}>
                {data.transaction_description}
              </div>
              <div className="pt-2">Type: {data.transaction_type}</div>
            </div>
            <div
              onClick={() => handleModalSplitOpen(data)}
              className={`d-flex align-self-center flex-column align-items-center ${classes.transactionValue}`}
            >
              <CallSplitRoundedIcon
                className={`splitIcon ${classes.splitIcon}`}
              />
              <div
                style={{
                  fontSize: 14,
                  fontWeight: 500,
                  whiteSpace: "nowrap",
                }}
              >
                {formatAmount(Number(data.transaction_value))}
              </div>
            </div>
          </div>
          <div
            className="col-2 text-center align-self-center"
            style={{ paddingTop: 11 }}
          >
            {selectedAction === "Match" ? (
              ""
            ) : (
              <button
                className={`btn btn-outline-primary pl-10 pr-${
                  sentTransaction ? "4" : "10"
                } py-2 btn-sm`}
                style={{ minWidth: 101 }}
                onClick={runValidationAction}
                type="submit"
                disabled={sentTransaction}
              >
                {selectedAction}
                {sentTransaction && (
                  <CheckIcon className="text-success ml-2" fontSize="1.5rem" />
                )}
              </button>
            )}
          </div>
          <div className="col-5">
            <ReconciliationActions
              data={data}
              handleSubmit={handleSubmit}
              setValidationAction={setValidationActionWrapper}
              TENANT_ID={TENANT_ID}
              organisationsList={organisationsList}
              setSelectedAction={setSelectedAction}
              accountsList={accountsList}
              taxesList={taxesList}
              setSentTransaction={setSentTransaction}
              setSentTransactionType={setSentTransactionType}
            />
          </div>
        </div>
      )}
    </>
  );
}
