import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../../hooks/fetch.hook";
import { importBankStatement, getLastUploadedBankDataResult } from "./_api";
import { BrowseFile } from "../../../ui/components/BrowseFile";
import { Select } from "../../../ui/components/Select";
import { Modal } from "../../../ui/components/Modal";
import File from "./File";
import { ReconciliationModul } from "./ReconciliationModul/ReconciliationModul";
import { getOrganisationsListSimple } from "../Organisations/_api";
import AlertBar from "../../../ui/components/AlertBar";
import { getAccountsList } from "../LedgerAccounts/_api";
import { getTaxes } from "../Fees/TaxesModule/_api";
import { IconButton, CircularProgress } from "@material-ui/core/";
import { History as HistoryIcon } from "@material-ui/icons";
import { TransactionHistory } from "./TransactionHistory/TransactionHistory";

export function Receipting() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastFileName, setLastFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateLastUpload, setUpdateLastUpload] = useState(false);
  const [organisationsList, setOrganisationsList] = useState([
    { value: 0, label: "Select Organisation" },
  ]);
  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);
  const [taxesList, setTaxesList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(0);
  const [dataLoading, setDataLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  async function postBankStatementData() {
    setLoading(true);
    setUpdateLastUpload(false);
    request(importBankStatement, TENANT_ID, lastFileName, selectedAccount).then(
      (data) => {
        if (!data) return;
        fetchBankStatementData();
      }
    );
  }

  async function fetchBankStatementData() {
    setLoading(true);
    request(getLastUploadedBankDataResult, TENANT_ID, selectedAccount).then(
      (response) => {
        if (response) {
          setData(response);
        }
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    Promise.all([
      request(getAccountsList, TENANT_ID, ORGANISATION_ID),
      request(getTaxes, TENANT_ID),
    ]).then(([accounts, taxes]) => {
      accounts && setAccountsList([{ value: 0, label: "Select Account" }]);
      accounts &&
        accounts
          .filter(({ status }) => status === "active")
          .forEach((item) => {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
          });
      taxes && setTaxesList(taxes);
    });

    request(getOrganisationsListSimple, TENANT_ID).then((data) => {
      if (data) {
        setOrganisationsList((prevList) => [...prevList, ...data]);
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedAccount) {
      fetchBankStatementData();
    }
    // eslint-disable-next-line
  }, [selectedAccount, setSelectedAccount]);

  useEffect(() => {
    if (lastFileName && updateLastUpload && selectedAccount) {
      postBankStatementData();
    }
    // eslint-disable-next-line
  }, [lastFileName, setLastFileName, updateLastUpload, setUpdateLastUpload]);

  useEffect(() => {
    if (selectedAccount && dataLoading) {
      fetchBankStatementData();
    }
    // eslint-disable-next-line
  }, [selectedAccount, setSelectedAccount, dataLoading, setDataLoading]);

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...acceptedFiles]);
  };
  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => {
    fetchBankStatementData();
    setModalOpen(false);
  };
  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <TransactionHistory TENANT_ID={TENANT_ID} />
        </Modal>
      )}
      <AlertBar message={"This page is under development!"} />
      <div className="row justify-content-center rounded align-content-start h-100 m-0">
        <div className="col-12 bg-white" style={{ height: 161 }}>
          <div className="pt-7 px-10">
            <div className="d-flex justify-content-between">
              <div style={{ minWidth: 240 }}>
                <div className="mb-8 d-flex justify-content-between align-content-start">
                  <h2 className="font-weight-bold">Receipting</h2>
                  <IconButton
                    className="ml-8"
                    onClick={() => handleModalOpen()}
                  >
                    <HistoryIcon />
                  </IconButton>
                </div>
                <div className="w-100 mb-5">
                  <Select
                    value={selectedAccount}
                    // className="bg-white"
                    onChange={(e) => {
                      setSelectedAccount(e.target.value);
                    }}
                    style={{ borderColor: "white" }}
                    options={accountsList && accountsList}
                  />
                </div>
              </div>
              {(selectedAccount && (
                <BrowseFile
                  onDrop={handleUploadFiles}
                  heading="Select Bank Transaction Data File"
                  inputHeight="54px"
                  disabled={!selectedAccount}
                >
                  {uploadedFiles.map((file) => (
                    <File
                      file={file}
                      tenant_id={TENANT_ID}
                      setLastFileName={setLastFileName}
                      updateLastUpload={updateLastUpload}
                      updateLastUploadState={setUpdateLastUpload}
                      key={file.lastModified}
                      account_id={selectedAccount}
                    />
                  ))}
                </BrowseFile>
              )) || (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: "center",
                    fontSize: 16,
                  }}
                >
                  <p>Please select an account to upload a new file</p>
                </div>
              )}
            </div>
          </div>
        </div>

        {loading ? (
          <CircularProgress className="text-warning mt-7" size="2rem" />
        ) : (
          <div className="col-12 pb-7">
            {data.length ? (
              <ReconciliationModul
                data={data}
                setData={setData}
                organisationsList={organisationsList}
                accountsList={accountsList}
                taxesList={taxesList}
                fetchBankStatementData={fetchBankStatementData}
                selectedAccount={selectedAccount}
              />
            ) : (
              ""
            )}
          </div>
        )}
      </div>
    </>
  );
}
