import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { CircularProgress } from "@material-ui/core/";
import {
  getIntegrationByType,
  getMailchimpLists,
  modifyIntegration,
  createIntegration,
  deleteIntegration,
} from "./_api/";

function getStatus(status) {
  switch (status) {
    case "loading":
      return <CircularProgress className="text-warning" size="2rem" />;
    case "needAPI":
      return "Need API Key";
    case "wrongAPI":
      return "Wrong API Key";
    case "fetchAudienceLists":
      return "Fetch Audience Lists...";
    case "selectAudienceList":
      return "Select Audience List";
    case "notSaved":
      return "Not Saved";
    case "disConnected":
      return "Disconnected";
    case "connected":
      return <CheckCircleIcon className="text-success" fontSize="large" />;
    case "failed":
      return <ErrorOutlineIcon className="text-danger" fontSize="large" />;
    default:
      return;
  }
}

export function MailchimpForm() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [apiKey, setApiKey] = useState("");
  const [mailchimpLists, setMailchimpLists] = useState([]);
  const [integrationId, setIntegrationId] = useState("");
  const [listId, setListId] = useState("");
  const [status, setStatus] = useState("");
  const [hasMailchimpIntegration, setHasMailchimpIntegration] = useState(false);

  const initialValues = {
    apiKey: apiKey,
    mailchimpListId: listId,
  };
  const { request } = useFetch();

  useEffect(() => {
    setStatus("loading");
    request(getIntegrationByType, TENANT_ID, "mailchimp").then((response) => {
      if (response) {
        if (response[0]) {
          setHasMailchimpIntegration(true);
          setApiKey(response[0].api_key);
          setListId(response[0].list_id);
          setIntegrationId(response[0].id);
        } else {
          setStatus("needAPI");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID]);

  useEffect(() => {
    if (apiKey && apiKey.length > 36) {
      setMailchimpLists([]);
      setStatus("fetchAudienceLists");
      request(getMailchimpLists, apiKey).then((res) => {
        if (typeof res !== "undefined") {
          res.forEach((item) => {
            setMailchimpLists((prevList) => [
              ...prevList,
              { value: item.id, label: item.name },
            ]);
          });
          if (hasMailchimpIntegration) {
            setStatus("connected");
          } else {
            setStatus("selectAudienceList");
          }
        } else {
          setStatus("wrongAPI");
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiKey, setApiKey]);

  useEffect(() => {
    if (!hasMailchimpIntegration && apiKey && listId) {
      setStatus("notSaved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listId, setListId]);

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to disconnect Mailchimp? This process is not reversible"
    );
    if (confirmation) {
      try {
        deleteIntegration(integrationId).then((res) => {
          if (res.status === 200 || res.status === 204) {
            setApiKey("");
            setListId("");
            setIntegrationId("");
            setMailchimpLists([]);
            setStatus("disConnected");
            setHasMailchimpIntegration(false);
          }
        });
      } catch {}
    }
  };

  const handleOnChange = (e) => {
    if (e.target.name === "apiKey") {
      setApiKey(e.target.value);
    } else if (e.target.name === "mailchimpListId") {
      setListId(e.target.value);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        apiKey: Yup.string()
          .matches(/[0-9a-f]{32}-us[0-9]{1,2}/gm, "Is not in correct format")
          .required(),
        mailchimpListId: Yup.string().required(),
      })}
      onSubmit={(values) => {
        setStatus("loading");
        const data = {
          status: "active",
          tenant_id: TENANT_ID,
          integration_type: "mailchimp",
          api_key: values.apiKey,
          list_id: values.mailchimpListId,
        };
        if (hasMailchimpIntegration) {
          request(modifyIntegration, integrationId, data).then((response) => {
            setStatus("connected");
            setHasMailchimpIntegration(true);
          });
        } else {
          request(createIntegration, data).then((response) => {
            setStatus("connected");
            setIntegrationId(response.id);
            setHasMailchimpIntegration(true);
          });
        }
      }}
    >
      {({ values, handleSubmit }) => (
        <div>
          <div className="row justify-content-between">
            <div className="d-flex flex-column col-6">
              <span className="mb-10 font-weight-bold">Mailchimp API Key</span>
              <MappedInput
                onChange={handleOnChange}
                name="apiKey"
                wrapperClassName="w-100"
              />
            </div>
            <span className="d-flex flex-column mb-n7 col-md">
              <span className="mb-10 font-weight-bold">Audience List</span>

              <MappedSelect
                onChange={handleOnChange}
                name="mailchimpListId"
                options={mailchimpLists}
                defaultValue="select list"
              />
            </span>
            <div className="d-flex flex-column ml-10 col-md text-center">
              <span className="mb-10 text-center font-weight-bold">Status</span>
              <span style={{ lineHeight: "3" }}>{getStatus(status)}</span>
            </div>
          </div>

          <div className="row align-items-end mt-10">
            <span>
              <button
                type="submit"
                className="px-10 mx-5 btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </span>
            <span>
              {hasMailchimpIntegration ? (
                <button
                  className="px-10 mr-5 btn btn-outline-primary"
                  onClick={handleDelete}
                >
                  Disconnect
                </button>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
      )}
    </Formik>
  );
}
