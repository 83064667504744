import config from "react-global-configuration";

export const getAverageWeeklySpend = (tenant_id, organisation_id, num_of_weeks) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/average_weekly_spend?${
      num_of_weeks ? `num_of_weeks=${num_of_weeks}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
