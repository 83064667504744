import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setDiscrepancyData } from "../_redux/actions";
import DataGrid from "../../../../ui/components/DataGrid";
import { onSearch, renderHeader } from "../../../../ui/helpers/helpers";
import { Button } from "@material-ui/core";
import { renderDiscrepancyRow } from "../helpers";
import { Modal } from "../../../../ui/components/Modal";
import { HEADINGS, BUYER_HEADINGS, BILLER_HEADINGS } from "../constants";
import { SearchBar } from "../../../../ui/structures/SearchBar";
import { ConfirmAction } from "../../../../ui/components/ConfirmAction";
import { AdjustDiscrepancy } from "../AdjustDiscrepancy";
import { CreateDiscrepancy } from "../CreateDiscrepancy";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  getDiscrepancies,
  approveDiscrepancy,
  rejectDiscrepancy,
} from "../_api";
import { getOrganisationsListSimple } from "../../Organisations/_api";
import { CustomDatePicker } from "../../../../ui/components/DatePicker";
import {
  formatDate,
  formatDateForBackend,
} from "../../../../ui/helpers/helpers";

export function ActiveList() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.discrepancy.discrepancyList);
  const user = useSelector(({ auth: { user } }) => user) || {};
  const TENANT_ID = user.selectedTenant;
  const userCategory = user.userCategory;

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [modalContent, setModalContent] = useState("");
  const [organisationList, setOrganisationList] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [startInvoiceDate, setStartInvoiceDate] = useState("");
  const [endInvoiceDate, setEndInvoiceDate] = useState("");
  const [openDateFilters, setOpenDateFilters] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  function fetchDiscrepancyData() {
    setLoading(true);
    request(
      getDiscrepancies,
      TENANT_ID,
      `${
        startDate ? "&gt-create_date=" + formatDateForBackend(startDate) : ""
      }${endDate ? "&lte-create_date=" + formatDateForBackend(endDate) : ""}${
        startInvoiceDate
          ? "&gt-invoice.invoice_date=" + formatDateForBackend(startInvoiceDate)
          : ""
      }${
        endInvoiceDate
          ? "&lte-invoice.invoice_date=" + formatDateForBackend(endInvoiceDate)
          : ""
      }`
    )
      .then((data) => {
        if (!data) return;

        const modifiedDiscrepencies = data.map((discrapency) => ({
          ...discrapency,
          biller_name: organisationList.filter(
            ({ value }) => discrapency.biller_id === value
          )[0].label,
          buyer_name: organisationList.filter(
            ({ value }) => discrapency.buyer_id === value
          )[0].label,
        }));
        dispatch(setDiscrepancyData(modifiedDiscrepencies));
        setInitialData(modifiedDiscrepencies);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (startDate || endDate || startInvoiceDate || endInvoiceDate) {
      fetchDiscrepancyData();
    }
    // eslint-disable-next-line
  }, [
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    startInvoiceDate,
    setStartInvoiceDate,
    endInvoiceDate,
    setEndInvoiceDate,
  ]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      request(getDiscrepancies, TENANT_ID),
      request(getOrganisationsListSimple, TENANT_ID),
    ]).then(([discrepencies, organisations]) => {
      if (!discrepencies && !organisations) return;

      const modifiedDiscrepencies = discrepencies.map((discrapency) => ({
        ...discrapency,
        biller_name: organisations.filter(
          ({ value }) => discrapency.biller_id === value
        )[0].label,
        buyer_name: organisations.filter(
          ({ value }) => discrapency.buyer_id === value
        )[0].label,
      }));
      setOrganisationList(organisations);
      dispatch(setDiscrepancyData(modifiedDiscrepencies));
      setInitialData(modifiedDiscrepencies);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    onSearch(
      search,
      isRemoveKey ? initialData : data,
      dispatch,
      setDiscrepancyData,
      HEADINGS
    );
  };

  const resetSearch = () => {
    setLoading(true);
    dispatch(setDiscrepancyData(data));
    setLoading(false);
  };

  const handleReject = (id) => {
    setComponentId(id);
    setModalContent("reject");
    setModalOpen(true);
  };

  const handleApprove = (id) => {
    setComponentId(id);
    setModalContent("approve");
    setModalOpen(true);
  };

  const handleAdjust = (id) => {
    setComponentId(id);
    setModalContent("adjust");
    setModalOpen(true);
  };

  const handleNew = () => {
    setModalContent("new");
    setModalOpen(true);
  };

  const handleSubmitReject = (id, setSubmitting) => {
    request(rejectDiscrepancy, {}, TENANT_ID, id)
      .then((data) => {
        data && fetchDiscrepancyData();
      })
      .finally(() => setSubmitting(false));
    handleModalClose();
  };

  const handleSubmitApprove = (id, setSubmitting) => {
    request(approveDiscrepancy, {}, TENANT_ID, id)
      .then((data) => {
        data && fetchDiscrepancyData();
      })
      .finally(() => setSubmitting(false));
    handleModalClose();
  };

  const KEY_MAP = {
    "Buyer Name": data.map((item) => item.buyer_name),
    "Biller Name": data.map((item) => item.biller_name),
    Status: data.map((item) => item.status),
  };

  const BILLER_MAP = {
    "Buyer Name": data.map((item) => item.buyer_name),
    Status: data.map((item) => item.status),
  };

  const BUYER_MAP = {
    "Biller Name": data.map((item) => item.biller_name),
    Status: data.map((item) => item.status),
  };

  const searchMap = {
    Biller: BILLER_MAP,
    Buyer: BUYER_MAP,
    BOH: KEY_MAP,
  };

  const headingsMap = {
    Biller: BILLER_HEADINGS,
    Buyer: BUYER_HEADINGS,
    BOH: HEADINGS,
  };

  const modalContentMap = {
    reject: (
      <ConfirmAction
        handleClose={handleModalClose}
        confirmLabel="Reject"
        warningMessage={
          <>
            <div className="mb-4 text-danger">WARNING!</div>
            <div>Are you sure you want to reject discrepancy?</div>
          </>
        }
        id={componentId}
        handleSubmit={handleSubmitReject}
      />
    ),
    approve: (
      <ConfirmAction
        handleClose={handleModalClose}
        confirmLabel="Approve"
        warningMessage={
          <>
            <div>Are you sure you want to approve discrepancy?</div>
          </>
        }
        id={componentId}
        handleSubmit={handleSubmitApprove}
      />
    ),
    adjust: (
      <AdjustDiscrepancy
        handleClose={handleModalClose}
        data={data.find(({ id }) => id === componentId)}
        fetchDiscrepancyData={fetchDiscrepancyData}
        organisationList={organisationList}
      />
    ),
    new: (
      <CreateDiscrepancy
        handleClose={handleModalClose}
        fetchDiscrepancyData={fetchDiscrepancyData}
        organisationList={organisationList}
      />
    ),
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={
            modalContent === "reject" || modalContent === "approve"
              ? "sm"
              : "md"
          }
        >
          {modalContentMap[modalContent]}
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <h3 className="text-dark">Discrepancy Management</h3>
              <div>
                <button
                  className="px-15 mr-5 btn btn-primary"
                  onClick={handleNew}
                >
                  New
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={searchMap[userCategory]}
                  placeholder="Filter..."
                />
              </div>
              <div className="col-4">
                <Button
                  className="mt-2"
                  onClick={() => setOpenDateFilters(!openDateFilters)}
                >
                  Set date filter
                </Button>
              </div>
            </div>
            {openDateFilters ? (
              <div className="row">
                <div className="col-4 pt-1">
                  <div className="d-flex align-items-center">
                    <div className="col-5">Discrepancy Date:</div>
                    <CustomDatePicker
                      selected={(startDate && new Date(startDate)) || null}
                      className="border border-secnodary"
                      placeholderText={formatDate(new Date(Date.now()))}
                      onChange={(date) => setStartDate(date)}
                      placement="top-end"
                    />
                    <div className="col-1 text-center">To</div>
                    <CustomDatePicker
                      selected={(endDate && new Date(endDate)) || null}
                      className="border border-secnodary"
                      placeholderText={formatDate(new Date(Date.now()))}
                      onChange={(date) => setEndDate(date)}
                      placement="top-end"
                    />
                  </div>
                </div>
                <div className="col-4 pt-1">
                  <div className="d-flex align-items-center">
                    <div className="col-5 text-center">Invoice Date:</div>
                    <CustomDatePicker
                      selected={
                        (startInvoiceDate && new Date(startInvoiceDate)) || null
                      }
                      className="border border-secnodary"
                      placeholderText={formatDate(new Date(Date.now()))}
                      onChange={(date) => setStartInvoiceDate(date)}
                      placement="top-end"
                    />
                    <div className="col-1 text-center">To</div>
                    <CustomDatePicker
                      selected={
                        (endInvoiceDate && new Date(endInvoiceDate)) || null
                      }
                      className="border border-secnodary"
                      placeholderText={formatDate(new Date(Date.now()))}
                      onChange={(date) => setEndInvoiceDate(date)}
                      placement="top-end"
                    />
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <DataGrid
              data={data}
              headings={headingsMap[userCategory]}
              renderHeader={renderHeader}
              renderRow={renderDiscrepancyRow}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              isUserList
              isRejectable
              approvable
              handleApprove={handleApprove}
              handleDelete={handleReject}
              editIsLink={false}
              editable
              handleEdit={handleAdjust}
              isAdjustView
            />
          </div>
        </div>
      </div>
    </>
  );
}
