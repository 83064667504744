/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import {
  formatAmount,
  formatDate,
} from "../../../../../../../ui/helpers/helpers";
import { DepositMatchFormSearchInvoices } from "./DepositMatchFormSearchInvoices";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "rgb(40, 167, 69) !important",
      color: "#ffffff !important",
    },
  },
});

export function DepositMatchForm({
  transaction_data,
  createMatchByTransactionId,
  TENANT_ID,
}) {
  const classes = useStyles();
  const [selectedDesposits, setSelectedDeposits] = useState([]);

  // const handleRevert = (transaction_id) => {
  //   request(revertBankTransaction, TENANT_ID, transaction_id).then(
  //     (response) => {
  //       if (response) {
  //         handleAfterMatching("invoices");
  //       }
  //     }
  //   );
  // };

  return (
    <>
      <h3 className="text-center mb-10">Match with a Deposit</h3>
      <div
        className="my-10 d-flex"
        style={{
          padding: "15px 12px",
          borderRadius: 7,
          border: "1px solid #f59220",
        }}
      >
        <div className="mr-10" style={{ minWidth: 170 }}>
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>Bank :</span>
            {transaction_data?.bank_name}
          </div>
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>
              Transaction Value :
            </span>

            {formatAmount(transaction_data?.transaction_value)}
          </div>
        </div>
        <div className="mr-10" style={{ minWidth: 130 }}>
          <span style={{ paddingRight: 12, fontWeight: 600 }}>Date :</span>
          {formatDate(transaction_data?.transaction_date)}
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>Type :</span>
            {transaction_data?.transaction_type}
          </div>
        </div>
        <div>
          <span style={{ paddingRight: 12, fontWeight: 600 }}>
            Description :
          </span>
          {transaction_data?.transaction_description}
        </div>
      </div>

      <div className="mb-2" style={{ fontWeight: 600 }}>
        Find deposits
      </div>
      <DepositMatchFormSearchInvoices
        TENANT_ID={TENANT_ID}
        transaction_data={transaction_data}
        setSelectedDeposits={setSelectedDeposits}
        createMatchByTransactionId={createMatchByTransactionId}
      />
    </>
  );
}
