import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAccountById, modifyAccount } from "./_api";
import LedgerAccountForm from "../../../ui/structures/LedgerAccountForm";
import { useSelector } from "react-redux";

export function Card({ match, location, history }) {
  const accountId = match.params.id;

  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;
  const [cardData, setCardData] = useState({});
  const { request } = useFetch();

  useEffect(() => {
    request(getAccountById, TENANT_ID, ORGANISATION_ID, accountId).then(
      (response) => {
        setCardData(response);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="border-bottom border-light p-7">
            <h4 className="mb-0">View / Edit Account</h4>
          </div>

          <LedgerAccountForm
            data={cardData}
            tenant_id={TENANT_ID}
            onSubmit={modifyAccount}
            setData={setCardData}
            default_organisation_id={ORGANISATION_ID}
          />
        </div>
      </div>
    </div>
  );
}
