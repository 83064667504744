import React, { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { InputFile } from "../../../ui/components/InputFile";
import File from "../../../ui/structures/File";
import { getLogoUploadUrl, modifyCurrentUser } from "./_api";

export const logoMapUrl = {
  "localhost.nonp.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
  "integration-1.portal.crs-nonp.c0.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
};

export const SignatureUpload = ({ signature, setSignature, user_id }) => {
  const { request } = useFetch();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [logoKey, setLogoKey] = useState("");

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const saveUrlFieldsValues = (value) => {
    setLogoKey(value);
  };

  const updateFiles = () => "updated files...";

  const submitLogo = () => {
    if (logoKey) {
      request(modifyCurrentUser, { signature_image_url: logoKey }, user_id)
        .then((data) => {
          if (!data) return;
          setSignature(data.signature_image_url);
          setUploadedFiles([]);
        })
        .finally(() => {
          setLogoKey("");
        });
    }
  };

  useEffect(() => {
    submitLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoKey]);

  return (
    <>
      <div className="bg-white p-10 pb-20">
        <h3 className="mb-10">
          <strong>Signature</strong>
        </h3>
        <div className="text-center">
          {signature ? (
            <img
              src={`${logoMapUrl[window.location.hostname]}/${signature}`}
              alt="Brand Logo"
              className="img-fluid w-25"
            />
          ) : (
            <h4>Signature not found</h4>
          )}
        </div>
      </div>
      <div className="bg-white p-10">
        <InputFile
          className="rounded"
          onDrop={handleUploadFiles}
          description="Upload User Signature"
          maxFilesQuantity={1}
        >
          {uploadedFiles.map((file) => (
            <File
              key={file.lastModified}
              senderId={user_id}
              senderType={"signature"}
              file={file}
              saveUrlFieldsValues={saveUrlFieldsValues}
              updateFiles={updateFiles}
              getUrl={getLogoUploadUrl}
            />
          ))}
        </InputFile>
      </div>
    </>
  );
};
