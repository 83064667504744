export const invoicesTypes = {
  SET_DATA: "INVOICES/SET_DATA",
  UPDATE_DATA: "INVOICES/UPDATE_DATA",
  UPDATE_INVOICE: "INVOICES/UPDATE_INVOICE",
};

export function setInvoicesList(data) {
  return {
    type: invoicesTypes.SET_DATA,
    payload: data,
  };
}

export function updateInvoice(data) {
  return {
    type: invoicesTypes.UPDATE_INVOICE,
    payload: data,
  };
}

export function updatenvoicesList(data) {
  return {
    type: invoicesTypes.UPDATE_DATA,
    payload: data,
  };
}
