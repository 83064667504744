import React from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { MappedSelect } from "../../../../../../../ui/components/Select";
import { MappedInput } from "../../../../../../../ui/components/Input";
import { useFetch } from "../../../../../../../hooks/fetch.hook";
import { createRule, modifyRule } from "../../../../_api";
import { NewRuleSchema } from "../../../../../../../helpers/schemas";
import { Autocomplete } from "../../../../../../../ui/components/Autocomplete";
import { AccountSelect } from "./AccountSelect";
import { FilterValueField } from "./FilterValueField";
import { formatDateToUTC } from "../../../../../../../ui/helpers/helpers";
import { fieldNames, operatorFIeldMap } from "./constants";

const actions = [{ label: "Create Transaction", value: "create_transaction" }];

export const NewRuleForm = ({ organisationsList = [], onClose, rule_data }) => {
  const { request } = useFetch();

  const initialValues = {
    action: rule_data?.action || "",
    organisation_id: rule_data?.account?.organisation_id || "",
    account_id: rule_data?.account_id || "",
    field_name: rule_data?.field_name || "",
    operator: rule_data?.operator || "",
    field_value: rule_data?.field_value || "",
    field_value_2: rule_data?.field_value_2 || "",
    field_value_3: rule_data?.field_value_3 || "",
    name: rule_data?.name || "",
  };

  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const handleSubmit = (values, { setSubmitting }) => {
    let payload;
    if (values.fiield_value instanceof Date) {
      payload = {
        ...values,
        tenant_id: TENANT_ID,
        field_value: [
          formatDateToUTC(values.field_value),
          formatDateToUTC(values.field_value_2),
          formatDateToUTC(values.field_value_3),
        ]
          .filter(Boolean)
          .join("#/#"),
      };
    } else {
      payload = {
        ...values,
        tenant_id: TENANT_ID,
        field_value: [
          values.field_value,
          values.field_value_2,
          values.field_value_3,
        ]
          .filter(Boolean)
          .join("#/#"),
      };
    }
    delete payload.field_value_2;
    delete payload.field_value_3;
    if (rule_data?.id) {
      request(modifyRule, payload, rule_data?.id, TENANT_ID)
        .then((data) => {
          data && onClose();
        })
        .finally(() => setSubmitting(false));
    } else {
      request(createRule, payload, TENANT_ID)
        .then((data) => {
          data && onClose();
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={NewRuleSchema}
        enableReinitialize
      >
        {({ handleSubmit, values, resetForm, isSubmitting, setFieldValue }) => (
          <>
            <div className="p-10">
              <h3 className="mb-5 text-center">Add New Rule</h3>
              <div className="mb-3 w-50">
                <MappedInput
                  name="name"
                  wrapperClassName="w-100 mb-5"
                  label="Rule Name"
                />
              </div>
              <hr />
              <h4 className="py-2">Rule Condition</h4>
              <div className="d-flex w-100 align-items-center mb-3 justify-content-between">
                <div style={{ width: "27%", minHeight: "100px" }}>
                  <MappedSelect
                    name="field_name"
                    options={fieldNames}
                    label="Field Name"
                  />
                </div>
                <div style={{ width: "27%", minHeight: "100px" }}>
                  <MappedSelect
                    name="operator"
                    label="Operator"
                    options={(operatorFIeldMap[values.field_name] || []).map(
                      (item) => ({
                        value: item,
                        label: item,
                      })
                    )}
                  />
                </div>
                <FilterValueField />
              </div>
              <hr />
              <div>
                <h4 className="py-2">Action</h4>
                <div className="mb-3 w-50">
                  <MappedSelect
                    name="action"
                    options={[
                      {
                        label: "Create Transaction",
                        value: "create_transaction",
                      },
                    ]}
                    label="Action"
                  />
                </div>
                <div className="mb-5 w-50">
                  <div className="mb-2">Organisation</div>
                  <Autocomplete
                    name="organisation_id"
                    placeholder=""
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    defaultValue={values.organisation_id}
                    defaultField="value"
                    label="label"
                  />
                </div>
                <AccountSelect tenantID={TENANT_ID} />
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9"
                >
                  Cancel
                </Button>
                <Button
                  onClick={resetForm}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 ml-5"
                >
                  Reset
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9 ml-5"
                  data-testid="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
