import React from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { invoiceLineDetailsSchema } from "../../../helpers/schemas";
import { MappedTextArea } from "../../../ui/components/TextArea";
import { useFetch } from "../../../hooks/fetch.hook";
import { createDiscrepancy, adjustDiscrepancy } from "./_api";

const reasonOptions = [
  { label: "Price", value: "Price" },
  { label: "Quantity", value: "Quantity" },
  { label: "Quality", value: "Quality" },
  { label: "Returned", value: "Returned" },
  { label: "Incorrect Buyer", value: "Incorrect Buyer" },
  { label: "Other", value: "other" },
];

export const InvoiceLineDetails = ({
  data = {},
  handleClose,
  fetchDiscrepancyData,
  mode = "create",
  discrepancy_id = "",
}) => {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const {
    description,
    unit_price,
    quantity,
    total_value,
    discrepancy_note = "",
    discrepancy_reason = "",
    corrected_quantity,
    corrected_unit_price,
    corrected_total_value,
  } = data;

  const initialValues = {
    corrected_unit_price:
      mode === "adjust" ? corrected_unit_price || "" : unit_price || "",
    corrected_quantity:
      mode === "adjust" ? corrected_quantity || "" : quantity || "",
    total_value:
      mode === "adjust" ? corrected_total_value || "" : total_value || "",
    discrepancy_reason:
      discrepancy_reason
        .replace("{", "")
        .replace("}", "")
        .split(",") || [],
    other_reason: "",
    discrepancy_note: discrepancy_note || "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      tenant_id: TENANT_ID,
      invoice_line_id: data?.id,
      ...values,
    };
    request(
      mode === "create" ? createDiscrepancy : adjustDiscrepancy,
      TENANT_ID,
      payload,
      discrepancy_id
    )
      .then((data) => {
        data && fetchDiscrepancyData() && handleClose();
      })
      .finally(() => setSubmitting(false));
    handleClose();
  };

  const calculateDifferenct = (newTotal, oldTotal) => {
    if (newTotal > oldTotal) return newTotal - oldTotal;
    else return oldTotal - newTotal;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={invoiceLineDetailsSchema}
      enableReinitialize
    >
      {({ values, handleSubmit }) => (
        <div className="mb-5 p-5 w-100 border border-primary rounded">
          <h5 className="mb-10 ml-5">{description}</h5>
          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Reason</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedSelect
                name="discrepancy_reason"
                options={reasonOptions}
                multiple
              />
            </div>
          </div>
          {values.discrepancy_reason.includes("other") && (
            <div className="row mb-5">
              <div className="col-2">
                <p className="text-right text-muted">Your Reason</p>
              </div>
              <div className="col-8 mr-auto">
                <MappedInput name="other_reason" wrapperClassName="w-100" />
              </div>
            </div>
          )}

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Price</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput
                name="corrected_unit_price"
                wrapperClassName="w-100"
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Corrected quantity</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput name="corrected_quantity" wrapperClassName="w-100" />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Total</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput
                name="total_value"
                wrapperClassName="w-100"
                disabled
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Note</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedTextArea name="discrepancy_note" rows={6} />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">New Total</p>
            </div>
            <div className="col-8 mr-auto">
              {values.corrected_unit_price * values.corrected_quantity}
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2">
              <p className="text-right text-muted">Difference</p>
            </div>
            <div className="col-8 mr-auto">
              {calculateDifferenct(
                values.corrected_unit_price * values.corrected_quantity,
                values.total_value
              )}
            </div>
          </div>
          <div className="mt-10 d-flex justify-content-end">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
