import { organisationsTypes } from "./actions";

const initialOrganisationsState = {
  organisationsList: [],
  users: [],
  files: [],
};

export function organisationsReducer(
  state = initialOrganisationsState,
  actions
) {
  const { type, payload, organisationId, index } = actions;

  switch (type) {
    case organisationsTypes.SET_DATA:
      return {
        ...state,
        organisationsList: payload,
      };

    case organisationsTypes.SET_ORGANISATION_DATA:
      return {
        ...state,
        ...payload,
      };

    case organisationsTypes.SET_TAGS:
      return {
        ...state,
        organisationsList: state.organisationsList.map((organisation) => {
          if (organisation.id === +organisationId) {
            return {
              ...organisation,
              tags: payload,
            };
          }
          return organisation;
        }),
      };

    case organisationsTypes.ARCHIVE_DATA:
      return {
        ...state,
        organisationsList: [
          ...state.organisationsList.filter(({ id }) => id !== payload),
        ],
      };

    case organisationsTypes.UNARCHIVE_DATA:
      return {
        ...state,
        organisationsList: [
          ...state.organisationsList.slice(0, index),
          payload,
          ...state.organisationsList.slice(index + 1),
        ],
      };

    case organisationsTypes.SET_COMMENTS:
      return {
        ...state,
        organisationsList: state.organisationsList.map((organisation) => {
          if (organisation.id === +organisationId) {
            return {
              ...organisation,
              comments: payload,
            };
          }
          return organisation;
        }),
      };

    case organisationsTypes.ADD_COMMENT:
      return {
        ...state,
        organisationsList: state.organisationsList.map((organisation) => {
          if (organisation.id === +organisationId) {
            return {
              ...organisation,
              comments: [...organisation.comments, payload],
            };
          }
          return organisation;
        }),
      };

    case organisationsTypes.SET_ARCHIVED_LIST:
      return {
        ...state,
        organisationsList: [...state.organisationsList, ...payload],
      };

    default:
      return state;
  }
}
