import { contactsTypes } from "./actions";

const initialContactsState = {
  contactsList: [],
  showArchived: false,
};

export function contactsReducer(state = initialContactsState, actions) {
  const { type, payload } = actions;

  const index = state.contactsList.findIndex(
    (item) => (item || {}).id === (payload || {}).id
  );

  switch (type) {
    case contactsTypes.SET_DATA:
      return {
        ...state,
        contactsList: [...payload],
      };
    case contactsTypes.UPDATE_DATA:
      return {
        ...state,
        contactsList: [...state.contactsList, ...payload],
      };
    case contactsTypes.ARCHIVE_DATA:
      return {
        ...state,
        contactsList: [
          ...state.contactsList.filter(({ id }) => id !== payload),
        ],
      };
    case contactsTypes.UNARCHIVE_DATA:
      return {
        ...state,
        contactsList: [
          ...state.contactsList.slice(0, index),
          payload,
          ...state.contactsList.slice(index + 1),
        ],
      };
    case contactsTypes.UPDATE_CONTACT:
      // const index = state.contactsList.findIndex(
      //   (item) => item.id === payload.id
      // );
      const myState = [...state.contactsList];
      myState.splice(index, 1, payload);

      return {
        ...state,
        contactsList: [...myState],
      };
    case contactsTypes.TOGGLE_SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: !state.showArchived,
      };
    default:
      return state;
  }
}
