import { depositTypes } from "./actions";

const initialState = {
  depositList: [],
};

export function depositReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case depositTypes.SET_DATA:
      return {
        ...state,
        depositList: payload,
      };

    case depositTypes.UPDATE_DATA:
      const index = state.depositList.findIndex(
        (item) => item.id === payload.id
      );
      const myState = [...state.depositList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        depositList: [...myState],
      };

    default:
      return state;
  }
}
