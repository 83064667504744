import config from "react-global-configuration";

export const getAccountsList = (tenant_id, organisation_id, query) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts?${query ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getArchivedAccountsList = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts?status=archived`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getAccountById = (tenant_id, organisation_id, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyAccount = (tenant_id, organisation_id, data, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
  );

export const deleteAccount = (tenant_id, organisation_id, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const verifyBSB = (integration_id, bsb_number) =>
  fetch(
    `${config.get("ApiUrl").Rest}/integrations/${integration_id}/verify-bsb`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ bsb: bsb_number }),
    }
  );

export const addAccount = (tenant_id, organisation_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }
  );

export const getAccountBalance = (
  tenant_id,
  organisation_id,
  account_id,
  within_days,
  total_only
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}/due-for-payment-invoices${(within_days ||
      total_only) &&
      "?"}${within_days ? "&within_days=" + within_days : ""}${
      total_only ? "&total_only=" + total_only : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
