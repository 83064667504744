/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Autocomplete } from "@material-ui/lab";
import { TextField, withStyles } from "@material-ui/core";
import { useFetch } from "../../../../../../hooks/fetch.hook";
import { getAccountsList } from "../../../../LedgerAccounts/_api";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
      },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
    },
  },
})(TextField);

export function ReconciliationCreateForm({
  selectedItem = [],
  organisationsList = [],
  TENANT_ID,
  handleSubmit,
  setValidationAction,
}) {
  const { request } = useFetch();

  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const organisation = organisationsList.find(
    (org) => org.value === ORGANISATION_ID
  );

  const { account, tax } = selectedItem;
  const [organisationId, setOrganisationId] = useState(ORGANISATION_ID);
  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);

  const initialValues = {
    organisation: organisation || "",
    account: account || "",
    tax: tax || "Tax",
  };

  const organisationsOptions = organisationsList.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  function fetchAccountsList() {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        data
          .filter(({ status }) => status === "active")
          .forEach((item) => {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
          });
      }
    });
  }

  useEffect(() => {
    if (organisationId) {
      setAccountsList([{ value: 0, label: "Select Account" }]);
      fetchAccountsList();
    }
    // eslint-disable-next-line
  }, [organisationId, setOrganisationId]);

  // const taxOptions = [
  //   { value: "Tax", label: "Tax" },
  //   ...taxesList.map(({ name, id }) => ({
  //     value: id,
  //     label: name,
  //   })),
  // ];

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          organisation: Yup.string().required("Organisation required"),
          account: Yup.string().required("Account required"),
        })}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <div className="rounded">
            {setValidationAction(props.handleSubmit)}
            <div className="row">
              <div className="col-12">
                <div className="row mt-3">
                  <div className="col-6 mr-auto">
                    <Autocomplete
                      id="grouped-demo"
                      className="w-100"
                      size="small"
                      // name="organisation"
                      // setValue={props.setFieldValue}
                      value={organisation}
                      onChange={(event, newValue) => {
                        setOrganisationId(newValue);
                        props.setFieldValue("organisation", newValue);
                      }}
                      options={organisationsOptions.sort(
                        (a, b) => -b.label.localeCompare(a.firstLetter)
                      )}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      groupBy={(option) => option.firstLetter}
                      getOptionLabel={(option) => option.label}
                      disabled={true}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          label="Organisation"
                          name="organisation"
                          variant="outlined"
                          value={props.values.organisation}
                        />
                      )}
                    />
                  </div>

                  <div className="col-6 mr-auto">
                    <Autocomplete
                      id="grouped-demo"
                      className="w-100"
                      value={account}
                      onChange={(event, newValue) => {
                        props.setFieldValue("account", newValue);
                      }}
                      size="small"
                      options={accountsList}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      disabled={!organisationId || props.isSubmitting}
                      renderInput={(params) => (
                        <CssTextField
                          {...params}
                          label="Accounts"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>

                  {/* <div className="col-4 mr-auto">
                    {props.values.account && (
                      <div className="row pb-2">
                        <div className="col-12 mr-auto">
                          <Autocomplete
                            id="grouped-demo"
                            className="w-100"
                            value={account}
                            onChange={(event, newValue) => {
                              props.setFieldValue("account", newValue);
                            }}
                            size="small"
                            options={accountsList}
                            getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) =>
                              option.value === value.value
                            }
                            disabled={!organisationId || props.isSubmitting}
                            renderInput={(params) => (
                              <CssTextField
                                {...params}
                                label="Accounts"
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                    )}
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
