import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormikContext, Formik, Form } from "formik";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { Select } from "../../../ui/components/Select";
import { formatDate } from "../../../ui/helpers/helpers";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { useFetch } from "../../../hooks/fetch.hook";
import { getLedgerAccountTransactionsAutocomplete } from "../Transactions/_api";

import { handleSearch, objectCheck } from "./helpers";
import { uniq } from "lodash";

const AutoSubmitToken = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    if (values.from || values.to) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

export const TRANSACTION_SEARCH_HELPER = [
  ["transaction_id", "Transaction ID"],
  ["transaction_value", "Amount"],
  ["transaction_type", "Type"],
  ["created_at", "Date"],
];

export const transactionSearchMap = {
  "Transaction ID": "transaction_id",
  Amount: "transaction_value",
  Type: "transaction_type",
  Date: "created_at",
};

export function ReportsForm({
  searchDates = {},
  setSearchDates,
  account,
  accountsList = [],
  setAccount,
  resetSearch,
  setSearch,
  setPage,
  search,
  TENANT_ID,
  ORGANISATION_ID,
  pageSize,
  loading,
}) {
  const { request } = useFetch();
  const { from = "", to = "" } = searchDates;

  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  function fetchTransactionsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getLedgerAccountTransactionsAutocomplete,
      TENANT_ID,
      ORGANISATION_ID,
      account,
      searchDates.from && searchDates.from.toISOString(),
      searchDates.to && searchDates.to.toISOString(),
      searchFields,
      pageSize,
      autocompleteField,
      autocompleteValue
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  }

  const initialValues = {
    from: from || "",
    to: to || "",
    account: account || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      // enableReinitialize
      validationSchema={Yup.object({
        from: Yup.date().required("From is a required field"),
        to: Yup.date()
          .required()
          .when(
            "from",
            (from, schema) =>
              from && schema.min(from, "Can't be earlier than start date")
          ),
      })}
      onSubmit={(values) => setSearchDates(values)}
    >
      {() => (
        <Form>
          <div className="row align-items-center col-12">
            <div className="col-2">
              <MappedDatePicker
                name="from"
                className="border border-secnodary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-1">
              <div className="text-center text-muted">to</div>
            </div>
            <div className="col-2">
              <MappedDatePicker
                name="to"
                className="border border-secnodary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-2">
              <Select
                name="account"
                onChange={(e) => setAccount(e.target.value)}
                value={account}
                options={accountsList}
                defaultValue="null"
                disabled={loading}
              />
            </div>
            <div className="col-5 pl-15 pt-5">
              {account ? (
                <ServerSearchBar
                  className="mb-5"
                  onSearch={(data) =>
                    handleSearch(data, transactionSearchMap, setSearch, setPage)
                  }
                  keyMap={TRANSACTION_SEARCH_HELPER}
                  loading={autocompleteLoading}
                  currentSearchList={autocompleteData}
                  fetchAutocompleteFunction={fetchTransactionsAutocomplete}
                  placeholder="Search Transactions..."
                  clearSearch={resetSearch}
                  searchMap={transactionSearchMap}
                  searchFields={search}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <AutoSubmitToken />
        </Form>
      )}
    </Formik>
  );
}
