export const modifyUserObject = (item = {}) => {
  return {
    ...item,
    email: item.user_info.email || "",
    name: item.user_info.name || item.name || "",
    phone: item.user_info.phone_number || "",
  };
};

export const modifyUsersData = (data = []) => {
  return data.map((item) => modifyUserObject(item));
};
