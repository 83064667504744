import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrganisations } from "./_redux/selectors";
import { HEADINGS, searchMap, SEARCH_HELPER } from "./constans";
import { Link } from "react-router-dom";
import { downloadFile } from "../AWS-S3";
import { useFetch } from "../../../hooks/fetch.hook";
import { renderHeaderWithSorting, renderOrganisationRow } from "./helpers";
import { Print as PrintIcon, Input as InputIcon } from "@material-ui/icons";
import { AutocompleteSearchBar } from "../../../ui/structures/AutocompleteSearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import ToolTip from "../../../ui/components/Tooltip";
import Can from "../../../ui/helpers/Can";
import {
  setOrganisationsList,
  archiveData,
  unArchiveData,
} from "./_redux/actions";
import {
  FormControlLabel,
  CircularProgress,
  Checkbox,
} from "@material-ui/core";
import {
  getOrganisationsListWithParams,
  getOrganisationsListAutocomplete,
  exportOrganisationsByIds,
  exportOrganisationById,
  modifyOrganisation,
  deleteOrganisation,
  getTagsList,
  getOrganisationsAmount,
  getTagsAutocomplete,
  getOrganisationsListSimple,
} from "./_api";
import { handleSearch, objectCheck } from "../../../ui/helpers/helpers";
import { uniq } from "lodash";

export function List() {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  // eslint-disable-next-line
  const data = useSelector(getOrganisations) || [];
  const initialRender = useRef(true);
  const [loading, setLoading] = useState(true);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [organisations, setOrganisations] = useState([]);
  const [organisationCycleTags, setOrganisationCycleTags] = useState([]);
  const [selected, setSelected] = useState({});
  const [pagesize, setPagesize] = useState(25);
  const [page, setPage] = useState(0);

  // For building the link
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [status, setStatus] = useState("");
  const [direction, setDirection] = useState("");
  const [archived, setArchived] = useState(true);
  const [printLoading, setPringLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  // For autocomplete
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [simpleOrganisations, setSimpleOrganisations] = useState([]);

  const fetchSimpleOrganisations = () => {
    setExportLoading(true);
    request(
      getOrganisationsListSimple,
      TENANT_ID,
      field,
      field && direction,
      search,
      archived
    )
      .then((data) => data && setSimpleOrganisations(data))
      .finally(() => setExportLoading(false));
  };

  function fetchOrganisations() {
    setLoading(true);
    if (archived) setArchivedLoading(true);
    if (!initialRender.current) dispatch(setOrganisationsList([]));

    request(
      getOrganisationsListWithParams,
      TENANT_ID,
      pagesize,
      page,
      field,
      field && direction,
      search,
      archived
    ).then((data) => {
      if (data) {
        const newData = data.map((item) => ({
          ...item,
          cycle_tags:
            item.tags &&
            item.tags.filter(function(str) {
              return /Cycle_/.test(str);
            }),
        }));
        dispatch(setOrganisationsList(newData));
        setOrganisations(newData);
        setLoading(false);
        setArchivedLoading(false);
      }
    });
    request(getTagsList, "organisation", TENANT_ID, "Cycle").then((data) => {
      setOrganisationCycleTags(data);
    });
  }

  const requestOptions = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteField === "tags") {
      return request(
        getTagsAutocomplete,
        TENANT_ID,
        pagesize,
        "name",
        autocompleteValue
      );
    } else
      return request(
        getOrganisationsListAutocomplete,
        TENANT_ID,
        pagesize,
        autocompleteField,
        autocompleteValue,
        field,
        field && direction,
        searchFields,
        archived
      );
  };

  function fetchOrganisationsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);

    requestOptions(autocompleteValue, autocompleteField, searchFields).then(
      (data) => {
        if (data && Array.isArray(data) && data.length && objectCheck(data)) {
          setAutocompleteData(uniq(data.map((item) => String(item))));
        } else {
          setAutocompleteData(["No option found"]);
        }
        setAutocompleteLoading(false);
      }
    );
  }

  useEffect(() => {
    fetchOrganisations();
    fetchOrganisationsAutocomplete();
    fetchSimpleOrganisations();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    fetchOrganisations();
    fetchSimpleOrganisations();
    // eslint-disable-next-line
  }, [search, field, direction, status, archived, page, pagesize]);

  useEffect(() => {
    request(getOrganisationsAmount, TENANT_ID, search, archived).then(
      (data) => data && setTotalRecords(data)
    );
    // eslint-disable-next-line
  }, [search, archived]);

  const resetSearch = () => {
    dispatch(setOrganisationsList(organisations));
    setSearch("");
  };

  const arrayOfSelectedIds = useMemo(
    () =>
      Object.keys(selected)
        .filter((item) => Boolean(selected[item]))
        .map(Number),
    [selected]
  );

  const arrayOfFilteredIds = useMemo(() => {
    const newData = simpleOrganisations.reduce((acc, item) => {
      acc[item.value] = true;
      return acc;
    }, {});
    return Object.keys(newData)
      .filter((item) => Boolean(newData[item]))
      .map(Number);
  }, [simpleOrganisations]);

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this organisation?"
    );
    const itemID = getId();
    const status = (data.find(({ id }) => id === Number(itemID)) || {}).status;
    if (confirmation && status === "active") {
      try {
        deleteOrganisation(TENANT_ID, itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(archiveData(+itemID));
            const idToString = String(itemID);
            setSelected({
              ...selected,
              [idToString]: false,
            });
          }
        });
      } catch {}
    }
  };

  const handleUnArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to reactivate this organisation?"
    );
    const itemID = getId();
    const status = (data.find(({ id }) => id === Number(itemID)) || {}).status;
    if (confirmation && status === "archived") {
      try {
        modifyOrganisation(TENANT_ID, { status: "active" }, itemID)
          .then((res) => {
            if (res.status === 200 || res.status === 204) {
              const idToString = String(itemID);
              setSelected({
                ...selected,
                [idToString]: false,
              });
            }
            return res.json();
          })
          .then((res) => {
            const index = data.findIndex((item) => item.id === res.data.id);
            index !== -1 && dispatch(unArchiveData(index, res.data));
          });
      } catch {}
    }
  };

  const showArchived = () => {
    setArchived(!archived);
    setStatus(archived ? "" : "all");
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handlePrint = () => {
    setPringLoading(true);
    downloadFile(
      arrayOfSelectedIds,
      exportOrganisationById,
      TENANT_ID,
      setPringLoading
    );
  };

  const handleExport = () => {
    setExportLoading(true);
    arrayOfSelectedIds.length
      ? downloadFile(
          arrayOfSelectedIds,
          exportOrganisationsByIds,
          TENANT_ID,
          setExportLoading
        )
      : downloadFile(
          arrayOfFilteredIds,
          exportOrganisationsByIds,
          TENANT_ID,
          setExportLoading
        );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Organisations</h3>
            <div className="d-flex">
              <ToolTip
                icon={<PrintIcon fontSize="large" />}
                title="Print Selected Organisation Data in PDF"
                disabled={arrayOfSelectedIds.length !== 1}
                handleClick={handlePrint}
                loading={printLoading}
                testid="print"
              />
              <ToolTip
                icon={<InputIcon fontSize="large" />}
                title="Export Organisations Data to CSV"
                handleClick={handleExport}
                loading={exportLoading}
                testid="export"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end mb-5">
            <Can
              perform="orglist:new"
              yes={() => (
                <>
                  <Link to="/contacts/add-organisation" data-testid="new-org">
                    <button className="px-5 mr-5 btn btn-primary">New</button>
                  </Link>
                  <button
                    className="px-5 mr-5 btn btn-primary"
                    onClick={handleArchive}
                    disabled={archiveDisabled}
                  >
                    Archive
                  </button>
                  {archived && (
                    <button
                      className="px-5 mr-5 btn btn-primary"
                      onClick={handleUnArchive}
                      disabled={archiveDisabled}
                    >
                      Unarchive
                    </button>
                  )}
                </>
              )}
            />
          </div>
          <div className="row">
            <div className="col-8">
              <AutocompleteSearchBar
                className="mb-5"
                onSearch={(data) =>
                  handleSearch(data, searchMap, setSearch, setPage)
                }
                keyMap={SEARCH_HELPER}
                loading={autocompleteLoading}
                currentSearchList={autocompleteData}
                fetchAutocompleteFunction={fetchOrganisationsAutocomplete}
                defaultKey="ShortCode"
                placeholder="Search organisations..."
                clearSearch={resetSearch}
                searchMap={searchMap}
                searchFields={search}
                setAutocompleteData={setAutocompleteData}
              />
            </div>
            <div className="col-4" style={{ display: "flex" }}>
              {/* <Button>Search</Button> */}
              <FormControlLabel
                control={
                  archivedLoading ? (
                    <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                  ) : (
                    <Checkbox
                      checked={archived}
                      onChange={() => showArchived()}
                    />
                  )
                }
                label="Display Archived Records"
              />
            </div>
          </div>
          <DataGrid
            data={data}
            headings={HEADINGS}
            renderHeader={(headings) =>
              renderHeaderWithSorting(
                headings,
                field,
                setField,
                direction,
                setDirection
              )
            }
            renderRow={(headings, item) =>
              renderOrganisationRow(headings, item, organisationCycleTags)
            }
            editable
            selectable
            selected={selected}
            setSelected={setSelected}
            link="contacts/organisations-list"
            isOrganisation
            loading={loading}
            perPageDefault={pagesize}
            setPageSize={setPagesize}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pagesize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
}
