import { userTypes } from "./actions";

const initialState = {
  usersList: [],
};

export function usersReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case userTypes.SET_DATA:
      return {
        ...state,
        usersList: payload,
      };

    case userTypes.DELETE_USER:
      return {
        ...state,
        usersList: [...state.usersList.filter(({ id }) => id !== payload)],
      };

    case userTypes.UPDATE_DATA:
      const index = state.usersList.findIndex((item) => item.id === payload.id);
      const myState = [...state.usersList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        usersList: [...myState],
      };

    default:
      return state;
  }
}
