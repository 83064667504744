import { lateHistoryTypes } from "./actions";

const initialState = {
  lateHistoryList: [],
};

export function lateHistoryReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case lateHistoryTypes.SET_DATA:
      return {
        ...state,
        lateHistoryList: payload,
      };

    case lateHistoryTypes.DELETE_DATA:
      return {
        ...state,
        lateHistoryList: [...state.lateHistoryList.filter(({ id }) => id !== payload)],
      };

    case lateHistoryTypes.UPDATE_DATA:
      const index = state.lateHistoryList.findIndex((item) => item.id === payload.id);
      const myState = [...state.lateHistoryList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        lateHistoryList: [...myState],
      };

    default:
      return state;
  }
}
