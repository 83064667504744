export const discrepancyTypes = {
  SET_DATA: "DISCREPANCY/SET_DATA",
  UPDATE_DATA: "DISCREPANCY/UPDATE_DATA",
};

export function setDiscrepancyData(data) {
  return {
    type: discrepancyTypes.SET_DATA,
    payload: data,
  };
}

export function updateDiscrepancyData(data) {
  return {
    type: discrepancyTypes.UPDATE_DATA,
    payload: data,
  };
}
