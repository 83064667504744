import config from "react-global-configuration";

export const getContactsListWithParams = (
  tenant_id,
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts?${field &&
      "&order_by=" + field}${direction && "&order_dir=" + direction}${search &&
      "&" + search}${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getArchivedContactsList = (tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/contacts?status=archived`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const postContact = (tenant_id, contact_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(contact_data),
    mode: "cors",
    credentials: "include",
  });

export const getContactById = (tenant_id, contact_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/${contact_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyContact = (tenant_id, contact_id, contact_data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/${contact_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteContact = (tenant_id, contact_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/${contact_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const updateContactRequest = (tenant_id, contact_data, contact_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/${contact_id}`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getContactTags = (tenant_id, contact_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/contacts/${contact_id}/tags`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addContactTags = (tenant_id, contact_id, contact_data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/contacts/${contact_id}/tags`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteContactTags = (tenant_id, contact_id, contact_data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/contacts/${contact_id}/tags`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getContactFiles = (tenant_id, contact_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/contacts/${contact_id}/files`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const exportAllContacts = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/export`, {
    method: "post",
    mode: "cors",
    credentials: "include",
  });

export const exportContactsByIds = (contactsId, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/export`, {
    method: "post",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ query: { id: contactsId } }),
  });

export const exportContactById = (contactId, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/contacts/${contactId}/export`, {
    method: "post",
    mode: "cors",
    credentials: "include",
  });
