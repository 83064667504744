import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import DescriptionIcon from "@material-ui/icons/Description";
import { setDelinquentData } from "./_redux/actions";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../..//ui/helpers/helpers";
import {
  renderDelinquentBuyersRow,
  renderOverdueSum,
  modifyDelinquentBuyers,
  addDelinquentBuyersField,
} from "./helpers";
import { Modal } from "../../../ui/components/Modal";
import { IssueLateLetter } from "./IssueLateLetter";
import { FilterOptions } from "./FilterOptions";
import { useFetch } from "../../../hooks/fetch.hook";
import { getDelinquentBuyers } from "./_api";
import { HEADINGS } from "./constants";

import { IconButton } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";
import { formatDateForBackend } from "../../../ui/helpers/helpers";
import { downloadFile } from "../AWS-S3";
import { downloadReportGET } from "../Reports/_api";

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();

  const data = useSelector((state) => state.delinquent.delinquentList);
  const user = useSelector(({ auth: { user } }) => user);
  const TENANT_ID = user.selectedTenant;

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [selectedStartDate, setSelectedStartDate] = useState(0);
  const [selectedRawStartDate, setSelectedRawStartDate] = useState("");
  const [fetchStatus, setFetchStatus] = useState("");

  const handleModalClose = () => setModalOpen(false);

  function fetchDelinquentBuyers(
    within_days = 0,
    start_in_x_days = 0,
    field_name
  ) {
    setLoading(true);
    request(
      getDelinquentBuyers,
      TENANT_ID,
      within_days,
      start_in_x_days + selectedStartDate
    )
      .then((response) => {
        return response && field_name !== "total"
          ? addDelinquentBuyersField(response, data, field_name)
          : modifyDelinquentBuyers(response);
      })
      .then((response) => {
        if (!response) return;
        dispatch(setDelinquentData(response));
        setFetchStatus(field_name);
        setLoading(false);
      });
    setLoading(false);
  }

  useEffect(() => {
    dispatch(setDelinquentData([]));
    fetchDelinquentBuyers(undefined, 0, "total");
    // eslint-disable-next-line
  }, [selectedStartDate]);

  useEffect(() => {
    if (data.length) {
      if (fetchStatus === "total") {
        fetchDelinquentBuyers(-8, 0, "0-7");
      }
      if (fetchStatus === "0-7") {
        fetchDelinquentBuyers(-15, -7, "7-13");
      }
      if (fetchStatus === "7-13") {
        fetchDelinquentBuyers(-22, -14, "14-20");
      }
      if (fetchStatus === "14-20") {
        fetchDelinquentBuyers(undefined, -21, "21+");
      }
    }
    // eslint-disable-next-line
  }, [fetchStatus, setFetchStatus]);

  const handleExpand = (id) => () => {
    setModalOpen(true);
    setComponentId(id);
  };

  const handleSubmitWithDate = (aging_date) => {
    setSelectedRawStartDate(aging_date.from);
    const difference_in_time = new Date() - new Date(aging_date.from);
    const difference_in_days = difference_in_time / (1000 * 3600 * 24);
    setSelectedStartDate(-1 * parseInt(difference_in_days));
  };

  const handleSubmit = () => {
    setReportLoading(true);

    const report_data = {
      report_name: "D05_delinquent_buyers",
      report_end: selectedRawStartDate
        ? formatDateForBackend(selectedRawStartDate)
        : "",
    };
    downloadFile(report_data, downloadReportGET, TENANT_ID).finally(() =>
      setReportLoading(false)
    );
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="md"
        >
          <IssueLateLetter
            handleClose={handleModalClose}
            data={data.find(({ id }) => id === componentId)}
            user={user}
          />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex">
              <h3 className="text-dark my-5">Delinquent Buyers</h3>
              <div className="ml-auto">
                <IconButton
                  color="secondary"
                  onClick={handleSubmit}
                  disabled={reportLoading}
                >
                  <PrintIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
            <FilterOptions handleSubmit={handleSubmitWithDate} />
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderDelinquentBuyersRow}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              expandable
              handleExpand={handleExpand}
              expandTitle="Issue Late Letter"
              expandIcon={
                <DescriptionIcon color="primary" variant="contained" />
              }
              footerRow={data.length ? renderOverdueSum : ""}
            />
          </div>
        </div>
      </div>
    </>
  );
}
