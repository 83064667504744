import config from "react-global-configuration";

export const getAuditLogs = (from, to, filter) =>
  fetch(
    `${config.get("ApiUrl").Rest}/audit-trail?${from &&
      "&start=" + from}${(to && "&end=" + to) || ""}${(filter &&
      "&q=" + filter) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
