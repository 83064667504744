import React from "react";
import DatePicker from "react-datepicker";
import { useField, useFormikContext } from "formik";
import { getTip } from "../helpers/helpers";

export function CustomDatePicker({
  tip,
  testId,
  dateFormat = "dd/MM/yyyy",
  placeholderText = "Select Date",
  placement = "top-start",
  ...other
}) {
  return (
    <div data-testid={testId}>
      <DatePicker
        dateFormat={dateFormat}
        placeholderText={placeholderText}
        {...other}
        className="form-control"
        popperPlacement={placement}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
}

export const MappedDatePicker = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);

  return (
    <CustomDatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      className="form-control"
      onChange={(val) => {
        setFieldValue(field.name, new Date(val));
        if (props.handleSubmit) props.handleSubmit(val);
      }}
      tip={getTip(meta, props.tip)}
    />
  );
};
