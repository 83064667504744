import React, { useState, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core";
import { get } from "lodash";

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#aee2f2",
    color: "black",
  },
  root: {
    zIndex: 0,
  },
});

export const FilterBarFieldSingle = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  defaultValueField = "id",
  disabled,
  label = "label",
  disableCloseOnSelect = true,
  variant = "outlined",
  ...other
}) => {
  const classes = useStyles();

  const handleChange = (_, value) => {
    setValue(name, get(value, defaultValueField, ""));
    setInputValue((value || {})[name] || "");
    setFieldValue(value);
  };

  const [inputValue, setInputValue] = useState("");
  const [fieldValue, setFieldValue] = useState(null);

  useEffect(() => {
    setFieldValue(options.find(({ value }) => value === defaultValue) || null);
    setInputValue((options.find(({ value }) => value === defaultValue) || {})[label] || "");
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (event, value) => {
    setInputValue(value);
  };

  return (
    <div>
      <MuiAutocomplete
        options={options}
        id={name}
        onChange={handleChange}
        getOptionLabel={(item) => item[label]}
        getOptionSelected={(option, value) => option.value === value.value}
        inputValue={inputValue}
        value={fieldValue}
        disabled={disabled}
        onInputChange={handleInputChange}
        classes={classes}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
            className={classes.root}
          />
        )}
        {...other}
      />
      {tip && <div className="text-danger">{tip}</div>}
    </div>
  );
};
