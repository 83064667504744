export const depositTypes = {
  SET_DATA: "DEPOSIT/SET_DATA",
  UPDATE_DATA: "DEPOSIT/UPDATE_DATA",
};

export function setDepositData(data) {
  return {
    type: depositTypes.SET_DATA,
    payload: data,
  };
}

export function updateDepositData(data) {
  return {
    type: depositTypes.UPDATE_DATA,
    payload: data,
  };
}
