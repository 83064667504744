/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../hooks/fetch.hook";
import { BrowseFile } from "../components/BrowseFile";
import { MappedInput } from "../components/Input";
import { MappedSelect } from "../components/Select";

import {
  Grid,
  Switch,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import {
  addCreditServiceMemo,
  editCreditServiceMemo,
} from "../../app/modules/DashboardFOHBiller/_api";
import File from "../../app/modules/DashboardFOHBiller/File";

export const audienceOptions = [
  { value: "buyer", label: "Buyer" },
  { value: "biller", label: "Biller" },
  { value: "all", label: "All" },
];

export function CreditServiceMemosForm({
  selectedMemoData = [],
  open,
  setOpen,
  tenantId,
}) {
  const { request } = useFetch();
  const { title, url, type, target_audience } = selectedMemoData;
  const isAddNew = selectedMemoData.length === 0;
  const [loadingButton, setLoadingButton] = useState(false);
  const [attachmentType, setAttachmentType] = useState("link");
  const [lastFileName, setLastFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateLastUpload, setUpdateLastUpload] = useState(false);
  const [resourceId, setResourceId] = useState(selectedMemoData.id);
  const initialValues = {
    title: title || "",
    type: type || "",
    url: url || "",
    target_audience: target_audience || "",
  };

  useEffect(() => {
    if (open) {
      type && setAttachmentType(type);
      if (isAddNew) {
        request(addCreditServiceMemo, tenantId, {
          title: "",
          status: "inactive",
          type: attachmentType,
        }).then((response) => {
          if (response) setResourceId(response.id);
        });
      } else if (selectedMemoData.id) {
        setResourceId(selectedMemoData.id);
        if (selectedMemoData.attachment.file_name)
          setLastFileName(selectedMemoData.attachment.file_name);
      }
    } else {
      setLastFileName("");
      setUpdateLastUpload(false);
      setUploadedFiles([]);
      setResourceId(null);
      setAttachmentType("link");
    }
    // eslint-disable-next-line
  }, [open, setOpen]);

  const handleClick = (e) => {
    const { type } = e.currentTarget.dataset;
    setAttachmentType(type);
  };

  const handleSwitch = (e) => {
    setAttachmentType(attachmentType === "link" ? "file" : "link");
  };

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...acceptedFiles]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {isAddNew ? "Add New " : "Edit "} Memo
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object({
              title: Yup.string().required("Required"),
              url: Yup.string(),
            })}
            onSubmit={(values) => {
              setLoadingButton(true);
              // isAddNew
              //   ? request(addCreditServiceMemo, tenantId, {
              //       ...values,
              //       status: "active",
              //     }).then((response) => {
              //       if (response) handleClose();
              //       setLoadingButton(false);
              //     })
              //   :
              request(editCreditServiceMemo, tenantId, resourceId, {
                ...values,
                type: attachmentType,
                status: "active",
              }).then((response) => {
                if (response) handleClose();
                setLoadingButton(false);
              });
            }}
          >
            {(props) => (
              <div className="bg-white rounded">
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-3">
                      <div className="col-2 my-auto">
                        <p className="text-right text-muted mb-0">Memo Title</p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedInput name="title" wrapperClassName="w-100" />
                      </div>
                    </div>

                    <div className="row mb-5">
                      <div className="col-2 my-auto">
                        <p className="text-right text-muted pb-3">
                          Select audience:
                        </p>
                      </div>
                      <div className="col-4 mr-auto">
                        <MappedSelect
                          name="target_audience"
                          options={audienceOptions}
                          data-testid="target_audience"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <Grid container alignItems="center" spacing={0}>
                        <div className="col-2 my-auto mr-4">
                          <p className="text-right text-muted">Type: </p>
                        </div>
                        <Grid
                          item
                          onClick={handleClick}
                          data-type={"link"}
                          style={{ cursor: "pointer" }}
                        >
                          Link
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={attachmentType === "file"}
                            onClick={handleSwitch}
                            name="attachmentType"
                          />
                        </Grid>
                        <Grid
                          item
                          onClick={handleClick}
                          data-type={"file"}
                          style={{ cursor: "pointer" }}
                        >
                          File
                        </Grid>
                      </Grid>
                    </div>

                    {attachmentType === "link" ? (
                      <div className="row mb-3">
                        <div className="col-2 my-auto">
                          <p className="text-right text-muted mb-0">Link</p>
                        </div>
                        <div className="col-8 mr-auto">
                          <MappedInput name="url" wrapperClassName="w-100" />
                        </div>
                      </div>
                    ) : (
                      <div className="row mb-3">
                        <div className="col-2 my-auto">
                          <p className="text-right text-muted mb-0">File</p>
                        </div>
                        <div
                          className="col-8 mr-auto"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <BrowseFile onDrop={handleUploadFiles} width="75">
                            {uploadedFiles.length ? (
                              <>
                                {uploadedFiles.map((file) => (
                                  <File
                                    key={file.lastModified}
                                    tenant_id={tenantId}
                                    resource_id={resourceId}
                                    file={file}
                                    lastFileName={lastFileName}
                                    setLastFileName={setLastFileName}
                                    updateLastUpload={updateLastUpload}
                                    updateLastUploadState={setUpdateLastUpload}
                                  />
                                ))}
                              </>
                            ) : (
                              <span
                                style={{
                                  height: "100%",
                                  display: "flex",
                                  minHeight: "38.4px",
                                  alignItems: "center",
                                }}
                              >
                                {lastFileName &&
                                  lastFileName
                                    .split("/")
                                    .pop()
                                    .split("-")
                                    .slice(1)
                                    .join("")}
                                &nbsp;
                              </span>
                            )}
                          </BrowseFile>
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-6">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                    disabled={loadingButton}
                  >
                    {loadingButton ? (
                      <CircularProgress
                        size="1.5rem"
                        style={{
                          margin: "0 auto",
                          // color: "#6ba3ee !important",
                        }}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
