import React from "react";
import {
  formatAmount,
  formatDate,
  formatLongRow,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";
import { Button } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

function hasLongContentRow(key) {
  return ["field_name, field_value"].includes(key);
}

export const modifyRuleObject = (rule = {}) => {
  return {
    ...rule,
    action: rule.action.split("_").join(" "),
  };
};

export const modifyRules = (data) => {
  return data.map((rule) => modifyRuleObject(rule));
};

export function renderReceiptingLogicRow(headings, item, handleDetails) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isDate(key) ? (
        item[key] && formatDate(new Date(item[key]))
      ) : isAmount(key) ? (
        formatAmount(item[key])
      ) : hasLongContentRow(key) ? (
        formatLongRow(item[key])
      ) : key === "edit" ? (
        <Button onClick={() => handleDetails(item)}>
          <MoreHorizIcon sx={{ fontSize: "1.6rem" }} />
        </Button>
      ) : (
        item[key]
      )}
    </td>
  ));
}
