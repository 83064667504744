const type = "taxes";

export const setTaxesList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      taxList: payload,
    };
  },
  type,
});

export const deleteTax = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      taxList: [...state.taxList.filter(({ id }) => id !== payload)],
    };
  },
  type
});
