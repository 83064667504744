import { Skeleton } from "@material-ui/lab";
import { useFormikContext } from "formik";
import React from "react";
import { Autocomplete } from "../../components/Autocomplete";

export const SelectOrganisation = ({
  organisationsList = [],
  organisationId,
  setData,
  data = {},
}) => {
  const { setFieldValue, touched, errors } = useFormikContext();

  const handleOrganisationEdit = (fieldName, value) => {
    setFieldValue(fieldName, value);
    setData((state) => ({
      ...state,
      [fieldName]: value,
    }));
  };

  return (
    <div className="row mb-3 align-items-center">
      <div className="col-2">
        <p className="text-right text-muted">Organisation ID</p>
      </div>
      <div className="col-4">
        {organisationsList.length ? (
          <div className="w-100">
            <Autocomplete
              name="organisation_id"
              setValue={handleOrganisationEdit}
              options={organisationsList}
              disabled={Boolean(organisationId)}
              defaultValue={data.organisation_id}
              defaultField="value"
              label="label"
            />
            {touched.organisation_id && errors.organisation_id ? (
              <div className="text-danger">{errors.organisation_id}</div>
            ) : null}
          </div>
        ) : (
          <div className="w-100">
            <Skeleton variant="rect" width={"100%"} height={44} />
          </div>
        )}
      </div>
    </div>
  );
};
