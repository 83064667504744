import config from "react-global-configuration";

export const getLedgerTransactions = (
  tenant_id,
  organisation_id,
  account_id,
  from_date,
  to_date
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/transactions?${organisation_id &&
      "&organisation_id=" + organisation_id}${(account_id &&
      "&account_id=" + account_id) ||
      ""}${(from_date && "&gte-transaction_date=" + from_date) ||
      ""}${(to_date && "&lte-transaction_date=" + to_date) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getLedgerAccountTransactions = (
  tenant_id,
  organisation_id,
  account_id,
  from_date,
  to_date,
  search = "",
  page = undefined,
  page_size = undefined,
  count_only = false
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}/transactions?${(from_date &&
      "&gte-transaction_date=" + from_date) ||
      ""}${(to_date && "&lte-transaction_date=" + to_date) || ""}${
      search ? "&" + search : ""
    }${page ? "&page=" + page : ""}${
      page_size ? "&page_size=" + page_size : ""
    }${count_only ? "&count_only=y" : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getLedgerAccountTransactionsAutocomplete = (
  tenant_id,
  organisation_id,
  account_id,
  from_date,
  to_date,
  search = "",
  page_size = 80,
  autocompleteField,
  autocompleteText = ""
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}/transactions?${(from_date &&
      "&gte-transaction_date=" + from_date) ||
      ""}${(to_date && "&lte-transaction_date=" + to_date) || ""}${
      search ? "&" + search : ""
    }${page_size ? "&page_size=" + page_size : ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createTransaction = (tenant_id, transaction_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/transactions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(transaction_data),
    mode: "cors",
    credentials: "include",
  });
