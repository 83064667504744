import React, { useEffect, useState } from "react";
import Tabs from "../../../ui/components/Tabs";
import { useSelector } from "react-redux";
import { TenantRole } from "./TenantRole";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export function UserRole({ newUser, userId }) {
  const { user } = useSelector((state) => state.auth);
  const [tenants, setTenants] = useState();

  useEffect(() => {
    if (user.tenants) {
      setTenants(
        user.tenants.map(({ id, name, icon }) => ({
          label: name,
          icon:
            (
              <span className="svg-icon menu-icon">
                <img src={toAbsoluteUrl(icon)} alt="" />
              </span>
            ) || icon,
          tabPanel: (
            <TenantRole userId={userId} tenantId={id} newUser={newUser} />
          ),
        }))
      );
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div className="w-50 ml-3">
      <div className="d-flex flex-column rounded h-100">
        {tenants && <Tabs defaultActive="0" tabs={tenants} />}
      </div>
    </div>
  );
}
