import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";

import {
  CheckCircle as CheckCircleIcon,
  Info as InfoIcon,
} from "@material-ui/icons";
import { CircularProgress, Tooltip } from "@material-ui/core/";

import {
  getIntegrationByType,
  modifyIntegration,
  createIntegration,
  deleteIntegration,
} from "./_api";
import { getAccountsList } from "../LedgerAccounts/_api";

function getStatus(status) {
  switch (status) {
    case "loading":
      return <CircularProgress className="text-warning" size="2rem" />;
    case "needAPI":
      return "Need Vendor Name, Settlement Account ID";
    case "notValidAPI":
      return "The saved API Key / API Secret pair is not valid!";
    case "notSaved":
      return "Not Saved";
    case "disConnected":
      return "Disconnected";
    case "connected":
      return <CheckCircleIcon className="text-success" fontSize="large" />;
    default:
      return;
  }
}

export function NABForm() {
  const { user } = useSelector((state) => state.auth);
  const { request } = useFetch();
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const [loading, setLoading] = useState(true);
  const [integrationData, setIntegrationData] = useState({
    vendor_name: "",
    settlement_account_id: "",
    visa_fixed_fee: "",
    visa_percentage_fee: "",
    mastercard_fixed_fee: "",
    mastercard_percentage_fee: "",
    americanexpress_fixed_fee: "",
    americanexpress_percentage_fee: "",
  });
  const [hasNABIntegration, setHasNABIntegration] = useState(false);
  const [integrationId, setIntegrationId] = useState("");
  const [status, setStatus] = useState("");

  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);

  const initialValues = {
    vendor_name: integrationData.vendor_name || "",
    settlement_account_id: integrationData.settlement_account_id || "",
    visa_fixed_fee: integrationData.visa_fixed_fee || "",
    visa_percentage_fee: integrationData.visa_percentage_fee || "",
    mastercard_fixed_fee: integrationData.mastercard_fixed_fee || "",
    mastercard_percentage_fee: integrationData.mastercard_percentage_fee || "",
    americanexpress_fixed_fee: integrationData.americanexpress_fixed_fee || "",
    americanexpress_percentage_fee:
      integrationData.americanexpress_percentage_fee || "",
  };

  function fetchNABIntegration() {
    request(getIntegrationByType, TENANT_ID, "nab").then((response) => {
      if (response) {
        if (response[0]) {
          setHasNABIntegration(true);
          setIntegrationData(response[0]);
          setIntegrationId(response[0].id);
          setStatus("connected");
        } else {
          setStatus("needAPI");
        }
      }
      setLoading(false);
    });
  }

  function fetchAccountsList() {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        data
          .filter(({ status }) => status === "active")
          .forEach((item) => {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
          });
      }
    });
  }

  function resetNABIntegration() {
    setIntegrationData({});
    setIntegrationId("");
    setStatus("disConnected");
    setHasNABIntegration(false);
  }

  useEffect(() => {
    resetNABIntegration();
    setStatus("loading");
    fetchNABIntegration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID]);

  useEffect(() => {
    if (ORGANISATION_ID) {
      setAccountsList([{ value: 0, label: "Select Account" }]);
      fetchAccountsList();
    }
    // eslint-disable-next-line
  }, [ORGANISATION_ID]);

  useEffect(() => {
    if (!loading) {
      setStatus("notSaved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationData, setIntegrationData]);

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to delete the ABA File integration? This process is not reversible"
    );
    if (confirmation) {
      try {
        deleteIntegration(integrationId).then((res) => {
          if (res.status === 200 || res.status === 204) {
            resetNABIntegration();
          }
        });
      } catch {}
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        vendor_name: Yup.string().required("Vendor Name is required"),
        settlement_account_id: Yup.string().required(
          "Settlement Account ID is required"
        ),
        visa_fixed_fee: Yup.string(),
        visa_percentage_fee: Yup.string(),
        mastercard_fixed_fee: Yup.string(),
        mastercard_percentage_fee: Yup.string(),
        americanexpress_fixed_fee: Yup.string(),
        americanexpress_percentage_fee: Yup.string(),
      })}
      onSubmit={(values) => {
        setStatus("loading");
        const data = {
          tenant_id: TENANT_ID,
          integration_type: "nab",
          vendor_name: values.vendor_name,
          settlement_account_id: values.settlement_account_id,
          visa_fixed_fee: values.visa_fixed_fee,
          visa_percentage_fee: values.visa_percentage_fee,
          mastercard_fixed_fee: values.mastercard_fixed_fee,
          mastercard_percentage_fee: values.mastercard_percentage_fee,
          americanexpress_fixed_fee: values.americanexpress_fixed_fee,
          americanexpress_percentage_fee: values.americanexpress_percentage_fee,
        };
        if (hasNABIntegration) {
          request(modifyIntegration, integrationId, data).then((response) => {
            setStatus("connected");
            setHasNABIntegration(true);
          });
        } else {
          request(createIntegration, data).then((response) => {
            setStatus("connected");
            setIntegrationId(response.id);
            setHasNABIntegration(true);
          });
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <div>
          <span className="mb-10 font-weight-bold">NAB integration</span>
          <div className="row justify-content-between py-10">
            <div className="d-flex flex-column col-6">
              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="d-flex text-right text-muted mb-0 justify-content-end">
                    Vendor Name
                    <div className="my-auto pl-1">
                      <Tooltip
                        className="mb-0"
                        title={`Please add a Vendor name.`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="vendor_name"
                    wrapperClassName="w-100"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // vendor_name: e.target.value,
                    // })
                    // }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="d-flex text-right text-muted justify-content-end">
                    Settlement Account ID
                    <div className="my-auto pl-1">
                      <Tooltip
                        className="mb-0"
                        title={`ID of one of their asset type CRS account.`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedSelect
                    name="settlement_account_id"
                    options={accountsList}
                    data-testid="settlement_account_id"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="d-flex text-right text-muted mb-0 justify-content-end">
                    Visa
                    <div className="my-auto pl-1">
                      <Tooltip
                        className="mb-0"
                        title={`Setup Visa fees`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="visa_fixed_fee"
                    wrapperClassName="w-100"
                    placeholder="Fixed fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // visa_fixed_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="visa_percentage_fee"
                    wrapperClassName="w-100"
                    placeholder="Percentage fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // visa_percentage_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="d-flex text-right text-muted mb-0 justify-content-end">
                    Mastercard
                    <div className="my-auto pl-1">
                      <Tooltip
                        className="mb-0"
                        title={`Setup Mastercard fees`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="mastercard_fixed_fee"
                    wrapperClassName="w-100"
                    placeholder="Fixed fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // mastercard_fixed_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="mastercard_percentage_fee"
                    wrapperClassName="w-100"
                    placeholder="Percentage fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // mastercard_percentage_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="d-flex text-right text-muted mb-0 justify-content-end">
                    American Express
                    <div className="my-auto pl-1">
                      <Tooltip
                        className="mb-0"
                        title={`Setup American Express fees`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="americanexpress_fixed_fee"
                    wrapperClassName="w-100"
                    placeholder="Fixed fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // americanexpress_fixed_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="americanexpress_percentage_fee"
                    wrapperClassName="w-100"
                    placeholder="Percentage fee"
                    // onChange={(e) =>
                    // setIntegrationData({
                    // ...integrationData,
                    // americanexpress_percentage_fee: e.target.value,
                    // })
                    // }
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ml-10 col-md text-center">
              <span className="mb-2 text-center font-weight-bold">Status</span>
              <span style={{ lineHeight: "3" }}>{getStatus(status)}</span>
            </div>
          </div>

          <div className="row align-items-end mt-10">
            <span>
              <button
                type="submit"
                className="px-10 mx-5 btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </span>
            {hasNABIntegration ? (
              <span>
                <button
                  className="px-10 mr-5 btn btn-outline-primary"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Formik>
  );
}
