import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormikContext, Formik, Form } from "formik";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { Select } from "../../../ui/components/Select";
import { formatDate } from "../../../ui/helpers/helpers";
import { SearchBar } from "../../../ui/structures/SearchBar";

const AutoSubmitToken = () => {
  // Grab values and submitForm from context
  const { values, submitForm } = useFormikContext();
  React.useEffect(() => {
    // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
    if (values.from || values.to) {
      submitForm();
    }
  }, [values, submitForm]);
  return null;
};

export function ReportsForm({
  searchDates = {},
  setSearchDates,
  account,
  accountsList = [],
  setAccount,
  handleSearch,
  resetSearch,
  KEY_MAP,
}) {
  const { from = "", to = "" } = searchDates;

  const initialValues = {
    from: from || "",
    to: to || "",
    account: account || [],
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={Yup.object({
        from: Yup.date().required("From is a required field"),
        to: Yup.date()
          .required()
          .when(
            "from",
            (from, schema) =>
              from && schema.min(from, "Can't be earlier than start date")
          ),
      })}
      onSubmit={(values) => setSearchDates(values)}
    >
      {(props) => (
        <Form>
          <div className="row align-items-center col-12">
            <div className="col-2">
              <MappedDatePicker
                name="from"
                className="border border-secondary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-1">
              <div className="text-center text-muted">to</div>
            </div>
            <div className="col-2">
              <MappedDatePicker
                name="to"
                className="border border-secondary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-2">
              <Select
                name="account"
                onChange={(e) => setAccount(e.target.value)}
                value={account}
                options={accountsList}
                defaultValue="null"
              />
            </div>
            <div className="col-5 pl-15 pt-5">
              <SearchBar
                className="mb-5"
                onSearch={handleSearch}
                clearSearch={resetSearch}
                keyMap={KEY_MAP}
                placeholder="Search by fields..."
              />
            </div>
          </div>
          <AutoSubmitToken />
        </Form>
      )}
    </Formik>
  );
}
