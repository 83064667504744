/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import {
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
} from "@material-ui/icons";
import { makeStyles, Checkbox, Button } from "@material-ui/core";
import {
  formatAmount,
  formatDate,
} from "../../../../../../../ui/helpers/helpers";
import { InvoiceMatchFormSearchInvoices } from "./InvoiceMatchFormSearchInvoices";
import { handleCheckboxChange, getInvoicesValue } from "./helper";
import { AmountDisplay } from "../../../../../../../ui/components/AmountDisplay";
import { CustomDatePicker } from "../../../../../../../ui/components/DatePicker";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "rgb(40, 167, 69) !important",
      color: "#ffffff !important",
    },
  },
});

export function InvoiceMatchForm({
  transaction_data,
  createMatchByTransactionId,
  submitting,
  TENANT_ID,
}) {
  const classes = useStyles();
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [isOpenDueDate, setIsOpenDueDate] = useState(false);
  const [dueDate, setDueDate] = useState("");

  return (
    <>
      <h3 className="text-center mt-4 mb-10">Match Invoices</h3>
      <div
        className="my-10 d-flex"
        style={{
          padding: "15px 12px",
          borderRadius: 7,
          border: "1px solid #f59220",
        }}
      >
        <div className="mr-10" style={{ minWidth: 170 }}>
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>Bank :</span>
            {transaction_data?.bank_name}
          </div>
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>
              Transaction Value :
            </span>
            <AmountDisplay
              amount={transaction_data?.transaction_value}
              amount_sub={Number.parseFloat(getInvoicesValue(selectedInvoices))}
              variant="red"
              inverse_diplay
              inverse_progress
            />
            {/* {formatAmount(transaction_data?.transaction_value)} */}
          </div>
        </div>
        <div className="mr-10" style={{ minWidth: 130 }}>
          <span style={{ paddingRight: 12, fontWeight: 600 }}>Date :</span>
          {formatDate(transaction_data?.transaction_date)}
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>Type :</span>
            {transaction_data?.transaction_type}
          </div>
        </div>
        <div>
          <span style={{ paddingRight: 12, fontWeight: 600 }}>
            Description :
          </span>
          {transaction_data?.transaction_description}
        </div>
      </div>

      <div className="d-flex justify-content-between">
        <div className="mb-2" style={{ fontWeight: 600 }}>
          Find invoices
        </div>
        <div className="mb-2 col-3 text-right" style={{ fontWeight: 600 }}>
          {isOpenDueDate ? (
            <div className="d-flex align-items-center">
              <div className="mr-4 col-6 text-right">Due Date</div>
              <CustomDatePicker
                selected={(dueDate && new Date(dueDate)) || null}
                className="border border-secnodary"
                placeholderText={formatDate(new Date(Date.now()))}
                // onChange={() => console.log("Asdasd")}
                onChange={(date) => setDueDate(date)}
                placement="top-end"
              />
            </div>
          ) : (
            <Button onClick={() => setIsOpenDueDate(true)}>
              Select Due Date
            </Button>
          )}
        </div>
      </div>
      <InvoiceMatchFormSearchInvoices
        TENANT_ID={TENANT_ID}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
        dueDate={dueDate}
      />
      <div
        className="mt-5"
        style={{
          color: "#28a745",
        }}
      >
        {selectedInvoices.length ? (
          <>
            <div
              className="row align-items-center"
              style={{
                borderBottom: `1px solid rgb(40, 167, 69)`,
                fontWeight: 500,
                margin: 0,
              }}
            >
              <div className="col-12 d-flex justify-content-between align-items-center">
                <p className="m-0">Selected invoices</p>
                <div className="row">
                  <p className="m-0 pr-5">
                    {formatAmount(
                      Number.parseFloat(getInvoicesValue(selectedInvoices))
                    )}
                  </p>
                  <button
                    className={`btn btn-outline-primary btn-sm ${classes.button}`}
                    style={{
                      padding: "0 0.5rem",
                      fontSize: 11,
                      color: "rgb(40, 167, 69)",
                      borderColor: "rgb(40, 167, 69)",
                      marginBottom: 4,
                    }}
                    onClick={() => {
                      createMatchByTransactionId(selectedInvoices, "invoices");
                    }}
                    type="submit"
                    disabled={submitting}
                  >
                    Match Selected Invoices
                  </button>
                </div>
              </div>
            </div>
            {selectedInvoices?.map((invoice) => (
              <div
                key={invoice.id}
                className="row align-items-center"
                style={{
                  // color: "#28a745",
                  margin: "0",
                }}
              >
                <>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="col-1">{invoice.invoice_number}</div>
                    <div
                      className="col-3"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {invoice.buyer_organisation_name}
                    </div>
                    <div
                      className="col-3"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {invoice.biller_organisation_name}
                    </div>
                    <div style={{ margin: "0 30px 0 auto" }}>
                      <AmountDisplay
                        amount={invoice.invoice_total}
                        amount_sub={invoice.invoice_payment_due}
                        inverse_diplay
                        inverse_progress
                      />
                    </div>
                    <div>
                      <Checkbox
                        className="p-1"
                        icon={<AddBoxOutlinedIcon />}
                        checkedIcon={<IndeterminateCheckBoxIcon />}
                        checked={
                          selectedInvoices.findIndex(
                            ({ id }) => id === invoice.id
                          ) !== -1
                        }
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            invoice,
                            selectedInvoices,
                            setSelectedInvoices
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
