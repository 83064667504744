import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { MappedInput } from "../../../components/Input";
import { MappedSwitch } from "../../../components/Switch";
import { MappedTextArea } from "../../../components/TextArea";
import { MappedDatePicker } from "../../../components/DatePicker";
import { fieldNameStyle } from "../constants";
import { FormActions } from "../../FormActions";
import { Autocomplete } from "../../../components/Autocomplete";
import { TransferShareSchema } from "../../../../helpers/schemas";

export function TransferShareForm({
  tradingName,
  legalName,
  handleSubmit,
  handleClose,
  id,
  data = {},
  selected,
  organisationsList = [],
}) {
  const [shareData, setShareData] = useState(data);

  useEffect(() => {
    setShareData(data);
    //TODO request share data by id and set according initial values
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialValues = {
    transfer_from: tradingName,
    shareholder_id: shareData.shareholder_id,
    share_number: shareData.share_number || "",
    share_certificate_number: shareData.share_certificate_number || "",
    held_beneficially: shareData.held_beneficially || false,
    beneficiary_id: shareData.beneficiary_id || "",
    admitted_date: shareData.admitted_date
      ? new Date(shareData.admitted_date)
      : new Date(Date.now()),
    admitted_folio: shareData.admitted_folio || "",
    date_transferred: shareData.date_transferred
      ? new Date(shareData.date_transferred)
      : new Date(Date.now()),
    remarks: shareData.remarks || "",
    billing_entity_id: shareData.billing_entity_id,
    short_name: tradingName,
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={TransferShareSchema}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
        <>
          <h3 className="px-10 my-6">
            <strong>
              Transfer Share from {tradingName} ({legalName})
            </strong>
          </h3>

          <div className="px-10 d-flex">
            <div className="py-5 w-50">
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Transfer From</p>
                </div>
                <div className="col-8">
                  <MappedInput
                    wrapperClassName="w-100"
                    name="transfer_from"
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Share Certificate Number</p>
                </div>
                <div className="col-8">
                  <MappedInput
                    wrapperClassName="w-100"
                    name="share_certificate_number"
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Share Number</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="share_number" />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Transfer To</p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="shareholder_id"
                    placeholder="Select Organisation"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    defaultValue={shareData.shareholder_id}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.shareholder_id &&
                  formik.errors.shareholder_id ? (
                    <div className="text-danger">
                      {formik.errors.shareholder_id}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Held Beneficially </p>
                </div>
                <div className="col-8">
                  <MappedSwitch
                    checked={values.held_beneficially}
                    name="held_beneficially"
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Beneficiary </p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="beneficiary_id"
                    placeholder="Select Beneficiary"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    disabled={!values.held_beneficially}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.beneficiary_id &&
                  formik.errors.beneficiary_id ? (
                    <div className="text-danger">
                      {formik.errors.beneficiary_id}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Short Name</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="short_name" />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Admitted Date</p>
                </div>
                <div className="col-8">
                  <MappedDatePicker name="admitted_date" />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Admitted Folio</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="admitted_folio" />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Date Transferred </p>
                </div>
                <div className="col-8">
                  <MappedDatePicker name="date_transferred" />
                </div>
              </div>
            </div>
            <div className="py-5 ml-20 w-50">
              <div className="row mb-2">
                <div className="col-3">
                  <p style={fieldNameStyle}>Remarks</p>
                </div>
                <div className="col-8">
                  <MappedTextArea
                    name="comments"
                    rows={6}
                    data-testid="comment"
                  />
                </div>
              </div>

              <div className="row my-5">
                <div className="col-3">
                  <p style={fieldNameStyle}>Billing Entity</p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="billing_entity_id"
                    placeholder="Select Billing Entity"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    defaultValue={shareData.billing_entity_id}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.billing_entity_id &&
                  formik.errors.billing_entity_id ? (
                    <div className="text-danger">
                      {formik.errors.billing_entity_id}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <FormActions
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleClose={handleClose}
          />
        </>
      )}
    </Formik>
  );
}
