import config from "react-global-configuration";

export const getDiscrepancies = (tenant_id, search = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/discrepancies${(search &&
      "?" + search) ||
      ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createDiscrepancy = (tenant_id, discrepancy_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/discrepancies`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(discrepancy_data),
    mode: "cors",
    credentials: "include",
  });

export const getDiscrepancy = (tenant_id, discrepancy_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyDiscrepancy = (
  tenant_id,
  discrepancy_data,
  discrepancy_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(discrepancy_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteDiscrepancy = (tenant_id, discrepancy_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const adjustDiscrepancy = (
  tenant_id,
  discrepancy_data,
  discrepancy_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}/adjust`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(discrepancy_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const approveDiscrepancy = (data, tenant_id, discrepancy_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}/approve`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const rejectDiscrepancy = (data, tenant_id, discrepancy_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/discrepancies/${discrepancy_id}/reject`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );
