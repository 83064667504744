import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { uniqBy } from "lodash";
import { FilterBarFieldSingle } from "../../components/FilterBarFieldSingle";
import { selectSortHelper } from "../../helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { Loader } from "../../components/Loader";
import { Skeleton } from "@material-ui/lab";

export const DependentField = ({
  tenantID,
  parentFieldName,
  filterOptions,
  name,
  modifyPlaceholder,
  DependentFieldAPI,
  label = "name",
  defaultValue,
  getSelectedItem,
}) => {
  const { values, touched, setFieldValue, errors } = useFormikContext();
  const { request } = useFetch();

  const [optionsList, setOptionsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetKey, setResetKey] = useState(Date.now());
  const [defaultID, setDefaultID] = useState(defaultValue);

  const handleLoaderClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setLoading(false);
  };

  useEffect(() => {
    if (!values[parentFieldName]) return;
    if (!defaultID) setFieldValue(name, "");
    setDefaultID(null);
    setResetKey(Date.now());
    setLoading(true);
    request(DependentFieldAPI, tenantID, values[parentFieldName])
      .then((data) => data && setOptionsList(data.filter(({ status }) => status === "active")))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, [values[parentFieldName]]);

  useEffect(() => {
    getSelectedItem && getSelectedItem(optionsList.find(({ id }) => id === values.account));
    // eslint-disable-next-line
  }, [values.account]);

  const options = optionsList.map((item) => ({
    value: item.id,
    label: item[label],
  }));

  return (
    <>
      {loading && <Loader title="Loading..." onClose={handleLoaderClose} isOpen={loading} />}
      <div style={{ width: "49%", minHeight: "80px" }} className="mb-5">
        {loading ? (
          <div className="mb-6">
            <Skeleton variant="rect" width={"100%"} height={55} />
          </div>
        ) : (
          <FilterBarFieldSingle
            filterOptions={filterOptions}
            name={name}
            placeholder={modifyPlaceholder(name)}
            setValue={setFieldValue}
            options={uniqBy(options, "label").sort(selectSortHelper)}
            defaultValueField="value"
            data-testid={name}
            tip={touched[name] && errors[name] ? errors[name] : null}
            key={resetKey}
            defaultValue={defaultValue}
          />
        )}
      </div>
    </>
  );
};
