export const organisationsTypes = {
  SET_DATA: "ORGANISATIONS/SET_DATA",
  ARCHIVE_DATA: "ORGANISATIONS/ARCHIVE_DATA",
  UNARCHIVE_DATA: "ORGANISATIONS/UNARCHIVE_DATA",
  SET_ORGANISATION_DATA: "ORGANISATIONS/SET_ORGANISATION_DATA",
  SET_TAGS: "ORGANISATION/SET_TAGS",
  SET_COMMENTS: "ORGANISATION/SET_COMMENTS",
  ADD_COMMENT: "ORGANISATION/ADD_COMMENT",
  SET_ARCHIVED_LIST: "ORGANISATION/ARCHIVED_LIST",
};

export function setOrganisationsList(data) {
  return {
    type: organisationsTypes.SET_DATA,
    payload: data,
  };
}

export function setOrganisationData(data) {
  return {
    type: organisationsTypes.SET_ORGANISATION_DATA,
    payload: data,
  };
}

export function setTags(tags, organisationId) {
  return {
    type: organisationsTypes.SET_TAGS,
    payload: tags,
    organisationId,
  };
}

export function archiveData(organisationId) {
  return {
    type: organisationsTypes.ARCHIVE_DATA,
    payload: organisationId,
  };
}

export function unArchiveData(index, data) {
  return {
    type: organisationsTypes.UNARCHIVE_DATA,
    payload: data,
    index
  };
}

export function setComments(comments, organisationId) {
  return {
    type: organisationsTypes.SET_COMMENTS,
    payload: comments,
    organisationId,
  };
}

export function addNewComment(comment, organisationId) {
  return {
    type: organisationsTypes.ADD_COMMENT,
    payload: comment,
    organisationId,
  };
}

export function setArchivedList(data) {
  return {
    type: organisationsTypes.SET_ARCHIVED_LIST,
    payload: data,
  };
}
