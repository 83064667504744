import React, { useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { Button } from "@material-ui/core/";
import { Modal } from "./Modal";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const AccessTokenRefresh = () => {
  const [warningModalOpen, setWarningModalOpen] = useState(false);

  const history = useHistory();

  const warningModalClose = () => setWarningModalOpen(false);
  const handleWarningModalOpen = () => setWarningModalOpen(true);

  const handleRelogin = () => {
    localStorage.setItem("sessionExpired", true);
    history.push("/logout");
  };

  useEffect(() => {
    Auth.currentSession()
      .then((data) => {
        if (!data) return;
        // Date when refresh token will expire
        const authTime =
          data.accessToken.payload.auth_time * 1000 + 10 * 60 * 60 * 1000;
        const currentDate = Date.parse(new Date(Date.now()));
        const twentyMinutesLeft = authTime - 1200 * 1000;
        // Amount of milliseconds left before the warning
        const timer = twentyMinutesLeft - currentDate;
        if (timer > 0) {
          //Open warning message with request to reauthenticate.
          setTimeout(handleWarningModalOpen, timer);
        }
        //Kick user out when refresh token is expired
        setTimeout(handleRelogin, authTime - currentDate);
      })
      .catch((err) => {
        //Kick user out if session error occures
        console.log(err);
        handleRelogin();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {warningModalOpen && (
        <Modal
          isOpen={warningModalOpen}
          submitable
          onClose={handleWarningModalOpen}
          maxWidth="md"
        >
          <>
            <span>
              <img
                className="img-fluid"
                src={toAbsoluteUrl("/assets/icons/BrismarkIcon.svg")}
                alt="Logo"
              />
            </span>
            <hr />
            <div className="text-center py-5">
              <h3 className="my-3">
                Your session will expire in 20 minutes. Please, save your
                progress and reauthenticate.
              </h3>
              <div className="d-flex mt-10 mb-5 align-items-center justify-content-center">
                <div className="mr-5">
                  <Button
                    onClick={handleRelogin}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                  >
                    Logout
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={warningModalClose}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9"
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  );
};
