// permission_type: string [global, tenant, resource]
// tenant_id: int or null (== every)
// resource_id: int or null (== every)
// permission_level: string [edit, readonly, deny]

const rules = {
  global: { static: ["global:edit"] },
  organisations: {
    deny: [],
    readonly: ["list:print", "list:search"],
    edit: ["list:new", "list:archive", "list:print", "list:search"],
  },
  accounts: {
    deny: [],
    readonly: [],
    edit: ["list:new", "list:edit"],
  },
  contacts: {
    deny: [],
    readonly: ["list:print", "list:search"],
    edit: [
      "list:new",
      "list:archive",
      "list:sms",
      "list:print",
      "list:search",
      "card:edit",
      "card:tags",
      "card:settings",
    ],
  },
  securities: {
    deny: [],
    readonly: [],
    edit: [],
  },
  comments: {
    deny: [],
    readonly: [],
    edit: [],
  },
  users: {
    deny: [],
    readonly: [],
    edit: ["card:roles"],
  },
  integrations: {
    deny: [],
    readonly: [],
    edit: [],
  },
  audit_trail: {
    deny: [],
    readonly: [],
    edit: [],
  },
  payment_cycles: {
    deny: [],
    readonly: [],
    edit: ["list:new", "list:edit"],
  },
  fees_taxes: {
    deny: [],
    readonly: [],
    edit: ["list:new", "list:edit"],
  },

  tenants: {
    deny: [],
    readonly: [],
    edit: ["list:new"],
  },

  only_portal_admin: ["important_message:add", "memos:add", "distributions:approve"],
};

export default rules;
