import React from "react";
import { formatAmount } from "../../../ui/helpers/helpers";
import { Link, NavLink } from "react-router-dom";
import { IconButton } from "@material-ui/core";
import { ContactPhone as ContactPhoneIcon } from "@material-ui/icons";
const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (key === "Status") {
    return `${searchMap[key]}=${encodeURIComponent(data[key])}`;
  } else if (key === "Pack Sizes") {
    return `pack_sizes.id=_null_`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) => setSearchKey(data, searchMap, key))
      .join("&")
  );
  setPage && setPage(0);
};

export function renderInvoicesRow(headings, item, handleDetails) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {key === "name" ? (
        <Link to={`/contacts/organisations-list/${item.organisation.id}`}>
          {item[key]}
        </Link>
      ) : key === "contact" ? (
        <NavLink
          to={{
            pathname: `/contacts/organisations-list/${item.organisation.id}`,
            defaultActiveTab: "2",
          }}
        >
          <IconButton
            color="secondary"
            aria-label="edit"
            style={{ padding: "0 2px" }}
          >
            <ContactPhoneIcon fontSize="small" />
          </IconButton>
        </NavLink>
      ) : key === "total" ? (
        item[key] ? (
          `${formatAmount(item[key])} (${item.total_cnt || 0})`
        ) : (
          ""
        )
      ) : key === "4_week_avg" ? (
        item[key] ? (
          <div
            style={{
              color:
                !item["total"] ||
                parseFloat(item["total"]) * 0.2 < parseFloat(item[key]) ||
                parseFloat(item[key]) < parseFloat(item["total"]) * 1.8
                  ? "rgba(255, 0, 0, 0.8)"
                  : "rgb(40, 167, 69)",
            }}
          >
            {formatAmount(item[key])} ({item["4_week_cnt"] || 0})
          </div>
        ) : (
          ""
        )
      ) : item[key]?.daily_total ? (
        `${formatAmount(item[key]["daily_total"])} (${item[key]["daily_cnt"] ||
          0})`
      ) : (
        ""
      )}
    </td>
  ));
}
