import React from "react";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";

import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { MappedSelect } from "../../../ui/components/Select";
import { formatDate, formatDateForBackend } from "../../../ui/helpers/helpers";
import { downloadFile } from "../AWS-S3";
import { downloadReportGET } from "../Reports/_api";

const REPORTS = [
  {
    value: "shareregister_exited_members",
    label: "Exited Members",
  },
  {
    value: "shareregister_new_members",
    label: "New Members",
  },
  {
    value: "shareregister_current_members",
    label: "Current Members",
  },
  {
    value: "shareregister_current_members_remarks",
    label: "Current Members Remarks",
  },
  {
    value: "shareregister_current_members_short",
    label: "Current Members Short",
  },
];

const initialValues = {
  report_start: "",
  report_end: "",
  report_name: "",
};

export function ReportsForm() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const handleSubmit = (values, { setSubmitting }) => {
    const report_data = {
      ...values,
      report_start: values.report_start
        ? formatDateForBackend(values.report_start)
        : "",
      report_end: values.report_end
        ? formatDateForBackend(values.report_end)
        : "",
    };
    downloadFile(report_data, downloadReportGET, TENANT_ID).finally(() =>
      setSubmitting(false)
    );
  };
  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ handleSubmit, isSubmitting, handleReset, values }) => (
        <>
          <div className="row align-items-center col-8 mt-6">
            <div className="col-4">
              <MappedSelect
                name="report_name"
                options={REPORTS}
                label="Select Report"
              />
            </div>
            <div className="col-2">
              <MappedDatePicker
                name="report_start"
                className="border border-secnodary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-1">
              <div className="text-center text-muted">to</div>
            </div>
            <div className="col-2">
              <MappedDatePicker
                name="report_end"
                className="border border-secnodary"
                placeholderText={formatDate(new Date(Date.now()))}
              />
            </div>
            <div className="col-3">
              <IconButton color="secondary" onClick={handleSubmit}>
                <PrintIcon fontSize="large" />
              </IconButton>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
