import React, { useEffect, useState } from "react";
import { Autocomplete } from "../../components/Autocomplete";

export const MultiFieldFilter = ({
  data = [],
  title,
  modifyData,
  setSelectedOption,
  filtersMap = [],
}) => {
  const [options, setOptions] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);

  useEffect(() => {
    if (!data.length) return;
    setOptions(modifyData ? modifyData(data) : data);
    // eslint-disable-next-line
  }, []);

  const getFilterFields = (name, value) => {
    let filter;
    let obj = {};
    let key = name;
    //Create new filter value as object
    obj = { [key]: value };
    const index = filterOptions.findIndex((obj) => obj[key]);
    //get array or filter fields
    const keys = filterOptions
      .map((item) => Object.keys(item))
      .reduce((acc, val) => acc.concat(val), []);
    //if key exists in array, update it
    if (keys.includes(key)) {
      filter = [
        ...filterOptions.slice(0, index),
        obj,
        ...filterOptions.slice(index + 1),
      ];
      //if doesn't exist, add it
    } else filter = [...filterOptions, obj];
    setFilterOptions(filter);
    return filter;
  };

  const filterData = (filterValue = []) => {
    //get keys of existing filterValues
    const keys = filterValue
      .map((item) => Object.keys(item))
      .reduce((acc, val) => acc.concat(val), []);
    //If no filters left - reset data
    if (!keys.length) {
      setOptions(modifyData ? modifyData(data) : data);
      //if at least one filter left - use it to filter data
    } else {
      setOptions(
        (modifyData ? modifyData(data) : data).filter((option) => {
          return keys.every(
            (key) =>
              option[key] ===
              filterValue.find((filterOption) => filterOption[key])[key]
          );
        })
      );
    }
  };

  const handleFilter = (name, value) => {
    setSelectedOption && setSelectedOption(name, value);
    // actions when user selects option
    if (value) {
      filterData(getFilterFields(name, value));
      // actions when user clears input
    } else if (!value) {
      const index = filterOptions.findIndex((obj) => obj[name]);
      filterOptions.splice(index, 1);
      setFilterOptions(filterOptions);
      filterData(filterOptions);
    }
  };

  return (
    <div>
      <h3 className="text-center mb-10">{title}</h3>

      {filtersMap.map(({ label, name, placeholder }) => (
        <div className="row mb-5 align-items-center">
          <div className="col-2">{label}</div>
          <div className="col-8">
            <Autocomplete
              name={name}
              placeholder={placeholder}
              setValue={handleFilter}
              defaultField={name}
              label={name}
              options={options}
            />
          </div>
        </div>
      ))}
    </div>
  );
};
