/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { makeStyles, Button, LinearProgress } from "@material-ui/core";
import { formatAmount, formatDate } from "../../../ui/helpers/helpers";

const useStyles = makeStyles({
  invoiceLine: {
    "&.highlitedLine": {
      backgroundColor: " rgba(255, 255, 255, 0.2)",
      "&:hover": {
        backgroundColor: "rgba(255, 255, 255, 0.3)",
      },
    },
    "&:hover": {
      backgroundColor: "rgba(0,0,0,0.1)",
    },
  },
  root: {},
  colorPrimary: {
    backgroundColor: "rgba(255, 255, 255, 0.5)",
  },
  bar: {
    backgroundColor: "rgba(255, 255, 255, 0.8)",
  },
});

export function InvoiceDetails({
  data = {},
  handleModalClose,
  title = true,
  highlightedLineId = "",
}) {
  const classes = useStyles();
  return (
    <div className="rounded" style={{ backgroundColor: "#F59220" }}>
      {title ? (
        <h3 className="text-center mb-10">{data?.invoice_number} Invoice</h3>
      ) : (
        ""
      )}
      <div
        className="my-10 d-flex"
        style={{
          padding: "15px 12px",
          borderBottom: "1px solid rgba(0, 0, 0, 0.87)",
        }}
      >
        <div className="mr-10">
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>
              Total Value {data?.invoice_payment_due ? "/ Payment Due" : ""}:
            </span>
            {data?.invoice_payment_due ? (
              <div>
                <div style={{ margin: "0 auto" }}>
                  {formatAmount(data?.invoice_total) +
                    " / " +
                    formatAmount(data?.invoice_payment_due)}
                </div>
                <LinearProgress
                  classes={classes}
                  className="rounded"
                  variant="determinate"
                  value={
                    100 -
                    (data?.invoice_payment_due / data?.invoice_total) * 100
                  }
                />
              </div>
            ) : (
              formatAmount(data?.invoice_total)
            )}
          </div>
        </div>
        <div className="mr-10" style={{ minWidth: 130 }}>
          <span style={{ paddingRight: 12, fontWeight: 600 }}>
            Invoice Date :
          </span>
          {formatDate(data?.invoice_date)}
          {data?.payment_due_date ? (
            <div>
              <span style={{ paddingRight: 12, fontWeight: 600 }}>
                Payment Due Date :
              </span>
              {formatDate(data?.payment_due_date)}
            </div>
          ) : (
            ""
          )}
          {data?.processed_date ? (
            <div>
              <span style={{ paddingRight: 12, fontWeight: 600 }}>
                Processed Date :
              </span>
              {formatDate(data?.processed_date)}
            </div>
          ) : (
            ""
          )}
        </div>
        {data?.note ? (
          <div>
            <div>
              <span style={{ paddingRight: 12, fontWeight: 600 }}>Note :</span>
              {data?.note}
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div
        className="mt-10 mb-7"
        style={{
          padding: "15px 12px",
          borderRadius: 7,
        }}
      >
        <h4 className="mb-10">Invoice Items</h4>
        {data?.invoice_lines.length ? (
          <>
            <div className={`d-flex w-100 py-2 px-4 font-weight-bold`}>
              <div className="col-1">Line</div>
              <div className="col-4">Description</div>
              <div className="col-1">Quantity</div>
              <div className="col-2">Unit Price</div>
              <div className="col-2">Tax Value</div>
              <div className="col-2">Total Value</div>
            </div>
            {data?.invoice_lines?.map((line) => (
              <div
                className={`d-flex w-100 py-2 px-4 ${classes.invoiceLine} ${
                  highlightedLineId === line.id ? "highlitedLine" : ""
                }`}
              >
                <div className="col-1">{line.line_number}</div>
                <div className="col-4">{line.description}</div>
                <div className="col-1 text-center">{line.quantity}</div>
                <div className="col-2">{formatAmount(line.unit_price)}</div>
                <div className="col-2">{formatAmount(line.tax_value)}</div>
                <div className="col-2">
                  {formatAmount(line.total_value + line.tax_value)}
                </div>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        {handleModalClose ? (
          <div className="text-right">
            <Button
              size="large"
              className="px-9 mt-8"
              onClick={handleModalClose}
            >
              Close
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
