import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Button } from "@material-ui/core";
import { useField } from "formik";

export function BrowseFile({
  title = "Browse",
  heading,
  width = 50,
  visibleFile = true,
  buttonVariant = "contained",
  description = "",
  maxFilesQuantity = 1,
  accept,
  onDrop,
  inputHeight = "38.4px",
  children,
  ...other
}) {
  const handleDrop = useCallback(
    (acceptedFiles) => {
      onDrop(acceptedFiles);
    },
    [onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept,
  });

  return (
    <>
      <div
        className={
          (visibleFile ? "d-flex " : "d-none ") + "flex-column mr-5 w-" + width
        }
      >
        {heading && <span className="mb-10 font-weight-bold">{heading}</span>}
        <div
          className="bg-secondary w-100"
          style={{
            minHeight: inputHeight,
            padding: "0 16px",
          }}
        >
          {children}
        </div>
      </div>
      <Button
        variant={buttonVariant}
        color="secondary"
        size="large"
        {...getRootProps({
          className: "px-9 mr-3",
        })}
      >
        <input {...getInputProps()} {...other} />
        {title}
      </Button>
    </>
  );
}

export function MappedInputFile(props) {
  const [field] = useField(props.name);

  return <BrowseFile {...field} {...props} />;
}
