import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { MappedCheckbox } from "../../../ui/components/Checkbox";
import { CheckCircle as CheckCircleIcon } from "@material-ui/icons";
import { CircularProgress } from "@material-ui/core/";
import {
  getIntegrationByType,
  modifyIntegration,
  createIntegration,
  deleteIntegration,
} from "./_api";
import { getAccountsList } from "../LedgerAccounts/_api";

function getStatus(status) {
  switch (status) {
    case "loading":
      return <CircularProgress className="text-warning" size="2rem" />;
    case "needAPI":
      return "Need API Key, API Secret";
    case "notValidAPI":
      return "The saved API Key / API Secret pair is not valid!";
    case "notSaved":
      return "Not Saved";
    case "disConnected":
      return "Disconnected";
    case "connected":
      return <CheckCircleIcon className="text-success" fontSize="large" />;
    default:
      return;
  }
}

export function ABAFileForm() {
  const { user } = useSelector((state) => state.auth);
  const { request } = useFetch();
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const [loading, setLoading] = useState(true);
  const [integrationData, setIntegrationData] = useState({});
  const [hasABAFileIntegration, setHasABAFileIntegration] = useState(false);
  const [integrationId, setIntegrationId] = useState("");
  const [status, setStatus] = useState("");
  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);

  const initialValues = {
    bank_code: integrationData.bank_code || "",
    bsb: integrationData.bsb || "",
    account_id: integrationData.account_id || "",
    account_number: integrationData.account_number || "",
    account_name: integrationData.account_name || "",
    remitter_name: integrationData.remitter_name || "",
    file_description: integrationData.file_description || "",
    self_balance_line: integrationData.self_balance_line || false,
  };

  function fetchABAFileIntegration() {
    request(getIntegrationByType, TENANT_ID, "bank_account").then(
      (response) => {
        if (response) {
          if (response[0]) {
            setHasABAFileIntegration(true);
            setIntegrationData(response[0]);
            setIntegrationId(response[0].id);
            setStatus("connected");
          } else {
            setStatus("needAPI");
          }
        }
        setLoading(false);
      }
    );
  }

  function fetchAccountsList() {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        data
          .filter(({ status }) => status === "active")
          .forEach((item) => {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
          });
      }
    });
  }

  function resetABAFileIntegration() {
    setIntegrationData({});
    setIntegrationId("");
    setStatus("disConnected");
    setHasABAFileIntegration(false);
  }

  useEffect(() => {
    resetABAFileIntegration();
    setStatus("loading");
    fetchABAFileIntegration();

    if (ORGANISATION_ID) {
      setAccountsList([{ value: 0, label: "Select Account" }]);
      fetchAccountsList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID]);

  useEffect(() => {
    if (!loading) {
      setStatus("notSaved");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationData, setIntegrationData]);

  const handleDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to delete the ABA File integration? This process is not reversible"
    );
    if (confirmation) {
      try {
        deleteIntegration(integrationId).then((res) => {
          if (res.status === 200 || res.status === 204) {
            resetABAFileIntegration();
          }
        });
      } catch {}
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        bank_code: Yup.string()
          .max(3)
          .required("Bank Code is required"),
        bsb: Yup.string()
          .max(7)
          .required("BSB is required"),
        account_id: Yup.string().required("Account id is required"),
        account_number: Yup.string()
          .max(9)
          .required("Account Number is required"),
        account_name: Yup.string()
          .max(32)
          .required("Account Name is required"),
        remitter_name: Yup.string()
          .max(16)
          .required("Remitter Name is required"),
        file_description: Yup.string()
          .max(12)
          .required("File Description is required"),
        self_balance_line: Yup.boolean(),
      })}
      onSubmit={(values) => {
        setStatus("loading");
        const data = {
          tenant_id: TENANT_ID,
          integration_type: "bank_account",
          bank_code: values.bank_code,
          bsb: values.bsb,
          account_id: values.account_id,
          account_number: values.account_number,
          account_name: values.account_name,
          remitter_name: values.remitter_name,
          file_description: values.file_description,
          self_balance_line: values.self_balance_line,
        };
        if (hasABAFileIntegration) {
          request(modifyIntegration, integrationId, data).then((response) => {
            setStatus("connected");
            setHasABAFileIntegration(true);
          });
        } else {
          request(createIntegration, data).then((response) => {
            setStatus("connected");
            setIntegrationId(response.id);
            setHasABAFileIntegration(true);
          });
        }
      }}
    >
      {({ values, handleSubmit }) => (
        <div>
          <span className="mb-10 font-weight-bold">ABA File Integration</span>
          <div className="row justify-content-between py-10">
            <div className="d-flex flex-column col-6">
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">Bank Code</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="bank_code"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        bank_code: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">BSB</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="bsb"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        bsb: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">Account Number</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="account_number"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        account_number: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">Account Name</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="account_name"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        account_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">Remitter Name</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="remitter_name"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        remitter_name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="text-right text-muted mb-0">File Description</p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedInput
                    name="file_description"
                    wrapperClassName="w-100"
                    onChange={(e) =>
                      setIntegrationData({
                        ...integrationData,
                        file_description: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 my-auto">
                  <p className="d-flex text-right text-muted justify-content-end">
                    Ledger Account
                  </p>
                </div>
                <div className="col-8 mr-auto">
                  <MappedSelect
                    name="account_id"
                    options={accountsList}
                    data-testid="account_id"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 my-auto"></div>
                <div className="col-8 mr-auto">
                  <MappedCheckbox
                    name="self_balance_line"
                    label="Include self-balancing transaction in the ABA file"
                    data-testid="email"
                    checked={values.self_balance_line}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex flex-column ml-10 col-md text-center">
              <span className="mb-2 text-center font-weight-bold">Status</span>
              <span style={{ lineHeight: "3" }}>{getStatus(status)}</span>
            </div>
          </div>

          <div className="row align-items-end mt-10">
            <span>
              <button
                type="submit"
                className="px-10 mx-5 btn btn-primary"
                onClick={handleSubmit}
              >
                Save
              </button>
            </span>
            {hasABAFileIntegration ? (
              <span>
                <button
                  className="px-10 mr-5 btn btn-outline-primary"
                  onClick={handleDelete}
                >
                  Delete
                </button>
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </Formik>
  );
}
