import React, { useState } from "react";
import { useSelector } from "react-redux";
import PaymentCyclesForm from "../../ui/structures/PaymentCyclesForm";
import { addPaymentCycle } from "../modules/PaymentCycles/_api";

export function AddNewPaymentCycles() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [data, setData] = useState({
    tenant_id: TENANT_ID,
    description: "",
    start_date: "",
    length: "",
    term: "",
    cycle_count: "",
  });

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="border-bottom border-light p-7">
            <h4 className="mb-0">Add Payment Cycle</h4>
          </div>

          <PaymentCyclesForm
            data={data}
            onSubmit={addPaymentCycle}
            setData={setData}
          />
        </div>
      </div>
    </div>
  );
}
