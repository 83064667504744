import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setDepositData } from "./_redux/actions";
import { Button } from "@material-ui/core";

import { useFetch } from "../../../hooks/fetch.hook";
import { Modal } from "../../../ui/components/Modal";
import DataGrid from "../../../ui/components/DataGrid";
import { Select } from "../../../ui/components/Select";
import { getAccountsList } from "../LedgerAccounts/_api";
import { SearchBar } from "../../../ui/structures/SearchBar";
import { BrowseFile } from "../../../ui/components/BrowseFile";
import { ConfirmAction } from "../../../ui/components/ConfirmAction";
import { onSearch, renderHeader } from "../../../ui/helpers/helpers";
import { info } from "../../../helpers/toasts";

import { DepositMatchForm } from "./DepositMatchForm/DepositMatchForm";
import { getDeposits, deleteDeposit, importNABStatement } from "./_api";
import { renderDepositRow } from "./helpers";
import { ReportsForm } from "./ReportsForm";
import { HEADINGS } from "./constants";
import File from "./File";

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.deposit.depositList);
  const user = useSelector(({ auth: { user } }) => user) || {};
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("editNote");
  const [componentId, setComponentId] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [accountList, setAccountList] = useState([]);

  const [selectedAccount, setSelectedAccount] = useState(0);
  const [lastFileName, setLastFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateLastUpload, setUpdateLastUpload] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  function fetchDepositsData() {
    setLoading(true);
    request(getDeposits, TENANT_ID)
      .then((deposits) => {
        if (!deposits) return;

        const modifiedDeposits = deposits.map((deposit) => ({
          ...deposit,
          account_name: accountList.filter(
            ({ id }) => deposit.account_id === id
          )[0].account_name,
        }));
        dispatch(setDepositData(modifiedDeposits));
        setInitialData(modifiedDeposits);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  async function postImportNABData() {
    setLoading(true);
    setUpdateLastUpload(false);
    request(importNABStatement, TENANT_ID, lastFileName, selectedAccount).then(
      (data) => {
        if (!data) return;
        fetchDepositsData();
      }
    );
  }

  useEffect(() => {
    if (lastFileName && updateLastUpload && selectedAccount) {
      info("File has been processed!");
      postImportNABData();
    }
    // eslint-disable-next-line
  }, [lastFileName, setLastFileName, updateLastUpload, setUpdateLastUpload]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      request(getDeposits, TENANT_ID),
      request(getAccountsList, TENANT_ID, ORGANISATION_ID),
    ])
      .then(([deposits, accounts]) => {
        if (!deposits && !accounts) return;

        const modifiedDeposits = deposits.map((deposit) => ({
          ...deposit,
          account_name: accounts.filter(
            ({ id }) => deposit.account_id === id
          )[0].account_name,
        }));
        // setAccountList(accounts);
        accounts &&
          accounts
            .filter(({ status }) => status === "active")
            .forEach((item) => {
              setAccountList((prevList) => [
                ...prevList,
                { ...item, value: item.id, label: item.account_name },
              ]);
            });
        dispatch(setDepositData(modifiedDeposits));
        setInitialData(modifiedDeposits);
      })
      .finally(() => {
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const resetSearch = () => {
    setLoading(true);
    dispatch(setDepositData(data));
    setLoading(false);
  };

  const handleDelete = (id) => {
    setComponentId(id);
    setModalContent("delete");
    setModalOpen(true);
  };

  const handleMatch = (id) => {
    setComponentId(id);
    setModalContent("match");
    setModalOpen(true);
  };

  const handleUpload = () => {
    setLastFileName("");
    setUploadedFiles([]);
    setUpdateLastUpload(false);
    setModalContent("uploadFile");
    setModalOpen(true);
  };

  const handleSubmitDelete = (deposit_id, setSubmitting) => {
    request(deleteDeposit, TENANT_ID, deposit_id)
      .then((data) => {
        data && fetchDepositsData();
      })
      .finally(() => setSubmitting(false));
    handleModalClose();
  };

  const KEY_MAP = {
    "Bank Name": data.map((item) => item.bank_name),
    "Account Name": data.map((item) => item.account_name),
    Status: data.map((item) => item.status),
  };

  const handleSearch = (search, isRemoveKey) => {
    onSearch(
      search,
      isRemoveKey ? initialData : data,
      dispatch,
      setDepositData,
      HEADINGS
    );
  };

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...acceptedFiles]);
  };

  const modalContentMap = {
    delete: (
      <ConfirmAction
        handleClose={handleModalClose}
        confirmLabel="Delete"
        warningMessage={
          <>
            <div className="mb-4 text-danger">WARNING!</div>
            <div>Are you sure you want to delete deposit?</div>
          </>
        }
        id={componentId}
        handleSubmit={handleSubmitDelete}
      />
    ),
    match: (
      <DepositMatchForm
        TENANT_ID={TENANT_ID}
        deposit_id={componentId}
        fetchDepositsData={fetchDepositsData}
        handleModalClose={handleModalClose}
      />
    ),
    uploadFile: (
      <div>
        <h3 className="text-center mb-10">
          Import Batch Files for BPAY from NAB
        </h3>
        <div className="w-100 mb-5">
          <Select
            value={selectedAccount}
            onChange={(e) => {
              setSelectedAccount(e.target.value);
            }}
            style={{ borderColor: "white" }}
            options={accountList && accountList}
            label="Select an account"
          />
        </div>
        {(selectedAccount && (
          <>
            <div className="mb-2">Select batch file</div>
            <BrowseFile
              onDrop={handleUploadFiles}
              heading="Select Bank Transaction Data File"
              // inputHeight="54px"
              visibleFile={false}
              disabled={!selectedAccount}
              title="Browse File"
            ></BrowseFile>
          </>
        )) ||
          ""}

        {uploadedFiles.length ? (
          <div className="d-flex flex-column mt-3 ml-auto">
            <div
              className="bg-secondary w-100"
              style={{
                minHeight: "38.4px",
                padding: "0 16px",
              }}
            >
              {uploadedFiles.map((file) => (
                <File
                  file={file}
                  tenant_id={TENANT_ID}
                  setLastFileName={setLastFileName}
                  updateLastUpload={updateLastUpload}
                  updateLastUploadState={setUpdateLastUpload}
                  key={file.lastModified}
                  account_id={selectedAccount}
                />
              ))}
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="text-right">
          <Button size="large" className="px-9 mt-8" onClick={handleModalClose}>
            Close
          </Button>
        </div>
      </div>
    ),
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="md"
        >
          {modalContentMap[modalContent]}
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <h3 className="text-dark">Cheque Deposits / Prepayments</h3>
              <div>
                <button
                  className="px-15 mr-5 btn btn-outline-primary"
                  onClick={handleUpload}
                >
                  Upload
                </button>
                <Link
                  to={{
                    pathname: `/deposits/add-deposit`,
                    newDeposit: true,
                  }}
                  data-testid="new-deposit"
                >
                  <button className="px-15 mr-5 btn btn-primary">New</button>
                </Link>
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={KEY_MAP}
                  placeholder="Filter..."
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderDepositRow}
              renderRowProps={[handleMatch]}
              loading={loading}
              link="deposits"
              isUserList
              handleDelete={handleDelete}
              editable
            />
            <hr />
            <ReportsForm />
          </div>
        </div>
      </div>
    </>
  );
}
