/* eslint-disable no-restricted-imports */
import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Tooltip,
  Button,
  Typography,
  FormControlLabel,
  CircularProgress,
  Fade,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  root: {
    marginLeft: 0,
    marginRight: 0,
  },
});

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

export default function ToolTip({
  title,
  icon,
  description,
  disabled,
  color = "secondary",
  handleClick,
  loading,
  testid,
  variant = "text",
  size = "small",
  placement = "bottom",
  isTextTooltip = false,
  content = "",
}) {
  const classes = useStyles();
  return (
    <div className="d-flex" data-testid={testid}>
      <FormControlLabel
        classes={classes}
        control={
          loading ? (
            <CircularProgress size="1.2rem" style={{ margin: 11 }} />
          ) : (
            <div>
              <HtmlTooltip
                title={
                  <React.Fragment>
                    <Typography>{title}</Typography>
                    {description}
                  </React.Fragment>
                }
                TransitionComponent={Fade}
                placement={placement}
              >
                {isTextTooltip ? (
                  content
                ) : (
                  <span>
                    <Button
                      disabled={disabled}
                      color={color}
                      onClick={handleClick}
                      data-testid="action-button"
                      variant={variant}
                      size={size}
                    >
                      {icon}
                    </Button>
                  </span>
                )}
              </HtmlTooltip>
            </div>
          )
        }
      />
    </div>
  );
}
