import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AsideMenuList } from "./AsideMenuList";
import { AsideMenuListFOHBiller } from "./AsideMenuListFOHBiller";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { AsideMenuListBuyer } from "./AsideMenuListBuyer";

export function AsideMenu({ disableScroll }) {
  const { user } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  const userCategoryMap = {
    Biller: <AsideMenuListFOHBiller layoutProps={layoutProps} />,
    BOH: <AsideMenuList layoutProps={layoutProps} />,
    Buyer: <AsideMenuListBuyer layoutProps={layoutProps} />,
  };

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 d-flex flex-column justify-content-between ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {userCategoryMap[user.userCategory]}
        <div
          className="my-3 w-100"
          style={{
            textAlign: "center",
            fontSize: ".9rem",
          }}
        >
          <Link className="menu-link" to="#">
            <span
              className="menu-text"
              style={{
                color: "#a2a3b7",
              }}
            >
              Privacy Policy
            </span>
          </Link>
          &nbsp; | &nbsp;
          <Link className="menu-link" to="#">
            <span
              className="menu-text"
              style={{
                color: "#a2a3b7",
              }}
            >
              Terms
            </span>
          </Link>
        </div>
      </div>
      {/* end::Menu Container */}
    </>
  );
}
