import config from "react-global-configuration";

export const getOrganisationsListWithParams = (
  tenant_id,
  page_size = 20,
  page = 0,
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations?&page=${page}&page_size=${page_size}${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}${(search &&
      "&" + search) ||
      ""}${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationsListAutocomplete = (
  tenant_id,
  page_size = 20,
  autocompleteField,
  autocompleteText = "",
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations?&page_size=${page_size}${(archived &&
      "&status=all") ||
      ""}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${(field && "&order_by=" + field) || ""}${(direction &&
      "&order_dir=" + direction) ||
      ""}${(search && "&" + search) || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationsListSimple = (
  tenant_id,
  field,
  direction,
  search,
  archived
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations?&format=simple${(field &&
      "&order_by=" + field) ||
      ""}${(direction && "&order_dir=" + direction) || ""}${(search &&
      "&" + search) ||
      ""}${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createOrganisation = (tenant_id, organisation_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(organisation_data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisation = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteOrganisation = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyOrganisation = (
  tenant_id,
  organisation_data,
  organisation_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(organisation_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationContacts = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/contacts`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationTenant = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/tenant`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationPermissions = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/permissions`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTagsList = (type, tenant_id, tag_type = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/tags?type=${type}${tag_type &&
      "&name=_like(" + tag_type + ")_"}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTagsAutocomplete = (
  tenant_id,
  page_size = 20,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/tags?&page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationTags = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/tags`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addOrganisationTags = (
  tenant_id,
  organisation_id,
  organisation_tags
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/tags`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(organisation_tags),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteOrganisationTags = (
  tenant_id,
  organisation_id,
  organisation_tags
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/tags`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(organisation_tags),
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationFiles = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/files`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const exportAllOrganisations = (tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/export`,
    {
      method: "post",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ format: "csv" }),
    }
  );

export const exportOrganisationsByIds = (organisationsId, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/export`,
    {
      method: "post",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ format: "csv", query: { id: organisationsId } }),
    }
  );

export const exportOrganisationsReports = (report_format, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/export`,
    {
      method: "post",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ format: "csv", query: { format: report_format } }),
    }
  );

export const exportOrganisationsReportsPdf = (report_format, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/export`,
    {
      method: "post",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ format: report_format }),
    }
  );

export const exportOrganisationById = (organisationId, tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisationId}/export`,
    {
      method: "post",
      mode: "cors",
      credentials: "include",
    }
  );

export const exportCommentsByType = (ids, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/${
      ids[0]
    }/comments/export`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({
        query: { comment_category: ids[1] },
      }),
    }
  );

export const getCommentType = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/comment-types`, {
    mode: "cors",
    credentials: "include",
  });

export const getComments = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/comments`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addComments = (tenant_id, organisation_id, comment_data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/comments`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(comment_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const editComments = (
  tenant_id,
  organisation_id,
  comment_id,
  comment_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/comments/${comment_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ comment: comment_data }),
      mode: "cors",
      credentials: "include",
    }
  );

export const addCommentType = (tenant_id, comment_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/comment-types`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(comment_data),
    mode: "cors",
    credentials: "include",
  });

export const deleteComment = (tenant_id, organisation_id, comment_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/comments/${comment_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const getArchivedOrganisations = (tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations?status=all`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationSecurities = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addOrganisationSecurity = (
  tenant_id,
  organisation_id,
  security_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(security_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationSecurity = (
  tenant_id,
  organisation_id,
  security_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities/${security_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const exportOrganisationSecurity = (ids, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/${
      ids[0]
    }/securities/export`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationSecurityFiles = (
  tenant_id,
  organisation_id,
  security_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities/${security_id}/files`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyOrganisationSecurity = (
  tenant_id,
  organisation_id,
  security_id,
  security_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities/${security_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(security_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteOrganisationSecurity = (
  tenant_id,
  organisation_id,
  security_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/securities/${security_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const getAccounts = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getArchivedAccounts = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts?status=archived`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getAccount = (tenant_id, organisation_id, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyAccount = (
  tenant_id,
  organisation_id,
  account_data,
  account_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(account_data),
    }
  );

export const deleteAccount = (tenant_id, organisation_id, account_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts/${account_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const verifyBSB = (integration_id, bsb_number) =>
  fetch(
    `${config.get("ApiUrl").Rest}/integrations/${integration_id}/verify-bsb`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ bsb: bsb_number }),
    }
  );

export const addAccount = (tenant_id, organisation_id, account_data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/accounts`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(account_data),
    }
  );

export const getOrganisationsAmount = (
  tenant_id,
  search = "",
  archived = ""
) => {
  return fetch(
    `${
      config.get("ApiUrl").Rest
    }${`/tenants/${tenant_id}`}/organisations?count_only=y${
      search ? "&" + search : ""
    }${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
};

export const getLogoUploadUrl = (tenant_id, organisation_id, fileName) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/request_logo_upload_url`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ file_name: fileName.name }),
    }
  );
