/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../../hooks/fetch.hook";
import { matchBankTransactionById, createBankTransaction } from "../../../_api";
import { formatDate, formatAmount } from "../../../../../../ui/helpers/helpers";
import { Modal } from "../../../../../../ui/components/Modal";
import { makeStyles, Button } from "@material-ui/core";
import { downloadFile } from "../../../../AWS-S3";
import { downloadReport } from "../../../../Reports/_api";
import { InvoiceMatchForm } from "./InvoiceMatchForm/InvoiceMatchForm";
import { DepositMatchForm } from "./DepositMatchForm/DepositMatchForm";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "rgb(40, 167, 69) !important",
      color: "#ffffff !important",
    },
  },
});

export function ReconciliationMatchForm({
  data = [],
  TENANT_ID,
  setSentTransaction,
  setSentTransactionType,
}) {
  const classes = useStyles();
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [rules, setRules] = useState([]);
  const [billings, setBillings] = useState([]);
  const [deposits, setDeposits] = useState([]);
  const [distributions, setDistributions] = useState([]);
  const [submitting, setSubmitting] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState("");

  function fetchMatchedBankById() {
    setLoading(true);
    request(matchBankTransactionById, TENANT_ID, data.id).then((data) => {
      if (data) {
        setRules(data.rules);
        setBillings(data.suggested_billings);
        setDeposits(data.suggested_deposits);
        setDistributions(data.suggested_distributions);
      }
      setLoading(false);
    });
  }

  const createMatchByTransactionId = (transaction_data, type) => {
    let newData = {};
    setSubmitting(true);
    if (type === "rule") {
      newData = {
        action: "rule",
        rule_id: transaction_data.id,
      };
    } else if (type === "billing") {
      newData = {
        action: "billing",
        billing_id: transaction_data.billing_id,
        buyer_id: transaction_data.buyer_id,
      };
    } else if (type === "deposit") {
      newData = {
        action: "deposit",
        deposit_id: transaction_data.id,
      };
    } else if (type === "distribution") {
      newData = {
        action: "distribution",
        distribution_id: transaction_data.id,
      };
    } else if (type === "invoices") {
      newData = {
        action: "invoices",
        invoice_ids: transaction_data.map(({ id }) => id),
      };
    }

    request(createBankTransaction, TENANT_ID, data.id, newData).then(
      (response) => {
        if (response) {
          handleAfterMatching(newData.action);
        }
      }
    );
    setSubmitting(false);
  };

  const handleAfterMatching = (action) => {
    setSentTransaction(true);
    setSentTransactionType(action);
  };

  useEffect(() => {
    fetchMatchedBankById();
    // eslint-disable-next-line
  }, []);

  const handleModalOpen = (content) => {
    setModalContent(content);
    setModalOpen(true);
  };

  const handleModalClose = () => setModalOpen(false);

  const modalContentMap = {
    deposits: (
      <DepositMatchForm
        transaction_data={data}
        createMatchByTransactionId={createMatchByTransactionId}
        submitting={submitting}
        TENANT_ID={TENANT_ID}
      />
    ),
    invoices: (
      <InvoiceMatchForm
        transaction_data={data}
        createMatchByTransactionId={createMatchByTransactionId}
        submitting={submitting}
        TENANT_ID={TENANT_ID}
      />
    ),
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={"lg"}
        >
          {modalContentMap[modalContent]}
        </Modal>
      )}
      {rules.length ||
      billings.length ||
      deposits.length ||
      distributions.length ? (
        <div
          className="mb-3"
          style={{
            color: "#28a745",
          }}
        >
          {distributions.length ? (
            <div className="mb-2">
              <p
                style={{
                  borderBottom: `1px solid #1b7730`,
                  fontWeight: 500,
                  margin: 0,
                  fontSize: 12,
                  color: "#1b7730",
                }}
              >
                Matched distributions
              </p>
              {distributions.map((distribution) => (
                <div
                  key={distribution.id}
                  className="row align-items-center"
                  style={{
                    // color: "#28a745",
                    margin: "0",
                  }}
                >
                  <>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <div>
                        {formatDate(new Date(distribution.payment_date))}
                      </div>
                      <div>{distribution.reference_number}</div>
                      <div>
                        <Button
                          // className={`btn btn-outline-primary btn-sm ${classes.Button}`}
                          style={{
                            padding: "0 0.5rem",
                            fontSize: 11,
                            color: "rgb(40, 167, 69)",
                            borderColor: "transparent",
                          }}
                          onClick={() =>
                            downloadFile(
                              {
                                report_name: "D03_distribution_payment_summary",
                                id: [distribution.id],
                              },
                              downloadReport,
                              TENANT_ID
                            )
                          }
                        >
                          Distribution report
                        </Button>
                      </div>
                      <div>
                        <button
                          className={`btn btn-outline-primary btn-sm ${classes.button}`}
                          style={{
                            padding: "0 0.5rem",
                            fontSize: 11,
                            color: "rgb(40, 167, 69)",
                            borderColor: "rgb(40, 167, 69)",
                          }}
                          onClick={() =>
                            createMatchByTransactionId(
                              distribution,
                              "distribution"
                            )
                          }
                          type="submit"
                          disabled={submitting}
                        >
                          Match
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {deposits.length ? (
            <div className="mb-2">
              <p
                style={{
                  borderBottom: `1px solid #1b7730`,
                  fontWeight: 500,
                  margin: 0,
                  fontSize: 12,
                  color: "#1b7730",
                }}
              >
                Matched Deposits
              </p>
              {deposits.map((deposit) => (
                <div
                  key={deposit.id}
                  className="row align-items-center"
                  style={{
                    // color: "#28a745",
                    margin: "0",
                  }}
                >
                  <>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <div>{formatDate(new Date(deposit.deposit_date))}</div>
                      <div>
                        {formatAmount(deposit?.deposit_value) +
                          " / " +
                          formatAmount(deposit?.deposit_payment_due)}
                      </div>
                      <div>{deposit.bank_name}</div>
                      <div>
                        <button
                          className={`btn btn-outline-primary btn-sm ${classes.button}`}
                          style={{
                            padding: "0 0.5rem",
                            fontSize: 11,
                            color: "rgb(40, 167, 69)",
                            borderColor: "rgb(40, 167, 69)",
                          }}
                          onClick={() =>
                            createMatchByTransactionId(deposit, "deposit")
                          }
                          type="submit"
                          disabled={submitting}
                        >
                          Match
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {rules.length ? (
            <div className="mb-2">
              <p
                style={{
                  borderBottom: `1px solid #1b7730`,
                  fontWeight: 500,
                  margin: 0,
                  fontSize: 12,
                  color: "#1b7730",
                }}
              >
                Matched Rules
              </p>
              {rules.map((rule) => (
                <div
                  key={rule.id}
                  className="row align-items-center"
                  style={{
                    // color: "#28a745",
                    margin: "0",
                  }}
                >
                  <>
                    <div className="col-12 d-flex justify-content-between align-items-center">
                      <div>{rule.name}</div>
                      <div>
                        <button
                          className={`btn btn-outline-primary btn-sm ${classes.button}`}
                          style={{
                            padding: "0 0.5rem",
                            fontSize: 11,
                            color: "rgb(40, 167, 69)",
                            borderColor: "rgb(40, 167, 69)",
                          }}
                          onClick={() =>
                            createMatchByTransactionId(rule, "rule")
                          }
                          type="submit"
                          disabled={submitting}
                        >
                          Match
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {billings.length ? (
            <div className="mb-2">
              <p
                style={{
                  borderBottom: `1px solid #1b7730`,
                  fontWeight: 500,
                  margin: 0,
                  fontSize: 12,
                  color: "#1b7730",
                }}
              >
                Matched billings
              </p>
              {billings.map((billing) => (
                <div
                  key={billing.billing_id}
                  className="row align-items-center"
                  style={{
                    // color: "#28a745",
                    margin: "0",
                  }}
                >
                  <>
                    <div className="col-12 d-flex justify-content-between align-items-center py-1">
                      <div>
                        {billing.billing_data?.organisation?.shortCode}{" "}
                        {billing.billing_data?.organisation?.name}
                      </div>
                      <div
                        style={{ fontSize: 14, fontWeight: 500, minWidth: 100 }}
                      >
                        $ {billing.billing_data.total}
                      </div>
                      <div>
                        <button
                          className={`btn btn-outline-primary btn-sm ${classes.button}`}
                          style={{
                            padding: "0 0.5rem",
                            fontSize: 11,
                            color: "rgb(40, 167, 69)",
                            borderColor: "rgb(40, 167, 69)",
                          }}
                          onClick={() =>
                            createMatchByTransactionId(billing, "billing")
                          }
                          type="submit"
                          disabled={submitting}
                        >
                          Match
                        </button>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <div className="row py-2">
          <div className="col-12 text-center pt-4">
            {loading ? "Loading..." : "We didnt find any match!"}
          </div>
        </div>
      )}
      <div className="row py-2">
        <div className="col-12 text-center pt-4">
          <Button
            color="secondary"
            size="large"
            className="px-9 mr-auto w-50"
            tabIndex="-1"
            // startIcon={<AddIcon />}
            onClick={() => handleModalOpen("invoices")}
          >
            Select multiple invoices
          </Button>
          <Button
            color="secondary"
            size="large"
            className="px-9 mr-auto w-50"
            tabIndex="-1"
            // startIcon={<AddIcon />}
            onClick={() => handleModalOpen("deposits")}
          >
            Select deposit
          </Button>
        </div>
      </div>
    </>
  );
}
