import { invoicesTypes } from "./actions";

const initialInvoicesState = {
  invoicesList: [],
};

export function invoiceEntryReducer(state = initialInvoicesState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case invoicesTypes.SET_DATA:
      return {
        ...state,
        invoicesList: [...payload],
      };
    case invoicesTypes.UPDATE_DATA:
      return {
        ...state,
        invoicesList: [...state.invoicesList, ...payload],
      };
    case invoicesTypes.UPDATE_INVOICES:
      const index = state.invoicesList.findIndex(
        (item) => item.id === payload.id
      );
      const myState = [...state.invoicesList];
      myState.splice(index, 1, payload);

      return {
        ...state,
        invoicesList: [...myState],
      };
    default:
      return state;
  }
}
