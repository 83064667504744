import React from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { FormControlLabel, CircularProgress } from "@material-ui/core";

export const TagsSearch = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  disabled,
  ...other
}) => {
  const handleChange = (_, value) => {
    setValue(name, value);
  };

  const getOptions = (options = []) => {
    return options.filter((option) => name in option);
  };
  
  return (
    <div>
      <MuiAutocomplete
        options={getOptions(options)}
        id={name}
        onChange={handleChange}
        getOptionLabel={({ name }) => name}
        defaultValue={options.find(({ name }) => name === defaultValue)}
        disabled={disabled}
        multiple
        {...other}
        renderInput={(params) => (
          <TextField {...params} label={placeholder} variant="outlined" color="secondary" />
        )}
      />
      <FormControlLabel
        control={loading ? <CircularProgress size="1.2rem" style={{ margin: 11 }} /> : <div></div>}
      />
      {tip && <div>{tip}</div>}
    </div>
  );
};
