import config from "react-global-configuration";

export const getLateHistoryRecords = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late-history-records?status=all`, {
    mode: "cors",
    credentials: "include",
  });

export const processLateHistoryRecord = (tenant_id, late_history_record_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/late-history-records/${late_history_record_id}/process`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );
