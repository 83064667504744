import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DataGrid from "../../../ui/components/DataGrid";
import { formatAmount, renderHeader } from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { ScrollableContainer } from "../DistributionsBOH/helpers";
import {
  renderBillingRow,
  renderExtendedRow,
  setInititalReviewData,
} from "./helpers";
import { processBiling } from "./_api";
import { Loader } from "../../../ui/components/Loader";

const HEADINGS = [
  ["buyer_name", "Buyer"],
  ["buyer_total", "Buyer Total"],
  ["fee", "Fee"],
  ["amount", "Total"],
];

export function Fees({
  handleNextStep,
  handlePrevStep,
  tenantId,
  reviewedItem = {},
  setReviewedItem,
}) {
  const { request } = useFetch();
  const data = setInititalReviewData(reviewedItem);

  const [loading, setLoading] = useState(false);

  const renderTotal = (data = []) => {
    const total = data
      .map(({ amount }) => amount)
      .reduce((acc, value) => acc + value, 0);
    return (
      <div className="text-right mr-20 border-bottom border-top py-5">
        <strong>Total : </strong> {formatAmount(total)}
      </div>
    );
  };

  const handleSubmit = () => {
    if (reviewedItem.status === "processed") {
      handleNextStep();
    } else {
      setLoading(true);
      request(processBiling, tenantId, reviewedItem.id)
        .then((data) => {
          if (!data) return;
          setReviewedItem(data);
          handleNextStep();
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {loading && (
        <Loader
          isOpen={loading}
          onClose={() => setLoading(false)}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ScrollableContainer>
              <div className="d-flex justify-content-between mb-5">
                <h3 className="text-dark mb-5">Calculate Fees</h3>
              </div>
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderBillingRow}
                extendable
                renderExtendedRow={renderExtendedRow}
                hideSelect={true}
                paginationEnabled={false}
              />
            </ScrollableContainer>
            {renderTotal(data)}
            <div className="d-flex justify-content-end align-items-center mt-5">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handlePrevStep}
              >
                Prev
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
