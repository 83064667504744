export const bankOptions = [
  { value: "CB", label: "Commonwealth Bank" },
  { value: "NAB", label: "National Australia Bank" },
  { value: "ANZ", label: "ANZ Bank" },
];

export const accountTypeOptions = [
  { value: "internal", label: "Ledger" },
  { value: "external", label: "External" },
  { value: "investment", label: "Investment" },
];

export const ledgerTypeOptions = [
  { value: "buyer", label: "Buyer" },
  { value: "biller", label: "Biller" },
];
