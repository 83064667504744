import { addDays } from "date-fns";
import * as Yup from "yup";

export const organisationDetailsSchema = Yup.object({
  name: Yup.string().required("Organisation Name is a required field"),
  legalName: Yup.string().required("Legal Name is a required field"),
  shortCode: Yup.string().matches(
    /(^[A-Z0-9]+$)/,
    "Only alphanumeric uppercase characters are allowed"
  ),
  phone: Yup.string()
    .length(10)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  mobile: Yup.string()
    .length(10)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  email: Yup.string()
    .email()
    .required("Email Address is a required field")
    .matches(/(^[a-z0-9@._+-]+$)/, "Only lowercase characters are allowed"),
  physicalAddress: Yup.string().required("Trading Address is a required field"),
  physicalAddress2: Yup.string(),
  physicalSuburb: Yup.string().matches(
    /(^[A-Z ]+$)/,
    "Only uppercase characters are allowed"
  ),
  physicalState: Yup.string().required("Trading State is a required field"),
  physicalPostCode: Yup.string()
    .required("Trading Post Code is a required field")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  postalAddress: Yup.string().required("Postal Address is a required field"),
  postalAddress2: Yup.string(),
  postalSuburb: Yup.string().matches(
    /(^[A-Z ]+$)/,
    "Only uppercase characters are allowed"
  ),
  postalState: Yup.string().required("Postal State is a required field"),
  postalPostCode: Yup.string()
    .required("Postal Post Code is a required field")
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  ABN: Yup.string().matches(/(^[0-9 ]+$)/, "Must be 11 digits"),
  ACN: Yup.string().matches(/(^[0-9 ]+$)/, "Must be 9 digits"),
  indicative_limit: Yup.string().matches(
    /(^[0-9]+$)/,
    "Only numbers are allowed"
  ),
});

export const ContactsDetailsSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  aka: Yup.string().required(),
  phone: Yup.string()
    .required()
    .length(10)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  mobile: Yup.string()
    .required()
    .length(10)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  organisation_id: Yup.string().matches(
    /(^[A-Z0-9]+$)/,
    "Only alphanumeric uppercase characters are allowed"
  ),
  email: Yup.string()
    .email()
    .required()
    .matches(/(^[a-z0-9@._+-]+$)/, "Only lowercase characters are allowed"),
  start_date: Yup.string(),
  end_date: Yup.string(),
  last_review_date: Yup.string(),
  access_card_expiry_date: Yup.string(),
});

export const CommentSchema = Yup.object({
  comment: Yup.string().required(),
});

const internalValidation = (newAccount) => {
  if (newAccount) {
    return Yup.string()
      .max(10)
      .matches(/(^[0-9a-zA-Z]+$)/, "Only alphanumeric characters are allowed");
  } else
    return Yup.string()
      .required()
      .max(10)
      .matches(/(^[0-9a-zA-Z]+$)/, "Only alphanumeric characters are allowed");
};

export const AccountSchema = (newAccount) => {
  return Yup.object({
    account_type: Yup.string().required(),
    ledger_type: Yup.string().when("account_type", {
      is: "internal",
      then: Yup.string().required("You must provide Ledger type"),
      otherwise: Yup.string().strip(),
    }),
    payment_cycle_id: Yup.string().when("ledger_type", {
      is: "buyer",
      then: Yup.string().required("You must provide a Payment Cycle"),
      otherwise: Yup.string().strip(),
    }),
    account_name: Yup.string()
      .required()
      .max(32, "Must be at most 32 characters including spaces")
      .matches(/(^[a-zA-Z0-9 ]+$)/, "Only alphanumeric characters are allowed"),
    account_number: Yup.string().when("account_type", {
      is: "external",
      then: Yup.string()
        .required()
        .max(9)
        .matches(/(^[0-9]+$)/, "Must contain only numbers"),
      otherwise: internalValidation(newAccount),
    }),
    bsb: Yup.string().when("account_type", {
      is: "external",
      then: Yup.string()
        .required("BSB is a required field")
        .matches(/(\d{3}-\d{3})/, "Must be 6 digits"),
      otherwise: Yup.string().strip(),
    }),
    bank: Yup.string().when("account_type", {
      is: "external",
      then: Yup.string().required(),
      otherwise: Yup.string().strip(),
    }),
  });
};

export const SecuritySchema = Yup.object({
  security_type: Yup.string().required("Security type is a required field"),
  required_value: Yup.string()
    .required("Required value is a required field")
    .matches(/^\d+\.\d{2}?$/, "Must be a digit with 2 decimal places"),
  actual_value: Yup.string()
    .required("Actual value is a required field")
    .matches(/^\d+\.\d{2}?$/, "Must be a digit with 2 decimal places"),
  date_commenced: Yup.string().required(),
  expires: Yup.boolean(),
  date_expiry: Yup.string().when("expires", {
    is: true,
    then: Yup.string().required(),
    otherwise: Yup.string().strip(),
  }),
});

export const TenantDetailSchema = Yup.object({
  address_1: Yup.string().required(),
  address_2: Yup.string(),
  city: Yup.string().matches(
    /(^[A-Z]+$)/,
    "Only uppercase characters are allowed"
  ),
  general_email: Yup.string().email(),
  legal_name: Yup.string(),
  name: Yup.string().required(),
  phone: Yup.string()
    .length(10)
    .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
  postcode: Yup.string()
    .length(4)
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  state: Yup.string().required(),
  web_address: Yup.string(),
});

export const LedgerAccountSchema = Yup.object({
  ledger_type: Yup.string().required(),
  account_name: Yup.string()
    .required()
    .max(32, "Must be at most 32 characters including spaces")
    .matches(/(^[a-zA-Z0-9 ]+$)/, "Only alphanumeric characters are allowed"),
  account_number: Yup.string()
    .max(10)
    .matches(/(^[0-9a-zA-Z]+$)/, "Only alphanumeric characters are allowed"),
});

const findDuplicate = (value, users, data) => {
  return users.find((email) => email === value && email !== data.email);
};

export const UserSchema = (users, data) => {
  return Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    phone_number: Yup.string()
      .required()
      .max(13)
      .min(9)
      .matches(/(^[0-9+]+$)/, "Only numbers are allowed"),
    email: Yup.string()
      .email()
      .required()
      .matches(/(^[a-z0-9@._+-]+$)/, "Only lowercase characters are allowed")
      .test(
        "Email duplicate",
        "Email Address already exists",
        (value) => value !== findDuplicate(value, users, data)
      ),
  });
};

export const PaymentCyclesSchema = Yup.object({
  description: Yup.string().required(),
  start_date: Yup.string().required(),
  length: Yup.number()
    .typeError("length must be a number")
    .required()
    .min(1)
    .max(365),
  term: Yup.number()
    .typeError("term must be a number")
    .required()
    .min(1)
    .max(365),
  cycle_count: Yup.number()
    .typeError("Cycle count must be a number")
    .required()
    .min(1)
    .max(104),
});

export const EditPaymentCycleSchema = Yup.object({
  start_date: Yup.string().required(),
  due_date: Yup.string().required(),
  finish_date: Yup.date()
    .required()
    .when(
      "start_date",
      (start, schema) =>
        start && schema.min(start, "Can't be earlier than start date")
    ),
  note: Yup.string().required(),
});

export const FeesCardSchema = Yup.object({
  minimum_fee_value: Yup.string().when("minimum_fee_enabled", {
    is: true,
    then: Yup.string().required("Minimum Fee is a required field"),
  }),
  name: Yup.string().required("Name is a required field"),
  start_date: Yup.string().required("Start Date is a required field"),
  fee_type: Yup.string().required("Type is a required field"),
  calculation: Yup.string().required("Calculation is a required field"),
  tax_id: Yup.string().required("Tax is a required field"),
  account_id: Yup.string().required("Account is a required field"),
  recurring_freq: Yup.string().when("fee_type", {
    is: "recurring",
    then: Yup.string().required("Frequency is a required field"),
    otherwise: Yup.string().strip(),
  }),
  recurring_day: Yup.string().when("fee_type", {
    is: "recurring",
    then: Yup.string().when("recurring_freq", {
      is: (recurring_freq) =>
        ["weekly", "fortnightly", "monthly", "annual"].includes(recurring_freq),
      then: Yup.string(),
      otherwise: Yup.string().strip(),
    }),
    otherwise: Yup.string().strip(),
  }),
  recurring_month: Yup.string(),
  action_hook: Yup.string().when("fee_type", {
    is: "action",
    then: Yup.string().required("Required field"),
    otherwise: Yup.string().strip(),
  }),
  action_tag_filter: Yup.string(),
});

export const TaxFormSchema = Yup.object({
  name: Yup.string().required(),
  rate: Yup.number()
    .typeError("Rate must be a number")
    .required(),
  account_id: Yup.string().required(),
});

export const ShareFormSchema = Yup.object({
  share_number: Yup.string().required("Share Number is required"),
  share_certificate_number: Yup.string().required(
    "Share Certificate Number is required"
  ),
  date_issued: Yup.string().required("Date is required"),
  held_beneficially: Yup.boolean().required("Required field"),
  beneficiary_id: Yup.string().when("held_beneficially", {
    is: true,
    then: Yup.string().required("Beneficiary is required"),
    otherwise: Yup.string().strip(),
  }),
  shareholder_id: Yup.string().required("Shareholder is required"),
  billing_entity_id: Yup.string().required("Billing Entity is required"),
});

export const TransferShareSchema = Yup.object({
  share_number: Yup.string().required("Share Number is required"),
  share_certificate_number: Yup.string().required(
    "Share Certificate Number is required"
  ),
  date_transferred: Yup.string().required("Date is required"),
  held_beneficially: Yup.boolean().required("Required field"),
  beneficiary_id: Yup.string().when("held_beneficially", {
    is: true,
    then: Yup.string().required("Beneficiary is required"),
    otherwise: Yup.string().strip(),
  }),
  shareholder_id: Yup.string().required("Shareholder is required"),
  billing_entity_id: Yup.string().required("Billing Entity is required"),
  short_name: Yup.string().required("Short Name is required"),
});

export const CancelShareSchema = Yup.object({
  cancelled_folio: Yup.string().required("Folio is required"),
  date_cancelled: Yup.string().required("Date is required"),
});

export const TenantSettingsSchema = Yup.object({
  ledger_connection_string: Yup.string(),
  primary_contact: Yup.string(),
  support_phone: Yup.string(),
  support_email: Yup.string(),
});

export const TenantBrandingSchema = Yup.object({
  brand_logo: Yup.string(),
  icon: Yup.string(),
});

export const TagsManagerFormSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  type: Yup.string().required("Type is a required field"),
});

export const CommentsManagerFormSchema = Yup.object({
  comment_type_name: Yup.string().required("Comment Type is a required field"),
  comment_category: Yup.string().required(
    "Comment Category is a required field"
  ),
});

export const TransactionRuleSchema = Yup.object({
  field_name: Yup.string().required("Name is required"),
  filter_operator: Yup.string().required("Operator is required"),
  filter_value: Yup.string().required("Value is required"),
});

export const TagPairSchema = Yup.object({
  organisation: Yup.string().required("Organisation Tag is required"),
  contact: Yup.string().required("Contact Tag is required"),
});

export const DisputeTransactionSchema = Yup.object({
  reason: Yup.string().required("Required field"),
  notes: Yup.string().required("Required field"),
});

export const LateLettersSchema = Yup.object({
  late_letter_template_id: Yup.string().required("Required field"),
  organisation_id: Yup.string().required("Required field"),
  contact_id: Yup.string().required("Required field"),
  content: Yup.string().required("Required field"),
});

const unique = (arr = []) => {
  return arr.filter((e, i) => arr.indexOf(e) === i);
};

const getUniqueCharacters = (value = "") => {
  return unique(value.split("")).length >= 5;
};

export const ReasonForDelayedPaymentSchema = Yup.object({
  note: Yup.string()
    .required("Required field")
    .min(5, "Must be at least 5 characters")
    .test("Unique Characters", "Must be at least 5 unique chracters", (value) =>
      getUniqueCharacters(value)
    ),
});

export const NewRuleSchema = Yup.object({
  name: Yup.string().required("Required field"),
  action: Yup.string().required("Required field"),
  organisation_id: Yup.string().required("Required field"),
  account_id: Yup.string().required("Required field"),
  field_name: Yup.string().required("Required field"),
  operator: Yup.string().required("Required field"),
  field_value: Yup.string().required("Required field"),
  field_value_2: Yup.string().strip(),
});

export const invoiceLineDetailsSchema = Yup.object({
  corrected_unit_price: Yup.string()
    .required("Price is a required field")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  corrected_quantity: Yup.string()
    .required("Quantity is a required field")
    .matches(/(^[0-9]+$)/, "Only numbers are allowed"),
  discrepancy_reason: Yup.string().required("Reason is a required field"),
  discrepancy_note: Yup.string().required("Notes is required field"),
});

export const lateLetterTemplateSchema = Yup.object({
  name: Yup.string().required("Name is a required field"),
  content: Yup.string().required("Content is a required field"),
});

export const auditTrailSchema = Yup.object({
  from: Yup.date().required("From is a required field"),
  to: Yup.date()
    .required()
    .when(
      "from",
      (from, schema) =>
        from &&
        schema
          .min(from, "Can't be earlier than start date")
          .max(addDays(from, 7), "Can't be later than 7 days after start date")
    ),
});

export const extendFormSchema = Yup.object({
  added_cycle_count: Yup.number()
    .typeError("Number of Cycles be a number")
    .required("Number of Cycles is a required field")
    .min(1, "Number of Cycles must be greater than or equal to 1")
    .max(100, "Number of Cycles must be less than or equal to 100"),
});

export const ledgerTransactionsInternalSchema = Yup.object({
  from: Yup.date().required("Start date is required"),
  to: Yup.date()
    .required("End date is required")
    .when(
      "from",
      (from, schema) =>
        from && schema.min(from, "Can't be earlier than start date")
    ),
  organisation: Yup.string().required("Organisation is required"),
  account: Yup.string().required("Account is required"),
});

export const ledgerTransactionsExternalSchema = Yup.object({
  from: Yup.date().required("Start date is required"),
  to: Yup.date()
    .required("End date is required")
    .when(
      "from",
      (from, schema) =>
        from && schema.min(from, "Can't be earlier than start date")
    ),
  organisation: Yup.string().required("Organisation is required"),
  account: Yup.string().required("Account is required"),
});

const commonStringValidator = Yup.number()
  .positive()
  .test(
    "is-decimal",
    "The amount should be a decimal with maximum two digits after comma",
    (val: any) => {
      if (val != undefined) {
        return /^\d+(\.\d{0,2})?$/.test(val);
      }
      return true;
    }
  )
  .required("Deposit Value is requiredd");

export const DepositDetailSchema = Yup.object({
  account_id: Yup.string()
    .required("Account is required")
    .test(
      "select account",
      "Please select an account",
      (value) => value !== "0"
    ),
  deposit_value: Yup.number()
    .positive()
    .test(
      "is-decimal",
      "The amount should be a decimal with maximum two digits after comma",
      (val) => {
        if (val !== undefined) {
          return /^\d+(\.\d{0,2})?$/.test(val);
        }
        return true;
      }
    )
    .required("Deposit Value is requiredd"),
  deposit_type: Yup.string().test(
    "select deposit type",
    "Please select a deposit type",
    (value) => value !== "0"
  ),
  deposit_description: Yup.string(),
  deposit_date: Yup.string(),
  bank_name: Yup.string(),
  deposit_source: Yup.string(),
  bank_payment_line_description: Yup.string(),
});
