import React from "react";

export function RulesAndByLaws() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          flex: "1 0 auto",
          width: "100%",
          backgroundColor: "#292824",
          backgroundImage:
            "linear-gradient(to right, #292824 0%, transparent 100%), url('/media/bg/cherry.jpg')",
          backgroundPosition: "50% 34%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxHeight: "200px",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Rules and By-Laws
          </div>
        </div>
        <div
          style={{
            width: "400px",
            height: "200px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div style={{ padding: 60, fontSize: 14 }}>
        <div style={{ fontSize: 16 }}>
          <b>Rules and By-Laws</b>
        </div>
        <br />
        <ul>
          <li>
            <a
              href="/media/brands/brismark/wholesaler/files/Brismark_Rules.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Brismark Rules
            </a>
          </li>
          <li>
            <a
              href="/media/brands/brismark/wholesaler/files/Credit_Service_By-Laws.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Credit Service By-Laws
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
