import React from "react";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { SecuritySchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { MappedSwitch } from "../../components/Switch";
import { MappedDatePicker } from "../../components/DatePicker";
import { useFetch } from "../../../hooks/fetch.hook";
import { addOrganisationSecurity } from "../../../app/modules/Organisations/_api";
import { securityTypeOptions } from "./constants";
import { formatDateToUTC } from "../../helpers/helpers";

export function SecurityAdd({ id, tenantId, securityData, onClose, setData }) {
  const {
    security_type,
    required_value,
    actual_value,
    date_commenced,
    expires,
    date_expiry,
  } = securityData;
  const { request } = useFetch();

  const initialValues = {
    security_type: security_type || "",
    required_value: required_value || "",
    actual_value: actual_value || "",
    date_commenced: date_commenced || "",
    expires: expires !== false,
    date_expiry: date_expiry || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (values) => {
    request(addOrganisationSecurity, tenantId, id, {
      ...values,
      date_commenced: formatDateToUTC(values.date_commenced),
      date_expiry: formatDateToUTC(values.date_expiry),
    }).then(() => onClose(false));
  };

  const handleClose = () => onClose(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SecuritySchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded px-21 py-19">
            <div className="row">
              <div className="col-5">
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Security Type</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedSelect
                      name="security_type"
                      options={securityTypeOptions}
                      data-testid="security_type"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Security Name</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="name"
                      wrapperClassName="w-100"
                      data-testid="name"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Required Value</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="required_value"
                      wrapperClassName="w-100"
                      data-testid="required_value"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Actual Value</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="actual_value"
                      wrapperClassName="w-100"
                      data-testid="actual_value"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Received Date</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedDatePicker
                      name="date_commenced"
                      onBlur={handleOnBlur}
                      className="border border-secnodary"
                      testId="date_commenced"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Expires</p>
                  </div>
                  <div className="col-10 mr-auto">
                    <MappedSwitch
                      name="expires"
                      checked={props.values.expires}
                      onBlur={handleOnBlur}
                      data-testid="expires"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Expiry Date</p>
                  </div>
                  <div className="col-10 mr-auto">
                    {props.values.expires ? (
                      <MappedDatePicker
                        name="date_expiry"
                        onBlur={handleOnBlur}
                        className="border border-secnodary"
                        testId="date_expiry"
                      />
                    ) : (
                      <MappedInput
                        name="date_expiry"
                        data-testid="date_expiry"
                        disabled
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={props.handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
