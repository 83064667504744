import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import { Select } from "../Select";

export const DefaultPagination = ({
  data,
  handleChangePerPage,
  perPage,
  page,
  hideSelect,
  handleChangePage,
  paginationOptions = [],
}) => {
  return (
    <div
      className={`container mt-5 p-5 bg-white d-flex 
          align-items-center
          justify-content-${hideSelect ? "center" : "between"}`}
    >
      {Math.ceil(data.length / perPage) > 1 ? (
        <Pagination
          count={Math.ceil(data.length / perPage)}
          page={page}
          showFirstButton
          showLastButton
          className="text-primary"
          color="secondary"
          classes={{ root: "text-primary" }}
          shape="rounded"
          onChange={handleChangePage}
        />
      ) : (
        <div />
      )}

      {hideSelect ? null : (
        <div className="d-flex px-8 justify-content-center align-items-center">
          <div>
            <Select
              className="px-2 py-1 h-auto"
              value={perPage}
              options={paginationOptions.map((option) => ({
                value: option,
                label: option,
              }))}
              IconComponent={ExpandMoreIcon}
              onChange={handleChangePerPage}
            />
          </div>
          <div className="text-muted pl-2">
            Showing {(page - 1) * perPage + 1}-
            {data.length > perPage ? page * perPage : data.length} of {data.length}
          </div>
        </div>
      )}
    </div>
  );
};
