import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSharesData } from "./_redux/actions";

import { getShareRegisterList } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader, renderUsersRow } from "../../../ui/helpers/helpers";
import { ReportsForm } from "./ReportsForm";

const HEADINGS = [
  ["share_number", "Share Number"],
  ["shortname", "Name"],
  ["phone", "Phone"],
  ["email", "Email"],
  ["isCancelled", "Status"],
];

export function List() {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [shareRegisters, setShareRegisters] = useState([]);
  const [loading, setLoading] = useState(true);

  const data =
    useSelector(({ sharesRegister }) => sharesRegister.sharesList) || [];

  useEffect(() => {
    request(getShareRegisterList, TENANT_ID)
      .then((data) => {
        if (data) {
          return data.map(
            ({
              share_number,
              shortname,
              billing_entity,
              date_cancelled,
              ...item
            }) => ({
              ...item,
              share_number,
              shortname,
              email: billing_entity?.email || "",
              phone: billing_entity?.phone || "",
              isCancelled: date_cancelled ? "Cancelled" : "Active",
            })
          );
        }
      })
      .then((data) => {
        dispatch(setSharesData(data));
        setShareRegisters(data);
        setLoading(false);
      });
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    "Share Number": data.map((item) => item.share_number),
    Name: data.map((item) => item.shortname),
    Phone: data.map((item) => item.phone),
    Email: data.map((item) => item.email),
    Status: data.map((item) => item.isCancelled),
  };

  const handleSearch = (search) => {
    const data = shareRegisters;
    dispatch(
      setSharesData(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            HEADINGS.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  const resetSearch = () => {
    dispatch(setSharesData(shareRegisters));
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Shareholders</h3>
            </div>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={KEY_MAP}
                  placeholder="Search by tags..."
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderUsersRow}
              link="share-register"
              loading={loading}
            />
            <hr />
            <ReportsForm />
          </div>
        </div>
      </div>
    </>
  );
}
