import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import * as auth from "../Auth/_redux/authRedux";
import { useFetch } from "../../../hooks/fetch.hook";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { useHistory } from "react-router-dom";
import TenantSwitcher from "./TenantSwitcher";
import {
  modifyUserSelectedTenant,
  getTenants,
  getDefaultTenantOrganisation,
} from "./_api";
import {
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Fade,
  ListItemIcon,
  ListItemText,
  CircularProgress,
} from "@material-ui/core/";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from "@material-ui/icons/Close";

function TenantSwitcherMenu(props) {
  const { user } = useSelector((state) => state.auth);
  const [tenants, setTenants] = useState(user.tenants);
  const [isLoading, setIsLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const open = Boolean(anchorEl);
  const { request } = useFetch();

  function fetchTenants() {
    request(getTenants)
      .then((tenants) => {
        setTenants(tenants);
      })
      .then(() => {
        let fetchedTenants = {
          ...user,
          tenants: tenants,
        };
        props.fulfillUser(fetchedTenants);
      });
  }

  useEffect(() => {
    if (anchorEl) {
      fetchTenants();
    }
    // eslint-disable-next-line
  }, [anchorEl, setAnchorEl]);

  const handleClick = (event) => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTenantClick = (e) => {
    setIsLoading(true);
    const { tenantId } = e.currentTarget.dataset;
    request(getDefaultTenantOrganisation, tenantId)
      .then((defaultTenantData) => {
        if (defaultTenantData) {
          let defaultOrgId = defaultTenantData.id;
          let defaultOrgName = defaultTenantData.name;

          let userChangeTenantAndOrg = {
            ...user,
            default_organisation_id: parseInt(defaultOrgId),
            default_organisation_name: defaultOrgName,
            selectedTenant: parseInt(tenantId),
          };
          props.fulfillUser(userChangeTenantAndOrg);

          const data = { "custom:selected_tenant": tenantId };
          request(modifyUserSelectedTenant, data).then(() => {
            history.push("/dashboard");
            setIsLoading(false);
          });
        }
      })
      .finally(handleClose);
  };

  return (
    <div>
      {(!user.selectedTenant || !user.tenants.length) && <TenantSwitcher />}
      <Button
        className="btn btn-warning btn-bold"
        style={{ width: "100%" }}
        aria-controls="fade-menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        Switch Credit Service
      </Button>
      <Menu
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        anchorReference="none"
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        PaperProps={{
          style: {
            width: 375,
            top: 65,
            right: 0,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            padding: 0,
            color: "white",
            background: "rgba(0, 0, 0, 0.54)",
          }}
        >
          <CloseIcon />
        </IconButton>

        <Typography
          variant="subtitle2"
          gutterBottom
          style={{ padding: "16px 0 0 25px", fontSize: "1rem" }}
        >
          Select Your Credit Service
        </Typography>
        {isLoading ? (
          <CircularProgress
            className="text-warning"
            size="2rem"
            style={{ margin: "20px auto", display: "block" }}
          />
        ) : (
          <div>
            {tenants.map((tenant) => {
              return (
                <MenuItem
                  key={tenant.id}
                  onClick={handleTenantClick}
                  data-tenant-id={tenant.id}
                >
                  <ListItemIcon style={{ paddingLeft: 8 }}>
                    {tenant.icon ? (
                      <span className="svg-icon menu-icon">
                        <img src={toAbsoluteUrl(tenant.icon)} alt="" />
                      </span>
                    ) : (
                      <BusinessIcon
                        style={{
                          color: tenant.id === user.selectedTenant && "#6993ff",
                        }}
                      />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <div>
                        {tenant.name}
                        {tenant.id === user.selectedTenant && (
                          <span
                            style={{
                              fontSize: 10,
                              display: "inline-block",
                              color: "#5E6973",
                              background: "#EEF0F8",
                              padding: "0px 5px",
                              margin: "0 0 0 15px",
                              borderRadius: "0.22rem",
                            }}
                          >
                            ACTIVE
                          </span>
                        )}
                      </div>
                    }
                  />
                </MenuItem>
              );
            })}
          </div>
        )}
      </Menu>
    </div>
  );
}

export default connect(null, auth.actions)(TenantSwitcherMenu);
