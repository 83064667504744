import React from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { LedgerAccountSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { useFetch } from "../../../hooks/fetch.hook";
import { ledgerTyeOptions } from "./constants";

function LedgerAccountForm({
  history,
  data,
  tenant_id,
  default_organisation_id,
  setData,
  onSubmit,
}) {
  const { request } = useFetch();
  const { ledger_type, account_name, account_type, account_number, id } = data;

  const initialValues = {
    ledger_type: ledger_type || "",
    account_name: account_name || "",
    account_number: account_number || "",
  };

  if (!Object.keys(data).length) {
    return <p className="text-center">Loading...</p>;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    request(
      onSubmit,
      tenant_id,
      default_organisation_id,
      { account_type: account_type, ...values },
      id
    ).then((data) => {
      if (data) {
        return history.push(`/ledger-accounts`);
      }
    }).finally(() => setSubmitting(false));
  };

  const handleClose = () => history.go(-1);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={LedgerAccountSchema}
      onSubmit={handleSubmit}
    >
      {({ values, handleSubmit, isSubmitting }) => (
        <>
          <div className="px-7 py-11">
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Type:</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedSelect
                  name="ledger_type"
                  options={ledgerTyeOptions}
                  data-testid="ledger_type"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Account Name:</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="account_name"
                  wrapperClassName="w-100"
                  data-testid="account_name"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Account Number:</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="account_number"
                  wrapperClassName="w-100"
                  data-testid="account_number"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center p-7">
            <div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary px-15 mr-5"
                data-testid="submit"
                disabled={isSubmitting}
              >
                Save
              </button>
              <button
                onClick={handleClose}
                className="btn btn-outline-primary px-15"
              >
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(LedgerAccountForm);
