/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../hooks/fetch.hook";
import { MappedInput } from "../components/Input";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import { Auth } from "aws-amplify";
import { error } from "../../helpers/toasts";

export function ChangePasswordForm({ open, setOpen }) {
  const [loading, setLoading] = useState(false);

  const initialValues = {
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              current_password: Yup.string().required(
                "Current password is required"
              ),
              new_password: Yup.string()
                .required("Please enter your password")
                .matches(
                  /^.*(?=.{14,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                  "Password must contain at least 14 characters, one uppercase, one number and one special case character"
                ),
              confirm_new_password: Yup.string()
                .required("Please confirm your password")
                .when("new_password", {
                  is: (new_password) =>
                    new_password && new_password.length > 0 ? true : false,
                  then: Yup.string().oneOf(
                    [Yup.ref("new_password")],
                    "Password doesn't match"
                  ),
                }),
            })}
            onSubmit={(values) => {
              setLoading(true);

              Auth.currentAuthenticatedUser()
                .then((user) => {
                  return Auth.changePassword(
                    user,
                    values.current_password,
                    values.new_password
                  );
                })
                .then((data) => {
                  if (data) {
                    setLoading(false);
                    handleClose();
                  }
                })
                .catch((err) => {
                  error(err.message);
                  setLoading(false);
                });
            }}
          >
            {(props) => (
              <div className="bg-white rounded">
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-3">
                      <div className="col-4 my-auto">
                        <p className="text-right text-muted mb-0">
                          Current Password
                        </p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedInput
                          name="current_password"
                          wrapperClassName="w-100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-4 my-auto">
                        <p className="text-right text-muted mb-0">
                          New Password
                        </p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedInput
                          name="new_password"
                          wrapperClassName="w-100"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-4 my-auto">
                        <p className="text-right text-muted mb-0">
                          Confirm New Password
                        </p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedInput
                          name="confirm_new_password"
                          wrapperClassName="w-100"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-4 mb-2">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size="1.5rem"
                        style={{
                          margin: "0 auto",
                          // color: "#6ba3ee !important",
                        }}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
