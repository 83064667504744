import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getDeposit } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import { getDepositById, createDeposit, modifyDeposit } from "./_api";
import DepositDetailsForm from "../../../ui/structures/DepositDetailsForm";

export function Card({ match, history }) {
  const deposit_id = match.params.id;

  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user && user.selectedTenant;

  const { request } = useFetch();
  const [cardData, setCardData] = useState({});

  const data = useSelector(getDeposit(deposit_id)) || {};

  useEffect(() => {
    if (deposit_id) {
      if (Object.keys(data).length === 0) {
        request(getDepositById, TENANT_ID, deposit_id)
          .then((data) => data)
          .then((data) => setCardData(data));
      } else setCardData(data);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <div className="font-weight-bold mb-3">
                <h3 className="text-dark">
                  {deposit_id ? "Edit" : "Create"} Deposit
                </h3>
              </div>
            </div>
            <DepositDetailsForm
              history={history}
              data={cardData}
              onSubmit={deposit_id ? modifyDeposit : createDeposit}
              setData={setCardData}
              isAddNewDeposit={deposit_id ? false : true}
            />
          </div>
        </div>
      </div>
    </>
  );
}
