import React, { useState, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core/";
import * as auth from "../../app/modules/Auth/_redux/authRedux";
import { FilterSelect } from "../components/FilterSelect";
import { useFetch } from "../../hooks/fetch.hook";
import { getOrganisationsListSimple } from "../../app/modules/Organisations/_api";
import { getTenant } from "../../app/modules/Tenants/_api";

const userTypeOptions = [
  { value: "BOH", label: "Back of House" },
  { value: "Biller", label: "Biller" },
  { value: "Buyer", label: "Buyer" },
];

function ProfileUserTypeSwitcher(props) {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [isLoading, setIsLoading] = useState(false);
  const [userType, setUserType] = useState((user || {}).userCategory || "BOH");
  const [defaultOrganisationId, setDefaultOrganisationId] = useState(
    (user || {}).default_organisation_id || 0
  );
  const [organisationsList, setOrganisationsList] = useState([
    { value: 0, label: "Select Organisation" },
  ]);

  function changeUser(type) {
    setIsLoading(true);

    if (organisationsList.length > 1 && type === "BOH") {
      request(getTenant, TENANT_ID).then((tenant) => {
        const defaultOrganisationId = tenant.default_organisation.id;
        console.log(typeof defaultOrganisationId);
        let userChangeOrgData = {
          ...user,
          userCategory: type,
          default_organisation_id: defaultOrganisationId,
          default_organisation_name: organisationsList.filter(
            ({ value }) => value === defaultOrganisationId
          )?.label,
        };
        props.fulfillUser(userChangeOrgData);
        setDefaultOrganisationId(defaultOrganisationId);
      });
    } else {
      let userChange = {
        ...user,
        userCategory: type,
      };
      props.fulfillUser(userChange);
    }

    setIsLoading(false);
  }

  function changeDefaultOrganisation() {
    setIsLoading(true);
    let userChangeTenantAndOrg = {
      ...user,
      default_organisation_id: defaultOrganisationId,
      default_organisation_name: organisationsList.filter(
        ({ value }) => value === defaultOrganisationId
      )?.label,
    };
    props.fulfillUser(userChangeTenantAndOrg);

    setIsLoading(false);
  }

  useEffect(() => {
    setOrganisationsList([{ value: 0, label: "Select Organisation" }]);
    request(getOrganisationsListSimple, TENANT_ID).then((data) => {
      if (data) {
        setOrganisationsList((prevList) => [...prevList, ...data]);
      }
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    changeUser(userType);
    // eslint-disable-next-line
  }, [userType, setUserType]);

  useEffect(() => {
    if (organisationsList.length > 1 && userType !== "BOH") {
      changeDefaultOrganisation();
    }
    // eslint-disable-next-line
  }, [defaultOrganisationId, setDefaultOrganisationId]);

  return (
    <>
      <div>
        <div className="col-12 d-flex align-items-center">
          <p className="mr-2 mt-3">
            I want to set the current user’s organisation to:
          </p>
          <div className="d-flex align-items-center">
            <FilterSelect
              options={organisationsList}
              multiple={false}
              minWidth={200}
              value={defaultOrganisationId}
              onChange={(e) => setDefaultOrganisationId(e.target.value)}
              disabled={userType === "BOH"}
            />
            <p className="mx-2 mt-3">and I want to change my user type to: </p>
            <FilterSelect
              options={userTypeOptions}
              multiple={false}
              minWidth={200}
              value={userType}
              onChange={(e) => setUserType(e.target.value)}
            />
            {isLoading && (
              <CircularProgress className="text-warning" size="2rem" />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default connect(null, auth.actions)(ProfileUserTypeSwitcher);
