import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Fade, CircularProgress } from "@material-ui/core/";
import { Check as CheckIcon } from "@material-ui/icons/";
import { useFetch } from "../../../../hooks/fetch.hook";
import { MappedTextArea } from "../../../../ui/components/TextArea";
import { MappedInput } from "../../../../ui/components/Input";
import { sendUserEmail } from "../../UserManagment/_api";
import ButtonMailto from "../../../../ui/components/ButtonMailto";

export function ContactPage({ TENANT_ID }) {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const initialValues = {
    subject: "",
    message: "",
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          flex: "1 0 auto",
          width: "100%",
          backgroundColor: "#292824",
          backgroundImage:
            "linear-gradient(to right, #292824 0%, transparent 100%), url('/media/bg/orange.jpg')",
          backgroundPosition: "50% 34%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxHeight: "200px",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Contact Us
          </div>

          <div style={{ color: "#f59220", fontSize: 16 }}>
            Need a hand? Fill out the form below, or contact one of the Credit
            Service team directly. We’re happy to help!
          </div>
        </div>
        <div
          style={{
            width: "400px",
            height: "200px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div
        className="row w-100 ml-0 bg-white"
        style={{ padding: "60px 22px", fontSize: 14 }}
      >
        <div
          className="col-md-5 col-xs-10 mx-auto pl-15"
          style={{ fontSize: 16 }}
        >
          <span style={{ fontSize: 18, fontWeight: "600", paddingBottom: 5 }}>
            Michael Costello <br />
          </span>
          Credit Service Controller <br />
          <ButtonMailto
            email="mcostello@brismark.com.au"
            subject="Hello & Welcome Credit Service"
            body="Dear Credit Service team!"
          >
            mcostello@brismark.com.au
          </ButtonMailto>
          <br />
          07 3915 4220 <br /> <br />
          <span style={{ fontSize: 18, fontWeight: "600", paddingBottom: 5 }}>
            Tammy Brereton
          </span>
          <br />
          Senior Credit Service Officer <br />
          <ButtonMailto
            email="brereton@brismark.com.au"
            subject="Hello & Welcome Credit Service"
            body="Dear Credit Service team!"
          >
            tbrereton@brismark.com.au
          </ButtonMailto>
          <br />
          07 3915 4227 <br /> <br />
          <span style={{ fontSize: 18, fontWeight: "600", paddingBottom: 5 }}>
            Audrey Stevens
          </span>
          <br />
          Credit Service Finance Officer <br />
          <ButtonMailto
            email="astevens@brismark.com.au"
            subject="Hello & Welcome Credit Service"
            body="Dear Credit Service team!"
          >
            astevens@brismark.com.au
          </ButtonMailto>
          <br />
          07 3915 4283
        </div>
        <div className="col-md-7 col-xs-10 mx-auto bg-white pb-12 px-8">
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              subject: Yup.string().required(),
              message: Yup.string().required(),
            })}
            onSubmit={(values) => {
              setLoading(true);
              request(sendUserEmail, TENANT_ID, values).then((data) => {
                setLoading(false);
                setSent(true);
              });
            }}
          >
            {(props) => (
              <div style={{ alignContent: "center" }}>
                <div
                  className="mb-10 text-center"
                  style={{ fontSize: "2rem", fontWeight: "400" }}
                >
                  Send us an email
                </div>
                <div className="row mb-3">
                  <div className="col-3 my-auto">
                    <p className="text-right text-muted mb-0">Subject</p>
                  </div>
                  <div className="col-md-6 col-xs-4 mr-auto">
                    <MappedInput
                      name="subject"
                      wrapperClassName="w-100"
                      placeholder="Hello"
                    />
                  </div>
                </div>
                <div className="row mb-8">
                  <div className="col-3">
                    <p className="text-right text-muted mt-2">Your Message</p>
                  </div>
                  <div className="col-md-9 col-xs-12 mr-auto">
                    <MappedTextArea
                      name="message"
                      rows={8}
                      placeholder="Your questions and special requests are always welcome."
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                  >
                    Send
                    {loading ? (
                      <Fade in={loading}>
                        <CircularProgress
                          className="text-white ml-3"
                          size="1rem"
                        />
                      </Fade>
                    ) : (
                      sent && (
                        <Fade in={sent}>
                          <CheckIcon
                            className="text-white ml-1"
                            fontSize="1.5rem"
                          />
                        </Fade>
                      )
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}
