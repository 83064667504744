const config = {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: "ap-southeast-2",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: "ap-southeast-2_fKJVnyAzj",

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "488pg6f0kmabhnmsj0qtdup4tf",

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: "app.creditservice.cloud",
      // OPTIONAL - Cookie path
      //path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 1,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true,
    },
  },
  ApiUrl: {
    Rest: "https://api.app.creditservice.cloud",
    WebSocket: "https://ws-api.app.creditservice.cloud",
  },
  Branding: {
    Logo: {
      "mmcs.app.creditservice.cloud": "/media/logos/mmcs/FSLLogo.png",
      "melbourne.app.creditservice.cloud": "/media/logos/mmcs/FSLLogo.png",
      "brisbane.app.creditservice.cloud": "/media/logos/crs/BrismarkLogo.svg",
      "app.creditservice.cloud": "/media/logos/generic/GenericLogo.svg",
    },
    Favicon: {
      "mmcs.creditservice.cloud": "/media/logos/mmcs/favicon.ico",
      "crs.creditservice.cloud": "/media/logos/crs/favicon.ico",
    },
    LoginBackground: {
      "mmcs.app.creditservice.cloud": "/media/brands/avocado.jpg",
      "melbourne.app.creditservice.cloud": "/media/brands/avocado.jpg",
      "brisbane.app.creditservice.cloud": "/media/brands/strawberry.jpg",
      "app.creditservice.cloud": "/media/brands/grapefruit.jpg",
      "crs.creditservice.cloud": "/media/brands/citrus.jpg",
    },
  },
};

export default config;
