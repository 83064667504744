import React from "react";
import { useSelector } from "react-redux";
import { ImportantMessages } from "../modules/DashboardFOHBiller/ImportantMessages";
import { CreditServiceMemos } from "../modules/DashboardFOHBiller/CreditServiceMemos";
import { StaticPageBox } from "../../ui/components/StaticPageBox";
import { NewPayments } from "../modules/DashboardFOHBiller/NewPayments";

const creditServicesItems = [
  {
    title: "Invoices and Statements",
    logo: "/media/brands/brismark/wholesaler/service-logos/HRandIR.svg",
    backgroundColor: "#5091CD",
    to: "/static/16",
  },
  {
    title: "Debt Recovery Solutions",
    logo: "/media/brands/brismark/wholesaler/service-logos/DebtRecovery.svg",
    backgroundColor: "#00AEEF",
    to: "/static/4",
  },
  {
    title: "Investment Service",
    logo:
      "/media/brands/brismark/wholesaler/service-logos/InvestmentService.svg",
    backgroundColor: "#00B5D1",
    to: "/static/45",
  },
  {
    title: "Rules and By-Laws",
    logo: "/media/brands/brismark/wholesaler/service-logos/WHS.svg",
    backgroundColor: "#5956A5",
    to: "/static/rules-and-by-laws",
  },
];
export function DashboardPageFOHBiller() {
  const { user } = useSelector((state) => state.auth);
  return (
    <div>
      <div className="row">
        <h3 className="col-12 mb-5">
          Welcome to the Brismark Credit Service, {user.fullname.split(" ")[0]}
        </h3>
      </div>
      <div className="row">
        <div className="col-xs-12 col-md-8">
          <ImportantMessages tenantId={user.selectedTenant} />
        </div>
        <div className="col-xs-12 col-md-4">
          <NewPayments
            TENANT_ID={user.selectedTenant}
            ORGANISATION_ID={user.default_organisation_id}
          />
          <CreditServiceMemos tenantId={user.selectedTenant} />
        </div>
      </div>
      <div className="row mt-8">
        <div className="col-12 ">
          <div className="w-100 mb-5">
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
                gridAutoRows: 160,
              }}
            >
              {creditServicesItems.map((item, id) => (
                <StaticPageBox key={id} item={item} />
              ))}
            </div>
          </div>
          <div
            className="card card-custom pr-3 align-items-center align-middle"
            style={{
              height: 205,
              backgroundImage:
                "url('/media/brands/brismark/wholesaler/InvestmentService.jpg')",
              backgroundPosition: "50% 34%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className="mt-24"
              style={{ fontSize: 24, fontWeight: 500 }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
