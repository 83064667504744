import { accountsTypes } from "./actions";

const initialAccountsState = {
  accountsList: [],
  showArchived: false,
};

export function accountsReducer(state = initialAccountsState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case accountsTypes.SET_DATA:
      return {
        ...state,
        accountsList: [...payload],
      };
    case accountsTypes.UPDATE_DATA:
      return {
        ...state,
        accountsList: [...state.accountsList, ...payload],
      };
    case accountsTypes.ARCHIVE_DATA:
      return {
        ...state,
        accountsList: [
          ...state.accountsList.filter(({ id }) => id !== payload),
        ],
      };
    case accountsTypes.UPDATE_ACCOUNT:
      const index = state.accountsList.findIndex(
        (item) => item.id === payload.id
      );
      const myState = [...state.accountsList];
      myState.splice(index, 1, payload);

      return {
        ...state,
        accountsList: [...myState],
      };
    case accountsTypes.TOGGLE_SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: !state.showArchived,
      };
    default:
      return state;
  }
}
