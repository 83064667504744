import React from "react";
import { Link } from "react-router-dom";
import DataGrid from "../../components/DataGrid";
import { List, ListItem, ListItemText } from "@material-ui/core";
import { renderContactsRow, renderHeader } from "../../helpers/helpers";

const HEADINGS = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["aka", "AKA"],
  ["mobile", "Mobile"],
  ["email", "Email"],
];

export function Contacts({
  contacts = [],
  organisationId,
  tenantId,
  name,
  loading,
  status,
}) {
  const flattenContactsTags = contacts
    .flatMap((contact) => (contact.tags ? contact.tags : []))
    .sort();
  const uniqueContactsTags = [...new Set(flattenContactsTags)];

  const contactsbytag = function contactsByTagArray(tag) {
    return (
      contacts
        .filter((contact) => {
          if (contact.tags) return contact.tags.includes(tag);
          return "";
        })
        .map((contact) => contact.id) || []
    );
  };

  return (
    <>
      <div className="m-5">
        <div className="pt-5">
          {status !== "archived" && (
            <Link
              className="btn btn-sm btn-primary px-12 mr-5"
              to={{
                pathname: `/contacts/add-contact`,
                dataId: {
                  organisation_id: organisationId,
                  tenant_id: tenantId,
                  name,
                },
              }}
            >
              New
            </Link>
          )}
        </div>
        <DataGrid
          renderHeader={renderHeader}
          renderRow={renderContactsRow}
          headings={HEADINGS}
          data={contacts}
          hideSelect
          loading={loading}
          editable={status !== "archived"}
          link="contacts/contacts-list"
        />

        <div className="py-5">
          <h6>Contacts by Tag</h6>
          <List>
            {uniqueContactsTags.map((tag) => (
              <ListItem key={tag}>
                <ListItemText
                  primary={tag}
                  secondary={contactsbytag(tag).map((contactID) => {
                    const contact = contacts.find((o) => o.id === contactID);
                    return (
                      <Link
                        key={contactID}
                        to={`/contacts/contacts-list/${contactID}`}
                        className="MuiTypography-colorTextSecondary px-1 mr-2"
                      >
                        {contact.firstName
                          ? contact.lastName
                            ? contact.firstName + " " + contact.lastName
                            : contact.firstName
                          : contact.lastName}
                      </Link>
                    );
                  })}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </div>
    </>
  );
}
