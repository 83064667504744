import { get } from "lodash";
import React from "react";
import differenceInDays from "date-fns/differenceInDays";
import {
  formatAmount,
  formatDate,
  formatLongRow,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";

function hasLongContentRow(key) {
  return ["note", "biller_name"].includes(key);
}

const getStatusRowStyle = (item = {}) => {
  if (item.status === "archived") {
    return "text-uppercase text-danger";
  } else return "text-uppercase";
};

const isOrganisation = (key) => key === "buyer_name";
const isAccount = (key) => key === "account_number";
const isDaysLateKey = (key) => key === "days_late";
const isStatus = (key) => key === "status";

const getDaysDiff = (endDate, startDate) => {
  if (!endDate || !startDate) return;
  return differenceInDays(new Date(endDate), new Date(startDate));
};

export function renderLateHistoryRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-1 ${isStatus(key) &&
        getStatusRowStyle(item)}`}
    >
      {isDate(key)
        ? item[key] && formatDate(new Date(item[key]))
        : isAmount(key)
        ? formatAmount(item[key])
        : hasLongContentRow(key)
        ? formatLongRow(item[key])
        : isOrganisation(key)
        ? get(item, "organisation.name", "")
        : isAccount(key)
        ? get(item, "account.account_number", "")
        : isDaysLateKey(key)
        ? getDaysDiff(item.payment_received_date, item.payment_due_date)
        : item[key]}
    </td>
  ));
}
