import React from "react";
import { Formik } from "formik";
import { MappedInput } from "../../../components/Input";
import { MappedSwitch } from "../../../components/Switch";
import { MappedTextArea } from "../../../components/TextArea";
import { MappedDatePicker } from "../../../components/DatePicker";
import { Autocomplete } from "../../../components/Autocomplete";
import { fieldNameStyle } from "../constants";
import { FormActions } from "../../FormActions";
import { ShareFormSchema } from "../../../../helpers/schemas";

export function NewShareForm({
  tradingName,
  legalName,
  handleSubmit,
  handleClose,
  id,
  organisationsList,
}) {
  const initialValues = {
    share_number: "",
    share_certificate_number: "",
    shareholder_id: id,
    held_beneficially: false,
    beneficiary_id: "",
    admitted_date: new Date(Date.now()),
    admitted_folio: "",
    date_issued: new Date(Date.now()),
    remarks: "",
    billing_entity_id: id,
    shortname: tradingName,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={ShareFormSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values, setFieldValue, ...formik }) => (
        <>
          <h3 className="px-10 my-6">
            <strong>
              Create New Share for {tradingName} ({legalName})
            </strong>
          </h3>

          <div className="px-10">
            <div className="py-5 w-50">
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Share Certificate Number</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="share_certificate_number" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Share Number</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="share_number" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Held Beneficially </p>
                </div>
                <div className="col-8">
                  <MappedSwitch checked={values.held_beneficially} name="held_beneficially" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Beneficiary </p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="beneficiary_id"
                    placeholder="Select Beneficiary"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    disabled={!values.held_beneficially}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.beneficiary_id && formik.errors.beneficiary_id ? (
                    <div className="text-danger">{formik.errors.beneficiary_id}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Shareholder </p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="shareholder_id"
                    placeholder="Select Shareholder"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    defaultValue={id}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.shareholder_id && formik.errors.shareholder_id ? (
                    <div className="text-danger">{formik.errors.shareholder_id}</div>
                  ) : null}
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Short Name</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="shortname" disabled />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Admitted Date</p>
                </div>
                <div className="col-8">
                  <MappedDatePicker name="admitted_date" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Admitted Folio</p>
                </div>
                <div className="col-8">
                  <MappedInput wrapperClassName="w-100" name="admitted_folio" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Date Issued</p>
                </div>
                <div className="col-8">
                  <MappedDatePicker name="date_issued" />
                </div>
              </div>
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Remarks</p>
                </div>
                <div className="col-8">
                  <MappedTextArea name="remarks" rows={6} data-testid="comment" />
                </div>
              </div>
              <div className="row my-4">
                <div className="col-4">
                  <p style={fieldNameStyle}>Billing Entity</p>
                </div>
                <div className="col-8">
                  <Autocomplete
                    name="billing_entity_id"
                    placeholder="Select Billing Entity"
                    setValue={setFieldValue}
                    options={organisationsList}
                    loading={!organisationsList.length}
                    defaultValue={id}
                    label="label"
                    defaultField="value"
                  />
                  {formik.touched.billing_entity_id && formik.errors.billing_entity_id ? (
                    <div className="text-danger">{formik.errors.billing_entity_id}</div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <FormActions
            handleSubmit={handleSubmit}
            isSubmitting={isSubmitting}
            handleClose={handleClose}
          />
        </>
      )}
    </Formik>
  );
}
