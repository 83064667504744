import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import DescriptionIcon from "@material-ui/icons/Description";
import SettingsIcon from "@material-ui/icons/Settings";
import { Tags } from "../../../ui/structures/Tags";
import { Files } from "../../../ui/structures/Files";
import { Settings } from "../../../ui/structures/Settings";
import Header from "../../../ui/structures/Header";
import Tabs from "../../../ui/components/Tabs";
import {
  addContactTags,
  deleteContactTags,
  getContactById,
  getContactFiles,
  updateContactRequest,
} from "./_api";
import { getContact } from "./_redux/selectors";
import { getOrganisation } from "../Organisations/_redux/selectors";
import { getOrganisation as getOrganisationById } from "../Organisations/_api";
import { updateContact } from "./_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import { downloadFile } from "../AWS-S3";
import { InputFile } from "../../../ui/components/InputFile";
import ContactDetailsForm from "../../../ui/structures/ContactDetailsForm";
import { ContactDetails } from "../../../ui/structures/ContactDetails/index";
import File from "../../../ui/structures/File";

export function Card({ match, location }) {
  const contactId = match.params.id;
  const { editMode } = location;
  const data = useSelector(getContact(contactId));
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const dispatch = useDispatch();
  const { request } = useFetch();

  const [cardData, setCardData] = useState({});

  const organisationId = cardData && cardData.organisation_id;
  const organisation = useSelector(getOrganisation(organisationId));
  const organisationName =
    (cardData && cardData.name) || (organisation && organisation.name);

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isEditable, setIsEditable] = useState(editMode);

  const {
    firstName,
    lastName,
    email,
    country,
    tags,
    files,
    status,
    position_title,
  } = cardData || {};

  const handleUpdateCardData = (newData) => {
    setCardData((prevState) => ({
      ...prevState,
      ...newData,
    }));

    if (data) {
      dispatch(updateContact({ ...cardData, ...newData }));
    }
  };

  const handleAddTag = useCallback(
    (tag) => {
      request(
        addContactTags,
        TENANT_ID,
        contactId,
        tags ? [...tags, tag] : [tag]
      ).then((data) => data && handleUpdateCardData({ tags: data }));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId, tags]
  );

  const handleDeleteTag = useCallback(
    (tag) => {
      const newTags = tags.filter((item) => item === tag);

      request(deleteContactTags, TENANT_ID, contactId, newTags).then(
        (data) => data && handleUpdateCardData({ tags: data })
      );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId, tags]
  );

  // const reloadFiles = () => (
  //   request(getContactFiles, contactId)
  //     .then(files => {
  //         setCardData(prevState => ({
  //           ...prevState,
  //           files
  //         }))
  //       }
  //     )
  // );

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getContactById, TENANT_ID, contactId),
        request(getContactFiles, TENANT_ID, contactId),
      ]).then(([contact, files]) => {
        contact &&
          files &&
          setCardData({
            country: "AU",
            aka: "00001",
            fax: "+1000444777",
            ...contact,
            files,
          });
      });
    } else {
      request(getContactFiles, TENANT_ID, contactId).then((files) =>
        setCardData({ ...data, files })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (cardData.organisation_id && !cardData.name) {
      request(getOrganisationById, TENANT_ID, cardData.organisation_id).then(
        (data) => {
          setCardData((prevState) => ({ ...prevState, name: data.name }));
        }
      );
    }
    // eslint-disable-next-line
  }, [cardData]);

  const updateFiles = () => {
    request(getContactFiles, TENANT_ID, contactId).then((files) =>
      setCardData({
        ...data,
        files,
      })
    );
  };

  const tabs = [
    {
      label: "Contact Details",
      icon: <LocalOfferIcon />,
      tabPanel: isEditable ? (
        <ContactDetailsForm
          data={cardData}
          tenantId={TENANT_ID}
          onClose={setIsEditable}
          onSubmit={updateContactRequest}
          setData={setCardData}
        />
      ) : (
        <ContactDetails
          data={cardData}
          tenantId={TENANT_ID}
          setIsEditable={setIsEditable}
        />
      ),
    },
    {
      label: "Tags",
      icon: <LocalOfferIcon />,
      tabPanel: (
        <Tags
          tagsList={tags}
          onTagAdd={handleAddTag}
          onTagDelete={handleDeleteTag}
          data={cardData}
          setData={setCardData}
          type="contact"
        />
      ),
    },
    {
      label: "Files",
      icon: <DescriptionIcon />,
      tabPanel: (
        <Files files={files} onDownload={downloadFile} data={cardData} />
      ),
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      tabPanel: (
        <Settings
          data={cardData}
          setData={setCardData}
          id={contactId}
          handleUpdateCardData={handleUpdateCardData}
        />
      ),
    },
  ];

  return (
    <>
      <div className="row mb-9">
        <div className="col-12">
          <Header
            firstName={firstName}
            lastName={lastName}
            email={email}
            geolocation={country}
            name={organisationName}
            id={contactId}
            organisationId={organisationId}
            tenantId={TENANT_ID}
            status={status}
            position_title={position_title}
            isContact
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mb-9">
            <div className="d-flex flex-column rounded h-100">
              <Tabs defaultActive="0" tabs={tabs} />
            </div>
          </div>
          {cardData.status !== "archived" && (
            <InputFile className="rounded" onDrop={handleUploadFiles}>
              {uploadedFiles.map((file) => (
                <File
                  key={file.lastModified}
                  senderId={contactId}
                  senderType="contact"
                  file={file}
                  updateFiles={updateFiles}
                />
              ))}
            </InputFile>
          )}
        </div>
      </div>
    </>
  );
}
