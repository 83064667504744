import config from "react-global-configuration";

export const getFees = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees`, {
    mode: "cors",
    credentials: "include",
  });

export const getFeeById = (tenant_id, fee_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees/${fee_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const createFee = (tenant_id, feeData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(feeData),
    mode: "cors",
    credentials: "include",
  });

export const modifyFee = (tenant_id, fee_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees/${fee_id}`, {
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const deleteFeeById = (tenant_id, fee_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees/${fee_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getFeeLogs = (tenant_id, fee_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/fees/${fee_id}/logs`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
