import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { TenantDetailSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { useFetch } from "../../../hooks/fetch.hook";
import { stateOptions } from "../../helpers/constants";
import { capitalizeObject } from "../../helpers/helpers";

function TenantDetailsForm({
  history,
  data = {},
  onSubmit,
  setData,
  tenantId,
  isAddNewTenant = false,
}) {
  const { request } = useFetch();
  const {
    name,
    legal_name,
    id,
    phone,
    general_email,
    address_1,
    address_2,
    city,
    state,
    postcode,
    web_address,
    status,
  } = data;

  const initialValues = {
    name: name || "",
    id: id || null,
    legal_name: legal_name || "",
    address_1: address_1 || "",
    address_2: address_2 || "",
    city: city || "",
    general_email: general_email || "",
    phone: phone || "",
    postcode: postcode || "",
    state: state || "",
    web_address: web_address || "",
    status: status || null,
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (tenantId && !Object.keys(data).length) {
    return <p className="text-center">Loading...</p>;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const titleCaseValues = capitalizeObject(values);
    if (isAddNewTenant) {
      request(onSubmit, { ...titleCaseValues })
        .then((data) => {
          if (data) {
            return history.push(`/tenants-list/`);
          }
        })
        .finally(() => setSubmitting(false));
    } else {
      request(onSubmit, { tenant_id: tenantId, ...data, ...titleCaseValues }, data.id)
        .then((data) => {
          if (data) {
            return history.push(`/tenants-list/`);
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={TenantDetailSchema}
      // validator={() => ({})}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Tenant Name</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="name"
                    wrapperClassName="w-100"
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Legal Name</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="legal_name"
                    wrapperClassName="w-100"
                    data-testid="legal_name"
                  />
                </div>
              </div>

              {!isAddNewTenant && (
                <div className="row mb-3">
                  <div className="col-2 my-auto">
                    <p className="text-right text-muted mb-0">Tenant ID</p>
                  </div>
                  <div className="col-3 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="id"
                      wrapperClassName="w-100"
                      data-testid="id"
                    />
                  </div>
                </div>
              )}

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Phone</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="phone"
                    icon="phone"
                    wrapperClassName="w-100"
                    data-testid="phone"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Email Address</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="general_email"
                    icon="alternate_email"
                    wrapperClassName="w-100"
                    data-testid="email"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Physical Address</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="address_1"
                    wrapperClassName="w-100"
                    data-testid="address_1"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Physical Address 2</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="address_2"
                    wrapperClassName="w-100"
                    data-testid="address_2"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Physical Suburb</p>
                </div>
                <div className="col-3">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="city"
                    wrapperClassName="w-100"
                    data-testid="city"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Physical State</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect options={stateOptions} name="state" data-testid="state" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Physical Postсode</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="postcode"
                    wrapperClassName="w-100"
                    data-testid="postcode"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Tenant Web Address</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="web_address"
                    wrapperClassName="w-100"
                    data-testid="web-address"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={() => history.go(-1)}
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                  disabled={props.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(TenantDetailsForm);
