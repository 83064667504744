import React from "react";
import { Button } from "@material-ui/core";
import { InvoiceDetails } from "../Invoices/InvoiceDetails";
import { Link } from "react-router-dom";
import { formatAmount, formatDate } from "../../../ui/helpers/helpers";

export const DiscrepanciesDetails = ({ data = {}, handleClose }) => {
  return (
    <div className="py-10">
      <h4 className="mb-10 text-center">
        <strong>Discrepancy Notes</strong>
      </h4>
      <div className="row my-15">
        {data?.status || data?.close_date ? (
          <div className="col-12 mb-5">
            {data?.close_date ? (
              <div
                style={{
                  display: "inline-block",
                  background: data.status == "approved" ? "#17c191" : "#f018a6",
                  color: "#fff",
                  // color: "#5E6973",
                  // background: "#EEF0F8",
                  padding: "2px 6px",
                  borderRadius: "0.22rem 0 0 0.22rem",
                }}
              >
                {formatDate(data.close_date)}
              </div>
            ) : (
              ""
            )}
            {data?.status ? (
              <div
                style={{
                  display: "inline-block",
                  background: data.status == "approved" ? "#17c191" : "#f018a6",
                  color: "#fff",
                  // color: "#5E6973",
                  // background: "#EEF0F8",
                  padding: "2px 6px",
                  borderRadius: "0 0.22rem 0.22rem 0",
                }}
              >
                {data.status}
              </div>
            ) : (
              ""
            )}
          </div>
        ) : (
          ""
        )}
        {data?.biller_name ? (
          <div className="col-6">
            Biller Name:
            <Link
              style={{ marginLeft: 5 }}
              to={`/contacts/organisations-list/${data.biller_id}`}
            >
              {data.biller_name}
            </Link>
          </div>
        ) : (
          ""
        )}
        {data?.buyer_name ? (
          <div className="col-6">
            Buyer Name:
            <Link
              style={{ marginLeft: 5 }}
              to={`/contacts/organisations-list/${data.buyer_id}`}
            >
              {data.buyer_name}
            </Link>
          </div>
        ) : (
          ""
        )}
        {data?.discrepancy_total_amount ? (
          <div className="col-12 mt-2">
            Discrepancy Total Amount:{" "}
            {formatAmount(data.discrepancy_total_amount)}
          </div>
        ) : (
          ""
        )}
        {data?.discrepancy_reason ? (
          <div className="col-12">
            Discrepancy Reason:{" "}
            {data.discrepancy_reason
              .replace("{", "")
              .replace("}", "")
              .split(",") || []}
          </div>
        ) : (
          ""
        )}
        {data?.discrepancy_note ? (
          <div className="col-12 mb-2">
            Discrepancy Note: {data.discrepancy_note}
          </div>
        ) : (
          ""
        )}
        {data?.corrected_tax_value ? (
          <div className="col-12">
            Corrected Tax Value: {data.corrected_tax_value}
          </div>
        ) : (
          ""
        )}
        {data?.corrected_tax_rate ? (
          <div className="col-12">
            Corrected Tax Rate: {data.corrected_tax_rate}
          </div>
        ) : (
          ""
        )}
        {data?.corrected_quantity ? (
          <div className="col-12">
            Corrected Quantity: {data.corrected_quantity}
          </div>
        ) : (
          ""
        )}
        {data?.corrected_unit_price ? (
          <div className="col-12">
            Corrected Unit Price: {formatAmount(data.corrected_unit_price)}
          </div>
        ) : (
          ""
        )}
        {data?.corrected_total_value ? (
          <div className="col-12">
            Corrected Total Value: {formatAmount(data.corrected_total_value)}
          </div>
        ) : (
          ""
        )}
        {data?.close_note ? (
          <div className="col-12">Close Note: {data.close_note}</div>
        ) : (
          ""
        )}
      </div>
      <div className="row my-15">
        <div
          className="col-12 rounded"
          style={{
            backgroundColor: "rgba(245, 146, 32, 1)",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px",
          }}
        >
          <h3 className="text-center mb-8 mt-5">Invoice</h3>
          <InvoiceDetails
            data={data?.invoice}
            title={false}
            highlightedLineId={data?.invoice_line_id}
          />
        </div>
        <div
          className="col-12 mt-15 rounded"
          style={{
            backgroundColor: "rgba(245, 146, 32, 1)",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px",
          }}
        >
          <h3 className="text-center mb-8 mt-5">Corrected Invoice</h3>

          <InvoiceDetails
            data={data?.corrected_invoice}
            title={false}
            highlightedLineId={data?.invoice_line_id}
          />
        </div>
      </div>
      <div className="text-right">
        <Button size="large" className="px-9 mt-8" onClick={handleClose}>
          Close
        </Button>
      </div>
    </div>
  );
};
