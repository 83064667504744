import React, { useEffect, useState, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFetch } from "../../../hooks/fetch.hook";
import { downloadFile } from "../AWS-S3";
import { Link } from "react-router-dom";
import Can from "../../../ui/helpers/Can";
import DataGrid from "../../../ui/components/DataGrid";
import { SearchBar } from "../../../ui/structures/SearchBar";
import { Input as InputIcon, Print as PrintIcon } from "@material-ui/icons";
import { renderContactsRow } from "../../../ui/helpers/helpers";
import { getContacts } from "./_redux/selectors";
import {
  FormControlLabel,
  Checkbox,
  TableSortLabel,
  CircularProgress,
} from "@material-ui/core";
import {
  getContactsListWithParams,
  exportContactsByIds,
  exportContactById,
  modifyContact,
  deleteContact,
} from "./_api";
import { postSendBulkSms } from "../../../app/modules/Integrations/_api";
import {
  archiveContact,
  setContactsList,
  unArchiveData,
} from "./_redux/actions";
import { SMSForm } from "../../../ui/structures/SMSForm";
import { ShareModal } from "../../../ui/structures/ShareModal";
import ToolTip from "../../../ui/components/Tooltip";

const HEADINGS = [
  ["shortCode", "ShortCode"],
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["aka", "AKA"],
  ["mobile", "Mobile"],
  ["phone", "Phone"],
  ["email", "Email"],
];

export function List() {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const initialRender = useRef(true);
  const data = useSelector(getContacts) || [];
  // const archived = useSelector(getShowArchived);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState({});
  const [contacts, setContacts] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);

  // For building the link
  const [search, setSearch] = useState("");
  const [field, setField] = useState("");
  const [status, setStatus] = useState("");
  const [direction, setDirection] = useState("");
  const [archived, setArchived] = useState(false);
  const [step, setStep] = useState("1");
  const [confirmedMessgae, setConfirmedMessage] = useState("");
  const [printLoading, setPringLoading] = useState(false);
  const [exportLoading, setExportLoading] = useState(false);

  function fetchContacts() {
    setLoading(true);
    if (archived) setArchivedLoading(true);
    if (!initialRender.current) dispatch(setContactsList([]));

    request(
      getContactsListWithParams,
      TENANT_ID,
      field,
      field && direction,
      search,
      archived
    ).then((data) => {
      if (data) {
        const newData = data.map((item) => ({
          shortCode: item.organisation.shortCode,
          settings: {
            email: false,
            sms: true,
            phone: false,
          },
          files: [],
          ...item,
        }));
        dispatch(setContactsList(newData));
        setContacts(newData);
        setLoading(false);
        setArchivedLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchContacts();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      if (search || search === "" || field || status || status === "") {
        fetchContacts();
      }
    }
    // eslint-disable-next-line
  }, [
    search,
    setSearch,
    field,
    setField,
    direction,
    setDirection,
    status,
    setStatus,
  ]);

  const KEY_MAP = {
    ShortCode: data.map((item) => item.shortCode),
    "First Name": data.map((item) => item.firstName),
    "Last Name": data.map((item) => item.lastName),
    AKA: data.map((item) => item.aka),
    Mobile: data.map((item) => item.mobile),
    Phone: data.map((item) => item.phone),
    Email: data.map((item) => item.email),
    Tags: data.map((item) => item.tags),
  };

  function renderHeaderWithSorting(headings) {
    return headings.map(([key, header]) => (
      <th
        key={key}
        className="text-dark px-5 text-nowrap"
        onClick={() => {
          setDirection(
            key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
          );
          setField(key);
        }}
      >
        <span style={{ cursor: "pointer" }}>{header}</span>
        {key === field && <TableSortLabel active direction={direction} />}
      </th>
    ));
  }

  const resetSearch = () => {
    dispatch(setContactsList(contacts));
    setSearch("");
  };

  const arrayOfSelectedIds = useMemo(
    () => Object.keys(selected).filter((item) => Boolean(selected[item])),
    [selected]
  );

  const arrayOfFilteredIds = useMemo(() => {
    const newData = data.reduce((acc, item) => {
      acc[item.id] = true;
      return acc;
    }, {});
    return Object.keys(newData).filter((item) => Boolean(newData[item]));
  }, [data]);

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this contact? This process is not reversible"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        deleteContact(TENANT_ID, itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(archiveContact(+itemID));
            const idToString = String(itemID);
            setSelected({
              ...selected,
              [idToString]: false,
            });
          }
        });
      } catch {}
    }
  };

  const handleUnArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to reactivate this organisation?"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        modifyContact(TENANT_ID, itemID, { status: "active" })
          .then((res) => {
            if (res.status === 200 || res.status === 204) {
              const idToString = String(itemID);
              setSelected({
                ...selected,
                [idToString]: false,
              });
            }
            return res.json();
          })
          .then((res) => {
            const index = data.findIndex((item) => item.id === res.data.id);
            index !== -1 && dispatch(unArchiveData(index, res.data));
          });
      } catch {}
    }
  };

  const showArchived = () => {
    setArchived(!archived);
    setStatus(archived ? "" : "all");
  };

  const archiveDisabled = useMemo(() => {
    if (Object.values(selected).filter(Boolean).length === 1) {
      let selectedId = Object.keys(selected).find(
        (key) => selected[key] === true
      );
      let selectedStatusArchived = data?.find((o) => "" + o.id === selectedId)
        ?.status;

      return selectedStatusArchived === "active" ? false : true;
    }
    return true;
  }, [selected]);

  const unarchiveDisabled = useMemo(() => {
    if (Object.values(selected).filter(Boolean).length === 1) {
      let selectedId = Object.keys(selected).find(
        (key) => selected[key] === true
      );
      let selectedStatusArchived = data?.find((o) => "" + o.id === selectedId)
        ?.status;

      return selectedStatusArchived === "archived" ? false : true;
    }
    return true;
  }, [selected]);

  const bulkDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 0,
    [selected]
  );

  const selectedItems = Object.keys(selected).filter((key) => Boolean(selected[key]));

  const handleBulkClose = () => {
    setModalOpen(false);
    setStep("1");
    setConfirmedMessage("");
  };

  const handleBulkOpen = () => setModalOpen(true);

  const handleBulkConfirm = (values, { setSubmitting }) => {
    setStep("2");
    setConfirmedMessage(values);
    setSubmitting(false);
  };

  const handleBulkSubmit = (values, { setSubmitting }) => {
    request(postSendBulkSms, TENANT_ID, {
      contact_ids: selectedItems,
      ...values,
    }).then((response) => {
      if (response) {
        console.log(response);
      }
      handleBulkClose();
    });
    setSubmitting(false);
  };

  const handlePrint = () => {
    setPringLoading(true);
    downloadFile(
      arrayOfSelectedIds,
      exportContactById,
      TENANT_ID,
      setPringLoading
    );
  };

  const handleExport = () => {
    setExportLoading(true);
    arrayOfSelectedIds.length
      ? downloadFile(
          arrayOfSelectedIds,
          exportContactsByIds,
          TENANT_ID,
          setExportLoading
        )
      : downloadFile(
          arrayOfFilteredIds,
          exportContactsByIds,
          TENANT_ID,
          setExportLoading
        );
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Contacts</h3>
            <ShareModal
              isOpen={modalOpen}
              submitable={true}
              maxWidth={`${step === "1" ? "xs" : "md"}`}
              modalContent={
                step === "1" ? (
                  <SMSForm
                    handleClose={handleBulkClose}
                    handleSubmit={handleBulkConfirm}
                    title={`Send SMS to ${
                      selectedItems.length
                    } contacts`}
                  />
                ) : (
                  <SMSForm
                    handleClose={handleBulkClose}
                    handleSubmit={handleBulkSubmit}
                    title={`Are you sure you want to send SMS to ${
                      selectedItems.length
                    } recipients?`}
                    data={confirmedMessgae}
                    titleClassName="text-danger"
                  />
                )
              }
            />
            <div className="d-flex">
              <ToolTip
                icon={<PrintIcon fontSize="large" />}
                title="Print Selected Contact Data in PDF"
                disabled={arrayOfSelectedIds.length !== 1}
                handleClick={handlePrint}
                loading={printLoading}
                testid="print"
              />
              <ToolTip
                icon={<InputIcon fontSize="large" />}
                title="Export Contacts Data to CSV"
                handleClick={handleExport}
                loading={exportLoading}
                testid="export"
              />
            </div>
          </div>
          <div className="d-flex mb-5">
            <div>
              <Can
                perform="list:new"
                yes={() => (
                  <Link to="/contacts/add-contact" data-testid="new-contact">
                    <button className="px-15 mr-5 btn btn-primary">New</button>
                  </Link>
                )}
              />
            </div>
            <div>
              <Can
                perform="list:archive"
                yes={() => (
                  <>
                    {archived && (
                      <button
                        className="px-15 mr-5 btn btn-primary"
                        onClick={handleUnArchive}
                        disabled={unarchiveDisabled}
                      >
                        Unarchive
                        {console.log(selected)}
                      </button>
                    )}
                    <button
                      className="px-15 mr-5 btn btn-primary"
                      onClick={handleArchive}
                      disabled={archiveDisabled}
                    >
                      Archive
                    </button>
                  </>
                )}
              />
            </div>
            <div>
              <Can
                perform="list:sms"
                yes={() => (
                  <button
                    className="px-15 mr-5 btn btn-primary"
                    onClick={handleBulkOpen}
                    disabled={!bulkDisabled}
                    data-testid="bulk"
                  >
                    Bulk SMS
                  </button>
                )}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-8">
              <SearchBar
                className="mb-5"
                onSearch={(data) => {
                  setSearch(
                    Object.keys(data)
                      .map(
                        (key) =>
                          `${
                            key === "AKA"
                              ? "aka"
                              : key.charAt(0).toLowerCase() +
                                key.slice(1).replace(/\s/g, "")
                          }=_like(${encodeURIComponent(data[key])})_`
                      )
                      .join("&")
                  );
                }}
                keyMap={KEY_MAP}
                placeholder="Search by tags..."
                clearSearch={resetSearch}
              />
            </div>
            <div className="col-4" style={{ display: "flex" }}>
              <FormControlLabel
                control={
                  archivedLoading ? (
                    <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                  ) : (
                    <Checkbox
                      checked={archived}
                      onChange={() => showArchived()}
                    />
                  )
                }
                label="Display Archived Records"
              />
            </div>
          </div>
          <Can
            perform="list:archive"
            yes={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeaderWithSorting}
                renderRow={renderContactsRow}
                editable
                selectable
                selected={selected}
                setSelected={setSelected}
                link="contacts/contacts-list"
                loading={loading}               
              />
            )}
            no={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeaderWithSorting}
                renderRow={renderContactsRow}
                link="contacts/contacts-list"
                loading={loading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
