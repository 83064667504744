import React from "react";
import {
  formatAmount,
  formatDate,
  formatLongRow,
  isDate,
} from "../../../ui/helpers/helpers";
import { get } from "lodash";

function hasLongContentRow(key) {
  return ["organisation_name"].includes(key);
}

function isAmount(key) {
  return ["0-7", "7-13", "14-20", "21+", "total"].includes(key);
}

export function renderDelinquentBuyersRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isDate(key)
        ? item[key] && formatDate(new Date(item[key]))
        : isAmount(key)
        ? formatAmount(item[key])
        : hasLongContentRow(key)
        ? formatLongRow(item[key])
        : item[key]}
    </td>
  ));
}

export const renderOverdueSum = (data) => {
  const sumValues = (key) => {
    // console.log(key, data);
    return formatAmount(
      data
        .map((item) => item[key])
        .reduce(
          (acc, value) => parseFloat(acc || 0) + parseFloat(value || 0),
          0
        )
    );
  };
  return (
    <tr className="border-bottom">
      <td className="no-line border-0 align-middle px-5 pt-20 pb-1">
        <strong>Sum : </strong>
      </td>
      <td className="pt-20 pb-1 pl-5"></td>
      <td className="pt-20 pb-1 pl-5">{sumValues("0-7")}</td>
      <td className="pt-20 pb-1 pl-5">{sumValues("7-13")}</td>
      <td className="pt-20 pb-1 pl-5">{sumValues("14-20")}</td>
      <td className="pt-20 pb-1 pl-5">{sumValues("21+")}</td>
      <td className="pt-20 pb-1 pl-5">{sumValues("total")}</td>
      <td className="pt-20 pb-1 pl-5"></td>
    </tr>
  );
};

export const modifyDelinquentBuyers = (data) =>
  Object.entries(data).map(([k, v], i) => modifyDelinquentBuyersData(k, v));

export const modifyDelinquentBuyersData = (key, value = {}) => {
  return {
    id: +value.organisation.id,
    name: value.organisation.legalName || "",
    total: value.total || 0,
  };
};

export const addDelinquentBuyersField = (data, prevData, field_name) => {
  // console.log("start add ");
  // console.log(data, prevData);
  if (data && Object.keys(data).length === 0)
    return prevData.map((org) =>
      modifyDelinquentBuyersDataField(org, {}, field_name)
    );

  // console.log("start add new entries ");
  const addNewEntries = Object.entries(data).map(([k, v], i) => {
    const found =
      prevData.find(({ id }) => {
        // console.log(id, v.organisation.id);
        return +id === +v.organisation.id;
      }) || {};
    // console.log(found);
    if (found && Object.keys(found).length !== 0) {
      // add new prop
      return modifyDelinquentBuyersDataField(found, v, field_name);
    } else {
      // add new entry
      return addDelinquentBuyersDataField(v, field_name);
    }
  });

  // console.log("start add old entries ");
  const oldEntries = prevData.map((org, i) => {
    const found = Object.entries(data).find(
      ([k, v], i) => +v.organisation.id === +org.id
    );
    // console.log(found);
    if ((!found && found === undefined) || Object.keys(found).length !== 0) {
      return org;
    }
  });
  // console.log(addNewEntries, oldEntries);
  var merge = (a, b, p) =>
    a.filter((aa) => !b.find((bb) => aa[p] === bb[p])).concat(b);

  // console.log("start end ");

  return merge(addNewEntries, oldEntries, "id");
};

export const addDelinquentBuyersDataField = (newObj, field_name) => {
  let newEntry = {
    id: newObj.organisation.id,
    name: newObj.organisation.legalName || "",
  };
  newEntry[field_name] = newObj.total || 0;
  return newEntry;
};

export const modifyDelinquentBuyersDataField = (
  found = {},
  newObject,
  field_name
) => {
  let newEntry = found;
  newEntry[field_name] = newObject.total || 0;
  return newEntry;
};
