import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedTextArea } from "../../../ui/components/TextArea";

export const LetterContentField = ({ letterTemplates = [] }) => {
  const { setFieldValue, values } = useFormikContext();

  useEffect(() => {
    const selectedTemplate =
      letterTemplates.find(({ id }) => id === values.late_letter_template_id) ||
      {};
    setFieldValue("content", (selectedTemplate.content || {}).body_template);
    // eslint-disable-next-line
  }, [values.late_letter_template_id]);

  return (
    <div className="row mb-5">
      <div className="col-2">
        <p className="text-right text-muted">Preview Letter Content</p>
      </div>
      <div className="col-8 mr-auto">
        <MappedTextArea
          name="content"
          rows={6}
          data-testid="content"
          disabled
        />
      </div>
    </div>
  );
};
