export const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};

export const BRISMARK_BILLER_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Home",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.heading,
    title: "Service Pages",
  },
  {
    type: ITEM_TYPES.menu,
    title: "About Us",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/static/13",
    items: [
      {
        type: "link",
        title: "Company Overview",
        to: "/static/13",
      },
      {
        type: "link",
        title: "Staff Directory",
        to: "/static/11",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Credit Service",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/transactions",
    items: [
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Transactions",
      //   to: "/transactions",
      // },
      {
        type: ITEM_TYPES.link,
        title: "Discrepancy Management",
        to: "/discrepancy-management",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Distributions",
      //   to: "/distributions-biller",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: "link",
      //   title: "Invoice Entry",
      //   to: "/settings/invoice-entry",
      // },
      {
        type: "link",
        title: "Rules and By-Laws",
        to: "/static/rules-and-by-laws",
      },
      {
        type: "link",
        title: "Debt Recovery Solutions",
        to: "/static/4",
      },
      {
        type: "link",
        title: "Investment Service",
        to: "/static/45",
      },
      {
        type: "link",
        title: "Fuel Cards",
        to: "/static/6",
      },
      {
        type: "link",
        title: "Master Files",
        to: "/static/master-files",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Brismark Services",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/static/business-services-overview",
    items: [
      {
        type: "link",
        title: "Business Services Overview",
        to: "/static/business-services-overview",
      },
      {
        type: "link",
        title: "Food Safety",
        to: "/static/15",
      },
      {
        type: "link",
        title: "FreshTest",
        to: "/static/5",
      },
      {
        type: "link",
        title: "HR & Industrial Relations",
        to: "/static/7",
      },
      {
        type: "link",
        title: "Recruitment",
        to: "/static/18",
      },
      {
        type: "link",
        title: "Training & Development",
        to: "/static/9",
      },
      {
        type: "link",
        title: "Training Centre",
        to: "/static/19",
      },
      {
        type: "link",
        title: "WHS",
        to: "/static/10",
      },
    ],
  },
  {
    type: "link",
    title: "Contact Us",
    iconUrl: "/assets/icons/list.svg",
    to: "/static/contact",
  },
  {
    type: ITEM_TYPES.link,
    title: "Swap Role",
    iconUrl: "/assets/icons/settings.svg",
    to: "/swap-role",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
  },
];

export const DEFAULT_BILLER_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Home",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.heading,
    title: "Service Pages",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Credit Service",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/transactions",
    items: [
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Transactions",
      //   to: "/transactions",
      // },
      {
        type: ITEM_TYPES.link,
        title: "Discrepancy Management",
        to: "/discrepancy-management",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Distributions",
      //   to: "/distributions-biller",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: "link",
      //   title: "Invoice Entry",
      //   to: "/settings/invoice-entry",
      // },
      {
        type: "link",
        title: "Rules and By-Laws",
        to: "/static/rules-and-by-laws",
      },
      {
        type: "link",
        title: "Master Files",
        to: "/static/master-files",
      },
    ],
  },
  {
    type: "link",
    title: "Contact Us",
    iconUrl: "/assets/icons/list.svg",
    to: "/static/contact",
  },
  {
    type: ITEM_TYPES.link,
    title: "Swap Role",
    iconUrl: "/assets/icons/settings.svg",
    to: "/swap-role",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
  },
];

export const BRISMARK_BUYER_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Home",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.heading,
    title: "Service Pages",
  },
  {
    type: ITEM_TYPES.menu,
    title: "About Us",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/static/13",
    items: [
      {
        type: "link",
        title: "Company Overview",
        to: "/static/13",
      },
      {
        type: "link",
        title: "Staff Directory",
        to: "/static/11",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Credit Service",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/discrepancy-management",
    items: [
      {
        type: ITEM_TYPES.link,
        title: "Discrepancy Management",
        to: "/discrepancy-management",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      {
        type: "link",
        title: "Credit Service Statements",
        to: "/credit-service-statement",
      },
      {
        type: "link",
        title: "Biller Statements",
        to: "/error-page",
      },
      {
        type: "link",
        title: "Biller Invoice Search",
        to: "/biller-invoice-search",
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Transactions",
      //   to: "/transactions",
      // },
      {
        type: "link",
        title: "Debt Recovery Solutions",
        to: "/static/4",
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Brismark Services",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/static/business-services-overview",
    items: [
      {
        type: "link",
        title: "Business Services Overview",
        to: "/static/business-services-overview",
      },
      {
        type: "link",
        title: "Food Safety",
        to: "/static/15",
      },
      {
        type: "link",
        title: "HR & Industrial Relations",
        to: "/static/7",
      },
      {
        type: "link",
        title: "Recruitment",
        to: "/static/18",
      },
      {
        type: "link",
        title: "Training & Development",
        to: "/static/9",
      },
      {
        type: "link",
        title: "Training Centre",
        to: "/static/19",
      },
      {
        type: "link",
        title: "WHS",
        to: "/static/10",
      },
    ],
  },
  {
    type: "link",
    title: "Contact Us",
    iconUrl: "/assets/icons/list.svg",
    to: "/static/contact",
  },
  {
    type: ITEM_TYPES.link,
    title: "Swap Role",
    iconUrl: "/assets/icons/settings.svg",
    to: "/swap-role",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
  },
];

export const DEFAULT_BUYER_MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Home",
    to: "/dashboard",
  },
  {
    type: ITEM_TYPES.heading,
    title: "Service Pages",
  },
  {
    type: ITEM_TYPES.menu,
    title: "Credit Service",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/static/14",
    items: [
      {
        type: ITEM_TYPES.link,
        title: "Discrepancy Management",
        to: "/discrepancy-management",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      {
        type: "link",
        title: "Credit Service Statements",
        to: "/credit-service-statement",
      },
      {
        type: "link",
        title: "Biller Invoice Search",
        to: "/biller-invoice-search",
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Transactions",
      //   to: "/transactions",
      // },
    ],
  },
  {
    type: "link",
    title: "Contact Us",
    iconUrl: "/assets/icons/list.svg",
    to: "/static/contact",
  },
  {
    type: ITEM_TYPES.link,
    title: "Swap Role",
    iconUrl: "/assets/icons/settings.svg",
    to: "/swap-role",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
  },
];
