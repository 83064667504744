import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../../../hooks/fetch.hook";
import { InvoiceLineDetails } from "../InvoiceLineDetails";
import { formatAmount, formatDate } from "../../../../ui/helpers/helpers";
import { Autocomplete } from "../../../../ui/components/Autocomplete";
import { MultiFieldFilter } from "../../../../ui/structures/MultiFieldFilter";
import { filtersMap } from "../constants";
import { getInvoicesWithFiltering } from "../../InvoiceEntry/_api";
import { TextField, withStyles, LinearProgress } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
      },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
    },
  },
})(TextField);
export const CreateDiscrepancy = ({
  handleClose,
  fetchDiscrepancyData,
  organisationList = [],
}) => {
  const { request } = useFetch();

  const [selectedInvoice, setSelectedInvoice] = useState({});
  const [selectedInvoiceLine, setSelectedInvoiceLine] = useState({});
  const [resetKey, setResetKey] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [biller, setBiller] = useState();
  const [buyer, setBuyer] = useState();
  const [organisationsList, setOrganisationsList] = useState([]);

  const { user } = useSelector((state) => state.auth);
  const userCategory = user.userCategory;
  const TENANT_ID = user.selectedTenant;

  useEffect(() => {
    setOrganisationsList((prevList) => [...prevList, ...organisationList]);
    // eslint-disable-next-line
  }, [organisationList]);

  useEffect(() => {
    if (biller || buyer) {
      setSelectedInvoice({});
      setSelectedInvoiceLine({});
      setInvoices([]);
      fetchInvoices();
    }
    // eslint-disable-next-line
  }, [biller, setBiller, buyer, setBuyer]);

  function fetchInvoices() {
    setLoading(true);
    request(getInvoicesWithFiltering, TENANT_ID, biller?.value, buyer?.value)
      .then((data) => {
        if (!data) return;
        setInvoices(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const organisationsOptions = organisationsList.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });

  const modifyInvoices = (data = []) => {
    console.log(data);
    return data.map(
      ({ invoice_date, amount, invoice_number, invoice_lines, ...data }) => ({
        ...data,
        amount: formatAmount(
          invoice_lines.reduce(
            (sum, { quantity, unit_price, tax_rate }) =>
              sum +
              (quantity * unit_price +
                quantity * unit_price * (tax_rate / 100)),
            0
          )
        ),
        invoice_date: formatDate(invoice_date),
        invoice_number: String(invoice_number),
      })
    );
  };

  const modifyInvoiceLines = (data = []) => {
    return data.map((data) => ({
      ...data,
      label: `${data.line_number}. Qty: ${data.quantity}. Price: ${
        data.total_value
      } | ${
        data.description.length > 40
          ? data.description.slice(0, 40) + "..."
          : data.description.slice(0, 40)
      }`,
    }));
  };

  const setInvoiceData = (name, value) => {
    console.log(name, value);
    if (value) {
      name === "invoice_number" &&
        setSelectedInvoice(
          invoices.find(({ invoice_number }) => invoice_number === value)
        );
    } else {
      name === "invoice_number" && setSelectedInvoice({});
    }
  };

  const setInvoiceLineValue = (name, value) => {
    if (value) {
      setSelectedInvoiceLine(
        selectedInvoice.invoice_lines.find(
          ({ line_number }) => line_number === value
        )
      );
      setResetKey(Date.now());
    } else {
      setSelectedInvoiceLine({});
    }
  };

  const getFilterMap = () => {
    if (userCategory === "Biller") {
      return filtersMap.filter(({ name }) => name !== "biller_name");
    } else if (userCategory === "Buyer") {
      return filtersMap.filter(({ name }) => name !== "buyer_name");
    } else return filtersMap;
  };

  return (
    <div>
      <h3 className="text-center mb-10">New Discrepancy</h3>
      {userCategory !== "Biller" && (
        <div className="row mb-5 align-items-center">
          <div className="col-2">Select Biller</div>
          <div className="col-8">
            <MuiAutocomplete
              id="grouped-demo"
              className="w-100"
              size="small"
              value={biller}
              onChange={(event, newValue) => {
                setBiller(newValue);
              }}
              options={organisationsOptions.sort(
                (a, b) => -b.label.localeCompare(a.firstLetter)
              )}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Biller"
                  name="biller"
                  variant="outlined"
                  value={biller}
                />
              )}
            />
          </div>
        </div>
      )}
      {userCategory !== "Buyer" && (
        <div className="row mb-5 align-items-center">
          <div className="col-2">Select Buyer</div>
          <div className="col-8">
            <MuiAutocomplete
              id="grouped-demo"
              className="w-100"
              size="small"
              value={buyer}
              onChange={(event, newValue) => {
                setBuyer(newValue);
              }}
              options={organisationsOptions.sort(
                (a, b) => -b.label.localeCompare(a.firstLetter)
              )}
              getOptionSelected={(option, value) =>
                option.value === value.value
              }
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <CssTextField
                  {...params}
                  label="Buyer"
                  name="buuyer"
                  variant="outlined"
                  value={buyer}
                />
              )}
            />
          </div>
        </div>
      )}
      {loading && <LinearProgress />}
      {invoices.length ? (
        <MultiFieldFilter
          data={invoices}
          modifyData={modifyInvoices}
          setSelectedOption={setInvoiceData}
          filtersMap={getFilterMap()}
        />
      ) : (
        ""
      )}
      {Object.keys(selectedInvoice).length ? (
        <>
          <div
            className="my-10 d-flex"
            style={{
              padding: "15px 12px",
              borderRadius: 7,
              border: "1px solid #f59220",
            }}
          >
            <div className="mr-10">
              <div>
                <span
                  style={{
                    color: "#F59220",
                    paddingRight: 12,
                    fontWeight: 700,
                  }}
                >
                  Biller Name :
                </span>
                {
                  organisationsList.filter(
                    ({ value }) => selectedInvoice.biller_id === value
                  )[0].label
                }
              </div>
              <div>
                <span
                  style={{
                    color: "#F59220",
                    paddingRight: 12,
                    fontWeight: 700,
                  }}
                >
                  Buyer Name :
                </span>
                {
                  organisationsList.filter(
                    ({ value }) => selectedInvoice.buyer_id === value
                  )[0].label
                }
              </div>
            </div>
            <div>
              <div>
                <span
                  style={{
                    color: "#F59220",
                    paddingRight: 12,
                    fontWeight: 700,
                  }}
                >
                  Invoice Date :
                </span>
                {formatDate(selectedInvoice.invoice_date)}
              </div>
              <div>
                <span
                  style={{
                    color: "#F59220",
                    paddingRight: 12,
                    fontWeight: 700,
                  }}
                >
                  Amount :
                </span>
                {selectedInvoice.invoice_lines.reduce(
                  (sum, { quantity, unit_price, tax_rate }) =>
                    sum +
                    (quantity * unit_price +
                      quantity * unit_price * (tax_rate / 100)),
                  0
                )}
              </div>
            </div>
          </div>
          <div className="row mb-10">
            <div className="col-2 mt-5">Select Invoice Line</div>
            <div className="col-8">
              <Autocomplete
                name="line_number"
                placeholder="Invoice Line"
                setValue={setInvoiceLineValue}
                defaultField="line_number"
                label="label"
                options={modifyInvoiceLines(selectedInvoice.invoice_lines)}
              />
            </div>
          </div>
        </>
      ) : null}
      <div>
        {Object.keys(selectedInvoiceLine).length &&
        Object.keys(selectedInvoice).length ? (
          <InvoiceLineDetails
            key={resetKey}
            data={selectedInvoiceLine}
            handleClose={handleClose}
            fetchDiscrepancyData={fetchDiscrepancyData}
          />
        ) : null}
      </div>
    </div>
  );
};
