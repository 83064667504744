import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { MappedSelect } from "../../../../ui/components/Select";
import { MappedInput } from "../../../../ui/components/Input";
import { getAccountsList } from "../../LedgerAccounts/_api";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getTax } from "./_redux/selectors";
import { getTaxById, createTax, modifyTax } from "./_api";
import { TaxFormSchema } from "../../../../helpers/schemas";

function TaxCard({ history, match, location }) {
  const taxId = match.params.id;
  const isNew = location.pathname === "/add-tax";

  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const data = useSelector(getTax(taxId));

  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;

  const [accounts, setAccounts] = useState([]);
  const [cardData, setCardData] = useState(data);

  useEffect(() => {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((response) => {
      if (response) {
        setAccounts(response);
      }
    });

    if (!isNew && !data) {
      request(getTaxById, TENANT_ID, taxId).then((response) =>
        setCardData(response)
      );
    }
    // eslint-disable-next-line
  }, [TENANT_ID]);

  const { name, rate, account_id } = cardData || {};

  const initialValues = {
    name: name || "",
    rate: rate || "",
    account_id: account_id || "",
  };

  const accountOptions = useMemo(
    () =>
      accounts.map(({ id, account_name }) => ({
        value: id,
        label: account_name,
      })),
    [accounts]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={TaxFormSchema}
      onSubmit={(values) => {
        if (isNew) {
          request(createTax, TENANT_ID, { ...values }).then((data) => {
            if (data) {
              return history.push({
                pathname: "/fees",
                from: "tax",
              });
            }
          });
        } else {
          request(modifyTax, TENANT_ID, taxId, { ...values }).then((data) => {
            if (data) {
              return history.push({
                pathname: "/fees",
                from: "tax",
              });
            }
          });
        }
      }}
    >
      {({ values, handleSubmit }) => (
        <>
          <div className="bg-white rounded mb-10">
            <h5 className="ml-15 pt-10">{taxId ? "Edit Tax" : "Add Tax"}</h5>
            <hr />
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-3">
                  <p>Tax Name</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput name="name" wrapperClassName="w-100" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <p>Rate (%)</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput name="rate" wrapperClassName="w-100" />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <p>G/L Account</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedSelect
                    name="account_id"
                    options={accounts && accountOptions}
                  />
                </div>
              </div>
              <hr className="mt-15 mb-10" />
              <div className="d-flex justify-content-end align-items-center mr-10 pb-10">
                <Button
                  onClick={() =>
                    history.push({
                      pathname: "/fees",
                      from: "tax",
                    })
                  }
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(TaxCard);
