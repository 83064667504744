import config from "react-global-configuration";

export const getTenants = () =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/`, {
    mode: "cors",
    credentials: "include",
  });

export const deleteTenant = (id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const createTenant = (tenantData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tenantData),
    mode: "cors",
    credentials: "include",
  });

export const getTenant = (tenantId) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTenant = (tenantData, tenantId) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tenantData),
    mode: "cors",
    credentials: "include",
  });

export const modifyUserSelectedTenant = (tenantData) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tenantData),
    mode: "cors",
    credentials: "include",
  });

export const createTenantCommentType = (tenant_id, commentTypeData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/comment-types`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(commentTypeData),
    mode: "cors",
    credentials: "include",
  });

export const getTenantCommentTypes = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/comment-types`, {
    mode: "cors",
    credentials: "include",
  });

export const modifyTenantCommentTypes = (
  tenant_id,
  comment_type_id,
  commentTypeData
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/comment-types/${comment_type_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(commentTypeData),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteTenantCommentType = (tenant_id, comment_type_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/comment-types/${comment_type_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantTags = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags/`, {
    mode: "cors",
    credentials: "include",
  });

export const createTenantTag = (tenant_id, commentTypeData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(commentTypeData),
    mode: "cors",
    credentials: "include",
  });

export const modifyTenantTag = (tenant_id, tag_id, tagData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags/${tag_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(tagData),
    mode: "cors",
    credentials: "include",
  });

export const deleteTenantTag = (tenant_id, tag_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags/${tag_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyTenantBulkTag = (tenant_id, tag_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags/${tag_id}/bulk-tag`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteTenantBulkTag = (tenant_id, tag_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/tags/${tag_id}/bulk-tag`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const getDefaultTenantOrganisation = (tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/default_organisation`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getTenantLogoUploadUrl = (tenant_id, organisation_id, fileName) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/request_logo_upload_url`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({ file_name: fileName.name }),
    }
  );
