import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import * as auth from "../Auth/_redux/authRedux";
import DataGrid from "../../../ui/components/DataGrid";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTenants, deleteTenant } from "./_api";
import { renderHeader, renderLedgerAccountsRow } from "../../../ui/helpers/helpers";
import Can from "../../../ui/helpers/Can";

const HEADINGS = [
  ["id", "ID"],
  ["name", "Name"],
  ["primary_contact", "Primary Contact"],
  ["support_phone", "Support Phone"],
  ["support_email", "Support Email"],
];

function List(props) {
  const { user } = useSelector((state) => state.auth);
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  function fetchTenants() {
    setLoading(true);
    request(getTenants)
      .then((tenants) => {
        let fetchedTenants = {
          ...user,
          tenants: tenants,
        };
        props.fulfillUser(fetchedTenants);

        setData(tenants);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchTenants();
    // eslint-disable-next-line
  }, []);

  const handleDelete = (tenantId) => {
    setLoading(true);
    request(deleteTenant, tenantId).then(() => fetchTenants());
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Tenants List</h3>
          </div>
          <div className="mb-5">
            <Can
              perform="list:new"
              yes={() => (
                <Link to={"/tenants-list/add-tenant"} data-testid="new-tenant">
                  <button className="px-15 mr-5 btn btn-primary">New</button>
                </Link>
              )}
            />
          </div>

          <Can
            perform="list:new"
            yes={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderLedgerAccountsRow}
                isUserList
                handleDelete={handleDelete}
                editable
                link="tenants-list"
                loading={loading}
              />
            )}
            no={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderLedgerAccountsRow}
                link="tenants-list"
                loading={loading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}

export default connect(null, auth.actions)(List);
