import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { PairForm } from "./PairForm";
import AlertBar from "../../components/AlertBar";

export const TagsPair = ({ onClose, data = [], setValue, selectedPairs = [] }) => {
  const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

  const setRowId = (max, rows = []) => {
    let random = getRandomNumber(max);
    if (!rows.find((item) => item.id === random)) {
      return random;
    } else setRowId(max);
  };

  const [pairs, setPairs] = useState([]);

  useEffect(() => {
    if (selectedPairs.length) {
      setPairs(selectedPairs);
    } else {
      const rowId = setRowId(100000, selectedPairs);
      const newPair = {
        organisation: "",
        contact: "",
        id: rowId,
      };
      setPairs([newPair]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRemovePair = (id) => () => {
    setPairs(pairs.filter((pair) => pair.id !== id));
  };

  const resetState = () => {
    setPairs([]);
  };

  const handleSave = () => {
    const payloadPairs = pairs.filter(({ organisation, contact }) => organisation && contact);
    setValue("tag_pairs", payloadPairs);
    onClose();
  };

  const addPair = () => {
    setPairs((state) => [...state, { id: setRowId(100000, state), organisation: "", contact: "" }]);
  };

  const organisationTags = data.filter(({ type }) => type === "organisation");
  const contactTags = data.filter(({ type }) => type === "contact");
  return (
    <>
      <AlertBar message="Tag Pair will be added only if both organisation tag and contact tag are selected" />
      <div>
        <h3 className="my-7">Create Tag Pairs</h3>
        <Button
          onClick={addPair}
          variant="contained"
          color="secondary"
          size="large"
          className="px-9 my-5"
        >
          Add Pair
        </Button>
        <div>
          {pairs.map(({ id }) => (
            <PairForm
              id={id}
              key={id}
              organisationTags={organisationTags}
              contactTags={contactTags}
              handleRemovePair={handleRemovePair}
              data={pairs.find((pair) => id === pair.id)}
              pairs={pairs}
              setPairs={setPairs}
            />
          ))}
        </div>
        <div className="d-flex justify-content-end mt-15">
          <Button
            onClick={onClose}
            variant="outlined"
            color="secondary"
            size="large"
            className="px-9"
          >
            Cancel
          </Button>
          <Button
            onClick={resetState}
            variant="outlined"
            color="secondary"
            size="large"
            className="px-9 ml-5"
            data-testid="submit"
          >
            Reset
          </Button>
          <Button
            onClick={handleSave}
            variant="contained"
            color="secondary"
            size="large"
            className="px-9 ml-5"
            data-testid="submit"
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
};
