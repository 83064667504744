/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { MatchItem } from "./MatchItem";
import { Button, Tooltip } from "@material-ui/core";
import { Check as CheckIcon } from "@material-ui/icons";
import { SplitPaymentForm } from "./SplitPaymentForm";
import { Modal } from "../../../../../ui/components/Modal";

import { IconButton } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";
import { downloadFile } from "../../../AWS-S3";
import { downloadReportGET } from "../../../Reports/_api";

const colorMap = {
  "Unmatched Transactions": "rgb(225, 67, 82)",
  "Matched Transactions": "rgb(40, 167, 69)",
};

export function MatchItemList({
  data,
  organisationsList,
  TENANT_ID,
  taxesList,
  accountsList,
  title,
  fetchBankStatementData,
  selectedAccount,
}) {
  const [modalSplitOpen, setModalSplitOpen] = useState(false);
  const [reportLoading, setReportLoading] = useState(false);

  const handleModalSplitOpen = (transaction_data) =>
    setModalSplitOpen(transaction_data);

  const handleModalSplitClose = () => setModalSplitOpen(false);

  const handleSubmit = () => {
    setReportLoading(true);
    const report_data = {
      report_name: "reconciliation_by_date",
      account_id: selectedAccount,
    };
    downloadFile(report_data, downloadReportGET, TENANT_ID).finally(() =>
      setReportLoading(false)
    );
  };

  return (
    <>
      {modalSplitOpen && (
        <Modal
          isOpen={modalSplitOpen}
          onClose={handleModalSplitClose}
          fullWidth={true}
          maxWidth="sm"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <SplitPaymentForm
            transactionData={modalSplitOpen}
            onClose={handleModalSplitClose}
            fetchBankStatementData={fetchBankStatementData}
          />
        </Modal>
      )}
      <div>
        {data.length ? (
          <div
            className="justify-content-between p-3 row mt-8 mb-3 align-items-center"
            style={{ borderBottom: `1px solid ${colorMap[title]}` }}
          >
            <h3 style={{ color: colorMap[title] }}>{title}</h3>
            <div className="ml-auto">
              <Tooltip
                title={`Download Reconciliation by Date report`}
                arrow
                interactive
                placement="top"
              >
                <IconButton
                  onClick={handleSubmit}
                  disabled={reportLoading}
                  size="small"
                >
                  <PrintIcon />
                </IconButton>
              </Tooltip>
            </div>
            {title === "Matched Transactions" && (
              <Button
                onClick={() => console.log("Accept All")}
                size="large"
                className="px-9"
                startIcon={<CheckIcon />}
                style={{ color: colorMap[title] }}
              >
                Accept All
              </Button>
            )}
          </div>
        ) : (
          ""
        )}
        {data.length
          ? data.map((item, id) => (
              <MatchItem
                data={item}
                key={id}
                organisationsList={organisationsList}
                TENANT_ID={TENANT_ID}
                accountsList={accountsList}
                taxesList={taxesList}
                handleModalSplitOpen={handleModalSplitOpen}
              />
            ))
          : ""}
      </div>
    </>
  );
}
