import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { renderHeader } from "../../helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getTenantCommentTypes,
  deleteTenantCommentType,
} from "../../../app/modules/Tenants/_api";

const HEADINGS = [
  ["id", "ID"],
  ["comment_category", "Comment Category"],
  ["comment_type_name", "Comment Type"],
];

export function CommentManagerList({ onView, tenantId, setAddCommentManager }) {
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadCommentTypes = () => {
    request(getTenantCommentTypes, tenantId).then((data) => {
      if (data) {
        setData(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadCommentTypes();
    // eslint-disable-next-line
  }, []);

  const renderSecuritiesRow = (headings, item) => {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5">
        {key === "comment_category" || key === "comment_type_name" ? (
          <p
            className="text-primary mb-0"
            onClick={() => onView(item)}
            style={{ cursor: "pointer" }}
          >
            {item[key]}
          </p>
        ) : (
          item[key]
        )}
      </td>
    ));
  };

  const handleDelete = (comment_type_id) => {
    request(deleteTenantCommentType, tenantId, comment_type_id).then(() =>
      loadCommentTypes()
    );
  };

  return (
    <>
      <div className="px-9 py-7 d-flex">
        <button
          className="btn btn-primary mr-6"
          onClick={() => setAddCommentManager(true)}
          data-testid="add-comment"
        >
          Add
        </button>
      </div>

      <div className="px-9">
        <DataGrid
          controls
          headings={HEADINGS}
          data={data}
          renderHeader={renderHeader}
          renderRow={renderSecuritiesRow}
          isUserList
          handleDelete={handleDelete}
          loading={loading}
        />
      </div>
    </>
  );
}
