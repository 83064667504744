import config from "react-global-configuration";

export const getImportantMessages = (tenantId, page_size, page) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenantId}/messages${page_size &&
      "?page_size=" + page_size + "&page=" + page}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addImportantMessage = (tenantId, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/messages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editImportantMessage = (tenantId, id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/messages/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteImportantMessage = (tenantId, id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/messages/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getCreditServiceMemos = (tenantId, page_size, page) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenantId}/memos${page_size &&
      "?page_size=" + page_size + "&page=" + page}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addCreditServiceMemo = (tenantId, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/memos`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const editCreditServiceMemo = (tenantId, id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/memos/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteCreditServiceMemo = (tenantId, id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenantId}/memos/${id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
