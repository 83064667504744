import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "../../../../ui/components/Input";

export const MinimumFee = ({ tenantId }) => {
  const { values, setFieldValue } = useFormikContext();
  const calculateValue = (value) => (value ? values.minimum_fee_value : "");
  useEffect(() => {
    setFieldValue("minimum_fee_value", calculateValue(values.minimum_fee_enabled));
    // eslint-disable-next-line
  }, [values.minimum_fee_enabled]);
  return (
    <div className="col-3">
      <MappedInput
        name="minimum_fee_value"
        wrapperClassName="w-100"
        disabled={!values.minimum_fee_enabled}
      />
    </div>
  );
};
