/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import SettingsIcon from "@material-ui/icons/Settings";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { Stepper, Step, StepLabel } from "@material-ui/core";
import { Pay } from "./Pay";
import { Approve } from "./Approve";
import { Fees } from "./Fees";
import { Calculate } from "./Calculate";
import { Review } from "./Review";
import AlertBar from "../../../ui/components/AlertBar";

export const Distributions = () => {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [step, setStep] = useState(0);
  const [reviewedItem, setReviewedItem] = useState({});

  const handleNextStep = () => setStep(step + 1);

  const handlePrevStep = () => setStep(step - 1);

  const stepsContent = [
    <Calculate
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      tenantId={TENANT_ID}
      setReviewedItem={setReviewedItem}
      setStep={setStep}
    />,

    <Review
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      reviewedItem={reviewedItem}
      tenantId={TENANT_ID}
      setReviewedItem={setReviewedItem}
    />,

    <Fees
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      reviewedItem={reviewedItem}
      setReviewedItem={setReviewedItem}
      tenantId={TENANT_ID}
    />,
    <Approve
      handlePrevStep={handlePrevStep}
      handleNextStep={handleNextStep}
      reviewedItem={reviewedItem}
      setReviewedItem={setReviewedItem}
      tenantId={TENANT_ID}
    />,
    <Pay reviewedItem={reviewedItem} setStep={setStep} tenantId={TENANT_ID} />,
  ];

  const steps = [
    {
      label: "Calculate",
      icon: <PersonAddIcon fontSize="large" />,
      key: "calculate",
    },
    {
      label: "Review",
      icon: <SettingsIcon fontSize="large" />,
      key: "review",
    },
    {
      label: "Fees",
      icon: <SettingsIcon fontSize="large" />,
      key: "fees",
    },
    {
      label: "Approve",
      icon: <LocationOnIcon fontSize="large" />,
      key: "approve",
    },
    {
      label: "Pay",
      icon: <PersonAddIcon fontSize="large" />,
      key: "pay",
    },
  ];

  return (
    <>
      <AlertBar message={"This page is under development!"} />
      <Stepper activeStep={step} alternativeLabel>
        {steps.map(({ label }) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="mt-5">
        {stepsContent.find((item, index) => index === step)}
      </div>
    </>
  );
};
