import { Button } from "@material-ui/core";
import React from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import {
  formatAmount,
  formatDate,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";
import { downloadOrganisationBillingsReport } from "../Billing/_api";
import { downloadFile } from "../AWS-S3";

const handlePrint = (id) => console.log(id);
const handleExport = (id) => console.log(id);

const isExportKey = (key) => key === "export";
const isPrintKey = (key) => key === "print";

const renderCSVButton = (item = {}) => {
  return (
    <Button onClick={() => handleExport(item.id)}>
      <DescriptionIcon color="primary" />
    </Button>
  );
};

const renderPrintButton = (item = {}, TENANT_ID, ORGANISATION_ID) => {
  const downloadBuyerReport = () => {
    const data = {
      billing_id: item.billing_id,
      organisation_id: ORGANISATION_ID,
    };
    downloadFile(data, downloadOrganisationBillingsReport, TENANT_ID);
  };
  return (
    <Button onClick={downloadBuyerReport}>
      <PrintIcon color="secondary" />
    </Button>
  );
};

export function renderStatementsRow(
  headings,
  item,
  TENANT_ID = 0,
  ORGANISATION_ID = 0
) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isDate(key)
        ? item[key] && formatDate(new Date(item[key]))
        : isAmount(key)
        ? formatAmount(item[key])
        : isExportKey(key)
        ? renderCSVButton(item)
        : isPrintKey(key)
        ? renderPrintButton(item, TENANT_ID, ORGANISATION_ID)
        : item[key]}
    </td>
  ));
}
