import React from "react";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import { IconButton } from "@material-ui/core";
import { Print as PrintIcon } from "@material-ui/icons";
import { FeesList } from "./FeesModule/FeesList";
import { TaxesList } from "./TaxesModule/TaxesList";
import Tabs from "../../../ui/components/Tabs";

const tabs = [
  {
    label: "Fees",
    icon: <ContactPhoneIcon />,
    tabPanel: <FeesList />,
  },
  {
    label: "Taxes",
    icon: <LocalOfferIcon />,
    tabPanel: <TaxesList />,
  },
];

export const MainPage = (props) => {
  const activeTab = props.location.from === "tax" ? "1" : "0";

  return (
    <>
      <div className="row mb-9">
        <div className="col-12">
          <div className="d-flex justify-content-between align-items-center bg-white rounded px-13 py-6">
            <h5 className="font-weight-bold">Fees, Charges &#38; Taxes</h5>
            <IconButton color="secondary" disabled>
              <PrintIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mb-9">
            <div className="d-flex flex-column rounded h-100">
              <Tabs defaultActive={activeTab} tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
