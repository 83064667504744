import config from "react-global-configuration";

export const getDistributions = (tenant_id, query) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/distributions?${
      query ? `&${query}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createDistribution = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/distributions`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteDistribution = (tenant_id, distribution_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/distributions/${distribution_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const updateDistribution = (tenant_id, distribution_id, data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/distributions/${distribution_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const approveDistribution = (tenant_id, distribution_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/distributions/${distribution_id}/approve `,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );

export const sendDistribution = (tenant_id, distribution_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/distributions/${distribution_id}/send `,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );
