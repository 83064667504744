import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { modifyUser, createUser } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { UserSchema } from "../../../helpers/schemas";

function UserProfileDetails({
  history,
  data = {},
  setData,
  newUser,
  id,
  users,
  organisationsList,
}) {
  const { request } = useFetch();

  const initialValues = {
    firstName: data.firstName || "",
    lastName: data.lastName || "",
    phone_number: data.mobile || "",
    email: data.email || "",
    organisation_id: data.organisation_id || "",
  };

  if (!newUser && !Object.keys(data).length) {
    return <p className="text-center">Loading...</p>;
  }

  const handleClose = () => {
    history.push("/users-list");
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={() => UserSchema(users, data)}
      onSubmit={({
        firstName,
        lastName,
        email,
        phone_number,
        organisation_id,
      }) => {
        !newUser
          ? request(
              modifyUser,
              {
                name: [firstName, lastName].join(" "),
                email,
                phone_number,
                organisation_id,
              },
              id
            ).then((data) => data && history.push(`/users-list`))
          : request(createUser, {
              name: [firstName, lastName].join(" "),
              email,
              phone_number,
              organisation_id,
            }).then((data) => data && history.push(`/users/${data.id}`));
      }}
    >
      {(props) => (
        <div className="w-50 mr-3">
          <div className="bg-white rounded">
            <div className="px-15 py-19">
              <div className="mb-10 text-center">Account information</div>

              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">First Name</p>
                </div>
                <div className="col-9 mr-auto">
                  <MappedInput
                    name="firstName"
                    wrapperClassName="w-100"
                    data-testid="firstName"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Last Name</p>
                </div>
                <div className="col-9 mr-auto">
                  <MappedInput
                    name="lastName"
                    wrapperClassName="w-100"
                    data-testid="lastName"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Organisation</p>
                </div>
                <div className="col-9 mr-auto">
                  <MappedSelect
                    name="organisation_id"
                    data-testid="organisation_id"
                    options={organisationsList}
                  />
                </div>
              </div>

              <div className="my-10 text-center">Contact information</div>

              <div className="row mb-3">
                <div className="col-3">
                  <p className="text-right text-muted">Mobile</p>
                </div>
                <div className="col-9 mr-auto">
                  <MappedInput
                    name="phone_number"
                    icon="phone"
                    wrapperClassName="w-100"
                    data-testid="phone_number"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-3 ">
                  <p className="text-right text-muted">Email Address</p>
                </div>
                <div className="col-9 mr-auto">
                  <MappedInput
                    name="email"
                    icon="alternate_email"
                    wrapperClassName="w-100"
                    data-testid="email"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-10">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(UserProfileDetails);
