export const HEADINGS = [
  ["shortCode", "ShortCode"],
  ["name", "Trading Name"],
  ["mobile", "Mobile"],
  ["email", "Email"],
  ["cycle_tags", "Cycle Tags"],
];

export const SEARCH_HELPER = [
  ["shortCode", "ShortCode"],
  ["name", "Trading Name"],
  ["phone", "Phone"],
  ["email", "Email"],
  ["mobile", "Mobile"],
  ["legalName", "Legal Name"],
  ["tags", "Tags"],
  ["physicalAddress", "Trading Address"],
  ["physicalAddress2", "Trading Address2"],
  ["physicalSuburb", "Trading Suburb"],
  ["physicalPostCode", "Trading PostCode"],
  ["physicalState", "Trading State"],
  ["postalAddress", "Postal Address"],
  ["postalAddress2", "Postal Address2"],
  ["postalSuburb", "Postal Suburb"],
  ["postalPostCode", "Postal PostCode"],
  ["postalState", "Postal State"],
];

export const searchMap = {
  ShortCode: "shortCode",
  "Trading Name": "name",
  Phone: "phone",
  Email: "email",
  Mobile: "mobile",
  "Legal Name": "legalName",
  Tags: "tags",
  "Trading Address": "physicalAddress",
  "Trading Address2": "physicalAddress2",
  "Trading Suburb": "physicalSuburb",
  "Trading PostCode": "physicalPostCode",
  "Trading State": "physicalState",
  "Postal Address": "postalAddress",
  "Postal Address2": "postalAddress2",
  "Postal Suburb": "postalSuburb",
  "Postal PostCode": "postalPostCode",
  "Postal State": "postalState",
};
