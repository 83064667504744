import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: { backgroundColor: "rgba(0,0,0,0.5)" },
}));

export function Modal({
  title,
  children,
  actions,
  onClose,
  isOpen,
  color,
  rounded,
  ...other
}) {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog classes={classes} onClose={handleClose} open={isOpen} {...other}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent
        style={{ backgroundColor: color ? color : "white" }}
        className={`${rounded ? "rounded" : ""}`}
        dividers
      >
        {children}
      </DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </Dialog>
  );
}
