import React, { useState } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  MenuItem,
  ListItemIcon,
  ListItemText,
  List,
  Dialog,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core/";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import BusinessIcon from "@material-ui/icons/Business";
import * as auth from "../Auth/_redux/authRedux";
import {
  modifyUserSelectedTenant,
  getTenants,
  getDefaultTenantOrganisation,
} from "./_api";

function TenantSwitcher(props) {
  const { user } = useSelector((state) => state.auth);
  const history = useHistory();
  const { request } = useFetch();

  const [open, setOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  function fetchTenants() {
    request(getTenants).then((tenants) => {
      let fetchedTenants = {
        ...user,
        tenants: tenants,
      };
      props.fulfillUser(fetchedTenants);
    });
  }

  const handleClick = (e) => {
    setIsLoading(true);

    const { tenantId } = e.currentTarget.dataset;

    request(getDefaultTenantOrganisation, tenantId)
      .then((defaultTenantData) => {
        if (defaultTenantData) {
          let defaultOrgId = defaultTenantData.id;
          let defaultOrgName = defaultTenantData.name;

          let userChangeTenantAndOrg = {
            ...user,
            default_organisation_id: parseInt(defaultOrgId),
            default_organisation_name: defaultOrgName,
            selectedTenant: parseInt(tenantId),
          };
          props.fulfillUser(userChangeTenantAndOrg);

          const data = { "custom:selected_tenant": tenantId };

          request(modifyUserSelectedTenant, data).then((response) => {
            setOpen(false);
            setIsLoading(false);
          });
        }
      })
      .then(history.push("/dashboard"));
  };

  return (
    <Dialog
      open={open}
      disableBackdropClick={false}
      disableEscapeKeyDown={false}
    >
      <DialogTitle id="simple-dialog-title">
        Select Your Credit Service
      </DialogTitle>
      {user.tenants.length ? (
        <List
          component="nav"
          aria-label="main mailbox folders"
          className="text-center"
        >
          {isLoading ? (
            <CircularProgress className="text-warning" size="2rem" />
          ) : (
            <div>
              {user.tenants.map((tenant) => {
                return (
                  <MenuItem
                    key={tenant.id}
                    onClick={handleClick}
                    data-tenant-id={tenant.id}
                  >
                    <ListItemIcon style={{ paddingLeft: 8 }}>
                      {tenant.icon ? (
                        <span className="svg-icon menu-icon">
                          <img src={toAbsoluteUrl(tenant.icon)} alt="" />
                        </span>
                      ) : (
                        <BusinessIcon />
                      )}
                    </ListItemIcon>
                    <ListItemText primary={tenant.name} />
                  </MenuItem>
                );
              })}
            </div>
          )}
        </List>
      ) : (
        <>
          <div className="text-center p-8">
            You don't have any tenant. Please contact the Portal Admin!
          </div>
          <button
            className="btn btn-primary mx-30 mb-4"
            onClick={() => fetchTenants()}
          >
            Refresh my tenants
          </button>
          <button
            className="btn btn-outline-primary mx-30 mb-8"
            onClick={() => history.push("/logout")}
          >
            Logout
          </button>
        </>
      )}
    </Dialog>
  );
}

export default connect(null, auth.actions)(TenantSwitcher);
