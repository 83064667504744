import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab/";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAccountBalance } from "../LedgerAccounts/_api";

import { getAccounts } from "../Organisations/_api";

export const NewPayments = ({ TENANT_ID, ORGANISATION_ID }) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [accountID, setAccountID] = useState("");
  const [accountBalance0, setAccountBalance0] = useState("");
  const [accountBalance7, setAccountBalance7] = useState("");
  const [accountBalance14, setAccountBalance14] = useState("");
  const [accountBalance21, setAccountBalance21] = useState("");

  function fetchAccountBalance(setter, within_days = "0") {
    setLoading(true);

    request(
      getAccountBalance,
      TENANT_ID,
      ORGANISATION_ID,
      accountID,
      within_days,
      true
    ).then((response) => {
      if (response) {
        setter(response.total);
      }
      setLoading(false);
    });
  }

  const fetchAccounts = () => {
    setLoading(true);

    request(getAccounts, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        setAccountID(
          (
            data?.filter(
              ({ account_type, ledger_type }) =>
                account_type === "internal" && ledger_type === "buyer"
            )[0] || {}
          ).id ||
            data[1].id ||
            ""
        );
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    fetchAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID]);

  useEffect(() => {
    if (accountID) {
      fetchAccountBalance(setAccountBalance0);
      fetchAccountBalance(setAccountBalance7, "7");
      fetchAccountBalance(setAccountBalance14, "14");
      fetchAccountBalance(setAccountBalance21, "21");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountID]);

  const a = [
    { title: "now", value: 0 },
    { title: "7", value: 233 },
    { title: "14", value: 233 },
    { title: "21", value: 233 },
  ];

  return (
    <div className="card card-custom mb-5">
      <div className="border-0" style={{ padding: "1rem 0 1rem 1.75rem" }}>
        <h4 className="font-weight-bolder text-dark">Account Balance</h4>
      </div>
      <div className="mx-7">
        <div className="border border-secondary mb-2 d-flex py-3 px-2 align-items-center rounded">
          <h6 className="mb-0">Due Now :</h6>
          {loading ? (
            <Skeleton
              variant="rect"
              width={"20%"}
              height={16}
              className="ml-5"
            />
          ) : (
            <h6 className="ml-5 mb-0">
              ${accountBalance0 ? accountBalance0 : "0"}
            </h6>
          )}
        </div>
        <div className="d-flex justify-content-space-around flex-wrap">
          <div className="col-4 border border-secondary mb-3 d-flex align-items-center px-1 py-2 rounded">
            <div className="d-flex w-100">
              <h6 className="mb-0">7+ :</h6>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={"40%"}
                  height={16}
                  className="ml-5"
                />
              ) : (
                <h6 className="ml-5 mb-0">
                  ${accountBalance7 ? accountBalance7 : "0"}
                </h6>
              )}
            </div>
          </div>
          <div className="col-4 border border-secondary mb-3 d-flex align-items-center px-1 py-2 rounded">
            <div className="d-flex w-100">
              <h6 className="mb-0">14+ :</h6>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={"40%"}
                  height={16}
                  className="ml-5"
                />
              ) : (
                <h6 className="ml-5 mb-0">
                  ${accountBalance14 ? accountBalance14 : "0"}
                </h6>
              )}
            </div>
          </div>
          <div className="col-4 border border-secondary mb-3 d-flex align-items-center px-1 py-2 rounded">
            <div className="d-flex w-100">
              <h6 className="mb-0">21+ :</h6>
              {loading ? (
                <Skeleton
                  variant="rect"
                  width={"40%"}
                  height={16}
                  className="ml-5"
                />
              ) : (
                <h6 className="ml-5 mb-0">
                  ${accountBalance21 ? accountBalance21 : "0"}
                </h6>
              )}
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-5">
          <div style={{ width: "45%" }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="w-100"
            >
              Reconcile
            </Button>
          </div>
          <div style={{ width: "45%" }}>
            <NavLink to="/buyer-payment">
              <Button
                variant="contained"
                color="primary"
                size="large"
                className="w-100"
                style={{ color: "#fff" }}
              >
                Pay
              </Button>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};
