import React from "react";
import { TextField } from "@material-ui/core";
import {
  Autocomplete as MuiAutocomplete,
  createFilterOptions,
} from "@material-ui/lab";
import { FormControlLabel, CircularProgress } from "@material-ui/core";
import get from "lodash/get";
import { uniqBy } from "lodash";

export const Autocomplete = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  disabled,
  defaultField = "id",
  variant = "outlined",
  label = "name",
  ...other
}) => {
  const handleChange = (_, value) => {
    setValue(name, get(value, defaultField, ""));
  };

  const sortHelper = function(a, b) {
    if (a[label] > b[label]) return 1;
    if (a[label] < b[label]) return -1;
    return 0;
  };

  const filterOptions = createFilterOptions({
    limit: 70,
  });

  return (
    <div>
      <MuiAutocomplete
        options={uniqBy(options, label).sort(sortHelper)}
        onChange={handleChange}
        filterOptions={filterOptions}
        getOptionLabel={(item) => item[label]}
        defaultValue={options.find(
          (item) => item[defaultField] === defaultValue
        )}
        disabled={disabled}
        {...other}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            variant={variant}
            color="secondary"
          />
        )}
      />
      {loading && (
        <FormControlLabel
          control={
            loading ? (
              <CircularProgress size="1.2rem" style={{ margin: 11 }} />
            ) : (
              <div></div>
            )
          }
        />
      )}
      {tip && <div>{tip}</div>}
    </div>
  );
};
