import React, { useState } from "react";
import { Tabs as MuiTabs, Tab, IconButton } from "@material-ui/core";
import TabContext from "@material-ui/lab/TabContext";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
} from "@material-ui/icons/";

const THEME = {
  primary: { indicatorColor: "primary", textColor: "primary" },
  secondary: { indicatorColor: "secondary", textColor: "secondary" },
};

const useStyles = makeStyles({
  root: {
    minWidth: "75px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    backgroundColor: "#F3F6F9",
  },
  labelIcon: {
    minHeight: "0px",
  },
  wrapper: {
    flexDirection: "row",
    textTransform: "capitalize",
  },
  selected: {
    backgroundColor: "#ffffff",
    "&:hover": {
      backgroundColor: "#ffffff",
    },
  },
  // "& .MuiTabScrollButton-root": {
  //   background: "black",
  //   "&$disabled": {
  //     opacity: 0,
  //     width: 0,
  //   },
  // },
  // "& .MuiTabScrollButton-root.Mui-disabled": {
  //   background: "black",
  //   // "&$disabled": {
  //   // opacity: 0,
  //   // width: 0,
  //   // },
  // },
});
// .MuiTabScrollButton-root.Mui-disabled

export default function Tabs({
  defaultActive,
  tabs,
  excludeTab,
  theme = "secondary",
  loading,
  getActive,
  fullWidth,
  naked,
}) {
  const [active, setActive] = useState(defaultActive);

  const classes = useStyles();
  const handleChange = (_, selected) => {
    setActive(selected);
    getActive && getActive(tabs[selected].label);
  };

  return (
    <>
      <TabContext value={active}>
        <MuiTabs
          value={active}
          onChange={handleChange}
          style={{ maxHeight: 42, minHeight: 42 }}
          {...THEME[theme]}
          variant={fullWidth ? "fullWidth" : "scrollable"}
          scrollButtons="auto"
          className="border-bottom border-secondary"
          ScrollButtonComponent={(props) => {
            if (props.direction === "left" && !props.disabled) {
              return (
                <IconButton {...props} style={{ borderRadius: "20% 0% 0% 0%" }}>
                  <ChevronLeftIcon />
                </IconButton>
              );
            } else if (props.direction === "right") {
              return (
                <IconButton {...props} style={{ borderRadius: "0% 20% 0% 0%" }}>
                  <ChevronRightIcon />
                </IconButton>
              );
            } else {
              return null;
            }
          }}
        >
          {tabs.map(({ label, icon }, index) => {
            if (label === excludeTab) {
              return null;
            } else {
              return (
                <Tab
                  value={String(index)}
                  label={<div className="p-2 mb-1">{label}</div>}
                  icon={icon}
                  key={index}
                  classes={classes}
                  disabled={loading}
                  style={{ background: naked && "None" }}
                />
              );
            }
          })}
        </MuiTabs>
        {tabs.map(({ tabPanel }, index) => (
          <div
            role="tabpanel"
            aria-hidden={active !== String(index)}
            key={index}
            className={
              active === String(index)
                ? `flex-grow-1 ${!naked && "bg-white"}`
                : ""
            }
          >
            {active === String(index) && tabPanel}
          </div>
        ))}
      </TabContext>
    </>
  );
}
