import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import PrintIcon from "@material-ui/icons/Print";
import { Link } from "react-router-dom";
import DataGrid from "../../../ui/components/DataGrid";
import { getPaymentCycleElementsById, deletePaymentCycleElement, extendPaymentCycle } from "./_api";
import {
  renderHeader,
  renderPaymentCycleRow,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";
import { ExtendForm } from "./ExtendForm";

const HEADINGS = [
  ["id", "ID"],
  ["start_date", "Start Date"],
  ["finish_date", "Finish Date"],
  ["due_date", "Due Date"],
  ["note", "Note"],
];

export function Card({ match, location, history }) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const paymentCyclesId = match.params.id;
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [extended, setExtended] = useState(false)
  const [selected, setSelected] = useState({});
  const [extendLoading, setExtendLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)

  const handleCloseExtendLoading = () => setExtendLoading(false);
  const handleModalClose = () => setModalOpen(false);
  const handleModalOpen = () => setModalOpen(true);

  useEffect(() => {
    setLoading(true);
    request(getPaymentCycleElementsById, TENANT_ID, paymentCyclesId).then(
      (response) => {
        if (response) {
          setData(response);
          setLoading(false);
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID, deleted, setDeleted, extended]);

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this element? This process is not reversible"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        deletePaymentCycleElement(TENANT_ID, paymentCyclesId, itemID).then(
          (res) => {
            if (res.status === 200 || res.status === 204) {
              setDeleted(!deleted);
              setSelected({});
            }
          }
        );
      } catch {}
    }
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleExtend = (values) => {
    handleModalClose()
    setExtendLoading(true);
    request(extendPaymentCycle, TENANT_ID, paymentCyclesId, values)
      .then((data) => data && setExtended(!extended))
      .finally(() => setExtendLoading(false));
  };

  return (
    <>
      {extendLoading && (
        <Loader
          title="Loading..."
          onClose={handleCloseExtendLoading}
          isOpen={extendLoading}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={handleModalClose} maxWidth="sm" fullWidth>
          <ExtendForm handleClose={handleModalClose} handleSubmit={handleExtend} />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Parent Payment Cycles</h3>
              <div>
                <IconButton color="secondary" disabled>
                  <PrintIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
            <div>
              <div className="mb-5 d-flex justify-content-between">
                <div>
                  <Link to={`/payment-cycles/${paymentCyclesId}/add-cycle-element`}>
                    <button className="px-15 mr-5 btn btn-primary">New</button>
                  </Link>
                  <button
                    className="px-15 mr-5 btn btn-primary"
                    onClick={handleArchive}
                    disabled={archiveDisabled}
                  >
                    Archive
                  </button>
                </div>
                <div>
                  <button className="px-15 mr-5 btn btn-primary" onClick={handleModalOpen}>
                    Extend
                  </button>
                </div>
              </div>
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderPaymentCycleRow}
                editable
                selectable
                selected={selected}
                setSelected={setSelected}
                link={`payment-cycles/${paymentCyclesId}/elements`}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
