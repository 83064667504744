export const userTypes = {
  SET_DATA: "USERS/SET_DATA",
  DELETE_USER: "USERS/DELETE_USER",
  UPDATE_DATA: "USERS/UPDATE_DATA"
}

export function setUsersList(data) {
  return {
    type: userTypes.SET_DATA,
    payload: data
  }
}

export function deleteUserData(id) {
  return {
    type: userTypes.DELETE_USER,
    payload: id
  }
}

export function updateUsersList(id) {
  return {
    type: userTypes.UPDATE_DATA,
    payload: id
  }
}