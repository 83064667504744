import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import {
  MailOutline as MailOutlineIcon,
  LocationOn as LocationOnIcon,
  PersonOutlineOutlined as PersonOutlineOutlinedIcon,
  Print as PrintIcon,
} from "@material-ui/icons/";
import { Button } from "@material-ui/core";
import { downloadFile } from "../../../app/modules/AWS-S3/download";
import { exportOrganisationById } from "../../../app/modules/Organisations/_api";
import { exportContactById } from "../../../app/modules/Contacts/_api";
import ToolTip from "../../components/Tooltip";
import ButtonMailto from "../../components/ButtonMailto";

function Header({
  firstName,
  lastName,
  name = "Organisation",
  email,
  geolocation,
  isContact,
  history,
  organisationId,
  tenantId,
  id,
  status,
  position_title,
  isTenant
}) {
  const [printLoading, setPrintLoading] = useState(false);
  const handleClick = () => {
    history.push(`/contacts/organisations-list/${organisationId}`);
  };

  const handlePrint = () => {
    setPrintLoading(true);
    isContact
      ? downloadFile([id], exportContactById, tenantId, setPrintLoading)
      : downloadFile([id], exportOrganisationById, tenantId, setPrintLoading);
  };

  return (
    <div className="d-flex justify-content-between align-items-center bg-white rounded px-13 py-6">
      <div>
        <div className="mb-4">
          <span className="font-weight-bolded h4 align-middle text-dark mr-3">
            {!isContact ? (status === "archived" ? name + " (Archived)" : name) : ""}
            {firstName && firstName} {lastName && lastName}{" "}
            {isContact && status === "archived" ? " (Archived)" : ""}
          </span>
        </div>
        <div>
          {position_title && (
            <span className="align-middle mr-13">
              <PersonOutlineOutlinedIcon fontSize="large" className="pr-3" />
              {position_title}
            </span>
          )}
          {email && (
            <span className="align-middle mr-13">
              <MailOutlineIcon fontSize="large" className="pr-3" />
              <ButtonMailto
                style={{ color: "#212121" }}
                email={email}
                subject={`Hello ${firstName && firstName} ${lastName && lastName}`}
                body={`Dear ${firstName && firstName} ${lastName && lastName},`}
              >
                {email}
              </ButtonMailto>
            </span>
          )}
          {geolocation && !isContact && (
            <span className="align-middle">
              <LocationOnIcon />
              {geolocation}
            </span>
          )}
        </div>
      </div>
      {isTenant ? null : (
        <div className="d-flex">
          <ToolTip
            icon={<PrintIcon fontSize="large" />}
            title={`Print Selected ${isContact ? "Contact" : "Organisation"} Data in PDF`}
            handleClick={handlePrint}
            loading={printLoading}
            testid="print"
          />
          {isContact && (
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="ml-10 px-13"
              onClick={handleClick}
            >
              {name}
            </Button>
          )}
        </div>
      )}
    </div>
  );
}

export default withRouter(Header);
