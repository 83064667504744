const type = "fees";

export const setFeesList = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      feesList: payload,
    };
  },
  type,
});

export const deleteFee = (payload) => ({
  execute: (state) => {
    return {
      ...state,
      feesList: [...state.feesList.filter(({ id }) => id !== payload)],
    };
  },
  type
});
