import { formatDate } from "../../../ui/helpers/helpers";

const past7Days = [...Array(7).keys()].map((index) => {
  const date = new Date();
  date.setDate(date.getDate() - 6 + index);

  return [formatDate(date), formatDate(date)];
});

export const HEADINGS = [
  ["name", "Biller"],
  ...past7Days,
  ["total", "Total"],
  ["4_week_avg", "4 Weeks Avg"],
  ["contact", ""],
];
