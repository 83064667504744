import React from "react";
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from "@material-ui/core/styles";
import { Input, InputLabel, MenuItem, FormControl, Select } from "@material-ui/core";
import { useField } from "formik";
import { getTip } from "../../helpers/helpers";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
  },
  root: {
    border: "1px solid #E4E6EF",
    borderRadius: "4px",
  },
  label: {
    left: "15px",
    top: "-20px",
  },
}));

export const FilterSelect = ({
  defaultValue = "",
  options = [],
  tip,
  label = "",
  minWidth = 120,
  multiple = true,
  ...other
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControl className={classes.formControl} style={{ minWidth }} color="secondary">
        <InputLabel id={label} className={classes.label}>
          {label}
        </InputLabel>
        <Select
          labelId={label}
          id="mutiple-checkbox"
          multiple={multiple}
          defaultValue={defaultValue}
          input={<Input />}
          className={`${classes.root} mt-0 pl-1`}
          MenuProps={MenuProps}
          disableUnderline={true}
          {...other}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value} children={label} />
          ))}
        </Select>
      </FormControl>
      {tip && <div>{tip}</div>}
    </>
  );
};

export function MappedFilterSelect(props) {
  const [field, meta] = useField(props.name);
  return <FilterSelect {...field} {...props} tip={getTip(meta, props.tip)} />;
}
