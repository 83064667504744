import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import File from "./File";
import { BrowseFile } from "../../../ui/components/BrowseFile";
import { getAllBsbIntegrations, getIntegrationById } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { formatDate } from "../../../ui/helpers/helpers";

export function BSBForm() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [lastUpload, setLastUpload] = useState("");
  const [lastUploadId, setLastUploadId] = useState();
  const [updateLastUpload, setUpdateLastUpload] = useState(true);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const { request } = useFetch();

  useEffect(() => {
    request(getAllBsbIntegrations, TENANT_ID).then((response) => {
      if (response[0]) {
        setLastUploadId(response[0].id);
      } else {
        setLastUpload("-");
      }
    });
    // eslint-disable-next-line
  }, [TENANT_ID, uploadedFiles, setUploadedFiles]);

  useEffect(() => {
    if (!lastUploadId) return;

    request(getIntegrationById, lastUploadId).then((response) => {
      if (response.csv_file.last_modified) {
        let date = formatDate(new Date(response.csv_file.last_modified));
        setLastUpload(date);
      } else {
        setLastUpload("-");
      }
    });
    // eslint-disable-next-line
  }, [lastUploadId, setLastUploadId, updateLastUpload, setUpdateLastUpload]);

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  return (
    <>
      <div className="row align-items-end">
        <BrowseFile
          onDrop={handleUploadFiles}
          heading="Select BSB Listing File"
        >
          {uploadedFiles.map((file) => (
            <File
              key={file.lastModified}
              status="active"
              tenant_id={TENANT_ID}
              integration_type="bsb"
              file={file}
              lastUploadId={lastUploadId}
              updateLastUpload={updateLastUpload}
              updateLastUploadState={setUpdateLastUpload}
            />
          ))}
        </BrowseFile>
        <div className="d-flex flex-column ml-10 mx-auto">
          <span className="mb-10 text-center font-weight-bold">
            Last Uploaded
          </span>
          <span
            className="text-center"
            style={{
              display: "flex",
              alignItems: "center",
              minHeight: "38.4px",
            }}
          >
            {lastUpload ? lastUpload : "Loading..."}
          </span>
        </div>
      </div>
      <div>
        <h2 className="font-weight-bold mt-15 mb-10">
          Updating your BSB Listing
        </h2>
        <p>
          To update your BSB Listing visit{" "}
          <a
            href="https://bsb.apca.com.au"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://bsb.apca.com.au
          </a>{" "}
          and download the current BSB Directory (Full Version) CSV file before
          importing it here.
        </p>
      </div>
    </>
  );
}
