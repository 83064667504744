import React, { useEffect, useState } from "react";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { MappedSwitch } from "../../components/Switch";
import { MappedDatePicker } from "../../components/DatePicker";
import { SecuritySchema } from "../../../helpers/schemas";
import { Files } from "../Files";
import { useFetch } from "../../../hooks/fetch.hook";
import { getOrganisationSecurityFiles } from "../../../app/modules/Organisations/_api";
import { downloadFile } from "../../../app/modules/AWS-S3";
import { securityTypeOptions } from "../SecurityAdd/constants";
import { formatDateToUTC } from "../../helpers/helpers";

export function SecurityForm({
  tenantId,
  securityData,
  onClose,
  onSubmit,
  setData,
}) {
  const { request } = useFetch();
  const [files, setFiles] = useState([]);

  const {
    security_type,
    name,
    required_value,
    actual_value,
    date_commenced,
    expires,
    date_expiry,
    organisation_id,
    id,
  } = securityData;

  useEffect(() => {
    request(getOrganisationSecurityFiles, tenantId, organisation_id, id).then(
      setFiles
    );
    // eslint-disable-next-line
  }, []);

  const initialValues = {
    security_type: security_type || "",
    name: name || "",
    required_value: required_value || "",
    actual_value: actual_value || "",
    date_commenced: (date_commenced && new Date(date_commenced)) || "",
    expires: expires !== false,
    date_expiry: (date_expiry && new Date(date_expiry)) || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleClose = () => onClose(false);

  if (!Object.keys(securityData).length) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={SecuritySchema}
      onSubmit={(values) => {
        onSubmit({
          ...values,
          date_commenced: formatDateToUTC(values.date_commenced),
          date_expiry: formatDateToUTC(values.date_expiry),
        }).then(() => onClose(false));
      }}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded px-21 py-19">
            <div className="row">
              <div className="col-5">
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Security Type</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedSelect
                      name="security_type"
                      options={securityTypeOptions}
                      data-testid="security_type"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Security Name</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="name"
                      wrapperClassName="w-100"
                      data-testid="name"
                    />
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Required Value</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="required_value"
                      wrapperClassName="w-100"
                      data-testid="required_value"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Actual Value</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedInput
                      onBlur={handleOnBlur}
                      name="actual_value"
                      wrapperClassName="w-100"
                      data-testid="actual_value"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Received Date</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedDatePicker
                      name="date_commenced"
                      testId="date_commenced"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Expires</p>
                  </div>
                  <div className="col-8 mr-auto">
                    <MappedSwitch
                      name="expires"
                      checked={props.values.expires}
                      onBlur={handleOnBlur}
                      data-testid="expires"
                    />
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-2">
                    <p className="text-right text-muted">Expiry Date</p>
                  </div>
                  <div className="col-8 mr-auto">
                    {props.values.expires ? (
                      <MappedDatePicker
                        name="date_expiry"
                        testId="date_expiry"
                      />
                    ) : (
                      <MappedInput
                        name="expiryDate"
                        data-testid="date_expiry"
                        value={(props.values.date_expiry = "")}
                        disabled
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-7">
                <h4 className="text-primary">Security Documents</h4>
                <Files files={files} onDownload={downloadFile} />
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                onClick={props.handleSubmit}
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
