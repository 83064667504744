import config from "react-global-configuration";

export const getNABPyByWeb = (tenant_id, organisation_id, card_type = "") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/nab_parameters${
      card_type ? "?card_type=" + card_type : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
export const getPaymentMethods = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/payment-methods`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
