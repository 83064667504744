import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { modifyUser } from "../modules/UserManagment/_api";
import { getUser } from "../modules/Auth/_api";
import { useFetch } from "../../hooks/fetch.hook";
import { Link } from "@material-ui/core/";
import { ChangePasswordForm } from "../../ui/structures/ChangePasswordForm";
import { SignatureUpload } from "../modules/UserManagment/SignatureUpload";

const UserProfile = (props) => {
  const { request } = useFetch();

  const [open, setOpen] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const [signature, setSignature] = useState("")

  const handleChangeFirstName = useHandler(setFirstName);
  const handleChangeLastName = useHandler(setLastName);
  const handleChangeTitle = useHandler(setTitle);
  const handleChangePhone = useHandler(setPhone);
  const handleChangeEmail = useHandler(setEmail);

  const handleSubmit = (e) => {
    e.preventDefault();
    request(
      modifyUser,
      {
        name: [firstName, lastName].join(" "),
        email,
        phone_number: phone,
        "custom:title": title,
      },
      id
    ).then((data) => data && props.history.push("/dashboard"));
  };

  useEffect(() => {
    request(getUser)
      .then((data) => mapUserData(data))
      .then((user) => {
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setTitle(user.title);
        setPhone(user.phone);
        setEmail(user.email);
        setId(user.id);
        setSignature(user.signature_image_url)
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="bg-white p-8 rounded">
        <ChangePasswordForm open={open} setOpen={setOpen} />
        <div className="row">
          <div className="col col-lg-12">
            <form onSubmit={handleSubmit}>
              <div
                className="row mx-0"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h3 className="">Edit User</h3>
                <Link onClick={() => setOpen(true)} style={{ color: "#3699FF" }}>
                  Change Password
                </Link>
              </div>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  name="firstName"
                  className="form-control form-control-solid"
                  value={firstName}
                  onChange={handleChangeFirstName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  name="lastName"
                  className="form-control form-control-solid"
                  value={lastName}
                  onChange={handleChangeLastName}
                />
              </div>
              <div className="form-group">
                <label htmlFor="title">Title</label>
                <input
                  type="text"
                  name="title"
                  className="form-control form-control-solid"
                  value={title}
                  onChange={handleChangeTitle}
                />
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="phone">Phone</label>
                  <input
                    type="tel"
                    name="phone"
                    className="form-control form-control-solid"
                    value={phone}
                    onChange={handleChangePhone}
                  />
                </div>
                <div className="form-group col-md-6 col-xs-12">
                  <label htmlFor="email">Email</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control form-control-solid"
                    value={email}
                    onChange={handleChangeEmail}
                  />
                </div>
              </div>
              <div className="row mt-10">
                <div className="col-md-6 col-xs-12"></div>
                <div className="col-md-3 col-xs-12">
                  <button
                    type="button"
                    className="btn w-100 px-9 mr-6 mb-4 btn-outline-secondary"
                    onClick={() => props.history.push("/dashboard")}
                  >
                    CANCEL
                  </button>
                </div>
                <div className="col-md-3 col-xs-12">
                  <button className="btn btn-primary w-100 px-9" type="submit">
                    SAVE
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <SignatureUpload user_id={id} signature={signature} setSignature={setSignature}/>
      </div>
    </>
  );
};

export default withRouter(UserProfile);

function useHandler(handler) {
  return useCallback((e) => handler(e.target.value), [handler]);
}

function mapUserData(response) {
  const {
    user_info: { email = "", phone_number: phone = "", "custom:title": title = "" } = {},
    name = "",
    id = "",
    signature_image_url = ""
  } = response || {};
  const [firstName = "", lastName = ""] = name.split(" ");
  return {
    firstName,
    lastName,
    title,
    email,
    phone,
    id,
    signature_image_url
  };
}
