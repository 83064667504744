import { useSelector } from "react-redux";
import rules from "./rules";

const check = (rules, selectedTenant, userPermissions = {}, action, data) => {
  // console.log("start Can check");
  // console.log(
  //   "rules: ",
  //   rules,
  //   " selectedTenant: ",
  //   selectedTenant,
  //   " action: ",
  //   action,
  //   " data: ",
  //   data
  // );
  // console.log("userPermissions: ", userPermissions);

  // If portal admin
  if (
    userPermissions.global &&
    Object.keys(userPermissions.global).length !== 0 &&
    userPermissions.global.permission_level === "edit"
  ) {
    // console.log("portal admin permission");
    return true;
  }

  // If tenant admin
  if (userPermissions.tenants[selectedTenant]?.tenant?.permission_level === "edit") {
    // console.log("tenant admin permission");
    if (rules["only_portal_admin"].includes(action)) {
      return false;
    } else {
      return true;
    }
  }

  let permissions = {};

  // We need to check tenant specific access level and type
  if (Object.keys(userPermissions.tenants).length !== 0) {
    Object.values(userPermissions.tenants)[0].resources.forEach((item) => {
      permissions = {
        ...permissions,
        ...rules[item.resource.name][item.permission_level],
      };
    });
  }

  if (!permissions) {
    // role is not present in the rules
    return false;
  }

  const staticPermissions = Object.values(permissions);
  // console.log("permissions, staticPermissions");
  // console.log(permissions, staticPermissions);
  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true;
  }

  // const dynamicPermissions = permissions.dynamic;

  // if (dynamicPermissions) {
  //   const permissionCondition = dynamicPermissions[action];
  //   if (!permissionCondition) {
  //     // dynamic rule not provided for action
  //     return false;
  //   }
  //   return permissionCondition(data);
  // }

  return false;
};

const Can = (props) => {
  const { user } = useSelector((state) => state.auth);

  // const haveAccessToThisTenant = user.roles.some(
  //   (e) => e.tenant_id === user.tenant_id || e.tenant_id === null
  // );

  return check(
    rules,
    user.selectedTenant,
    user.permissions,
    props.perform,
    props.data
  )
    ? props.yes()
    : props.no();
};

Can.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default Can;
