import { billingCheckTypes } from "./actions";

const initialState = {
  billingCheckList: [],
};

export function billingCheckReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case billingCheckTypes.SET_DATA:
      return {
        ...state,
        billingCheckList: payload,
      };

    default:
      return state;
  }
}
