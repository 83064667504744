/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { NewRuleForm } from "./NewRuleForm";
import { Modal } from "../../../../../../ui/components/Modal";
import { getOrganisationsListSimple } from "../../../../Organisations/_api";
import { useFetch } from "../../../../../../hooks/fetch.hook";

export function ReconciliationTeachForm({ selectedItem = [] }) {
  const { name, rule } = selectedItem;

  const { request } = useFetch();

  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [modalOpen, setModalOpen] = useState(false);
  const [organisationsList, setOrganisationsList] = useState([]);

  const handleModalOpen = () => setModalOpen(true);

  const handleModalClose = () => setModalOpen(false);

  const initialValues = {
    name: name || "",
    rule: rule || "",
  };

  useEffect(() => {
    request(getOrganisationsListSimple, TENANT_ID).then(
      (data) => data && setOrganisationsList(data)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="lg"
          disableBackdropClick
          disableEscapeKeyDown
        >
          <NewRuleForm
            organisationsList={organisationsList}
            onClose={handleModalClose}
          />
        </Modal>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          title: Yup.string().required("Required"),
          type: Yup.string().required("Required"),
          message: Yup.string().required("Required"),
        })}
        onSubmit={(values) => {
          console.log("send transform data");
        }}
      >
        {(props) => (
          <div className="rounded">
            <div className="row">
              <div className="col-12">
                <div className="row py-2 justify-content-center">
                  <div className="col-6">
                    <Button
                      variant="outlined"
                      color="secondary"
                      size="large"
                      className="px-9 mr-3 w-100"
                      onClick={handleModalOpen}
                    >
                      Create New Rule
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </>
  );
}
