import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import InputIcon from "@material-ui/icons/Input";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import {
  getSelectedId,
  renderHeader,
  renderUsersRow,
} from "../../../ui/helpers/helpers";
import { getAllUsers, deleteUser, reactivateUser, getUserById } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  setUsersList,
  deleteUserData,
  updateUsersList,
} from "./_redux/actions";
import { SEARCH_DATA } from "./constants";
import ToolTip from "../../../ui/components/Tooltip";
import { modifyUserData, modifyUsers } from "./helpers";
import { Modal } from "../../../ui/components/Modal";
import { ConfirmAction } from "../../../ui/components/ConfirmAction";

const HEADINGS = [
  ["firstName", "First Name"],
  ["lastName", "Last Name"],
  ["mobile", "Mobile"],
  ["email", "Email Address"],
];

export function List() {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState({});
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [unArchiving, setUnarchiving] = useState(false);
  const [archived, setArchived] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");

  const handleModalClose = () => setModalOpen(false);

  const data = useSelector(({ users }) => users.usersList) || [];

  const fetchUsers = () => {
    setLoading(true);
    request(getAllUsers, TENANT_ID, archived)
      .then((data) => data && modifyUsers(data))
      .then((data) => {
        dispatch(setUsersList(data));
        setUsers(data);
      })
      .finally(() => {
        setLoading(false);
        setArchivedLoading(false);
      });
  };

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, [archived]);

  const unArchiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const handleUnarchive = () => {
    const userID = Number(getSelectedId(selected));
    const status = (data.find(({ id }) => id === userID) || {}).status;
    if (status !== "archived") return;
    setUnarchiving(true);
    request(reactivateUser, userID)
      .then((data) => {
        if (!data) return;
        dispatch(updateUsersList(modifyUserData(data)));
        setSelected({});
      })
      .finally(() => setUnarchiving(false));
  };

  const KEY_MAP = {
    "First Name": data.map((item) => item.firstName),
    "Last Name": data.map((item) => item.lastName),
    "Email Address": data.map((item) => item.email),
  };

  const handleSearch = (search) => {
    const data = users;
    dispatch(
      setUsersList(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            SEARCH_DATA.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  const resetSearch = () => {
    dispatch(setUsersList(users));
  };

  const handleSubmitDeleteUser = (id, setSubmitting) => {
    setSubmitting(true);
    request(deleteUser, id)
      .then((data) => {
        if (!data) return;
        if (archived) {
          request(getUserById, id).then(
            (data) => data && dispatch(updateUsersList(modifyUserData(data)))
          );
        } else dispatch(deleteUserData(id));
        setSelected({});
      })
      .finally(() => {
        setSubmitting(false);
        setModalOpen(false);
      });
  };

  const handleDeleteUser = (id) => {
    setComponentId(id);
    setModalOpen(true);
  };

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="sm"
        >
          <ConfirmAction
            handleClose={handleModalClose}
            confirmLabel="Confirm"
            warningMessage={
              <>
                <div>{`Are you sure you want to archive ${
                  (data.find(({ id }) => id === componentId) || {}).firstName
                }?`}</div>
              </>
            }
            id={componentId}
            handleSubmit={handleSubmitDeleteUser}
          />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Users</h3>
              <ToolTip
                icon={<InputIcon fontSize="large" />}
                title="Export Users Data to CSV"
                disabled
              />
            </div>
            <div className="mb-5 d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <Link
                  to={{
                    pathname: `/add-user`,
                    newUser: true,
                  }}
                  data-testid="new-user"
                >
                  <button className="px-7 mr-5 btn btn-primary">
                    New User
                  </button>
                </Link>
                {archived && (
                  <div className="ml-5">
                    <FormControlLabel
                      control={
                        unArchiving ? (
                          <CircularProgress
                            size="1.2rem"
                            style={{ margin: 11 }}
                          />
                        ) : (
                          <button
                            className="px-7 mr-5 btn btn-primary"
                            onClick={handleUnarchive}
                            disabled={unArchiveDisabled}
                          >
                            Unarchive
                          </button>
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div style={{ display: "flex" }}>
                <FormControlLabel
                  control={
                    archivedLoading ? (
                      <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                    ) : (
                      <Checkbox
                        checked={archived}
                        onChange={() => showArchived()}
                      />
                    )
                  }
                  label="Display Archived Records"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={KEY_MAP}
                  placeholder="Search by tags..."
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderUsersRow}
              editable
              selectable
              isUserList
              handleDelete={handleDeleteUser}
              selected={selected}
              setSelected={setSelected}
              link="users"
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
