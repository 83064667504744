import { paymentCyclesTypes } from "./actions";

const initialPaymentCyclesState = {
  paymentCyclesList: [],
  showArchived: false,
};

export function paymentCyclesReducer(state = initialPaymentCyclesState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case paymentCyclesTypes.SET_DATA:
      return {
        ...state,
        paymentCyclesList: [...payload],
      };
    case paymentCyclesTypes.UPDATE_DATA:
      return {
        ...state,
        paymentCyclesList: [...state.paymentCyclesList, ...payload],
      };
    case paymentCyclesTypes.ARCHIVE_DATA:
      return {
        ...state,
        paymentCyclesList: [
          ...state.paymentCyclesList.filter(({ id }) => id !== payload),
        ],
      };
    case paymentCyclesTypes.UPDATE_ACCOUNT:
      const index = state.paymentCyclesList.findIndex(
        (item) => item.id === payload.id
      );
      const myState = [...state.paymentCyclesList];
      myState.splice(index, 1, payload);

      return {
        ...state,
        paymentCyclesList: [...myState],
      };
    case paymentCyclesTypes.TOGGLE_SHOW_ARCHIVED:
      return {
        ...state,
        showArchived: !state.showArchived,
      };
    default:
      return state;
  }
}
