import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { renderHeader } from "../../helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTenantTags, deleteTenantTag } from "../../../app/modules/Tenants/_api";
import { Modal } from "../../components/Modal";
import { ConfirmDelete } from "../../components/ConfirmDelete";

const HEADINGS = [
  ["id", "ID"],
  ["name", "Tag Name"],
  ["type", "Tag Type"],
];

export function TagsManagerList({ onView, tenantId, setAddTagsManager }) {
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");

  const handleModalOpen = (id) => {
    setModalOpen(true);
    setComponentId(id);
  };
  const handleModalClose = () => setModalOpen(false);

  const loadTags = () => {
    request(getTenantTags, tenantId).then((data) => {
      if (data) {
        setData(data);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    loadTags();
    // eslint-disable-next-line
  }, []);

  const renderTagsRow = (headings, item) => {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5">
        {key === "name" || key === "type" ? (
          <p
            className="text-primary mb-0"
            onClick={() => onView(item)}
            style={{ cursor: "pointer" }}
          >
            {item[key]}
          </p>
        ) : (
          item[key]
        )}
      </td>
    ));
  };

  const handleDelete = (tag_id, setSubmitting) => {
    request(deleteTenantTag, tenantId, tag_id)
      .then(() => loadTags())
      .finally(() => {
        setSubmitting(false);
        handleModalClose();
      });
  };

  return (
    <>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={handleModalClose}>
          <ConfirmDelete
            handleClose={handleModalClose}
            id={componentId}
            handleSubmit={handleDelete}
            warningMessage={
              <>
                <div className="mb-4">WARNING!</div>
                <div>
                  Deleting this tag will remove the tag entirely from the system. It is not possible
                  to reverse this action. Are you sure you want to continue?
                </div>
              </>
            }
          />
        </Modal>
      )}
      <div className="px-9 py-7 d-flex">
        <button
          className="btn btn-primary mr-6"
          onClick={() => setAddTagsManager(true)}
          data-testid="add-tag"
        >
          Add
        </button>
      </div>

      <div className="px-9">
        <DataGrid
          controls
          headings={HEADINGS}
          data={data}
          renderHeader={renderHeader}
          renderRow={renderTagsRow}
          isUserList
          handleDelete={handleModalOpen}
          loading={loading}
        />
      </div>
    </>
  );
}
