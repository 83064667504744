export const lateHistoryTypes = {
  SET_DATA: "LATE_HISTORY/SET_DATA",
  UPDATE_DATA: "LATE_HISTORY/UPDATE_DATA",
  DELETE_DATA: "LATE_HISTORY/DELETE_DATA",
};

export function setLateHistoryData(data) {
  return {
    type: lateHistoryTypes.SET_DATA,
    payload: data,
  };
}

export function updateLateHistoryData(data) {
  return {
    type: lateHistoryTypes.UPDATE_DATA,
    payload: data,
  };
}

export function removeRecord(id) {
  return {
    type: lateHistoryTypes.DELETE_DATA,
    payload: id,
  };
}
