import React from "react";
import { useDispatch } from "react-redux";
import { setLogsList } from "./_redux/actions";
import { getAuditLogs } from "./_api";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { MappedInput } from "../../../ui/components/Input";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { millisToSeconds, modifyAuditsData } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { auditTrailSchema } from "../../../helpers/schemas";
import endOfDay from "date-fns/endOfDay";
import { format } from "date-fns";

export function AuditTrailForm({ setLogs, setLoading, setKey }) {
  const dispatch = useDispatch();

  const { request } = useFetch();

  const initialValues = {
    from: "",
    to: "",
    user: "",
  };

  const handleSubmit = ({ from, to, user }, { setSubmitting }) => {
    setKey(Date.now());
    setLoading(true);
    const from_utc = from.toISOString();
    const to_utc = new Date(
      new Date(to).setDate(to.getDate() + 1)
    ).toISOString();
    request(
      getAuditLogs,
      Date.parse(from_utc) / 1000,
      Date.parse(to_utc) / 1000,
      user
    )
      .then((response) => response && modifyAuditsData(response))
      .then((data) => {
        dispatch(setLogsList(data));
        setLogs(data);
      })
      .finally(() => {
        setLoading(false);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={auditTrailSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="py-5">
              <div className="row mb-6">
                <div className="col-2 my-auto">
                  <div className="text-right text-muted mb-0">Date Range: </div>
                </div>
                <div className="col-1 my-auto">
                  <div className="text-right text-muted mb-0">From</div>
                </div>
                <div className="col-2">
                  <MappedDatePicker
                    name="from"
                    className="border border-secnodary"
                  />
                </div>
                <div className="col-1 my-auto">
                  <div className="text-right text-muted mb-0">to</div>
                </div>
                <div className="col-2">
                  <MappedDatePicker
                    name="to"
                    className="border border-secnodary w-100"
                  />
                </div>
                <div className="col-4 my-auto">
                  <div className="text-right text-muted mb-0">
                    (7 Days maximum)
                  </div>
                </div>
              </div>
              <div className="row mb-6">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Filter query: </p>
                </div>
                <div className="col-10 mr-auto">
                  <MappedInput name="user" wrapperClassName="w-100" />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  onClick={props.handleSubmit}
                  disabled={props.isSubmitting}
                >
                  Search
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
