import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { ContactsDetailsSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";
import { useFetch } from "../../../hooks/fetch.hook";
import { capitalizeObject } from "../../helpers/helpers";

function ContactDetailsForm({ history, data, tenantId, onClose, setData, onSubmit }) {
  const { request } = useFetch();
  const {
    firstName,
    lastName,
    aka,
    phone,
    mobile,
    email,
    name,
    position_title,
  } = data;

  const initialValues = {
    firstName: firstName || "",
    lastName: lastName || "",
    aka: aka || "",
    phone: phone || "",
    mobile: mobile || "",
    email: email || "",
    name: name || "",
    position_title: position_title || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (values) => {
    const titleCaseValues = capitalizeObject(values);
    request(onSubmit, tenantId, { ...data, ...titleCaseValues }, data.id).then((data) => {
      if (data) {
        history.push(`/contacts/contacts-list/${data.id}`);
        if (onClose) return onClose(false);
      }
    });
  };

  const handleClose = () => (onClose ? onClose(false) : history.go(-1));
  if (!Object.keys(data).length) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={ContactsDetailsSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">First Name</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="firstName"
                    wrapperClassName="w-100"
                    data-testid="firstName"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Last Name</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="lastName"
                    wrapperClassName="w-100"
                    data-testid="lastName"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">AKA</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="aka"
                    wrapperClassName="w-100"
                    data-testid="aka"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Position Title</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="position_title"
                    wrapperClassName="w-100"
                    data-testid="position_title"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Phone</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="phone"
                    icon="phone"
                    wrapperClassName="w-100"
                    data-testid="phone"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Mobile</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="mobile"
                    icon="phone"
                    wrapperClassName="w-100"
                    data-testid="mobile"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Email Address</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="email"
                    icon="alternate_email"
                    wrapperClassName="w-100"
                    data-testid="email"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted">Organisation Name</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="name"
                    onBlur={handleOnBlur}
                    wrapperClassName="w-100"
                    disabled
                    data-testid="name"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(ContactDetailsForm);
