import { useState, useCallback, useRef } from "react";
import { error } from "../helpers/toasts";

export const useFetch = () => {
  const [isLoading, setLoading] = useState(false);
  let errorTime = useRef(0);

  const request = useCallback(async (apiCall, ...params) => {
    let tryTimes = 0;
    while (tryTimes < 2) {
      try {
        // console.log("start try the apicall");
        setLoading(true);
        const response = await apiCall(...params);
        // console.log(response);

        if (response.status === 204) return "success";
        if (response.status === 403) {
          error("Your session is expired");
          // error("Something goes wrong, please login again");
        }

        const jsonData = await response.json();
        if (!response.ok) {
          throw new Error((jsonData || {}).message);
        }

        // console.log("jsonData: ", jsonData);
        return jsonData.data;
      } catch (err) {
        if (tryTimes === 1 && Date.now() - errorTime.current > 3000) {
          errorTime.current = Date.now();
          error(err.message || "Something goes wrong...");
        }
        // console.log(err);
      } finally {
        tryTimes++;
        setLoading(false);
      }
    }
    // eslint-disable-next-line
  }, []);

  return { isLoading, request };
};
