import React, { useState } from "react";
import { Button } from "@material-ui/core";
import { Edit as EditIcon, Textsms as TextsmsIcon } from "@material-ui/icons/";
import { ShareModal } from "../../structures/ShareModal";
import { useFetch } from "../../../hooks/fetch.hook";
import { SMSForm } from "../../structures/SMSForm";
import { formatDate, formatAmount } from "../../helpers/helpers";
import { postSendBulkSms } from "../../../app/modules/Integrations/_api";
import ButtonMailto from "../../components/ButtonMailto";

export function OrganisationDetails({ data, tenantId, setIsEditable }) {
  const {
    status,
    phone,
    mobile,
    email,
    name,
    shortCode,
    legalName,
    physicalAddress,
    physicalAddress2,
    physicalSuburb,
    physicalState,
    physicalPostCode,
    postalAddress,
    postalAddress2,
    postalSuburb,
    postalState,
    postalPostCode,
    start_date,
    end_date,
    last_review_date,
    access_card_expiry_date,
    indicative_limit,
    ABN,
    ACN,
    customer_reference_number,
  } = data || {};
  const { request } = useFetch();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);

  const handleOpen = () => setModalOpen(true);

  const handleSubmit = (values, { setSubmitting }) => {
    request(postSendBulkSms, tenantId, {
      organisation_ids: [data.id],
      ...values,
    }).then((response) => {
      if (response) {
        console.log(response);
      }
      handleClose();
    });
    setSubmitting(false);
  };

  return (
    <div className="bg-white rounded">
      <ShareModal
        isOpen={modalOpen}
        submitable={true}
        maxWidth="xs"
        modalContent={
          <SMSForm
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            title={`Send SMS to ${mobile}`}
          />
        }
      />
      {status !== "archived" && (
        <Button
          variant="contained"
          color="secondary"
          data-testid="edit"
          onClick={() => setIsEditable(true)}
          style={{ position: "absolute", right: 50, top: 90 }}
        >
          <EditIcon />
        </Button>
      )}
      <div className="pt-19 pb-25">
        <div className="row" data-testid="short-code">
          <div className="col-2">
            <p className="text-right text-muted">Short Code / ID</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{shortCode}</span>
          </div>
        </div>

        <div className="row" data-testid="name">
          <div className="col-2">
            <p className="text-right text-muted">Organisation Name</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{name}</span>
          </div>
        </div>

        <div className="row" data-testid="legal name">
          <div className="col-2">
            <p className="text-right text-muted">Legal Name</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{legalName}</span>
          </div>
        </div>

        <div className="row" data-testid="phone">
          <div className="col-2">
            <p className="text-right text-muted">Phone</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{phone}</span>
          </div>
        </div>

        <div className="row" data-testid="mobile">
          <div className="col-2">
            <p className="text-right text-muted">Mobile</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark mr-4">{mobile}</span>
            {mobile && (
              <span>
                <TextsmsIcon onClick={handleOpen} data-testid="sms" />
              </span>
            )}
          </div>
        </div>

        <div className="row" data-testid="email">
          <div className="col-2">
            <p className="text-right text-muted">Email Address</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-primary">
              <ButtonMailto
                email={email}
                subject={`Hello ${name && name}`}
                body={`Dear ${legalName && legalName},`}
              >
                {email}
              </ButtonMailto>
            </span>
          </div>
        </div>

        <div className="row" data-testid="address">
          <div className="col-2">
            <p className="text-right text-muted">Trading Address</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {[
                physicalAddress,
                physicalAddress2,
                physicalSuburb,
                physicalState,
                physicalPostCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </span>
          </div>
        </div>

        <div className="row" data-testid="postal-address">
          <div className="col-2">
            <p className="text-right text-muted">Postal Address</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {[
                postalAddress,
                postalAddress2,
                postalSuburb,
                postalState,
                postalPostCode,
              ]
                .filter(Boolean)
                .join(", ")}
            </span>
          </div>
        </div>

        <div className="row" data-testid="abn">
          <div className="col-2">
            <p className="text-right text-muted">ABN</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{ABN}</span>
          </div>
        </div>

        <div className="row" data-testid="acn">
          <div className="col-2">
            <p className="text-right text-muted">ACN</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{ACN}</span>
          </div>
        </div>

        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">Start Date</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {start_date && formatDate(new Date(start_date))}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">End Date</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {end_date && formatDate(new Date(end_date))}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">Last Review Date</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {last_review_date && formatDate(new Date(last_review_date))}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">Access card expiry date</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {access_card_expiry_date &&
                formatDate(new Date(access_card_expiry_date))}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">Indicative Limit</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {indicative_limit ? formatAmount(indicative_limit) : ""}
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-2">
            <p className="text-right text-muted">BPay CRN</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">
              {customer_reference_number ? customer_reference_number : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
