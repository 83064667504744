/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Tabs from "../../../../../ui/components/Tabs";
import { ReconciliationTransferForm } from "./ReconciliationDialogForms/ReconciliationTransferForm";
import { ReconciliationMatchForm } from "./ReconciliationDialogForms/ReconciliationMatchForm";
import { ReconciliationCreateForm } from "./ReconciliationDialogForms/ReconciliationCreateForm";
import { ReconciliationTeachForm } from "./ReconciliationDialogForms/ReconciliationTeachForm";

export function ReconciliationActions({
  data,
  // selectedAction,
  handleSubmit,
  organisationsList,
  setSelectedAction,
  TENANT_ID,
  setValidationAction,
  accountsList,
  taxesList,
  setSentTransaction,
  setSentTransactionType,
}) {
  const tabs = [
    {
      label: "Transfer",
      tabPanel: (
        <ReconciliationTransferForm
          data={data}
          organisationsList={organisationsList}
          TENANT_ID={TENANT_ID}
          handleSubmit={handleSubmit}
          setValidationAction={setValidationAction}
        />
      ),
    },
    {
      label: "Create",
      tabPanel: (
        <ReconciliationCreateForm
          data={data}
          organisationsList={organisationsList}
          TENANT_ID={TENANT_ID}
          handleSubmit={handleSubmit}
          setValidationAction={setValidationAction}
          // accountsList={accountsList}
          // taxesList={taxesList}
        />
      ),
    },
    {
      label: "Match",
      //   icon: <LocalOfferIcon />,
      tabPanel: (
        <ReconciliationMatchForm
          data={data}
          TENANT_ID={TENANT_ID}
          setSentTransaction={setSentTransaction}
          setSentTransactionType={setSentTransactionType}
        />
      ),
    },
    {
      label: "Teach",
      //   icon: <LocalOfferIcon />,
      tabPanel: <ReconciliationTeachForm data={data} />,
    },
  ];

  return (
    <>
      <Tabs
        defaultActive="0"
        tabs={tabs}
        getActive={setSelectedAction}
        fullWidth
        naked
      />
    </>
  );
}
