import config from "react-global-configuration";

export const getBillings = (tenant_id, query) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/billings?${
      query ? `&${query}` : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createBillings = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/billings`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteBilling = (tenant_id, billing_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/billings/${billing_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const updateBilling = (tenant_id, billing_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/billings/${billing_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const processBiling = (tenant_id, billing_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/billings/${billing_id}/process`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );

export const sendBilling = (tenant_id, billing_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/billings/${billing_id}/send`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );

export const getOrganisationBillings = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/billings`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const downloadOrganisationBillingsReport = (report_data, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/organisations/${
      report_data.organisation_id
    }/billings/${report_data.billing_id}/download`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
