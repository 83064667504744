import { discrepancyTypes } from "./actions";

const initialState = {
  discrepancyList: [],
};

export function discrepancyReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case discrepancyTypes.SET_DATA:
      return {
        ...state,
        discrepancyList: payload,
      };

    case discrepancyTypes.UPDATE_DATA:
      const index = state.discrepancyList.findIndex((item) => item.id === payload.id);
      const myState = [...state.discrepancyList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        discrepancyList: [...myState],
      };

    default:
      return state;
  }
}
