/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../../ui/components/DataGrid";
import {
  renderHeader,
  formatDateToUTC,
  renderTransactionRow,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { setTransactionsData } from "./_redux/actions";
import { getLedgerAccountTransactions } from "../Transactions/_api";
import { getAccounts, getOrganisationsListSimple } from "../Organisations/_api";
import { FilterBar } from "../../../ui/structures/FilterBar";
import { SearchBar } from "../../../ui/structures/SearchBar";
import {
  ledgerTransactionsExternalSchema,
  ledgerTransactionsInternalSchema,
} from "../../../helpers/schemas";
import { addDays, startOfDay } from "date-fns";
import { Skeleton } from "@material-ui/lab";

const HEADINGS = [
  ["created_at", "Date"],
  ["transaction_id", "Transaction ID"],
  ["transaction_note", "Description"],
  ["transaction_type", "Type"],
  ["transaction_value", "Amount"],
];

export function List({ match, location }) {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [organisationsList, setOrganisationsList] = useState([]);
  const [organisationsLoading, setOrganisationsLoading] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");

  const [resetKey, setResetKey] = useState(Date.now());
  const [isLocation, setIsLocation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);

  const data =
    useSelector(({ transactions }) => transactions.transactionsList) || [];

  function fetchLedgerTransactions(values = {}, actions) {
    const count_only = values.count_only ? values.count_only : false;
    setLoading(true);
    request(
      getLedgerAccountTransactions,
      TENANT_ID,
      values.organisation,
      values.account,
      formatDateToUTC(values.from),
      formatDateToUTC(values.to, true),
      search,
      page,
      pageSize,
      count_only
    )
      .then((data) => {
        if (!data) return;
        if (count_only) {
          setTotalRecords(transactions);
        } else {
          const modifiedData = data.map(({ transaction_value, ...item }) => ({
            ...item,
            transaction_value: String(transaction_value),
          }));
          dispatch(setTransactionsData(modifiedData));
          setTransactions(modifiedData);
        }
      })
      .finally(() => {
        setLoading(false);
        actions && actions.setSubmitting(false);
      });
  }

  useEffect(() => {
    if (selectedAccount?.organisation_id && selectedAccount?.id) {
      fetchLedgerTransactions({
        organisation: selectedAccount.organisation_id,
        account: selectedAccount.id,
        from: startOfDay(new Date(Date.now())),
        to: addDays(new Date(Date.now()), 6),
      });
      fetchLedgerTransactions({
        organisation: selectedAccount.organisation_id,
        account: selectedAccount.id,
        from: startOfDay(new Date(Date.now())),
        to: addDays(new Date(Date.now()), 6),
        count_only: true,
      });
    }
  }, [
    search,
    setSearch,
    page,
    setPage,
    pageSize,
    setPageSize,
    selectedAccount,
    setSelectedAccount,
  ]);

  useEffect(() => {
    dispatch(setTransactionsData([]));
    setTransactions([]);
    setOrganisationsLoading(true);
    request(getOrganisationsListSimple, TENANT_ID)
      .then((data) => data && setOrganisationsList(data))
      .finally(() => setOrganisationsLoading(false));
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    if (location.organisation_id && location.account_id) {
      setIsLocation(true);
    }
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    if (location.organisation_id && location.account_id) {
      fetchLedgerTransactions({
        organisation: location.organisation_id,
        account: location.account_id,
        from: startOfDay(new Date(Date.now())),
        to: addDays(new Date(Date.now()), 6),
      });
      fetchLedgerTransactions({
        organisation: location.organisation_id,
        account: location.account_id,
        from: startOfDay(new Date(Date.now())),
        to: addDays(new Date(Date.now()), 6),
        count_only: true,
      });
    }
  }, []);

  const KEY_MAP = {
    "Transaction ID": data.map((item) => item.transaction_id),
    Amount: data.map((item) => item.transaction_value),
    Type: data.map((item) => item.transaction_type),
  };

  const handleSearch = (search) => {
    dispatch(
      setTransactionsData(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            HEADINGS.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  const resetSearch = () => {
    dispatch(setTransactionsData(transactions));
  };

  const singleValueFields = ["organisation", "from", "to"];

  const filterValues = {
    organisation: organisationsList.map(({ value, label }) => ({
      value,
      label,
    })),
  };

  const initialValues = {
    organisation: isLocation ? location.organisation_id : "",
    account: isLocation ? location.account_id : "",
    from: isLocation ? startOfDay(new Date(Date.now())) : "",
    to: isLocation ? addDays(new Date(Date.now()), 6) : "",
  };

  const onReset = (handleReset) => () => {
    setIsLocation(false);
    handleReset({
      values: {
        organisation: "",
        account: "",
        from: "",
        to: "",
      },
    });
    setResetKey(new Date(Date.now()));
  };

  if (organisationsLoading) {
    return <Skeleton variant="rect" width={"100%"} height={612} />;
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div>
          <FilterBar
            filterValues={filterValues}
            singleValueFields={singleValueFields}
            onReset={onReset}
            title="Search Transactions"
            key={resetKey}
            onSubmit={fetchLedgerTransactions}
            schema={
              selectedAccount?.account_type === "external"
                ? ledgerTransactionsExternalSchema
                : ledgerTransactionsInternalSchema
            }
            tenantID={TENANT_ID}
            defaultExpanded
            initialValues={initialValues}
            parentFieldName="organisation"
            hasDependentField
            DependentFieldAPI={getAccounts}
            dependentFieldLabel="account_name"
            dependentFieldDefaultValue={location.account_id}
            getSelectedItem={setSelectedAccount}
          />
        </div>
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">
              Buyer / Biller Ledger Transactions
            </h3>
          </div>
          <SearchBar
            className="mb-5"
            onSearch={handleSearch}
            clearSearch={resetSearch}
            keyMap={KEY_MAP}
            placeholder="Filter Transactions..."
          />

          <DataGrid
            data={data}
            headings={HEADINGS}
            renderHeader={renderHeader}
            renderRow={renderTransactionRow}
            loading={loading}
            perPageDefault={pageSize}
            setPageSize={setPageSize}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            isServerPagination
            totalRecords={totalRecords}
          />

          <div className="row col-12">
            {selectedAccount ? (
              <>
                <div className="col-3 pt-5">
                  <button className="px-10 btn btn-success">
                    Account Balance: ${" "}
                    {(selectedAccount || {}).balance || "0.0"}
                  </button>
                </div>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
