import React from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { MappedInput } from "../../components/Input";
import { MappedDatePicker } from "../../components/DatePicker";
import { Skeleton } from "@material-ui/lab/";
import { useFetch } from "../../../hooks/fetch.hook";
import { EditPaymentCycleSchema } from "../../../helpers/schemas";
import { formatDateToUTC } from "../../helpers/helpers";

function PaymentCycleElementForm({ history, data, tenantId, setData, onSubmit }) {
  const { request } = useFetch();
  const { payment_cycles_id, start_date, due_date, finish_date, note } = data;

  if (data.payment_cycle_element_id && !start_date) {
    return <Skeleton variant="rect" width={"100%"} height={352} />;
  }

  const initialValues = {
    start_date: start_date || "",
    due_date: due_date || "",
    finish_date: finish_date || "",
    note: note || "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={EditPaymentCycleSchema}
      onSubmit={(values) => {
        request(onSubmit, tenantId, payment_cycles_id, {
          ...values,
          start_date: formatDateToUTC(values.start_date),
          due_date: formatDateToUTC(values.due_date, true),
          finish_date: formatDateToUTC(values.finish_date, true),
          payment_cycle_element_id: data.payment_cycle_element_id,
        }).then((data) => {
          if (data) {
            return history.push(`/payment-cycles/${payment_cycles_id}`);
          }
        });
      }}
    >
      {({ values, handleSubmit }) => (
        <>
          <div className="px-7 py-11">
            <div className="row mb-5">
              <div className="col-2">
                <p className="text-left text-muted mb-0">Start Date</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedDatePicker
                  name="start_date"
                  className="border border-secondary"
                  style={{ width: "100%" }}
                  testId="start_date"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Finish Date</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedDatePicker
                  name="finish_date"
                  className="border border-secondary"
                  style={{ width: "100%" }}
                  testId="finish_date"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Due Date</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedDatePicker
                  name="due_date"
                  className="border border-secondary"
                  style={{ width: "100%" }}
                  testId="due_date"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Note</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput name="note" wrapperClassName="w-100" data-testid="note" />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center p-7">
            <div>
              <button
                onClick={handleSubmit}
                type="submit"
                data-testid="submit"
                className="btn btn-primary px-15 mr-5"
              >
                Save
              </button>
              <button onClick={() => history.go(-1)} className="btn btn-outline-primary px-15">
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(PaymentCycleElementForm);
