export const getAccounts = (state) => state.accounts.accountsList;

export const getAccountItems = ({ accounts }) => ({
  files: accounts.files,
});

export const getAccount = (accountId) => ({ accounts }) =>
  accounts.accountsList.find(({ id }) => id === accountId);

export const getShowArchived = (state) => state.accounts.showArchived;
