/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { getStaticPages } from "../../../../../app/modules/StaticPages/_api";
import { useFetch } from "../../../../../hooks/fetch.hook";
import {
  BRISMARK_BUYER_MENU,
  DEFAULT_BUYER_MENU,
  ITEM_TYPES,
} from "./constants";

export function AsideMenuListBuyer({ layoutProps }) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [data, setData] = useState([]);
  const { request } = useFetch();

  function fetchStaticPages() {
    request(getStaticPages, TENANT_ID).then((response) => {
      if (response) {
        const newResponse = response.map((page) => ({
          ...page,
          iconUrl: "/assets/icons/user.svg",
          type: ITEM_TYPES.link,
          to: `/static/${page.id}`,
        }));
        setData((prev) => [
          ...prev,
          response.length && {
            type: ITEM_TYPES.heading,
            title: "Services Pages",
          },
          ...newResponse,
        ]);
      }
    });
  }

  useEffect(() => {
    if (TENANT_ID === 1) {
      setData(BRISMARK_BUYER_MENU);
    } else {
      setData(DEFAULT_BUYER_MENU);
      fetchStaticPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID]);

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {data.map((item, index) => (
          <MenuItem key={index} item={item} />
        ))}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const history = useHistory();
  const { type } = item;
  switch (type) {
    case ITEM_TYPES.heading:
      return (
        <li className="menu-item" style={{ pointerEvents: "none" }}>
          <div className="menu-link">
            <span
              className="menu-text font-weight-bold"
              style={{ color: "#464E5F", cursor: "default" }}
            >
              {item.title}
            </span>
          </div>
        </li>
      );
    case ITEM_TYPES.link:
      return (
        <li className={`menu-item`} aria-haspopup="true">
          <NavLink
            className="menu-link"
            to={{
              pathname: item.to,
              editMode: true,
            }}
          >
            {Boolean(subMenu) && (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
            {Boolean(item.iconUrl) && (
              <span className="svg-icon menu-icon">
                <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
              </span>
            )}
            <span className="menu-text">{item.title}</span>
          </NavLink>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li className={`menu-item menu-item-submenu`} aria-haspopup="true">
          <a
            // className="menu-link"
            className="menu-link menu-toggle"
            to={item.to}
            data-menu-toggle="hover"
            onClick={() => history.push(item.to)}
          >
            <span className="svg-icon menu-icon">
              <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
            </span>
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </a>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{item.title}</span>
                </span>
              </li>
              {item.items.map((subItem, index) => (
                <MenuItem key={index} item={subItem} subMenu />
              ))}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
