import config from "react-global-configuration";

export const getUser = () =>
  fetch(`${config.get("ApiUrl").Rest}/users/me`, {
    mode: "cors",
    credentials: "include",
  });

export const DBWakeUp = () =>
  fetch(`${config.get("ApiUrl").Rest}/wake-up`, {
    mode: "cors",
    credentials: "include",
  });

export const getBackendVersion = () =>
  fetch(`${config.get("ApiUrl").Rest}/version`, {
    mode: "cors",
    credentials: "include",
  });
