import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getTransactionsHistory, revertBankTransaction } from "../_api";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import {
  formatAmount,
  formatDate,
  formatDateForBackend,
} from "../../../../ui/helpers/helpers";
import { Button, IconButton } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab/";

export const TransactionHistory = ({ TENANT_ID }) => {
  const { request } = useFetch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [loading, setLoading] = useState(true);
  const [transactionsList, setTransactionsList] = useState([]);

  const fetchTransactionHistory = () => {
    setLoading(true);
    const sevenDaysAgo = new Date() - 7 * 24 * 60 * 60 * 1000;
    request(
      getTransactionsHistory,
      TENANT_ID,
      formatDateForBackend(sevenDaysAgo),
      page,
      pageSize
    )
      .then((transactions) => transactions && setTransactionsList(transactions))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchTransactionHistory();
    // eslint-disable-next-line
  }, [page, pageSize]);

  const handleRevert = (transaction_id) => {
    request(revertBankTransaction, TENANT_ID, transaction_id).then(
      (response) => {
        if (response) {
          fetchTransactionHistory();
        }
      }
    );
  };

  return (
    <>
      <h3 className="text-center mb-10">Transaction History</h3>
      {loading ? (
        <Skeleton variant="rect" height={200} className="ml-5" />
      ) : (
        <>
          {transactionsList.length ? (
            <>
              {transactionsList.map((invoice) => (
                <div
                  key={invoice.id}
                  className="row align-items-center"
                  style={{
                    margin: "0",
                  }}
                >
                  <>
                    <div className="col-12 d-flex justify-content-between">
                      <div className="mr-2">
                        {formatDate(new Date(invoice.transaction_type))}
                      </div>
                      <div className="mr-2">{invoice.bank_name}</div>
                      <div className="mr-2">
                        {formatAmount(
                          Number.parseFloat(invoice.transaction_value)
                        )}
                      </div>
                      <div>[{invoice.transaction_description}]</div>
                      <div className="ml-auto">
                        <Button onClick={() => handleRevert(invoice.id)}>
                          Revert Transaction
                        </Button>
                      </div>
                    </div>
                  </>
                </div>
              ))}
            </>
          ) : (
            <div className="col-12 d-flex justify-content-center align-content-center">
              <p>No transactions were found</p>
            </div>
          )}
        </>
      )}

      {page === 0 && transactionsList < pageSize ? (
        ""
      ) : (
        <div className="flex row justify-content-between align-items-center mx-0 mt-1 px-2">
          <p className="text-muted">{`Showing ${pageSize * page}-${pageSize *
            page +
            pageSize}`}</p>
          <div>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page - 1)}
              disabled={!page}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page + 1)}
              disabled={transactionsList.length < pageSize}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
