import React, { useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../../ui/helpers/helpers";
import { renderDistributionsRow, ScrollableContainer, setInititalReviewData, renderExtendedRow, renderTotal } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { updateDistribution } from "./_api";
import { Loader } from "../../../ui/components/Loader";

const HEADINGS = [
  ["biller_name", "Biller"],
  ["amount", "Total"],
];

const getPositiveValues = (data) => {
  return data
    .filter(({ amount }) => amount > 0)
    .map((item) => ({ [item.id]: true }))
    .reduce((acc, value) => ({ ...acc, ...value }), {});
};

export function Review({
  reviewedItem = {},
  handleNextStep,
  handlePrevStep,
  tenantId,
  setReviewedItem,
}) {
  const { request } = useFetch();
  const data = setInititalReviewData(reviewedItem);

  const [selected, setSelected] = useState(getPositiveValues(data));
  const [loading, setLoading] = useState(false);

  const selectedItems = Object.keys(selected).filter((key) => selected[key]);

  const submitEnabled = useMemo(() => Object.values(selected).filter(Boolean).length !== 0, [
    selected,
  ]);

  const handleSubmit = () => {
    setLoading(true);
    request(updateDistribution, tenantId, reviewedItem.id, {
      biller_ids: selectedItems.map((id) => id),
    })
      .then((data) => {
        if (!data) return;
        setReviewedItem(data);
        handleNextStep();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <Loader
          isOpen={loading}
          onClose={() => setLoading(false)}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ScrollableContainer>
              <h3 className="text-dark mb-5">Distributions</h3>
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderDistributionsRow}
                selected={selected}
                setSelected={setSelected}
                selectable
                extendable
                renderExtendedRow={renderExtendedRow}
                hideSelect={true}
                paginationEnabled={false}
              />
            </ScrollableContainer>
            {renderTotal(data)}
            <div className="d-flex justify-content-end align-items-center mt-10">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handlePrevStep}
              >
                Prev
              </Button>
              <Button
                onClick={handleSubmit}
                disabled={!submitEnabled}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
