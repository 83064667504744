import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../../ui/helpers/helpers";
import { SearchBar } from "../../../ui/structures/SearchBar";
import { useFetch } from "../../../hooks/fetch.hook";
import { getDistributions } from "./_api";
import { renderDistributionsRow, modifyDistributions } from "./helpers";

const HEADINGS = [
  ["period_start_date", "From"],
  ["period_end_date", "To"],
  ["amount", "Amount"],
  ["status", "Status"],
  ["aba_file", "ABA File"],
  ["distribution_report", "Distribution Report"],
];

export function HistoricalDistributions() {
  const { user } = useSelector((state) => state.auth);
  const { request } = useFetch();
  const history = useHistory();

  const TENANT_ID = user.selectedTenant;

  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);
  const [data, setData] = useState([]);

  function fetchDistributions() {
    setLoading(true);
    request(getDistributions, TENANT_ID, "status=sent")
      .then((data) => {
        if (!data) return;
        const modifiedData = modifyDistributions(data);
        setData(modifiedData);
        setInitialData(modifiedData);
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchDistributions();
    // eslint-disable-next-line
  }, []);

  const KEY_MAP = {
    Status: data.map((item) => item.status),
  };

  const handleSearch = (search, isRemoveKey) => {
    setData(
      (isRemoveKey ? initialData : data).filter((item) => {
        const _search = Object.entries(search).map((item) => [
          HEADINGS.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    );
  };

  const resetSearch = () => {
    fetchDistributions();
  };

  const handleDrafts = () => {
    history.push("/distributions");
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <Button
              onClick={handleDrafts}
              variant="contained"
              color="secondary"
              size="small"
              className="px-5 mb-5"
            >
              View Drafts
            </Button>
            <h3 className="text-dark mb-5">Historical Distributions</h3>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={KEY_MAP}
                  placeholder="Filter..."
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderDistributionsRow}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
