import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { MappedInput } from "../../../components/Input";
import { MappedTextArea } from "../../../components/TextArea";
import { MappedDatePicker } from "../../../components/DatePicker";
import { fieldNameStyle } from "../constants";
import { FormActions } from "../../FormActions";
import { CancelShareSchema } from "../../../../helpers/schemas";

export function CancelShareForm({
  tradingName,
  legalName,
  handleSubmit,
  handleClose,
  data = {},
  id,
  selected,
}) {
  const [shareData, setShareData] = useState(data || {});

  useEffect(() => {
    setShareData(data);
    //TODO request share data by id and set according initial values
  }, [data]);

  const initialValues = {
    date_cancelled: shareData?.date_cancelled
      ? new Date(shareData?.date_cancelled)
      : new Date(Date.now()),
    cancelled_folio: shareData?.cancelled_folio || "",
    cancelled_comment: shareData?.cancelled_comment || "",
    remarks: shareData?.remarks || "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={CancelShareSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <>
          <h3 className="px-10 my-6">
            <strong>
              Cancel Share for {tradingName} ({legalName})
            </strong>
          </h3>

          <div className="px-10">
            <div className="py-5 w-50">
              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Cancelled Date</p>
                </div>
                <div className="col-8">
                  <MappedDatePicker
                    name="date_cancelled"
                    disabled={shareData?.date_cancelled}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Cancelled Folio</p>
                </div>
                <div className="col-8">
                  <MappedInput
                    wrapperClassName="w-100"
                    name="cancelled_folio"
                    disabled={shareData?.date_cancelled}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Cancelled Comment</p>
                </div>
                <div className="col-8">
                  <MappedTextArea
                    name="cancelled_comment"
                    rows={6}
                    data-testid="cancelled_comment"
                    disabled={shareData?.date_cancelled}
                  />
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-4">
                  <p style={fieldNameStyle}>Remarks</p>
                </div>
                <div className="col-8">
                  <MappedTextArea
                    name="remarks"
                    rows={6}
                    data-testid="comment"
                    disabled={shareData?.date_cancelled}
                  />
                </div>
              </div>
            </div>
          </div>
          {!shareData?.date_cancelled && (
            <FormActions
              handleSubmit={handleSubmit}
              isSubmitting={isSubmitting}
              handleClose={handleClose}
            />
          )}
        </>
      )}
    </Formik>
  );
}
