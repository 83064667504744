import React, { useEffect, useState } from "react";
import DataGrid from "../../components/DataGrid";
import {
  renderHeader,
  renderOrganisationUsersRow,
} from "../../helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { getOrganisationUserList } from "../../../app/modules/UserManagment/_api";
import { modifyUsersData } from "./helpers";

const HEADINGS = [
  ["name", "Name"],
  ["email", "Email"],
  ["phone", "Phone Number"],
  ["status", "Status"],
];

export function Users({ organisation_id, TENANT_ID }) {
  const { request } = useFetch();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  function fetchUsers() {
    setLoading(true);
    request(getOrganisationUserList, TENANT_ID, organisation_id).then(
      (data) => {
        if (data) {
          setData(modifyUsersData(data));
          setLoading(false);
        }
      }
    );
  }

  useEffect(() => {
    fetchUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        <DataGrid
          headings={HEADINGS}
          data={data}
          renderHeader={renderHeader}
          renderRow={renderOrganisationUsersRow}
          loading={loading}
        />
      </div>
    </>
  );
}
