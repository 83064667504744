/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { formatDate } from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { ImportantMessagesForm } from "../../../ui/structures/ImportantMessagesForm";
import { Container, Button, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core";
import {
  Delete as DeleteIcon,
  Edit as EditIcon,
  Add as AddIcon,
} from "@material-ui/icons";
import htmr from "htmr";
import SVG from "react-inlinesvg";
import { getImportantMessages, deleteImportantMessage } from "./_api";
import Can from "../../../ui/helpers/Can";

const ScrollableContainer = styled(Container)({
  flex: "1 1 auto",
  minHeight: "1px",
  padding: "0 1.25rem 0 2.25rem",
  height: 565,
  overflowY: "auto",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    //   outline: "1px solid slategrey",
  },
  scrollbarColor: "rgba(0,0,0,.1) transparent",
  scrollbarWidth: "thin",
  "&>.d-flex:last-child": {
    marginBottom: "0 !important",
  },
});

function getTypeIcon(messageType) {
  switch (messageType) {
    case "Security":
      return (
        <div className="symbol symbol-40 symbol-light-info mr-5">
          <span className="symbol-label svg-icon svg-icon-lg svg-icon-info">
            <SVG
              className="h-75"
              src={toAbsoluteUrl("/assets/icons/Brismark_Icons_security.svg")}
            ></SVG>
          </span>
        </div>
      );
    case "Warning":
      return (
        <div className="symbol symbol-40 symbol-light-warning mr-5">
          <span className="symbol-label svg-icon svg-icon-lg svg-icon-warning">
            <SVG
              className="h-75"
              src={toAbsoluteUrl("/assets/icons/Brismark_Icons_warning.svg")}
            ></SVG>
          </span>
        </div>
      );
    case "Event":
      return (
        <div className="symbol symbol-40 symbol-light-success mr-5">
          <span className="symbol-label svg-icon svg-icon-lg svg-icon-success">
            <SVG
              className="h-75"
              src={toAbsoluteUrl("/assets/icons/Brismark_Icons_event.svg")}
            ></SVG>
          </span>
        </div>
      );
    default:
      return (
        <div className="symbol symbol-40 symbol-light-primary mr-5">
          <span className="symbol-label svg-icon svg-icon-lg svg-icon-primary">
            <SVG
              className="h-75"
              src={toAbsoluteUrl("/assets/icons/Brismark_Icons_information.svg")}
            ></SVG>
          </span>
        </div>
      );
  }
}

export function ImportantMessages({ tenantId }) {
  const { request } = useFetch();
  const displayedMessages = 5;
  const [expanded, setExpanded] = useState(false);
  const [numberOfMessages, setNumberOfMessages] = useState(displayedMessages);
  const [selectedMessageData, setSelectedMessageData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(true);

  function fetchImportantMessages(refreshList = false) {
    setLoading(true);

    request(
      getImportantMessages,
      tenantId,
      refreshList ? numberOfMessages : displayedMessages,
      refreshList ? 0 : numberOfMessages / displayedMessages
    ).then((response) => {
      if (response) {
        const overwriteResponse = response.map((item) => ({
          date: formatDate(new Date(item.last_modified)),
          ...item,
        }));
        if (refreshList) {
          setData(overwriteResponse);
        } else setData((prev) => [...prev, ...overwriteResponse]);
        setIsLoadMore(numberOfMessages <= response.length);
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchImportantMessages(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantId]);

  useEffect(() => {
    if (open === false) {
      setIsLoadMore(true);
      fetchImportantMessages(true);
      setSelectedMessageData([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setOpen]);

  const handleClickOpenDialog = () => {
    setOpen(true);
  };

  function handleDeleteMessage(id) {
    request(deleteImportantMessage, tenantId, id).then(() => {
      setData(
        data.filter(function(message) {
          return message.id !== id;
        })
      );
    });
  }

  return (
    <>
      <ImportantMessagesForm
        open={open}
        setOpen={setOpen}
        tenantId={tenantId}
        selectedMessageData={selectedMessageData}
      />
      <div className="card card-custom pr-3">
        {/* Head */}
        <div className="card-header border-0" style={{ paddingRight: 0 }}>
          <h3 className="card-title font-weight-bolder text-dark">Important Messages</h3>
          <Can
            perform="important_message:add"
            yes={() => (
              <Button
                color="default"
                style={{ margin: "19px 0" }}
                startIcon={<AddIcon />}
                onClick={handleClickOpenDialog}
              >
                Add New
              </Button>
            )}
          />
        </div>
        {/* Body */}
        <ScrollableContainer>
          {data.length > 0 &&
            data.slice(0, numberOfMessages).map((item) => {
              let isExpanded = expanded === item.id;
              return (
                <div
                  key={item.id}
                  className="d-flex mb-10 align-items-start"
                  style={{ minHeight: 56 }}
                >
                  {getTypeIcon(item.type)}
                  <div
                    className="d-flex flex-column flex-grow-1 font-weight-bold"
                    style={{
                      transition: "height 0.5s cubic-bezier(0, 1, 0, 1)",
                    }}
                  >
                    <div className="row">
                      <div className="col-3">
                        <div
                          style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            overflow: "hidden",
                            maxHeight: isExpanded ? "none" : "38px",
                            lineHeight: "1.3em",
                          }}
                        >
                          {item.title}
                        </div>
                        <div className="d-flex align-items-center">
                          <div className="text-muted" style={{ fontSize: "12px" }}>
                            {item.date}
                          </div>
                          {isExpanded && (
                            <div className="ml-auto">
                              <IconButton
                                color="secondary"
                                aria-label="edit"
                                style={{ padding: "0 2px", marginRight: 3 }}
                                onClick={(e) => {
                                  setSelectedMessageData(item);
                                  handleClickOpenDialog();
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                style={{
                                  padding: "0 2px",
                                  color: "#e14352",
                                }}
                                onClick={() => handleDeleteMessage(item.id)}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="col-8"
                        style={{
                          overflow: "hidden",
                          maxHeight: isExpanded ? "none" : "56px",
                          fontWeight: "400",
                        }}
                      >
                        {htmr(`${item.message}`)}
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-outline-primary px-10 py-2 btn-sm"
                    style={{ minWidth: 101 }}
                    onClick={() => setExpanded(expanded === item.id ? false : item.id)}
                  >
                    {isExpanded ? "LESS" : "MORE"}
                  </button>
                </div>
              );
            })}
          {isLoadMore ? (
            <div className="text-center m-4">
              <button
                className="btn btn-outline-secondary px-10 py-2 btn-sm"
                style={{ minWidth: 225 }}
                onClick={() => {
                  setNumberOfMessages((prev) => prev + displayedMessages);
                  fetchImportantMessages();
                }}
              >
                {loading ? "loading..." : "Load more"}
              </button>
            </div>
          ) : data.length ? (
            <div className="text-center m-4">
              <button
                className="btn btn-outline-secondary px-10 py-2 btn-sm"
                style={{ minWidth: 225 }}
                disabled
              >
                No more messages
              </button>
            </div>
          ) : (
            <div className="text-center">There is no available Important Message to display</div>
          )}
        </ScrollableContainer>
      </div>
    </>
  );
}
