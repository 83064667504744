/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useCallback } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";

const ITEM_TYPES = {
  link: "link",
  heading: "heading",
  menu: "menu",
};
const MENU = [
  {
    type: ITEM_TYPES.link,
    iconUrl: "/assets/icons/dashboard.svg",
    title: "Dashboard",
    to: "/dashboard",
    visibleToRole: [
      "Global Admin",
      "Tenant Admin",
      "Marketing",
      "Accounts",
      "Business",
      "Credit Service",
    ],
  },
  {
    type: ITEM_TYPES.heading,
    title: "Home",
    visibleToRole: [
      "Global Admin",
      "Tenant Admin",
      "Marketing",
      "Accounts",
      "Business",
      "Credit Service",
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Contacts",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/contacts",
    visibleToRole: [
      "Global Admin",
      "Tenant Admin",
      "Marketing",
      "Accounts",
      "Business",
      "Credit Service",
    ],
    items: [
      {
        type: ITEM_TYPES.link,
        title: "Organisations List",
        to: "/contacts/organisations-list",
        visibleToRole: [
          "Global Admin",
          "Tenant Admin",
          "Marketing",
          "Accounts",
          "Business",
          "Credit Service",
        ],
      },
      {
        type: ITEM_TYPES.link,
        title: "Contacts List",
        to: "/contacts/contacts-list",
        visibleToRole: [
          "Global Admin",
          "Tenant Admin",
          "Marketing",
          "Accounts",
          "Business",
          "Credit Service",
        ],
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Administration",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/administration",
    visibleToRole: [
      "Global Admin",
      "Tenant Admin",
      "Accounts",
      "Business",
      "Credit Service",
    ],
    items: [
      {
        type: ITEM_TYPES.link,
        title: "User Management",
        iconUrl: "/assets/icons/user.svg",
        to: "/users-list",
        visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
      },
      {
        type: ITEM_TYPES.link,
        title: "Integrations",
        iconUrl: "/assets/icons/settings.svg",
        to: "/settings/integrations",
        visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
      },
      {
        type: ITEM_TYPES.link,
        title: "Audit Trail",
        iconUrl: "/assets/icons/stats.svg",
        to: "/audit-trail",
        visibleToRole: ["Global Admin", "Accounts", "Credit Service"],
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Ledger Setup",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/ledger-accounts",
      //   visibleToRole: [
      //     "Global Admin",
      //     "Tenant Admin",
      //     "Accounts",
      //     "Credit Service",
      //   ],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Payment Cycles",
      //   iconUrl: "/assets/icons/payment.svg",
      //   to: "/payment-cycles",
      //   visibleToRole: [
      //     "Global Admin",
      //     "Tenant Admin",
      //     "Accounts",
      //     "Credit Service",
      //   ],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Fees",
      //   iconUrl: "/assets/icons/stats.svg",
      //   to: "/fees",
      //   visibleToRole: [
      //     "Global Admin",
      //     "Tenant Admin",
      //     "Accounts",
      //     "Credit Service",
      //   ],
      // },
      {
        type: ITEM_TYPES.link,
        title: "Share Register",
        iconUrl: "/assets/icons/list.svg",
        to: "/share-register",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Receipting Logic",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/receipting-logic",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Credit Service",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/credit-service",
    visibleToRole: [
      "Global Admin",
      "Tenant Admin",
      "Accounts",
      "Credit Service",
    ],
    items: [
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Deposits",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/deposits",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Invoice Entry",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/settings/invoice-entry",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Billing",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/billing",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Receipting",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/receipting",
      //   visibleToRole: [
      //     "Global Admin",
      //     "Tenant Admin",
      //     "Accounts",
      //     "Credit Service",
      //   ],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Distributions",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/distributions",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.menu,
      //   title: "Credit Management",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/credit-management",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      //   items: [
      //     {
      //       type: ITEM_TYPES.link,
      //       title: "Late History",
      //       iconUrl: "/assets/icons/list.svg",
      //       to: "/late-history",
      //       visibleToRole: ["Global Admin", "Tenant Admin"],
      //     },
      //     {
      //       type: ITEM_TYPES.link,
      //       title: "Delinquent Buyers",
      //       iconUrl: "/assets/icons/list.svg",
      //       to: "/delinquent-buyers",
      //       visibleToRole: ["Global Admin", "Tenant Admin"],
      //     },
      //     {
      //       type: ITEM_TYPES.link,
      //       title: "Billing Check",
      //       iconUrl: "/assets/icons/list.svg",
      //       to: "/billing-check",
      //       visibleToRole: ["Global Admin", "Tenant Admin"],
      //     },
      //   ],
      // },
      {
        type: ITEM_TYPES.link,
        title: "Discrepancy Management",
        iconUrl: "/assets/icons/list.svg",
        to: "/discrepancy-management",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Invoices",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/invoices",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Transactions",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/transactions",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      // {
      //   type: ITEM_TYPES.link,
      //   title: "Ledger Transactions",
      //   iconUrl: "/assets/icons/list.svg",
      //   to: "/ledger-transactions",
      //   visibleToRole: ["Global Admin", "Tenant Admin"],
      // },
      {
        type: ITEM_TYPES.link,
        title: "Reports",
        iconUrl: "/assets/icons/list.svg",
        to: "/reports",
        visibleToRole: ["Global Admin", "Tenant Admin"],
      },
    ],
  },
  {
    type: ITEM_TYPES.menu,
    title: "Platform",
    iconUrl: "/assets/icons/menu_item.svg",
    to: "/platform",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
    items: [
      {
        type: ITEM_TYPES.link,
        title: "Tenant Administration",
        iconUrl: "/assets/icons/house.svg",
        to: "/tenants-list",
        visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
      },
    ],
  },
  {
    type: ITEM_TYPES.link,
    title: "Swap Role",
    iconUrl: "/assets/icons/settings.svg",
    to: "/swap-role",
    visibleToRole: ["Global Admin", "Tenant Admin", "Credit Service"],
  },
];

export function AsideMenuList({ layoutProps }) {
  const { user } = useSelector((state) => state.auth);
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {MENU.map((item, index) => {
          return (
            user.roles.some((role) =>
              item.visibleToRole?.includes(role.name)
            ) && <MenuItem key={index} item={item} />
          );
        })}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}

function MenuItem({ item, subMenu }) {
  const location = useLocation();
  const { user } = useSelector((state) => state.auth);
  const getMenuItemActive = useCallback(
    (url) => {
      return checkIsActive(location, url)
        ? " menu-item-active menu-item-open "
        : "";
    },
    [location]
  );
  const { type } = item;
  switch (type) {
    case ITEM_TYPES.link:
      return (
        <li
          className={`menu-item ${getMenuItemActive(item.to)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to={item.to}>
            {Boolean(subMenu) && false && (
              <i className="menu-bullet menu-bullet-dot">
                <span />
              </i>
            )}
            {Boolean(item.iconUrl) && (
              <span className="svg-icon menu-icon">
                <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
              </span>
            )}
            <span className="menu-text">{item.title}</span>
          </NavLink>
        </li>
      );
    case ITEM_TYPES.heading:
      return (
        <li className="menu-item" style={{ pointerEvents: "none" }}>
          <div className="menu-link">
            <span
              className="menu-text font-weight-bold"
              style={{ color: "#464E5F", cursor: "default" }}
            >
              {item.title}
            </span>
          </div>
        </li>
      );
    case ITEM_TYPES.menu:
      return (
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            item.to
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to={item.to}>
            <span className="svg-icon menu-icon">
              <img src={toAbsoluteUrl(item.iconUrl)} alt="" />
            </span>
            <span className="menu-text">{item.title}</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">{item.title}</span>
                </span>
              </li>
              {item.items.map(
                (subItem, index) =>
                  user.roles.some((role) =>
                    subItem.visibleToRole?.includes(role.name)
                  ) && <MenuItem key={index} item={subItem} subMenu />
              )}
            </ul>
          </div>
        </li>
      );
    default:
      return null;
  }
}
