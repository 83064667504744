import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router";
import Skeleton from "@material-ui/lab/Skeleton";
import { Formik } from "formik";
import * as Yup from "yup";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import {
  formatAmount,
  formatDate,
  formatDateToUTC,
  isAmount,
  isDate,
  renderHeader,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { createBillings, deleteBilling, getBillings } from "./_api";
import { modifyBilling, modifyBillings } from "./helpers";
import { Modal } from "../../../ui/components/Modal";

const initialValues = {
  period_start_date: "",
  period_end_date: "",
};

const HEADINGS = [
  ["period_start_date", "From"],
  ["period_end_date", "To"],
  ["amount", "Amount"],
  ["status", "Status"],
  ["review", "Review"],
  ["delete", "Delete"],
];

const historicalDistrHeadings = [
  ["period_start_date", "From"],
  ["period_end_date", "To"],
  ["amount", "Amount"],
];

const isReviewKey = (key) => key === "review";
const isDeleteKey = (key) => key === "delete";

export const Calculate = ({
  handleNextStep,
  tenantId,
  setReviewedItem,
  setStep,
}) => {
  const { request } = useFetch();
  const history = useHistory();

  const [historicalDistributions, setHistoricalDistributions] = useState([]);
  const [draftedDistributions, setDraftedDistributions] = useState([]);
  const [draftsLoading, setDraftsLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    setDraftsLoading(true);
    request(getBillings, tenantId)
      .then((data) => {
        if (!data) return;
        setDraftedDistributions(
          modifyBillings(data.filter(({ status }) => status !== "sent"))
        );
        setHistoricalDistributions(
          modifyBillings(
            data.filter(({ status }) => status === "sent").slice(0, 5)
          )
        );
      })
      .finally(() => setDraftsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteDraft = (id) => () => {
    setDraftsLoading(true);
    request(deleteBilling, tenantId, id)
      .then((data) => {
        if (!data) return;
        setDraftedDistributions((state) =>
          state.filter((item) => item.id !== id)
        );
      })
      .finally(() => setDraftsLoading(false));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const payload = {
      ...values,
      period_start_date: formatDateToUTC(values.period_start_date),
      period_end_date: formatDateToUTC(values.period_end_date, true),
    };
    request(createBillings, tenantId, payload)
      .then((data) => {
        if (!data) return;
        if (Object.keys(data.content).length) {
          setDraftedDistributions((state) => [...state, modifyBilling(data)]);
          setReviewedItem(data);
          handleNextStep();
        } else {
          request(deleteBilling, tenantId, data.id).finally(() =>
            setModalOpen(true)
          );
        }
      })
      .finally(() => setSubmitting(false));
  };

  const handleReview = (item = {}) => {
    setReviewedItem(item);
    if (item.status === "processed") {
      setStep(2);
    } else {
      handleNextStep();
    }
  };

  const handleHistorical = () => history.push("/historical-billing");

  const renderReviewButton = (item = {}) => {
    return (
      <Button
        onClick={() => handleReview(item)}
        variant="outlined"
        color="secondary"
        size="small"
        className="px-5"
      >
        Review
      </Button>
    );
  };

  const renderDeleteButton = (item = {}) => {
    return (
      <Button
        onClick={handleDeleteDraft(item.id)}
        variant="outlined"
        color="secondary"
        size="small"
        className="px-5"
      >
        Delete
      </Button>
    );
  };

  function renderRow(headings, item) {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5 py-1">
        {isDate(key)
          ? item[key] && formatDate(new Date(item[key]))
          : isAmount(key)
          ? formatAmount(item[key])
          : isReviewKey(key)
          ? renderReviewButton(item)
          : isDeleteKey(key)
          ? renderDeleteButton(item)
          : key === "status"
          ? (item[key] || "").toUpperCase()
          : item[key]}
      </td>
    ));
  }

  return (
    <>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={handleModalClose} maxWidth="md">
          <div className="pt-10 pb-3 px-15">
            <h3>
              <strong>No items found for selected period</strong>
            </h3>
            <div className="text-right mt-5">
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                onClick={handleModalClose}
              >
                OK
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <div className="bg-white p-10">
        <div className="d-flex">
          <div style={{ width: "40%" }}>
            <Formik
              onSubmit={handleSubmit}
              initialValues={initialValues}
              validationSchema={Yup.object({
                period_start_date: Yup.string().required("Required field"),
                period_end_date: Yup.string().required("Required field"),
              })}
            >
              {({ values, handleSubmit, isSubmitting }) => (
                <div className="border border-secondary px-5 py-10">
                  <h6 className="text-center mb-5">Select Period</h6>

                  <div className="row mb-3">
                    <div className="col-4">
                      <p className="text-right text-muted">Start Date</p>
                    </div>
                    <div className="col-8 mr-auto">
                      <MappedDatePicker
                        name="period_start_date"
                        className="border border-secnodary"
                        placeholderText={formatDate(new Date(Date.now()))}
                      />
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-4">
                      <p className="text-right text-muted">Finish Date</p>
                    </div>
                    <div className="col-8 mr-auto">
                      <MappedDatePicker
                        className="border border-secnodary"
                        placeholderText={formatDate(new Date(Date.now()))}
                        name="period_end_date"
                      />
                    </div>
                  </div>

                  <div className="text-center mt-5">
                    <Button
                      variant="contained"
                      color="secondary"
                      className="px-9"
                      disabled={isSubmitting}
                      type="submit"
                      onClick={handleSubmit}
                    >
                      Calculate
                    </Button>
                  </div>
                </div>
              )}
            </Formik>

            <div className="border border-secondary mt-5 p-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6 className="text-center">Historical Billing</h6>
                <Button
                  variant="contained"
                  color="secondary"
                  className="px-5"
                  onClick={handleHistorical}
                >
                  View All
                </Button>
              </div>
              <div>
                {historicalDistributions.length ? (
                  <table className="table">
                    <thead>
                      <tr>{renderHeader(historicalDistrHeadings)}</tr>
                    </thead>
                    <tbody>
                      {historicalDistributions.map((item) => (
                        <tr
                          className="border-bottom cursor-pointer"
                          key={item.id}
                        >
                          {renderRow(historicalDistrHeadings, item)}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p className="text-center my-5">No data found</p>
                )}
              </div>
            </div>
          </div>
          <div className="ml-10 p-4">
            <h6 className="ml-4">Draft Billing</h6>
            <div>
              {draftsLoading ? (
                <div style={{ minWidth: "700px" }}>
                  <Skeleton variant="rect" width={"100%"} height={400} />
                </div>
              ) : (
                <table className="table" style={{ minWidth: "700px" }}>
                  <thead>
                    <tr>{renderHeader(HEADINGS)}</tr>
                  </thead>
                  <tbody>
                    {draftedDistributions.map((item) => (
                      <tr className="border-bottom" key={item.id}>
                        {renderRow(HEADINGS, item)}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
