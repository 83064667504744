import { formatDate } from "../../../../ui/helpers/helpers";

export function millisToSeconds(millis) {
  return (millis / 1000).toFixed();
}

export const modifyAuditsObject = (audit = {}) => {
  const messageArr = audit.message.split("'");
  return {
    ...audit,
    timestamp: formatDate(new Date(audit.timestamp)),
    name: messageArr[messageArr.indexOf("name") + 2],
  };
};

export const modifyAuditsData = (data = []) => {
  return data.map((item) => modifyAuditsObject(item));
};
