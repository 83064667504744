import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import UserProfile from "./pages/UserProfile";
import { DashboardPage } from "./pages/DashboardPage";
import { DashboardPageFOHBiller } from "./pages/DashboardPageFOHBiller";
import { StaticPages } from "./modules/StaticPages";
import { OrganisationsListPage } from "./pages/OrganisationsListPage";
import { OrganisationCardPage } from "./pages/OrganisationCardPage";
import { ContactsListPage } from "./pages/ContactsListPage";
import { ContactCardPage } from "./pages/ContactCardPage";
import { UsersListPage } from "./pages/UsersListPage";
import { UserCardPage } from "./pages/UserCardPage";
import { AddNewOrganisation } from "./pages/AddNewOrganisation";
import { AddNewContactPage } from "./pages/AddNewContactPage";
import { AuditTrail } from "./modules/AuditTrail";
import { Integrations } from "./modules/Integrations";
import { Receipting } from "./modules/Receipting";
import { InvoiceEntry } from "./modules/InvoiceEntry";
import { LedgerAccountsListPage } from "./pages/LedgerAccountsListPage";
import { LedgerAccountsCardPage } from "./pages/LedgerAccountsCardPage";
import { AddNewLedgerAccount } from "./pages/AddNewLedgerAccount";
import { TenantsListPage } from "./pages/TenantsListPage";
import { TenantsCardPage } from "./pages/TenantsCardPage";
import { AddNewTenant } from "./pages/AddNewTenant";
import { PaymentCyclesListPage } from "./pages/PaymentCyclesListPage";
import { PaymentCyclesCardPage } from "./pages/PaymentCyclesCardPage";
import { AddNewPaymentCycles } from "./pages/AddNewPaymentCycles";
import { AddNewPaymentCycleElement } from "./pages/AddNewPaymentCycleElement";
import { EditPaymentCycleElement } from "./pages/PaymentCycleElement";
import { FeesPage } from "./pages/FeesPage";
import { FeeCardPage } from "./pages/AddFeePage";
import { TaxCardPage } from "./pages/AddTaxPage";
import { ShareRegisterPage } from "./pages/ShareRegisterPage";
import { TransactionsPage } from "./pages/TransactionsPage";
import { TransactionsBillerPage } from "./pages/TransactionsBillerPage";
import { LedgerTransactionsPage } from "./pages/LedgerTransactionsPage";
import { MissingFeature } from "./pages/MissingFeature";
import { LateHistoryPage } from "./pages/LateHistoryListPage";
import { DelinquentBuyersListPage } from "./pages/DelinquentBuyersListPage";
import { DistributionsListPage } from "./pages/DistributionsListPage";
import { SwapRole } from "./pages/SwapRole";
import { CreditServiceStatementsPage } from "./pages/CreditServiceStatementsPage";
import { BillerInvoicesListPage } from "./pages/BillerInvoicesListPage";
import { ReceiptingLogicListPage } from "./pages/ReceiptingLogicListPage";
import { DiscrepancyListPage } from "./pages/DiscrepancyListPage";
import { DashboardPageFOHBuyer } from "./pages/DashboardPageFOHBuyer";
import { DistributionsBillerPage } from "./pages/DistributionBillerPage";
import { BillingPage } from "./pages/BillingPage";
import { BuyerPaymentPage } from "./pages/BuyerPaymentPage";
import { HistoricalBilling } from "./modules/Billing/HistoricalBilling";
import { HistoricalDistributions } from "./modules/DistributionsBOH/HistoricalDistributions";
import { DepositsPage } from "./pages/DepositsPage";
import { DepositsCardPage } from "./pages/DepositsCardPage";
import { InvoicesPage } from "./pages/InvoicesListPage";
import { BillingCheckPage } from "./modules/BillingCheck/List";
import { ReportsPage } from "./modules/Reports/";

export default function BasePage() {
  const { user } = useSelector((state) => state.auth);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        {user.userCategory === "BOH" ? (
          <ContentRoute path="/dashboard" component={DashboardPage} />
        ) : user.userCategory === "Buyer" ? (
          <ContentRoute path="/dashboard" component={DashboardPageFOHBuyer} />
        ) : (
          <ContentRoute path="/dashboard" component={DashboardPageFOHBiller} />
        )}

        <ContentRoute path="/user/edit" component={UserProfile} />
        <ContentRoute path="/audit-trail" component={AuditTrail} />
        <ContentRoute path="/settings/integrations" component={Integrations} />
        <ContentRoute path="/receipting" component={Receipting} />
        <ContentRoute path="/settings/invoice-entry" component={InvoiceEntry} />

        <Route
          path="/contacts/organisations-list/:id"
          component={OrganisationCardPage}
        />
        <ContentRoute
          path="/contacts/organisations-list"
          component={OrganisationsListPage}
        />
        <ContentRoute
          path="/contacts/add-organisation"
          component={AddNewOrganisation}
        />

        <Route path="/contacts/contacts-list/:id" component={ContactCardPage} />
        <ContentRoute
          path="/contacts/contacts-list"
          component={ContactsListPage}
        />
        <ContentRoute
          path="/contacts/add-contact"
          component={AddNewContactPage}
        />

        <ContentRoute
          path="/ledger-accounts/add-account"
          component={AddNewLedgerAccount}
        />
        <Route path="/ledger-accounts/:id" component={LedgerAccountsCardPage} />
        <ContentRoute
          path="/ledger-accounts"
          component={LedgerAccountsListPage}
        />

        <ContentRoute path="/users-list" component={UsersListPage} />
        <Route path="/users/:id" component={UserCardPage} />
        <ContentRoute path="/add-user" component={UserCardPage} />

        <ContentRoute
          path="/tenants-list/add-tenant"
          component={AddNewTenant}
        />
        <Route path="/tenants-list/:id" component={TenantsCardPage} />
        <ContentRoute path="/tenants-list" component={TenantsListPage} />

        <ContentRoute
          path="/payment-cycles/add-cycle"
          component={AddNewPaymentCycles}
        />
        <Route
          path="/payment-cycles/:id?/elements/:elementid"
          component={EditPaymentCycleElement}
        />
        <ContentRoute
          path="/payment-cycles/:id/add-cycle-element"
          component={AddNewPaymentCycleElement}
        />
        <Route path="/payment-cycles/:id" component={PaymentCyclesCardPage} />
        <ContentRoute
          path="/payment-cycles"
          component={PaymentCyclesListPage}
        />

        <ContentRoute path="/fees" component={FeesPage} />
        <Route path="/fee/:id" component={FeeCardPage} />
        <ContentRoute path="/add-fee" component={FeeCardPage} />

        <ContentRoute path="/share-register" component={ShareRegisterPage} />

        <Route path="/tax/:id" component={TaxCardPage} />
        <ContentRoute path="/add-tax" component={TaxCardPage} />

        {user.userCategory === "BOH" ? (
          <ContentRoute path="/transactions" component={TransactionsPage} />
        ) : (
          <ContentRoute
            path="/transactions"
            component={TransactionsBillerPage}
          />
        )}
        <ContentRoute
          path="/ledger-transactions"
          component={LedgerTransactionsPage}
        />

        <ContentRoute path="/late-history" component={LateHistoryPage} />

        <ContentRoute
          path="/discrepancy-management"
          component={DiscrepancyListPage}
        />

        <ContentRoute
          path="/delinquent-buyers"
          component={DelinquentBuyersListPage}
        />

        <ContentRoute path="/distributions" component={DistributionsListPage} />

        <ContentRoute
          path="/distributions-biller"
          component={DistributionsBillerPage}
        />

        <ContentRoute path="/swap-role" component={SwapRole} />

        <ContentRoute
          path="/credit-service-statement"
          component={CreditServiceStatementsPage}
        />

        <ContentRoute
          path="/biller-invoice-search"
          component={BillerInvoicesListPage}
        />

        <ContentRoute
          path="/receipting-logic"
          component={ReceiptingLogicListPage}
        />

        <ContentRoute path="/billing" component={BillingPage} />

        <ContentRoute
          path="/historical-billing"
          component={HistoricalBilling}
        />

        <ContentRoute
          path="/historical-distributions"
          component={HistoricalDistributions}
        />

        <ContentRoute path="/buyer-payment" component={BuyerPaymentPage} />

        <ContentRoute
          path="/deposits/add-deposit"
          component={DepositsCardPage}
        />
        <Route path="/deposits/:id" component={DepositsCardPage} />
        <ContentRoute path="/deposits" component={DepositsPage} />

        <ContentRoute path="/invoices" component={InvoicesPage} />

        <ContentRoute path="/billing-check" component={BillingCheckPage} />

        <ContentRoute path="/reports" component={ReportsPage} />

        <ContentRoute path="/static/:id" component={StaticPages} />

        <ContentRoute path="/error-page" component={MissingFeature} />

        <Redirect to="/error-page" />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
