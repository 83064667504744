export const transactionsTypes = {
  SET_DATA: "Transactions/SET_DATA",
};

export function setTransactionsData(data) {
  return {
    type: transactionsTypes.SET_DATA,
    payload: data,
  };
}
