/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { TextField, withStyles } from "@material-ui/core";
import MaskInput from "react-input-mask";
import DatePicker from "react-datepicker";
import { formatDate } from "../../../../../ui/helpers/helpers";

const CssTextField = withStyles({
  root: {
    // "& label.Mui-focused": {
    //   color: "white",
    // },
    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "yellow",
    // },
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
        // background-color: #C9F7F5;
        // color: #1BC5BD;
      },
      // "&:hover fieldset": {
      //   borderColor: "white",
      // "&.Mui-focused fieldset": {
      //   borderColor: "yellow",
      // },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "#fff",
      },
    },
  },
})(TextField);

export function InvoiceHeaderRowItem({ value = "", handleDataUpdate, column, item = {} }) {
  const [changed, setChanged] = useState(false);
  const [checked, setChecked] = useState(false);
  const [data, setData] = useState(value);
  const [selected, setSelected] = useState(new Date())

  useEffect(() => {
    const payload = {
      ...item,
      [column]: data,
    };
    handleDataUpdate(payload);
    // eslint-disable-next-line
  }, [data, setData]);

  return (
    <>
      {column === "invoice_date" ? (
        <DatePicker
          value={data || formatDate(Date.now())}
          selected={selected}
          onChange={(data) => {
            data !== value ? setChanged(true) : setChanged(false);
            setData(formatDate(new Date(data)));
            setSelected(data)
          }}
          onBlur={() => (!changed ? setChecked(true) : setChecked(false))}
          type="text"
          dateFormat="dd/MM/yyyy"
          tabIndex="-1"
          customInput={
            <MaskInput
              defaultValue={data || formatDate(Date.now())}
              mask="99/99/9999"
              maskPlaceholder="dd/MM/yyyy"
              // onChange={(e) =>
              //   e.target.value !== value ? setChanged(true) : setChanged(false)
              // }
              // onBlur={() => (!changed ? setChecked(true) : setChecked(false))}
            >
              {() => (
                <CssTextField
                  className={`${changed && "changed-input"} ${checked &&
                    "checked-input"}`}
                  label={""}
                  placeholder="dd/MM/yyyy"
                  InputLabelProps={{ shrink: false }}
                  variant="outlined"
                />
              )}
            </MaskInput>
          }
        />
      ) : (
        <CssTextField
          className={`w-100 ${changed && "changed-input"} ${checked &&
            "checked-input"}`}
          onChange={(e) => {
            setData(e.target.value);
            e.target.value !== data ? setChanged(true) : setChanged(false);
          }}
          onBlur={() => (!changed ? setChecked(true) : setChecked(false))}
          label={""}
          InputLabelProps={{ shrink: false }}
          defaultValue={column === "invoice_type" ? (data ? data : "i") : data}
          variant="outlined"
        />
      )}
    </>
  );
}
