export const delinquentTypes = {
  SET_DATA: "DELINQUENT/SET_DATA",
  UPDATE_DATA: "DELINQUENT/UPDATE_DATA",
};

export function setDelinquentData(data) {
  return {
    type: delinquentTypes.SET_DATA,
    payload: data,
  };
}

export function updateDelinquentData(data) {
  return {
    type: delinquentTypes.UPDATE_DATA,
    payload: data,
  };
}
