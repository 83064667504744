import React, { useState, useEffect } from "react";
import { Modal } from "../../components/Modal";
import DataGrid from "../../components/DataGrid";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { IconButton, Tooltip } from "@material-ui/core";
import { renderHeader, renderRow } from "../../helpers/helpers";
import {
  deleteComment,
  exportCommentsByType,
  tenantId,
} from "../../../app/modules/Organisations/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { downloadFile } from "../../../app/modules/AWS-S3/download";
import { Select } from "../../components/Select";

const HEADINGS = [
  ["last_modified", "Date"],
  ["user", "User"],
  ["comment_type_name", "Type"],
  ["comment", "Comment Text"],
];

const Comments = ({
  data = [],
  setNewComment,
  id,
  commentType,
  commentTypeOptions,
  onAfterDelete,
  setItemId,
  tenantId,
  loading,
  status,
}) => {
  const { request } = useFetch();
  const [modal, setModal] = useState(false);
  const [deleteCommentId, setDeleteCommentId] = useState();
  const [selectedType, setSelectedType] = useState(0);
  const [filteredData, setFilteredData] = useState(data);

  const handleDelete = (commentId) => {
    request(deleteComment, tenantId, id, deleteCommentId).then(() =>
      onAfterDelete()
    );
    setModal(false);
    setDeleteCommentId(null);
  };

  function showDeleteModal(commentId) {
    setModal(true);
    setDeleteCommentId(commentId);
  }

  useEffect(() => {
    if (selectedType === 0) {
      setFilteredData(data);
    } else {
      setFilteredData(
        data.filter(({ comment_type_id }) => comment_type_id === selectedType)
      );
    }
    // eslint-disable-next-line
  }, [selectedType]);

  return (
    <>
      {status !== "archived" && (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          actions={
            <div className="m-auto">
              <button
                onClick={() => setModal(false)}
                className="btn btn-warning mr-8"
              >
                Cancel
              </button>
              <button onClick={handleDelete} className="btn btn-primary">
                Yes
              </button>
            </div>
          }
          fullWidth={true}
        >
          <p className="text-center">
            Are you sure you want to delete this comment?
          </p>
        </Modal>
      )}
      <div className="m-5 d-flex align-items-center">
        {status !== "archived" && (
          <button
            className="btn btn-sm btn-primary px-8 mr-5"
            onClick={() => {
              setItemId((prevState) => ({
                ...prevState,
                editMode: false,
              }));
              setNewComment(true);
            }}
          >
            New Comment
          </button>
        )}
        <Select
          onChange={(e) => setSelectedType(e.target.value)}
          value={selectedType}
          options={commentTypeOptions}
          defaultValue="null"
        />
        <Tooltip title="Download Comments Report" arrow interactive>
          <IconButton
            color="secondary"
            onClick={() =>
              downloadFile([id, commentType], exportCommentsByType, tenantId)
            }
            style={{ marginLeft: "auto" }}
          >
            <CloudDownloadIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="d-flex justify-content-between mb-3"></div>
          <DataGrid
            data={filteredData}
            headings={HEADINGS}
            renderHeader={renderHeader}
            renderRow={renderRow}
            isUserList={status !== "archived"}
            handleDelete={showDeleteModal}
            isCommentList={status !== "archived"}
            setNewComment={setNewComment}
            setItemId={setItemId}
            loading={loading}
          />
        </div>
      </div>
    </>
  );
};

export default Comments;
