import React from "react";
import { Button } from "@material-ui/core";
import { Formik } from "formik";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { MappedTextArea } from "../../components/TextArea";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  addComments,
  editComments,
} from "../../../app/modules/Organisations/_api";
import { CommentSchema } from "../../../helpers/schemas";

export function CommentForm({
  id,
  tenantId,
  organisationName,
  commentTypes = {},
  user,
  setNewComment,
  onAfterSubmit,
  comment,
}) {
  const { request } = useFetch();

  const commentTypeOptions = Object.entries(commentTypes).map(
    ([value, label]) => ({
      value: +value,
      label,
    })
  );

  const initialValues = {
    user,
    organisationName,
    comment: comment.comment || "",
    comment_type_id: comment.comment_type_id || "",
  };

  const handleSubmit = (values) => {
    comment
      ? request(editComments, tenantId, id, comment.id, values.comment).then(
          () => {
            onAfterSubmit();
            setNewComment(false);
          }
        )
      : request(addComments, tenantId, id, { ...values }).then(() => {
          onAfterSubmit();
          setNewComment(false);
        });
  };

  const handleClose = () => setNewComment(false);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={CommentSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">User</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput name="user" wrapperClassName="w-100" disabled />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">
                    Organisation Name
                  </p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="name"
                    value={organisationName}
                    wrapperClassName="w-100"
                    disabled
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted mt-3">Type</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedSelect
                    name="comment_type_id"
                    disabled={comment ? true : false}
                    options={commentTypeOptions}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted mt-2">Comment</p>
                </div>
                <div className="col-10 mr-auto">
                  <MappedTextArea
                    name="comment"
                    rows={6}
                    data-testid="comment"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
