/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {toAbsoluteUrl} from "../../../_helpers";

const NEWS = [
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 1',
    link: '#'
  },
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 2',
    link: '#'
  },
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 3',
    link: '#'
  },
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 4',
    link: '#'
  },
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 5',
    link: '#'
  },
  {
    imageUrl: '/assets/icons/news.svg',
    title: 'News 6',
    link: '#'
  },
]

export function CRSNewsWidget({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Head */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">CRS NEWS</h3>
        </div>
        {/* Body */}
        <div className="card-body pt-2">
          {NEWS.slice(0, 5).map((item, index) => (
            <div key={index} className="d-flex align-items-center mb-10">
              <div className="symbol symbol-40 symbol-light-success mr-5">
              <span className="symbol-label">
                <img
                  className="h-75"
                  src={toAbsoluteUrl(item.imageUrl)}
                  alt=""
                />
              </span>
              </div>
              <div className="d-flex flex-column flex-grow-1 font-weight-bold">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  {item.title}
                </a>
              </div>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-outline-primary px-10 py-2 btn-sm"
              >
                MORE
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

