import React, { useState } from "react";
import { ServerSearchBar } from "../../../ui/structures/ServerSearchBar";
import { useFetch } from "../../../hooks/fetch.hook";
import { getInvoicesAutocomplete } from "../InvoiceEntry/_api";

import { handleSearch, objectCheck } from "./helpers";
import { uniq } from "lodash";

export const TRANSACTION_SEARCH_HELPER = [
  ["invoice_number", "Invoice Number"],
  ["invoice_type", "Invoice Type"],
  ["biller.legalName", "Biller"],
  ["buyer.legalName", "Buyer"],
  ["note", "Note"],
  ["invoice_date", "Invoice Date"],
];

export const transactionSearchMap = {
  "Invoice Number": "invoice_number",
  "Invoice Type": "invoice_type",
  Biller: "biller.legalName",
  Buyer: "buyer.legalName",
  Note: "note",
  "Invoice Date": "invoice_date",
};

export function ReportsForm({
  resetSearch,
  setSearch,
  setPage,
  search,
  TENANT_ID,
  pageSize,
}) {
  const { request } = useFetch();

  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);

  function fetchTransactionsAutocomplete(
    autocompleteValue,
    autocompleteField,
    searchFields
  ) {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getInvoicesAutocomplete,
      TENANT_ID,
      pageSize,
      autocompleteField,
      autocompleteValue,
      searchFields
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  }

  return (
    <div className="row align-items-center col-12">
      <ServerSearchBar
        className="mb-5 w-100"
        onSearch={(data) =>
          handleSearch(data, transactionSearchMap, setSearch, setPage)
        }
        keyMap={TRANSACTION_SEARCH_HELPER}
        loading={autocompleteLoading}
        currentSearchList={autocompleteData}
        fetchAutocompleteFunction={fetchTransactionsAutocomplete}
        placeholder="Search Invoices..."
        clearSearch={resetSearch}
        searchMap={transactionSearchMap}
        searchFields={search}
      />
    </div>
  );
}
