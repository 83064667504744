import React from "react";
import { Formik } from "formik";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { formatDate } from "../../../ui/helpers/helpers";

export const FilterOptions = ({ handleSubmit }) => {
  const initialValues = {
    aging_date: "",
  };
  return (
    <Formik onSubmit={handleSubmit} initialValues={initialValues}>
      {({ handleSubmit, isSubmitting }) => (
        <div className="row align-items-center col-12 py-5">
          <div className="col-1">
            <div className="">Aging Date</div>
          </div>
          <div className="col-2">
            <MappedDatePicker
              name="from"
              className="border border-secnodary"
              placeholderText={formatDate(new Date(Date.now()))}
              // onChange={() => console.log("Asdasd")}
              handleSubmit={handleSubmit}
            />
          </div>
        </div>
      )}
    </Formik>
  );
};
