import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { InputFile } from "../../components/InputFile";
import File from "../File";
import {
  getTenantLogoUploadUrl,
  modifyTenant,
} from "../../../app/modules/Tenants/_api";

export const logoMapUrl = {
  "localhost.nonp.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
  "integration-1.portal.crs-nonp.c0.creditservice.cloud":
    "https://api.integration-1.portal.crs-nonp.c0.creditservice.cloud",
};

export const TenantLogoUpload = ({ data = {}, tenant_id, setCardData }) => {
  const { request } = useFetch();

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [logoKey, setLogoKey] = useState("");

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const saveUrlFieldsValues = (value) => {
    setLogoKey(value);
  };

  const updateFiles = () => "updated files...";

  const submitLogo = () => {
    if (logoKey) {
      request(modifyTenant, { brand_logo: logoKey }, tenant_id)
        .then((data) => {
          if (!data) return;
          setCardData((state) => ({
            ...state,
            brand_logo: data.brand_logo,
          }));
          setUploadedFiles([]);
        })
        .finally(() => {
          setLogoKey("");
        });
    }
  };

  useEffect(() => {
    submitLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoKey]);

  return (
    <div className="d-flex">
      <div className="col-8 py-10 px-0">
        <InputFile
          className="rounded"
          onDrop={handleUploadFiles}
          description="You can upload only one file at a time"
          title="Upload Brand Logo"
          maxFilesQuantity={1}
        >
          {uploadedFiles.map((file) => (
            <File
              key={file.lastModified}
              senderId={tenant_id}
              senderType={tenant_id}
              file={file}
              saveUrlFieldsValues={saveUrlFieldsValues}
              updateFiles={updateFiles}
              getUrl={getTenantLogoUploadUrl}
            />
          ))}
        </InputFile>
      </div>
      <div className="col-4 p-10 px-20">
        <div className="text-center">
          {data.brand_logo ? (
            <img
              src={`${logoMapUrl[window.location.hostname]}/${data.brand_logo}`}
              alt="Brand Logo"
              className="img-fluid"
            />
          ) : (
            <h4>Logo not found</h4>
          )}
        </div>
      </div>
    </div>
  );
};
