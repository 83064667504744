const dataKeys = (data = []) => {
  return data.reduce((acc, item = {}) => {
    acc[item.id] = true;
    return Object.keys(acc);
  }, []);
};

const getRandomNumber = (max) => Math.floor(Math.random() * Math.floor(max));

export const setID = (max, data = []) => {
  let random = getRandomNumber(max);
  const keysArr = dataKeys(data);
  if (!(keysArr || []).find((item) => item === random)) {
    return random;
  } else setID(max);
};

export const setIDMultiple = (max, data = []) => {
  let random = getRandomNumber(max);
  if (!data.includes(random)) {
    return random;
  } else return setID(max, data);
};

