export const logsTypes = {
  SET_DATA: 'LOGS/SET_DATA',
}

export function setLogsList(data) {
  return {
    type: logsTypes.SET_DATA,
    payload: data
  }
}
