import React, { useEffect, useMemo, useState } from "react";
import { NavLink } from "react-router-dom";
import DataGrid from "../../components/DataGrid";
import { renderHeader } from "../../helpers/helpers";
import { Select } from "../../components/Select";
import { Checkbox } from "../../components/Checkbox";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  deleteAccount,
  getAccounts,
  getArchivedAccounts,
  modifyAccount,
} from "../../../app/modules/Organisations/_api";
import { Modal } from "../../components/Modal";

const HEADINGS = [
  ["id", "ID"],
  ["account_type", "Type"],
  ["account_name", "Account Name"],
  ["account_number", "Account Number"],
  ["bank", "Bank"],
  ["balance", "Balance"],
];

const mappedType = {
  external: "External",
  internal: "Ledger",
};

export function Accounts({ onView, id, tenantId, status }) {
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [accountType, setAccountType] = useState("");
  const [directDebitAccount, setDirectDebitAccount] = useState("");
  const [archived, setArchived] = useState(false);
  const [loading, setLoading] = useState(true);
  const { request } = useFetch();

  const loadAccounts = () => {
    request(getAccounts, tenantId, id).then((data) => {
      setData(data);
      setAccountType(
        (data.filter(({ default_account }) => default_account)[0] || {}).id ||
          ""
      );
      setDirectDebitAccount(
        (data.filter(({ billing_account }) => billing_account)[0] || {}).id ||
          ""
      );
      setLoading(false);
    });
  };

  useEffect(() => {
    loadAccounts();
    // eslint-disable-next-line
  }, []);

  const handleChangeAccountType = (e) => {
    const accountId = e.target.value;
    const account = data.find((acc) => acc.id === accountId);
    request(
      modifyAccount,
      tenantId,
      id,
      { ...account, default_account: true },
      accountId
    ).then((data) => data && setAccountType(accountId));
  };

  const handleChangeDirectDebit = (e) => {
    const accountId = e.target.value;
    const account = data.find((acc) => acc.id === accountId);
    request(
      modifyAccount,
      tenantId,
      id,
      { ...account, billing_account: true },
      accountId
    ).then((data) => data && setDirectDebitAccount(accountId));
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    const accountId = Number(getId());
    const selectedAccBalance = (data.find(({ id }) => id === accountId) || {})
      .balance;
    if (selectedAccBalance !== 0) {
      setModal(false);
      return;
    }
    request(deleteAccount, tenantId, id, accountId).then(() => {
      setData((prevState) => [
        ...prevState.filter((item) => item.id !== accountId),
      ]);
      setSelected({});
      setModal(false);
      loadAccounts();
    });
  };

  const showArchived = () => {
    setLoading(true);
    if (!archived) {
      request(getArchivedAccounts, tenantId, id)
        .then((data) => setData((prevState) => [...prevState, ...data]))
        .finally(() => setLoading(false));
    } else {
      request(getAccounts, tenantId, id)
        .then(setData)
        .finally(() => setLoading(false));
    }
    setArchived(!archived);
  };

  const renderAccountsRow = (headings, item) => {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5">
        {key === "account_name" && (
          <p
            className={`${
              item.status === "archived" ? "text-danger" : "text-primary"
            } mb-0`}
            onClick={() => status !== "archived" && onView(item)}
          >
            {item[key]}
          </p>
        )}
        {key === "account_type" && mappedType[item[key]]}
        {key === "balance" && (
          <NavLink
            to={{
              pathname: `/transactions`,
              organisation_id: item.organisation_id || "",
              account_id: item.id || "",
            }}
          >
            {/* {`$ ${item[key]}`} */}
            {`$ ${item.balance || 0}`}
          </NavLink>
        )}
        {key !== "account_name" &&
          key !== "account_type" &&
          key !== "balance" &&
          item[key]}
      </td>
    ));
  };

  const renderModalContent = () => {
    const accountId = Number(getId());
    const selectedAccBalance = (data.find(({ id }) => id === accountId) || {})
      .balance;
    if (selectedAccBalance === 0) {
      return "Are you sure you want to delete this account?";
    } else return "Can't delete account with balance other than 0";
  };

  return (
    <>
      {status !== "archived" && (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          actions={
            <div className="m-auto">
              <button onClick={handleArchive} className="btn btn-primary">
                OK
              </button>
            </div>
          }
          fullWidth={true}
        >
          <p className="text-center">{renderModalContent()}</p>
        </Modal>
      )}
      <div>
        {status !== "archived" && (
          <div className="d-flex justify-content-between align-items-center px-9 py-7">
            <div>
              <button
                className="btn btn-primary mr-6"
                onClick={() => onView(true)}
              >
                Add
              </button>
              <button
                className="btn btn-primary"
                disabled={archiveDisabled}
                onClick={() => setModal(true)}
              >
                Archive
              </button>
            </div>
            <div className="col-4 text-right">
              <Checkbox
                label="Show archived accounts"
                value={archived}
                onChange={showArchived}
              />
            </div>
          </div>
        )}
        <DataGrid
          controls
          headings={HEADINGS}
          data={data}
          renderHeader={renderHeader}
          renderRow={renderAccountsRow}
          selectable={status !== "archived"}
          hideSelect
          selected={selected}
          setSelected={setSelected}
          loading={loading}
        />

        <div
          className="d-flex justify-content-start align-items-center px-8 py-6"
          style={{ borderTop: "solid 4px #eef0f8" }}
        >
          <div className="d-flex align-items-center mr-10">
            <p className="m-0 mr-2">Distribution Account</p>
            <Select
              value={accountType}
              data-testid="distribution"
              options={data
                .filter(({ status }) => status === "active")
                .map(({ id, account_name }) => ({
                  value: id,
                  label: account_name,
                }))}
              onChange={handleChangeAccountType}
              disabled={status === "archived"}
            />
          </div>
          <div className="d-flex align-items-center">
            <p className="m-0 mr-2">Direct Debit Account</p>
            <Select
              value={directDebitAccount}
              data-testid="distribution"
              options={data
                .filter(
                  ({ status, account_type }) =>
                    status === "active" && account_type === "external"
                )
                .map(({ id, account_name }) => ({
                  value: id,
                  label: account_name,
                }))}
              onChange={handleChangeDirectDebit}
              disabled={status === "archived"}
            />
          </div>
        </div>
      </div>
    </>
  );
}
