import React from "react";
import { Skeleton } from "@material-ui/lab/";

export function StaticPageLoading() {
  return (
    <>
      <div
        style={{
          backgroundColor: "#292824",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          width: "100%",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            <Skeleton variant="rect" width={410} height={60} />
          </div>
        </div>
      </div>
      <div style={{ padding: "60px", fontSize: "14px", width: "100%" }}>
        <div style={{ fontSize: "16px" }}>
          <Skeleton variant="rect" width={410} height={24} />
        </div>
        <br />
        <div style={{ width: "100%" }}>
          <Skeleton variant="rect" height={600} />
        </div>
      </div>
    </>
  );
}
