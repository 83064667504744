import config from 'react-global-configuration';

export const uploadFile = (type, id, fileName, saveUrlFieldsValues, getUrl = getUploadFileUrl) =>
  getUrl(type, id, fileName)
    .then((data) => data.json())
    .then(({ data }) => {
      saveUrlFieldsValues && data && saveUrlFieldsValues(data.fields.key);
      return startUpload(fileName, data);
    })
    .catch(console.error);

const getUploadFileUrl = (type, id, fileName) => (
  fetch(
    `${config.get("ApiUrl").Rest}/files/request-upload-url`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      body: JSON.stringify({type: type, resource_id: id, file_name: fileName.name})
    }
  )
);

const startUpload = (fileName, data) => {
  const formData = new FormData();
  const {url, fields} = data;

  Object.keys(fields).forEach(key => {
    const value = fields[key];

    formData.append(key, value);
  });

  formData.append("file", fileName);

  return fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: formData
  });
}
