import React from "react";
import { Autocomplete } from "@material-ui/lab";
import { TextField, withStyles } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
      },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
    },
  },
})(TextField);

export const AutocompleteGroupBy = ({
  value,
  setValue,
  valuesOptions,
  label = "",
  disabled,
}) => {

  return (
    <Autocomplete
      id="grouped-demo"
      className="w-100"
      size="small"
      value={value || null}
      onChange={(event, newValue) => {
        setValue(newValue);
      }}
      options={valuesOptions.sort(
        (a, b) => -b.label.localeCompare(a.firstLetter)
      )}
      getOptionSelected={(option, value) => {
        return option.value === value.value
      }}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.label || ""}
      disabled={disabled}
      renderInput={(params) => (
        <CssTextField {...params} label={label} variant="outlined" />
      )}
    />
  );
};