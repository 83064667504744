export const receiptingLogicTypes = {
  SET_DATA: "RECEIPRING_LOGIC/SET_DATA",
  UPDATE_DATA: "RECEIPRING_LOGIC/UPDATE_DATA",
  ARCHIVE_DATA: "RECEIPRING_LOGIC/ARCHIVE_DATA"
};

export function setReceiptingLogicsData(data) {
  return {
    type: receiptingLogicTypes.SET_DATA,
    payload: data,
  };
}

export function updateReceiptingLogicData(data) {
  return {
    type: receiptingLogicTypes.UPDATE_DATA,
    payload: data,
  };
}

export function archiveRule(id) {
  return {
    type: receiptingLogicTypes.ARCHIVE_DATA,
    payload: id,
  };
}