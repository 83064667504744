import React, { useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { Button, Grow } from "@material-ui/core";
import { MappedCheckbox } from "../../components/Checkbox";
import { updateContactRequest } from "../../../app/modules/Contacts/_api";
import { modifyOrganisation } from "../../../app/modules/Organisations/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import CheckIcon from "@material-ui/icons/Check";
import { useSelector } from "react-redux";
import Can from "../../helpers/Can";

export function Settings({
  data,
  setData,
  id,
  isOrganisation,
  handleUpdateCardData,
}) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const { settings_email, settings_sms, settings_phone, is_biller, is_buyer } =
    data || {};
  const [saved, setSaved] = useState(false);
  const { request } = useFetch();

  const initialValues = {
    settings_email: settings_email || false,
    settings_sms: settings_sms || false,
    settings_phone: settings_phone || false,
    is_biller: is_biller || false,
    is_buyer: is_buyer || false,
  };

  const validationSchema = Yup.object({
    settings_email: Yup.boolean(),
    settings_sms: Yup.boolean(),
    settings_phone: Yup.boolean(),
  });

  const handleOnBlur = (e) => {
    e.target &&
      setData((prevState) => ({
        ...prevState,
        settings: {
          ...prevState.settings,
          [e.target.name]: e.target.value === "true",
        },
      }));
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSaved(false);
    request(
      isOrganisation ? modifyOrganisation : updateContactRequest,
      TENANT_ID,
      values,
      id
    )
      .then(() => {
        handleUpdateCardData(values);
        setSaved(true);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <div className="h-100 d-flex flex-column pl-10">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, handleReset, isSubmitting }) => (
          <>
            <div className="d-flex flex-column justify-content-around flex-grow-1">
              <div className="py-5">
                <h6>Account Preferences</h6>
              </div>
              <div className="flex-grow-1 d-flex flex-column">
                <span className="pr-5">Communication</span>
                <Can
                  perform="card:settings"
                  yes={() => (
                    <div className="d-flex">
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_email"
                        label="Email"
                        data-testid="email"
                        checked={values.settings_email}
                        labelPlacement="end"
                        disabled={data.status === "archived"}
                      />
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_sms"
                        label="SMS"
                        data-testid="sms"
                        checked={values.settings_sms}
                        labelPlacement="end"
                        disabled={data.status === "archived"}
                      />
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_phone"
                        data-testid="phone"
                        label="Phone"
                        checked={values.settings_phone}
                        labelPlacement="end"
                        disabled={data.status === "archived"}
                      />
                    </div>
                  )}
                  no={() => (
                    <div className="d-flex">
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_email"
                        label="Email"
                        data-testid="email"
                        checked={values.settings_email}
                        labelPlacement="end"
                        disabled
                      />
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_sms"
                        label="SMS"
                        data-testid="sms"
                        checked={values.settings_sms}
                        labelPlacement="end"
                        disabled
                      />
                      <MappedCheckbox
                        onClick={() => setSaved(false)}
                        onBlur={handleOnBlur}
                        name="settings_phone"
                        data-testid="phone"
                        label="Phone"
                        checked={values.settings_phone}
                        labelPlacement="end"
                        disabled
                      />
                    </div>
                  )}
                />
              </div>
            </div>
            {isOrganisation ? (
              <div className="d-flex flex-column justify-content-around border-top border-light flex-grow-1">
                <div className="pb-3 pt-8">
                  <h6>Organisation Type</h6>
                </div>
                <div className="flex-grow-1 d-flex flex-column">
                  <Can
                    perform="card:settings"
                    yes={() => (
                      <>
                        <MappedCheckbox
                          onClick={() => setSaved(false)}
                          onBlur={handleOnBlur}
                          name="is_biller"
                          label="Biller"
                          data-testid="biller"
                          checked={values.is_biller}
                          labelPlacement="end"
                          disabled={data.status === "archived"}
                        />
                        <MappedCheckbox
                          onClick={() => setSaved(false)}
                          onBlur={handleOnBlur}
                          name="is_buyer"
                          data-testid="buyer"
                          label="Buyer"
                          checked={values.is_buyer}
                          labelPlacement="end"
                          disabled={data.status === "archived"}
                        />
                      </>
                    )}
                    no={() => (
                      <>
                        <MappedCheckbox
                          onClick={() => setSaved(false)}
                          onBlur={handleOnBlur}
                          name="is_biller"
                          label="Biller"
                          data-testid="biller"
                          checked={values.is_biller}
                          labelPlacement="end"
                          disabled
                        />
                        <MappedCheckbox
                          onClick={() => setSaved(false)}
                          onBlur={handleOnBlur}
                          name="is_buyer"
                          data-testid="buyer"
                          label="Buyer"
                          checked={values.is_buyer}
                          labelPlacement="end"
                          disabled
                        />
                      </>
                    )}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
            {data.status !== "archived" && (
              <div className="d-flex align-items-center flex-row-reverse py-6 px-11">
                <Grow
                  in={saved}
                  style={{ transformOrigin: "0 0 0" }}
                  {...(saved ? { timeout: 1000 } : {})}
                >
                  <CheckIcon className="text-success ml-4" fontSize="large" />
                </Grow>

                <Can
                  perform="card:settings"
                  yes={() => (
                    <Button
                      onClick={handleSubmit}
                      variant="contained"
                      color="secondary"
                      size="large"
                      className="px-4"
                      disabled={isSubmitting}
                    >
                      Save Changes
                    </Button>
                  )}
                />
              </div>
            )}
          </>
        )}
      </Formik>
    </div>
  );
}
