import React, { useCallback, useState, useEffect, Fragment } from "react";
import { NavLink } from "react-router-dom";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import Skeleton from "@material-ui/lab/Skeleton";
import { Checkbox, Button, IconButton, Switch } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import { ReactComponent as Edit } from "../../app/modules/UserManagment/assets/Edit.svg";
import { ReactComponent as Empty } from "../../app/modules/UserManagment/assets/Empty.svg";
import { ReactComponent as Delete } from "../../app/modules/UserManagment/assets/Delete.svg";
import { ReactComponent as CertificateIcon } from "../structures/Shares/static/Certificate.svg";
import ToolTip from "./Tooltip";
import { DefaultPagination } from "./DatagridFooter/DefaultPagination";
import { ServerPagination } from "./DatagridFooter/ServerPagination";

export default function DataGrid({
  renderHeader,
  renderRow,
  headings = [],
  data = [],
  selectable,
  editable,
  link,
  title,
  hideSelect,
  selected = {},
  setSelected,
  isUserList,
  handleDelete,
  isCommentList,
  setNewComment,
  setItemId,
  loading,
  expandable,
  handleExpand,
  certificate,
  handleDownload,
  perPageDefault = 20,
  paginationOptions = [5, 10, 20],
  pageSize,
  setPageSize,
  expandTitle = "Expand",
  approvable,
  handleApprove,
  expandIcon = <MoreHorizIcon color="primary" variant="contained" />,
  footerRow,
  editIsLink = true,
  handleEdit,
  isRejectable,
  isAdjustView,
  extendable,
  renderExtendedRow,
  paginationEnabled = true,
  isServerPagination,
  serverPage,
  setServerPage,
  totalRecords = 0,
  switchable,
  handleSwitch,
  switchHeader = "Status",
  renderRowProps = [],
}) {
  const [perPage, setPerPage] = useState(perPageDefault);
  const [page, setPage] = useState(1);
  const [extendedRows, setExtendedRows] = useState([]);
  const show =
    isServerPagination || !paginationEnabled
      ? data
      : data.slice((page - 1) * perPage, page * perPage);
  const allSelected = show.every(({ id }) => selected[id]);

  const handleChangePerPage = (e) => {
    setPerPage(e.target.value);
    setPageSize && setPageSize(e.target.value);
    setPage(1);
    setServerPage && setServerPage(0);
  };

  useEffect(() => {
    if (data.length < perPage) {
      setPage(1);
    }
    // eslint-disable-next-line
  }, [data]);

  const handleChangePage = (_, page) => {
    setPage(page);
    setServerPage && setServerPage(page);
  };

  const handleChangeSelected = useCallback(
    (id) =>
      setSelected({
        ...selected,
        [id]: !selected[id],
        // eslint-disable-next-line
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selected]
  );

  const handleSelectAll = useCallback(() => {
    if (allSelected) {
      setSelected({});
    } else {
      setSelected(
        show.reduce((acc, item) => {
          acc[item.id] = true;
          return acc;
        }, {})
      );
    }
    // eslint-disable-next-line
  }, [allSelected, show]);

  const renderEditButton = (item) => {
    return editIsLink ? (
      <td className="align-middle py-1">
        <NavLink
          to={{
            pathname: `/${link}/${item.id}`,
            editMode: item.status !== "archived",
            newUser: false,
          }}
          data-testid="edit"
        >
          <Button className="mr-5 bg-light" variant="contained" size="small">
            <Edit />
          </Button>
        </NavLink>
      </td>
    ) : (
      <td className="align-middle py-1">
        {isAdjustView ? (
          <Button onClick={() => handleEdit(item.id)}>
            <EditIcon color="action" />
          </Button>
        ) : (
          <Button
            className="mr-5 bg-light"
            variant="contained"
            size="small"
            data-testid="edit"
            onClick={() => handleEdit(item.id)}
          >
            <Edit />
          </Button>
        )}
      </td>
    );
  };

  const renderDeleteButton = (item) => {
    return isRejectable ? (
      <Button onClick={() => handleDelete(item.id)}>
        <HighlightOffIcon color="error" />
      </Button>
    ) : (
      <Button
        variant="contained"
        data-testid="delete"
        className="bg-light"
        size="small"
        onClick={() => handleDelete(item.id)}
      >
        <Delete />
      </Button>
    );
  };

  const handleExtend = (id) => () => {
    if (extendedRows.includes(id)) {
      setExtendedRows((state) => state.filter((row) => row !== id));
    } else setExtendedRows([...extendedRows, id]);
  };

  const renderExtendIcon = (id) => {
    if (extendedRows.includes(id)) {
      return <RemoveIcon size="small" color="secondary" />;
    } else return <AddIcon size="small" />;
  };

  return (
    <div className="bg-white rounded" data-testid="datagrid">
      <h3 className="text-dark">{title}</h3>
      <div className="overflow-auto flex-grow-1">
        <table className="table" style={{ minWidth: "700px" }}>
          <thead>
            <tr>
              {extendable && <th></th>}
              {selectable && (
                <th className="align-middle px-5">
                  <Checkbox
                    className="p-0"
                    checked={allSelected}
                    onChange={handleSelectAll}
                  />
                </th>
              )}
              {renderHeader(headings)}
              {switchable && <th>{switchHeader}</th>}
              {editable && <th>{"Edit"}</th>}
              {isCommentList && <th>Edit</th>}
              {isUserList && <th>{isRejectable ? "Reject" : "Delete"}</th>}
              {approvable && <th>Approve</th>}
              {certificate && <th>Certificate</th>}
            </tr>
          </thead>
          {loading === true ? (
            <tbody>
              <tr>
                {selectable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {headings.map((item) => (
                  <td key={item[0]}>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                ))}
                {switchable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {editable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {isCommentList && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {isUserList && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {approvable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {certificate && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
                {expandable && (
                  <td>
                    <Skeleton variant="rect" width={"100%"} height={520} />
                  </td>
                )}
              </tr>
            </tbody>
          ) : (
            <tbody>
              {show.map((item, id) => (
                <Fragment key={item.id || item.timestamp + item.message + id}>
                  <tr className="border-bottom">
                    {extendable && (
                      <td>
                        <span
                          className="cursor-pointer"
                          onClick={handleExtend(id)}
                        >
                          {renderExtendIcon(id)}
                        </span>
                      </td>
                    )}
                    {selectable && (
                      <td className="no-line border-0 align-middle px-5 py-1">
                        <Checkbox
                          className="p-0"
                          checked={Boolean(selected[item.id])}
                          onChange={() => handleChangeSelected(item.id)}
                          aria-label="checkbox"
                        />
                      </td>
                    )}
                    {renderRow(headings, item, ...renderRowProps)}
                    {switchable && (
                      <Switch
                        checked={item.status === "active"}
                        onChange={() => handleSwitch(item)}
                      />
                    )}
                    {editable && renderEditButton(item)}
                    {isCommentList && (
                      <td className="py-1">
                        <Button
                          className="mr-5 bg-light"
                          variant="contained"
                          data-testid="edit"
                          size="small"
                          onClick={() => {
                            setNewComment(true);
                            setItemId((prevState) => ({
                              ...prevState,
                              editMode: true,
                              id: item.id,
                            }));
                          }}
                        >
                          <Edit />
                        </Button>
                      </td>
                    )}
                    {isUserList && (
                      <td className="align-middle py-1">
                        {renderDeleteButton(item)}
                      </td>
                    )}
                    {approvable && (
                      <td className="align-middle py-1">
                        <Button onClick={() => handleApprove(item.id)}>
                          <CheckCircleIcon color="primary" />
                        </Button>
                      </td>
                    )}
                    {certificate && (
                      <td className="py-1">
                        <IconButton
                          onClick={handleDownload(item.id)}
                          size="small"
                        >
                          <CertificateIcon
                            color="primary"
                            variant="contained"
                          />
                        </IconButton>
                      </td>
                    )}
                    {expandable && (
                      <td className="py-1">
                        <ToolTip
                          icon={expandIcon}
                          placement="top"
                          title={expandTitle}
                          handleClick={handleExpand(item.id)}
                        />
                      </td>
                    )}
                  </tr>
                  {extendedRows.includes(id) &&
                    renderExtendedRow &&
                    renderExtendedRow(item)}
                </Fragment>
              ))}
              {footerRow && footerRow(show)}
            </tbody>
          )}
        </table>
      </div>
      {show.length === 0 && loading === false ? (
        <div style={{ textAlign: "center", padding: "100px 0" }}>
          <Empty />
        </div>
      ) : (
        ""
      )}
      {paginationEnabled ? (
        isServerPagination ? (
          <ServerPagination
            handleChangePerPage={handleChangePerPage}
            perPage={pageSize}
            page={serverPage}
            handleChangePage={handleChangePage}
            loading={loading}
            data={show}
            totalRecords={totalRecords}
          />
        ) : (
          <DefaultPagination
            data={data}
            paginationOptions={paginationOptions}
            handleChangePerPage={handleChangePerPage}
            perPage={perPage}
            page={page}
            hideSelect={hideSelect}
            handleChangePage={handleChangePage}
          />
        )
      ) : null}
    </div>
  );
}
