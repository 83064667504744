import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTaxesList, deleteTax } from "./_redux/actions";
import DataGrid from "../../../../ui/components/DataGrid";
import { renderHeader } from "../../../../ui/helpers/helpers";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getAccountsList } from "../../LedgerAccounts/_api";
import { getTaxes, deleteTaxById } from "./_api";
import Can from "../../../../ui/helpers/Can";

const HEADINGS = [
  ["name", "Tax Name"],
  ["rate", "Rate"],
  ["account_id", "G/L Account"],
];

export function TaxesList() {
  const { request } = useFetch();
  const data = useSelector((state) => state.taxes.taxList);

  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;

  const dispatch = useDispatch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [accounts, setAccounts] = useState([]);

  function fetchTaxes() {
    setLoading(true)
    Promise.all([
      request(getAccountsList, TENANT_ID, ORGANISATION_ID),
      request(getTaxes, TENANT_ID),
    ]).then(([accounts, taxes]) => {
      if (!accounts || !fetchTaxes) return;
      setAccounts(accounts);
      dispatch(setTaxesList(taxes));
      setLoading(false);
    });
  }

  useEffect(() => {
    fetchTaxes();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  const handleDeleteTax = (id) => {
    request(deleteTaxById, TENANT_ID, id).then(dispatch(deleteTax(id)));
  };

  function renderRow(headings, item) {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5 py-1">
        {key === "account_id"
          ? (accounts.length &&
              (accounts.filter((account) => account["id"] === item[key])[0] || {})
                .account_name) ||
            ""
          : key === "rate"
          ? `${item[key]}%`
          : item[key]}
      </td>
    ));
  }

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex mb-3">
            <Can
              perform="list:new"
              yes={() => (
                <Link to={{ pathname: "/add-tax", isNew: true }}>
                  <button className="px-10 mr-5 btn btn-primary">Add</button>
                </Link>
              )}
            />
          </div>

          <Can
            perform="list:edit"
            yes={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderRow}
                loading={loading}
                handleDelete={handleDeleteTax}
                selected={selected}
                setSelected={setSelected}
                link="tax"
                selectable
                editable
                isUserList
              />
            )}
            no={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderRow}
                loading={loading}
                handleDelete={handleDeleteTax}
                link="tax"
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
