import {logsTypes} from "./actions";

const initialAuditsState = {
  auditLogs: [],
};

export function auditReducer(state = initialAuditsState, actions) {
  const {type, payload} = actions;

  switch (type) {
    case logsTypes.SET_DATA:
      return {
        ...state,
        auditLogs: payload,
      };
    default:
      return state;
  }
}
