import React, { useState, useEffect } from "react";
import { useFetch } from "../../hooks/fetch.hook";
import LedgerAccountForm from "../../ui/structures/LedgerAccountForm";
import { addAccount } from "../modules/LedgerAccounts/_api";
import { useSelector } from "react-redux";

export function AddNewLedgerAccount() {
  const { request } = useFetch();

  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const DEFAULT_ORGANISATION_ID = user.default_organisation_id;

  const [data, setData] = useState({
    type: "",
    account_name: "",
    account_number: "",
    organisation_id: "",
    account_type: "internal",
  });

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="border-bottom border-light p-7">
            <h4 className="mb-0">Add New Account</h4>
          </div>

          <LedgerAccountForm
            data={data}
            tenant_id={TENANT_ID}
            default_organisation_id={DEFAULT_ORGANISATION_ID}
            onSubmit={addAccount}
            setData={setData}
          />
        </div>
      </div>
    </div>
  );
}
