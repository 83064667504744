import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { getUser } from "./_redux/selectors";
import Can from "../../../ui/helpers/Can";
import UserProfileDetails from "./UserProfileDetails";
import { UserRole } from "./UserRole";
import { useFetch } from "../../../hooks/fetch.hook";
import { getUserById, getAllUsers, resetAccount } from "./_api";
import { ReactComponent as MailIcon } from "./assets/Mail.svg";
import { modifyUserData } from "./helpers";
import { NotificationAlert } from "../../../ui/components/AlertBar";
import { getOrganisationsListSimple } from "../Organisations/_api";

export function Card({ match, history }) {
  const userId = match.params.id;
  const newUser = history.location.newUser;

  const { user } = useSelector((state) => state.auth);

  const { request } = useFetch();
  const [cardData, setCardData] = useState({});
  const [users, setUsers] = useState([]);
  const [resetLoading, setResetLoading] = useState(false);
  const [resetAlert, setResetAlert] = useState(false);
  const [organisationsList, setOrganisationsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleCloseResetAlert = () => setResetAlert(false);

  const data = useSelector(getUser(userId));
  const TENANT_ID = user && user.selectedTenant;

  useEffect(() => {
    request(getAllUsers, TENANT_ID)
      .then((data) => data && data.map(({ user_info }) => user_info.email))
      .then((data) => setUsers(data));
    if (!data && !newUser) {
      request(getUserById, userId)
        .then((data) => data && modifyUserData(data))
        .then((data) => setCardData(data));
    } else setCardData(data);

    request(getOrganisationsListSimple, TENANT_ID).then((data) => {
      if (data) {
        setOrganisationsList((prevList) => [...prevList, ...data]);
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const resetAccountData = () => {
    setResetLoading(true);
    request(resetAccount, userId)
      .then((data) => {
        if (!data) return;
        setResetAlert(true);
      })
      .finally(() => setResetLoading(false));
  };

  return (
    <>
      {resetAlert && (
        <div className="">
          <NotificationAlert
            severity="info"
            message="User's password has been reset! Temporary password has been sent to user's email."
            open={resetAlert}
            handleClose={handleCloseResetAlert}
          />
        </div>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          {cardData && (
            <div className="d-flex justify-content-between align-items-center bg-white rounded px-13 py-6 mb-10">
              <div>
                <div className="font-weight-bold mb-3">
                  {cardData.firstName} {cardData.lastName}
                </div>
                <div>
                  <MailIcon /> {cardData.email}
                </div>
              </div>
              <div>
                {resetLoading ? (
                  <div className="mr-15">
                    <CircularProgress size="1.2rem" />
                  </div>
                ) : (
                  <button
                    className="px-10 mr-5 btn btn-primary"
                    onClick={resetAccountData}
                  >
                    Reset Password
                  </button>
                )}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-between mb-3">
            <UserProfileDetails
              data={cardData}
              setData={setCardData}
              newUser={newUser}
              id={userId}
              users={users}
              organisationsList={organisationsList}
              loading={loading}
            />
            {!newUser && (
              <Can
                perform="card:roles"
                yes={() => <UserRole userId={userId} newUser={newUser} />}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}
