import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getTenant,
  modifyTenant,
  modifyTenantCommentTypes,
  createTenantCommentType,
  modifyTenantTag,
  createTenantTag,
} from "./_api";
import Tabs from "../../../ui/components/Tabs";
import Header from "../../../ui/structures/Header";
import TenantDetailsForm from "../../../ui/structures/TenantDetailsForm";
import TenantSettingsForm from "../../../ui/structures/TenantSettingsForm";
import TenantBrandingForm from "../../../ui/structures/TenantBrandingForm";
import { CommentManagerList } from "../../../ui/structures/CommentManagerList";
import { CommentManagerForm } from "../../../ui/structures/CommentManagerForm";
import { TagsManagerList } from "../../../ui/structures/TagsManagerList";
import { TagsManagerForm } from "../../../ui/structures/TagsManagerForm";
import {
  ContactMail as ContactMailIcon,
  Settings as SettingsIcon,
  Business as BusinessIcon,
  Chat as ChatIcon,
  LocalOffer as LocalOfferIcon,
} from "@material-ui/icons";
import { TenantLateLetters } from "./TenantLateLetters";

export function Card({ match, location, history }) {
  const tenantId = match.params.id;
  const { user } = useSelector((state) => state.auth);
  const data = user.tenants.find(({ id }) => id === +tenantId);
  const { request } = useFetch();
  const [cardData, setCardData] = useState(data);
  const [viewCommentManager, setViewCommentManager] = useState(false);
  const [addCommentManager, setAddCommentManager] = useState(false);
  const [viewTagsManager, setViewTagsManager] = useState(false);
  const [addTagsManager, setAddTagsManager] = useState(false);

  useEffect(() => {
    request(getTenant, tenantId).then((data) => {
      setCardData(data);
    });
    // eslint-disable-next-line
  }, []);

  const handleViewCommentManager = (id) => {
    setViewCommentManager(id);
  };

  const handleUpdateCommentManager = (data) => {
    return request(
      modifyTenantCommentTypes,
      tenantId,
      viewCommentManager.id,
      data
    );
  };

  const handleAddCommentManager = (data) => {
    return request(createTenantCommentType, tenantId, data);
  };

  const handleViewTagsManager = (id) => {
    setViewTagsManager(id);
  };

  const handleUpdateTagsManager = (data) => {
    return request(modifyTenantTag, tenantId, viewTagsManager.id, data);
  };

  const handleAddTagsManager = (data) => {
    return request(createTenantTag, tenantId, { ...data, tenant_id: tenantId });
  };

  const tabs = [
    {
      label: "Contact Details",
      icon: <ContactMailIcon />,
      tabPanel: (
        <TenantDetailsForm
          data={cardData}
          history={history}
          onSubmit={modifyTenant}
          setData={setCardData}
          tenantId={tenantId}
        />
      ),
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      tabPanel: (
        <TenantSettingsForm
          data={cardData}
          history={history}
          onSubmit={modifyTenant}
          setData={setCardData}
          tenantId={tenantId}
        />
      ),
    },
    {
      label: "Branding",
      icon: <BusinessIcon />,
      tabPanel: (
        <TenantBrandingForm
          data={cardData}
          history={history}
          onSubmit={modifyTenant}
          setData={setCardData}
          tenantId={tenantId}
        />
      ),
    },
    {
      label: "Comment Manager",
      icon: <ChatIcon />,
      tabPanel: viewCommentManager ? (
        <CommentManagerForm
          commentManagerData={viewCommentManager}
          tenantId={tenantId}
          onClose={setViewCommentManager}
          setData={setViewCommentManager}
          onSubmit={handleUpdateCommentManager}
        />
      ) : addCommentManager ? (
        <CommentManagerForm
          tenantId={tenantId}
          onClose={setAddCommentManager}
          setData={setAddCommentManager}
          onSubmit={handleAddCommentManager}
          isNewCommentType={addCommentManager}
        />
      ) : (
        <CommentManagerList
          tenantId={tenantId}
          onView={handleViewCommentManager}
          setAddCommentManager={setAddCommentManager}
        />
      ),
    },
    {
      label: "Tags Manager",
      icon: <LocalOfferIcon />,
      tabPanel: viewTagsManager ? (
        <TagsManagerForm
          tagsManagerData={viewTagsManager}
          tenantId={tenantId}
          onClose={setViewTagsManager}
          setData={setViewTagsManager}
          onSubmit={handleUpdateTagsManager}
        />
      ) : addTagsManager ? (
        <TagsManagerForm
          tenantId={tenantId}
          onClose={setAddTagsManager}
          setData={setAddTagsManager}
          onSubmit={handleAddTagsManager}
          isNewTags={addTagsManager}
        />
      ) : (
        <TagsManagerList
          tenantId={tenantId}
          onView={handleViewTagsManager}
          setAddTagsManager={setAddTagsManager}
        />
      ),
    },
    {
      label: "Late Letters",
      icon: <ContactMailIcon />,
      tabPanel: (
        <TenantLateLetters
          data={cardData}
          setData={setCardData}
          tenantId={tenantId}
          user={user}
        />
      ),
    },
  ];

  return (
    <>
      <div className="row mb-9">
        <div className="col-12">
          <Header
            name={data && data.name}
            email={data && data.general_email}
            isTenant
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mb-9">
            <div className="d-flex flex-column rounded h-100">
              <Tabs defaultActive="0" tabs={tabs} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
