import React from "react";
import { NavLink, Link } from "react-router-dom";
import { zonedTimeToUtc } from "date-fns-tz";
import endOfDay from "date-fns/endOfDay";
import { Tooltip } from "@material-ui/core";
import { FiberManualRecord as CircleIcon } from "@material-ui/icons";
import ButtonMailto from "../components/ButtonMailto";
import ToolTip from "../components/Tooltip";

export function getTip(meta = {}, tip) {
  if (meta.touched && meta.error) {
    return <span className="text-danger">{meta.error}</span>;
  }
  if (tip) {
    return <span className="text-muted">{tip}</span>;
  }
}

export function renderHeader(headings) {
  return headings.map(([key, header]) => (
    <th key={key} className="text-dark px-5 text-nowrap">
      {header}
    </th>
  ));
}

export function renderRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {item[key]}
    </td>
  ));
}

export function isLink(key) {
  return ["shortCode", "name", "firstName", "lastName"].includes(key);
}

export function isValidMobile(item) {
  var matches = item["mobile"]?.match(
    new RegExp(
      /^(\+?\(61\)|\(\+?61\)|\+?61|\(0[1-9]\)|0[1-9])?( ?-?[0-9]){7,9}$/gm
    )
  );

  return matches && item.settings_sms ? (
    <>
      {item["mobile"]}{" "}
      <Tooltip title={`SMS enabled`} arrow interactive>
        <CircleIcon
          className="text-success pb-1"
          style={{ fontSize: "1rem", padding: 2 }}
        />
      </Tooltip>
    </>
  ) : (
    <>
      {item["mobile"]}{" "}
      {item["mobile"] && item["mobile"].replace(/\s/g, "").length ? (
        <Tooltip title={`SMS disabled`} arrow interactive>
          <CircleIcon
            className="text-danger pb-1"
            style={{ fontSize: "1rem", padding: 2 }}
          />
        </Tooltip>
      ) : (
        ""
      )}
    </>
  );
}

export function renderContactsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-1 ${
        item.status === "archived" ? "text-danger" : "text-dark"
      }`}
    >
      {isLink(key) ? (
        <Link
          className={`${
            item.status === "archived" ? "text-danger" : "text-dark"
          }`}
          to={`/contacts/contacts-list/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : key === "mobile" && item["mobile"] ? (
        isValidMobile(item)
      ) : key === "email" ? (
        <ButtonMailto
          email={item[key]}
          subject={`Hello ${item["firstName"] && item["firstName"]} ${item[
            "lastName"
          ] && item["lastName"]}`}
          body={`Dear ${item["firstName"] && item["firstName"]} ${item[
            "lastName"
          ] && item["lastName"]},`}
        >
          {item[key]}
        </ButtonMailto>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderUsersRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-1 `}>
      {isLink(key) ? (
        <Link
          className={`${
            item.status === "archived" ? "text-danger" : "text-dark"
          }`}
          to={`/users/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : key === "created_at" && item["created_at"] ? (
        formatDate(new Date(item[key]))
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderTransactionRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {key === "transaction_value"
        ? `$ ${item[key]}`
        : key === "created_at" && item["created_at"]
        ? formatDate(new Date(item[key]))
        : item[key]}
    </td>
  ));
}

export function renderLedgerAccountsRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className="align-middle no-line border-0 px-5 text-capitalize py-1"
    >
      <p
        className={`${
          item.status === "archived" ? "text-danger" : "text-dark"
        } mb-0`}
      >
        {key === "balance" ? (
          <NavLink
            to={{
              pathname: `/ledger-transactions`,
              account_id:
                (item.ledger_account && item.ledger_account.id) || item.id,
            }}
          >
            {formatAmount(item[key])}
          </NavLink>
        ) : (
          item[key]
        )}
      </p>
    </td>
  ));
}

export function renderPaymentCyclesRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className="align-middle no-line border-0 px-5 text-capitalize py-1"
    >
      {key === "term" || key === "length" ? (
        <>
          {item[key]} {" days"}
        </>
      ) : key === "start_date" ? (
        formatDate(new Date(item[key]))
      ) : (
        item[key]
      )}
    </td>
  ));
}

export function renderPaymentCycleRow(headings, item) {
  return headings.map(([key]) => (
    <td
      key={key}
      className="align-middle no-line border-0 px-5 text-capitalize py-1"
    >
      {key === "start_date" || key === "due_date" || key === "finish_date"
        ? formatDate(new Date(item[key]))
        : item[key]}
    </td>
  ));
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

export function formatDate(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    return day + "/" + monthWithNull + "/" + d.getFullYear();
  }
  console.error("Not a valid Date");
}

export function formatDateForBackend(date) {
  const d = new Date(date);
  if (isValidDate(d)) {
    let day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
    let month = d.getMonth() + 1;
    let monthWithNull = month < 10 ? "0" + month : month;
    return d.getFullYear() + "-" + monthWithNull + "-" + day;
  }
  console.error("Not a valid Date");
}

export function formatDateWithoutYear(date, locale = "en-AU") {
  if (isValidDate(date)) {
    return new Intl.DateTimeFormat(locale, {
      day: "numeric",
      month: "numeric",
    }).format(new Date(date));
  }
}

export function formatFileName(name) {
  return name
    .split("-")
    .slice(1)
    .join("-");
}

export function sortByDate(a, b) {
  return new Date(b.last_modified) - new Date(a.last_modified);
}

export const isTitleCaseValue = (key) =>
  [
    "name",
    "legalName",
    "physicalAddress",
    "physicalAddress2",
    "postalAddress",
    "postalAddress2",
    "firstName",
    "lastName",
    "aka",
    "address_1",
    "address_2",
    "legal_name",
  ].includes(key);

export const capitalizeWord = (value, separator = " ") => {
  const words = value.split(separator);
  return words
    .map((word) =>
      word
        .charAt(0)
        .toUpperCase()
        .concat(word.slice(1).toLowerCase())
    )
    .join(" ");
};

export const capitalizeObject = (obj) => {
  let capitalized = {};
  for (var key in obj) {
    capitalized[key] = isTitleCaseValue(key)
      ? capitalizeWord(obj[key])
      : obj[key];
  }
  return capitalized;
};

const isShareDetailsDate = (key) =>
  ["admitted_date", "date_issued", "date_cancelled"].includes(key);

const renderBeneficially = (field) => {
  return field ? "Yes" : "No";
};

export function renderShareDetailsField(data, field) {
  return isShareDetailsDate(field) ? (
    <div className="col-8">
      <span className="text-dark">
        {data[field] && formatDate(new Date(data[field]))}
      </span>
    </div>
  ) : (
    <div className="col-8">
      <span className="text-dark">
        {field === "held_beneficially"
          ? renderBeneficially(data[field])
          : data[field]}
      </span>
    </div>
  );
}

export function renderSharesRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isShareDetailsDate(key)
        ? item[key] && formatDate(new Date(item[key]))
        : item[key]}
    </td>
  ));
}

export function renderOrganisationUsersRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className={`align-middle no-line border-0 px-5 py-1 `}>
      {["name", "email", "phone"].includes(key) ? (
        <Link
          className={`${
            item.status === "archived" ? "text-danger" : "text-dark"
          }`}
          to={`/users/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : key === "created_at" && item["created_at"] ? (
        formatDate(new Date(item[key]))
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const isDate = (key) =>
  [
    "payment_date",
    "due_date",
    "invoice_date",
    "discrepancy_date",
    "date",
    "start_date",
    "end_date",
    "period_start_date",
    "period_end_date",
    "payment_received_date",
    "payment_due_date",
    "create_date",
  ].includes(key);

export const isAmount = (key) =>
  [
    "amount",
    "total",
    "fee",
    "gross_total",
    "buyer_total",
    "biller_total",
    "transaction_value",
  ].includes(key);

export function formatAmount(price) {
  if (typeof price !== "number") return;
  if (price >= 0) {
    return `$ ${price.toFixed(2)}`;
  } else return `$ (${price.toFixed(2).slice(1)})`;
}

export const formatLongRow = (content = "") => {
  if (content === null) return content;
  if (content.length > 11) {
    return (
      <ToolTip
        content={<div>{content.slice(0, 11) + "..."}</div>}
        title={content}
        isTextTooltip
        placement="top"
      />
    );
  } else return content;
};

export const onSearch = (search, data, dispatch, action, headings) => {
  dispatch(
    action(
      data.filter((item) => {
        const _search = Object.entries(search).map((item) => [
          (headings.find((i) => i[1] === item[0]) || [])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return item[k] === v;
          })
        );
      })
    )
  );
};

export const selectSortHelper = function(a, b) {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

export const removeEmptyFields = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "") delete obj[key];
  });
};

export const getSelectedId = (selected) => {
  for (let key in selected) {
    if (selected[key]) {
      return key;
    }
  }
};

export const formatDateToUTC = (date, isEndDate) => {
  if (isValidDate(date)) {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const utcDate = zonedTimeToUtc(date, timeZone);
    const utcEndDate = zonedTimeToUtc(endOfDay(new Date(date)), timeZone);
    return isEndDate ? utcEndDate.toISOString() : utcDate.toISOString();
  }
};

export const handleSearch = (data, searchMap, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) =>
        searchMap[key]
          ? `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`
          : ""
      )
      .join("&")
  );
  setPage && setPage(0);
};

export const objectCheck = (data = []) => {
  return data.every((item) => typeof item !== "object");
};

export function getDifferenceTwoArrayOfObject(array1, array2) {
  return array1.filter((object1) => {
    return !array2.some((object2) => {
      return object1.id === object2.id;
    });
  });
}

export const objectIsEmpty = (obj) => {
  for (var i in obj) return false;
  return true;
};
