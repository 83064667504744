import config from "react-global-configuration";

export const getTenantsList = () =>
  fetch(`${config.get("ApiUrl").Rest}/tenants`, {
    mode: "cors",
    credentials: "include",
  });

export const getUsersTenants = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/tenants`, {
    mode: "cors",
    credentials: "include",
  });

export const getAllUsers = (tenant_id, archived = "") =>
  fetch(
    `${config.get("ApiUrl").Rest}/users${(tenant_id &&
      "?tenant_id=" + tenant_id) ||
      ""}${(archived && "&status=all") || ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getUserById = (user_id, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}${
      tenant_id ? "?tenant_id=" + tenant_id : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteUser = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const modifyUser = (user_data, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user_data),
    mode: "cors",
    credentials: "include",
  });

export const createUser = (user_data) =>
  fetch(`${config.get("ApiUrl").Rest}/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user_data),
    mode: "cors",
    credentials: "include",
  });

export const getInactiveUsers = () =>
  fetch(`${config.get("ApiUrl").Rest}/users?status=inactive`, {
    mode: "cors",
    credentials: "include",
  });

export const getRoleByUserId = (user_id, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/users/${user_id}/roles${tenant_id &&
      "?tenant_id=" + tenant_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getRolesByTenantId = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/roles`, {
    mode: "cors",
    credentials: "include",
  });

export const addRoleToUser = (user_id, tenant_id, role_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/roles`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      tenant_id: tenant_id,
      role_ids: [role_id],
    }),
    mode: "cors",
    credentials: "include",
  });

export const deleteRoleToUser = (user_id, tenant_id, role_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/roles`, {
    method: "DELETE",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      tenant_id: tenant_id,
      role_ids: [role_id],
    }),
    mode: "cors",
    credentials: "include",
  });

export const getPermissionsByTenantId = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/permissions`, {
    mode: "cors",
    credentials: "include",
  });

export const getPermissionsByRoleId = (role_id) =>
  fetch(`${config.get("ApiUrl").Rest}/roles/${role_id}/permissions`, {
    mode: "cors",
    credentials: "include",
  });

export const sendUserEmail = (tenant_id, user_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/email`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user_data),
    mode: "cors",
    credentials: "include",
  });

export const reactivateUser = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/reactivate`, {
    mode: "cors",
    credentials: "include",
  });

export const resetAccount = (user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}/admin-reset-password`, {
    mode: "cors",
    credentials: "include",
  });

export const getLogoUploadUrl = (type, id, fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/users/me/request_signature_upload_url`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ file_name: fileName.name }),
  });

export const modifyCurrentUser = (user_data, user_id) =>
  fetch(`${config.get("ApiUrl").Rest}/users/${user_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user_data),
    mode: "cors",
    credentials: "include",
  });

export const getOrganisationUserList = (tenant_id, organisation_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/organisations/${organisation_id}/users`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
