import { transactionsTypes } from "./actions";

const initialState = {
  transactionsList: [],
};

export function transactionsReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case transactionsTypes.SET_DATA:
      return {
        ...state,
        transactionsList: payload,
      };

    default:
      return state;
  }
}
