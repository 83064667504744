import React from "react";
import { formatAmount, formatDate } from "../../../ui/helpers/helpers";
import { Button } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (key === "Status") {
    return `${searchMap[key]}=${encodeURIComponent(data[key])}`;
  } else if (key === "Pack Sizes") {
    return `pack_sizes.id=_null_`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) => setSearchKey(data, searchMap, key))
      .join("&")
  );
  setPage && setPage(0);
};

export const objectCheck = (data = []) => {
  return data.every((item) => typeof item !== "object");
};

export const getInvoiceTotalValue = (data = []) => {
  const totalValue = Number.parseFloat(
    data.invoice_lines.reduce((acc, value) => {
      const tax_value =
        value.quantity * value.unit_price * (value.tax_rate / 100);
      return acc + (value.quantity * value.unit_price + tax_value);
    }, 0)
  ).toFixed(2);
  return totalValue;
};

export const modifyInvoices = (data) =>
  Object.entries(data).map(([k, v], i) => modifyInvoicesData(k, v));

export const modifyInvoicesData = (key, value = {}) => {
  return {
    ...value,
    payment_status: value?.processed_date ? "paid" : "unpaid",
    // total_value: Number.parseFloat(getInvoiceTotalValue(value)) || 0,
  };
};

export function renderInvoicesRow(headings, item, handleDetails) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {key === "total_value" ? (
        formatAmount(item[key])
      ) : ["invoice_date", "payment_due_date"].includes(key) ? (
        formatDate(new Date(item[key]))
      ) : key === "details" ? (
        <Button onClick={() => handleDetails(item)}>
          <MoreHorizIcon sx={{ fontSize: "1.6rem" }} />
        </Button>
      ) : (
        item[key]
      )}
    </td>
  ));
}
