import config from "react-global-configuration";

export const getTaxes = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/taxes`, {
    mode: "cors",
    credentials: "include",
  });

export const getTaxById = (tenant_id, tax_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/taxes/${tax_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const createTax = (tenant_id, taxData) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/taxes`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(taxData),
    mode: "cors",
    credentials: "include",
  });

export const modifyTax = (tenant_id, tax_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/taxes/${tax_id}`, {
    method: "PUT",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const deleteTaxById = (tenant_id, tax_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/taxes/${tax_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });
