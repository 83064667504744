import config from "react-global-configuration";

export const getDelinquentBuyers = (tenant_id, within_days, start_in_x_days) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/delinquent-buyers?${
      within_days ? "within_days=" + within_days : ""
    }${start_in_x_days ? "&start_in_x_days=" + start_in_x_days : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
