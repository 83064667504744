import React, {forwardRef, useCallback} from "react";
import { useField } from "formik";
import { Input as MuiInput, Icon } from '@material-ui/core';
import {getTip} from "../helpers/helpers";

export const Input = forwardRef(
  function (
    {solid, label, icon, tip, wrapperClassName, labelClassName, inputClassName, inputGroupClassName, ...other},
    ref
  ) {
    return (
      <label className={`${wrapperClassName} mb-0`}>
        {label && <div className={labelClassName}>{label}</div>}
        <div className={`input-group border border-secondary rounded px-0 ${inputGroupClassName}`}>
          {icon &&
          <div className="input-group-prepend border-right border-secondary mr-0">
            <div className="input-group-text border-0 align">
              <Icon>{icon}</Icon>
            </div>
          </div>
          }
          <MuiInput
            className={`form-control form-control-solid bg-white ${inputClassName}`}
            disableUnderline
            inputRef={ref}
            data-testid="input"
            {...other}
          />
        </div>
        {tip && <div>{tip}</div>}
      </label>
    );
  }
);

export function MappedInput(props) {
  const [field, meta] = useField(props.name);

  const getInputClasses = useCallback(
    () => {
      if (meta.touched && meta.error) {
        return "is-invalid";
      }

      if (meta.touched && !meta.error) {
        return "is-valid";
      }

      return "";
    },
    [meta]
  );
  return (
    <Input
      {...field}
      {...props}
      inputClassName={getInputClasses()}
      tip={getTip(meta, props.tip)}
    />
  );
}
