export const getOrganisations = (state) =>
  state.organisations.organisationsList;

export const getOrganisationItems = ({ organisations }) => ({
  files: organisations.files,
  contacts: organisations.contacts,
});

export const getOrganisation = (organisationId) => ({ organisations }) =>
  organisations.organisationsList.find(({ id }) => id === +organisationId);

export const getOrganisationComments = (organisationId) => ({
  organisations,
}) => {
  const organisation = organisations.organisationsList.find(
    ({ id }) => id === +organisationId
  );
  return organisation.comments;
};
