import React from "react";
import { makeStyles } from "@material-ui/core/";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    justifyContent: "flex-start",
    cursor: "pointer",
    textDecoration: "none",
    display: "flex",
    alignItems: "center",
    margin: "20px",
    borderRadius: "12px",
    background: "#eef0f8",
    overflow: "hidden",
    fontSize: "17px",
    transition: "all 1.5s",
    backgroundPosition: "50% 1%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",

    "&:hover": {
      textDecoration: "none",
      backgroundPosition: "50% 11% !important",
      fontSize: "17px",
    },
  },
  title: {
    color: "#fff",
    paddingLeft: 20,
    fontWeight: "600",
  },
  imageContainer: {
    minWidth: 68,
    paddingLeft: 25,
  },
}));

export function StaticPageBox({ item }) {
  const classes = useStyles();
  return (
    <Link
      className={classes.root}
      to={item.to}
      style={{
        backgroundColor: item.backgroundColor,
      }}
    >
      <div className={classes.imageContainer}>
        <img src={toAbsoluteUrl(item.logo)} alt="" />
      </div>
      <div className={classes.title}>{item.title}</div>
    </Link>
  );
}
