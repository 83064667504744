export const shareTypes = {
  SET_DATA: "SHARES/SET_DATA",
}

export function setSharesData(data) {
  return {
    type: shareTypes.SET_DATA,
    payload: data
  }
}
