export const getInvoiceTotalValue = (data = []) => {
  const totalValue = Number.parseFloat(
    data.invoice_lines.reduce((acc, value) => {
      const tax_value =
        value.quantity * value.unit_price * (value.tax_rate / 100);
      return acc + (value.quantity * value.unit_price + tax_value);
    }, 0)
  ).toFixed(2);
  return totalValue;
};

export const getInvoicesValue = (data = []) => {
  const invoiceLines = data
    .map((item) => item.invoice_lines)
    .reduce((acc, value) => [...acc, ...value], [])
    .filter((item) => item.unit_price && item.quantity);
  const totalValue = Number.parseFloat(
    invoiceLines.reduce((acc, value) => {
      const tax_value =
        value.quantity * value.unit_price * (value.tax_rate / 100);
      return acc + (value.quantity * value.unit_price + tax_value);
    }, 0)
  ).toFixed(2);
  return totalValue;
};

export const handleCheckboxChange = (event, invoice, selected, setSelected) => {
  if (event.target.checked) {
    setSelected((prev) => [...prev, invoice]);
  } else {
    setSelected(selected.filter(({ id }) => id !== invoice.id));
  }
};
