import React from "react";
import { Button } from "@material-ui/core";

export const ConfirmCreateAll = ({ handleSubmit, handleClose }) => {
  return (
    <div className="px-10 pt-10 pb-2">
      <h3 className="mb-10">Are you sure you want to create all invoices?</h3>
      <div className="text-right">
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          className="px-9 mr-3"
          onClick={handleClose}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          size="large"
          className="px-9"
          data-testid="submit"
        >
          OK
        </Button>
      </div>
    </div>
  );
};
