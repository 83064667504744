import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import {
  ErrorOutline as ErrorOutlineIcon,
  CheckCircle as CheckCircleIcon,
} from "@material-ui/icons";
import { uploadFile } from "./upload";

const uploadFileStatus = {
  uploaded: "uploaded",
  failed: "failed",
};

function getIcon(fileStatus) {
  const { uploaded, failed } = uploadFileStatus;

  switch (fileStatus) {
    case uploaded:
      return (
        <CheckCircleIcon
          className="text-success"
          fontSize="large"
          style={{ marginLeft: "auto" }}
        />
      );
    case failed:
      return (
        <ErrorOutlineIcon
          className="text-danger"
          fontSize="large"
          style={{ marginLeft: "auto" }}
        />
      );
    default:
      return (
        <CircularProgress
          className="text-warning"
          size="2rem"
          style={{ marginLeft: "auto" }}
        />
      );
  }
}

export default function File({
  file,
  tenant_id,
  setLastFileName,
  updateLastUpload,
  updateLastUploadState,
}) {
  const [fileStatus, setFileStatus] = useState();

  useEffect(() => {
    uploadFile(tenant_id, file, setLastFileName)
      .then(() => {
        setFileStatus(uploadFileStatus.uploaded);
        updateLastUploadState(!updateLastUpload);
      })
      .catch(() => setFileStatus(uploadFileStatus.failed));
    // eslint-disable-next-line
  }, []);

  return (
    <span
      style={{
        height: "100%",
        display: "flex",
        minHeight: "38.4px",
        alignItems: "center",
      }}
    >
      {file.name}&nbsp;{getIcon(fileStatus)}
    </span>
  );
}
