import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { setFeesList, deleteFee } from "./_redux/actions";
import DataGrid from "../../../../ui/components/DataGrid";
import { formatDate, renderHeader } from "../../../../ui/helpers/helpers";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getFees, deleteFeeById, getFeeLogs } from "./_api";
import Can from "../../../../ui/helpers/Can";
import { Modal } from "../../../../ui/components/Modal";
import { Button } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const HEADINGS = [
  ["name", "Fee Name"],
  ["start_date", "Start"],
  ["end_date", "End"],
  ["fee_type", "Trigger"],
  ["category", "Category"],
  ["view_logs", "Logs"],
];

const LOGS_HEADINGS = [
  ["id", "Id"],
  ["log_time", "Log time"],
  ["created_at", "Created"],
  ["fee_amount", "Fee amount"],
];

export function FeesList() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fees.feesList) || [];
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const [modalContent, setModalContent] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  function fetchFees() {
    setLoading(true);
    request(getFees, TENANT_ID).then((response) => {
      if (response) {
        dispatch(setFeesList(response));
      }
      setLoading(false);
    });
  }

  function fetchFeeLogs(fee_id) {
    setModalLoading(true);
    request(getFeeLogs, TENANT_ID, fee_id).then((response) => {
      if (response) {
        setModalContent(response);
      }
      setModalLoading(false);
    });
  }

  useEffect(() => {
    fetchFees();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  const handleDeleteFee = (id) => {
    request(deleteFeeById, TENANT_ID, id).then(
      (data) => data && dispatch(deleteFee(id))
    );
  };

  const isDate = (key) => {
    return ["start_date", "end_date"].includes(key);
  };

  function renderRow(headings, item) {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5 py-1">
        {isDate(key) ? (
          item[key] && formatDate(new Date(item[key]))
        ) : key === "view_logs" ? (
          <Button onClick={() => handleDetails(item)}>
            <MoreHorizIcon sx={{ fontSize: "1.6rem" }} />
          </Button>
        ) : key === "created_at" || key === "log_time" ? (
          formatDate(new Date(item[key]))
        ) : (
          item[key]
        )}
      </td>
    ));
  }

  const handleModalClose = () => setModalOpen(false);

  const handleDetails = (data) => {
    setModalOpen(true);
    fetchFeeLogs(data.id);
  };

  return (
    <div className="row justify-content-center">
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={"md"}
        >
          <DataGrid
            data={modalContent}
            headings={LOGS_HEADINGS}
            renderHeader={renderHeader}
            renderRow={renderRow}
            loading={modalLoading}
          />
        </Modal>
      )}
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex mb-3">
            <Can
              perform="list:new"
              yes={() => (
                <Link
                  to={{
                    pathname: `/add-fee`,
                  }}
                  data-testid="new-fee"
                >
                  <button className="px-10 mr-5 btn btn-primary">New</button>
                </Link>
              )}
            />
          </div>
          <Can
            perform="list:edit"
            yes={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRowProps={[handleDetails]}
                renderRow={renderRow}
                loading={loading}
                handleDelete={handleDeleteFee}
                link="fee"
                isUserList
                editable
              />
            )}
            no={() => (
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRowProps={[handleDetails]}
                renderRow={renderRow}
                loading={loading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
}
