import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { ServerSearchBar } from "../../../../ui/components/ServerSearchBar";
import { AmountDisplay } from "../../../../ui/components/AmountDisplay";
import {
  getInvoicesAutocomplete,
  getInvoicesWithSearch,
} from "../../InvoiceEntry/_api";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import { Button, IconButton } from "@material-ui/core";
import { uniq } from "lodash";
import { formatAmount } from "../../../../ui/helpers/helpers";
import { getInvoiceTotalValue } from "./helper";

export const DepositMatchFormSearchInvoices = ({
  TENANT_ID,
  selectedInvoices,
  setSelectedInvoices,
}) => {
  const { request } = useFetch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);

  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoicesList, setInvoicesList] = useState([]);

  const fetchInvoices = () => {
    setLoading(true);
    request(
      getInvoicesWithSearch,
      TENANT_ID,
      search + "processed_date=_null_&order_by=invoice_date&order_dir=asc",
      page,
      pageSize
    )
      .then(
        (invoices) =>
          invoices &&
          setInvoicesList(
            invoices?.map((invoice) => ({
              ...invoice,
              total_value: getInvoiceTotalValue(invoice),
            }))
          )
      )
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line
  }, [search, page, pageSize]);

  const setSearchKey = (data = {}, searchMap, key) => {
    if (key === "Tag") {
      return `tags.name=${data.Tag}`;
    } else if (searchMap[key]) {
      return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
    } else return "";
  };
  const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
    setSearch(
      Object.keys(data)
        .map((key) => setSearchKey(data, searchMap, key))
        .join("&")
    );
    setPage && setPage(0);
  };
  const SITE_SEARCH_HELPER = [
    ["invoice_number", "Invoice Number"],
    ["invoice_type", "Invoice Type"],
    ["status", "Status"],
  ];
  const siteSearchMap = {
    "Invoice Number": "invoice_number",
    "Invoice Type": "invoice_type",
    Status: "status",
  };
  const objectCheck = (data = []) => {
    return data.every((item) => typeof item !== "object");
  };
  const fetchInvoicesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getInvoicesAutocomplete,
      TENANT_ID,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields + "&processed_date=_null_"
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };
  const resetSearch = () => {
    setSearch("");
  };
  return (
    <>
      <div className="mb-3">
        <ServerSearchBar
          onSearch={(data) =>
            handleSearch(data, siteSearchMap, setSearch, setPage)
          }
          keyMap={SITE_SEARCH_HELPER}
          loading={autocompleteLoading}
          currentSearchList={autocompleteData}
          fetchAutocompleteFunction={fetchInvoicesAutocomplete}
          placeholder="Search Invoices..."
          clearSearch={resetSearch}
          focused={focused}
          setFocused={setFocused}
          searchMap={siteSearchMap}
          searchFields={search}
        />
      </div>

      {invoicesList.map((invoice) => (
        <div
          key={invoice.id}
          className="row align-items-center"
          style={{
            margin: "0",
          }}
        >
          <>
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div className="col-1">{invoice.invoice_number}</div>
              <div
                className="col-3"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {invoice.buyer_organisation_name}
              </div>
              <div
                className="col-3"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {invoice.biller_organisation_name}
              </div>
              <div style={{ margin: "0 30px 0 auto" }}>
                <AmountDisplay
                  amount={invoice.invoice_total}
                  amount_sub={invoice.invoice_payment_due}
                  variant="red"
                  inverse_diplay
                  inverse_progress
                />
              </div>
              <div>
                <Button
                  onClick={() =>
                    setSelectedInvoices((prev) => {
                      if (prev.findIndex(({ id }) => id === invoice.id) === -1)
                        return [...prev, invoice];
                      return prev;
                    })
                  }
                  disabled={selectedInvoices.find(
                    ({ id }) => id === invoice.id
                  )}
                >
                  Select Invoice
                </Button>
              </div>
            </div>
          </>
        </div>
      ))}
      {page === 0 && invoicesList < pageSize ? (
        ""
      ) : (
        <div className="flex row justify-content-between align-items-center mx-0 mt-1 px-2">
          <p className="text-muted">{`Showing ${pageSize * page}-${pageSize *
            page +
            pageSize}`}</p>
          <div>
            <IconButton
              color="secondary"
              aria-label="previous"
              onClick={() => setPage(page - 1)}
              disabled={!page}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <IconButton
              color="secondary"
              aria-label="next"
              onClick={() => setPage(page + 1)}
              disabled={invoicesList.length < pageSize}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
