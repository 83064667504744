import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";
import DataGrid from "../../../ui/components/DataGrid";
import { useDispatch, useSelector } from "react-redux";
import {
  renderHeader,
  renderLedgerAccountsRow,
} from "../../../ui/helpers/helpers";
import {
  getAccountsList,
  getArchivedAccountsList,
  deleteAccount,
} from "./_api";
import {
  archiveAccount,
  setAccountsList,
  updateAccountsList,
} from "./_redux/actions";
import { getAccounts, getShowArchived } from "./_redux/selectors";
import { useFetch } from "../../../hooks/fetch.hook";
import ToolTip from "../../../ui/components/Tooltip";
import Can from "../../../ui/helpers/Can";

const HEADINGS = [
  ["number", "Number"],
  ["name", "Name"],
  ["ledger_type", "Type"],
  ["balance", "Balance"],
];

export function List() {
  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const data = useSelector(getAccounts) || [];
  const archived = useSelector(getShowArchived);
  const { request } = useFetch();

  function fetchLedgerAccounts() {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID)
      .then((data) => {
        if (data) {
          const newData = data.map((item) => ({
            name: item.account_name,
            number: item.account_number,
            balance: (item.ledger_account && item.ledger_account.balance) || 0,
            ...item,
          }));
          dispatch(setAccountsList(newData));
          setLoading(false);
        }
      })
      .then(() => {
        if (archived)
          request(
            getArchivedAccountsList,
            TENANT_ID,
            ORGANISATION_ID
          ).then((data) => dispatch(updateAccountsList(data)));
      });
  }

  useEffect(() => {
    ORGANISATION_ID && fetchLedgerAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ORGANISATION_ID]);

  // const arrayOfSelectedIds = useMemo(
  //   () => Object.keys(selected).filter((item) => Boolean(selected[item])),
  //   [selected]
  // );

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this contact? This process is not reversible"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        deleteAccount(TENANT_ID, ORGANISATION_ID, itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            dispatch(archiveAccount(itemID));
            fetchLedgerAccounts();
          }
        });
      } catch {}
    }
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Ledger Accounts</h3>
            <div>
              <ToolTip
                icon={<PrintIcon fontSize="large" />}
                title="Print Selected Account Data in PDF"
                disabled
              />
            </div>
          </div>
          {Number.isInteger(ORGANISATION_ID) ? (
            <div>
              <Can
                perform="list:new"
                yes={() => (
                  <div className="mb-5">
                    <Link
                      to={"/ledger-accounts/add-account"}
                      data-testid="new-account"
                    >
                      <button className="px-15 mr-5 btn btn-primary">
                        New
                      </button>
                    </Link>
                    <button
                      className="px-15 mr-5 btn btn-primary"
                      onClick={handleArchive}
                      disabled={archiveDisabled}
                      data-testid="archive"
                    >
                      Archive
                    </button>
                  </div>
                )}
              />
              <Can
                perform="list:edit"
                yes={() => (
                  <DataGrid
                    data={data}
                    headings={HEADINGS}
                    renderHeader={renderHeader}
                    renderRow={renderLedgerAccountsRow}
                    editable
                    selectable
                    selected={selected}
                    setSelected={setSelected}
                    link="ledger-accounts"
                    loading={loading}                   
                  />
                )}
                no={() => (
                  <DataGrid
                    data={data}
                    headings={HEADINGS}
                    renderHeader={renderHeader}
                    renderRow={renderLedgerAccountsRow}
                    link="ledger-accounts"
                    loading={loading}
                  />
                )}
              />
            </div>
          ) : (
            <div className="text-center">
              <h6>Opps, missing default organization for this tenant</h6>
              <Link to={"/tenants-list/" + user.selectedTenant}>
                <button className="px-15 mr-5 btn btn-primary">
                  Click here to setup
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
