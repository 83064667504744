const initialState = {
  feesList: [
    // {
    //   name: "Tesst Fee #1",
    //   start: "12/11/2020",
    //   end: "25/12/2021",
    //   trigger: "Distribution",
    //   category: "Distribution",
    //   id: 1
    // },
    // {
    //   name: "Tesst Fee #2",
    //   start: "12/12/2020",
    //   end: "04/05/2021",
    //   trigger: "Statement Issue",
    //   category: "Statement",
    //   id: 2
    // },
  ],
};

export const feesReducer = (state = initialState, action) => {
  if (typeof action.execute === "function" && action.type === "fees")
    return action.execute(state);
  return state;
};
