import React from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { MappedInput } from "../../../ui/components/Input";
import { ReasonForDelayedPaymentSchema } from "../../../helpers/schemas";
import { MappedSwitch } from "../../../ui/components/Switch";

export const EditNote = ({ handleSubmit, handleClose, data = {} }) => {
  const { note, requires_payment_monitoring } = data;

  const initialValues = {
    note: note || "",
    requires_payment_monitoring: requires_payment_monitoring || false,
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={ReasonForDelayedPaymentSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <div className="py-10">
          <h4 className="mb-10 text-center">
            <strong>Reason for Delayed Payment</strong>
          </h4>
          <div className="row mt-15 mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted mb-0">Reason</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput name="note" wrapperClassName="w-100" data-testid="note" />
            </div>
          </div>

          <div className="row mt-5 mb-15">
            <div className="col-2 my-auto">
              <p className="text-right text-muted mb-0">Monitor Future Payments</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedSwitch
                name="requires_payment_monitoring"
                checked={values.requires_payment_monitoring}
                data-testid="requires_payment_monitoring"
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disabled={isSubmitting || data.status === "processed"}
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Process
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
