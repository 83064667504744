export const invoicesTypes = {
  SET_DATA: "Invoices/SET_DATA",
};

export function setInvoicesData(data) {
  return {
    type: invoicesTypes.SET_DATA,
    payload: data,
  };
}
