import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInvoicesData } from "../Invoices/_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import { getInvoicesWithSearch } from "../InvoiceEntry/_api";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../../ui/helpers/helpers";
import { ReportsForm } from "../Invoices/ReportsForm";
import { modifyInvoices, renderInvoicesRow } from "../Invoices/helpers";
import { HEADINGS } from "../Invoices/constants";
import { Modal } from "../../../ui/components/Modal";
import { InvoiceDetails } from "../Invoices/InvoiceDetails";

export function List() {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const [invoices, setInvoices] = useState([]);
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const data = useSelector(({ invoices }) => invoices.invoicesList) || [];

  function fetchInvoices(count_only = false) {
    setLoading(true);
    request(
      getInvoicesWithSearch,
      TENANT_ID,
      search,
      page,
      pageSize,
      count_only,
      ORGANISATION_ID
    )
      .then((invoices) => {
        if (invoices) {
          if (count_only) {
            setTotalRecords(invoices);
          } else {
            const modifiedInvoices = modifyInvoices(invoices);

            dispatch(setInvoicesData(modifiedInvoices));
            setInvoices(modifiedInvoices);
          }
        }
      })
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    clearData();
    fetchInvoices();
    fetchInvoices(true);
    // eslint-disable-next-line
  }, [TENANT_ID, search, page, pageSize]);

  const resetSearch = () => {
    dispatch(setInvoicesData(invoices));
  };

  const clearData = () => {
    dispatch(setInvoicesData([]));
    setInvoices([]);
    setTotalRecords(0);
  };
  const handleModalClose = () => setModalOpen(false);

  const handleDetails = (data) => {
    setModalContent(data);
    setModalOpen(true);
  };
  return (
    <div className="row justify-content-center">
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={"md"}
          color={"#F59220"}
        >
          <InvoiceDetails
            data={modalContent}
            handleModalClose={handleModalClose}
          />
        </Modal>
      )}
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Invoices</h3>
          </div>
          <ReportsForm
            resetSearch={resetSearch}
            setSearch={setSearch}
            setPage={setPage}
            search={search}
            TENANT_ID={TENANT_ID}
            pageSize={pageSize}
            loading={loading}
          />

          <DataGrid
            data={data}
            headings={HEADINGS}
            renderHeader={renderHeader}
            renderRow={renderInvoicesRow}
            renderRowProps={[handleDetails]}
            loading={loading}
            perPageDefault={pageSize}
            setPageSize={setPageSize}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
}
