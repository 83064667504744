import React, { useState, useEffect } from "react";
import DescriptionIcon from "@material-ui/icons/Description";
import DataGrid from "../../../../ui/components/DataGrid";
import { renderHeader, renderRow } from "../../../../ui/helpers/helpers";
import { Modal } from "../../../../ui/components/Modal";
import { NewLateLetter } from "./LateLetterDetails";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  createLateLetterTemplate,
  deleteLateLetterTemplate,
  getLateLetterTemplates,
  modifyLateLetterTemplate,
  printLateLetter,
} from "./_api";
import { Loader } from "../../../../ui/components/Loader";
import { downloadFile } from "../../AWS-S3";

const HEADINGS = [["name", "Letter Name"]];

export function TenantLateLetters({ tenantId, user = {} }) {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [modalHeader, setModalHeader] = useState("New Late Letter");
  const [componentId, setComponentId] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [actionLoading, setActionLoading] = useState(false)

  const handleModalClose = () => setModalOpen(false);

  const handlePreview = (id) => () => {
    setActionLoading(true)
    downloadFile([id], printLateLetter, tenantId, setActionLoading);
  };

  useEffect(() => {
    setLoading(true);
    request(getLateLetterTemplates, tenantId)
      .then((data) => data && setData(data))
      .finally(() => setLoading(false));
    // eslint-disable-next-line
  }, []);

  const handleAddLatter = (values, { setSubmitting }) => {
    const payload = {
      content: {
        values: {
          signature_name: user.fullname,
        },
        body_template: values.content,
      },
      name: values.name,
    };
    request(createLateLetterTemplate, tenantId, payload)
      .then((data) => {
        if (!data) return;
        setData((state) => [...state, data]);
      })
      .finally(() => {
        setSubmitting(false);
        handleModalClose();
      });
  };

  const handleEditLetter = (values, { setSubmitting }) => {
    const editedItem = data.find(({ id }) => id === componentId);
    if (!editedItem) return;
    const payload = {
      ...editedItem,
      content: {
        values: {
          signature_name: user.fullname,
        },
        body_template: values.content,
      },
      name: values.name,
    };
    request(modifyLateLetterTemplate, tenantId, payload, componentId)
      .then((res) => {
        if (!res) return;
        const index = data.findIndex(({ id }) => id === res.id);
        setData((state) => [...state.slice(0, index), res, ...state.slice(index + 1)]);
      })
      .finally(() => {
        setSubmitting(false);
        handleModalClose();
      });
  };

  const handleDelete = (id) => {
    setLoading(true);
    request(deleteLateLetterTemplate, tenantId, id)
      .then((res) => res && setData((state) => data.filter((item) => item.id !== id)))
      .finally(() => setLoading(false));
  };

  const handleNew = () => {
    setComponentId("");
    setModalHeader("New Late Letter");
    setModalOpen(true);
  };

  const handleEdit = (id) => {
    setComponentId(id);
    setModalHeader("Edit Late Letter");
    setModalOpen(true);
  };
  return (
    <>
      {actionLoading && (
        <Loader
          isOpen={actionLoading}
          onClose={() => setActionLoading(false)}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={handleModalClose} fullWidth={true} maxWidth="md">
          <NewLateLetter
            handleAdd={handleAddLatter}
            handleEdit={handleEditLetter}
            handleClose={handleModalClose}
            header={modalHeader}
            data={data.find(({ id }) => id === componentId)}
          />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <h3 className="text-dark">Late Letters</h3>
              <div>
                <button className="px-15 mr-5 btn btn-primary" onClick={handleNew}>
                  New
                </button>
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderRow}
              selected={selected}
              setSelected={setSelected}
              selectable
              expandable
              editable
              isUserList
              handleDelete={handleDelete}
              handleExpand={handlePreview}
              expandIcon={<DescriptionIcon color="primary" variant="contained" />}
              expandTitle="Preview"
              editIsLink={false}
              handleEdit={handleEdit}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
