import React from "react";
import { formatDate } from "../../../ui/helpers/helpers";
import { Button } from "@material-ui/core";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import { AmountDisplay } from "../../../ui/components/AmountDisplay";

export function renderDepositRow(headings, item, handleMatch) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {key === "deposit_date" ? (
        item[key] && formatDate(new Date(item[key]))
      ) : key === "deposit_value" ? (
        <AmountDisplay
          amount={item[key]}
          amount_sub={item["deposit_payment_due"]}
          inverse_diplay
          variant="red"
        />
      ) : key === "match" ? (
        <Button onClick={() => handleMatch(item.id)}>
          <CompareArrowsIcon color="primary" sx={{ fontSize: "1.6rem" }} />
        </Button>
      ) : (
        item[key]
      )}
    </td>
  ));
}

export const modifyDeposits = (data = []) =>
  data.map((deposit) => modifyDepositData(deposit));

export const modifyDepositData = (deposit = {}) => {
  return {
    ...deposit,
    deposit_date_str: formatDate(new Date(deposit.deposit_date)),
  };
};
