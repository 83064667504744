import React, {useCallback, useState} from "react";
import {useDropzone} from 'react-dropzone';
import {useField} from "formik";

export function InputFile({
  title = "Drop files here or click to upload.",
  description = "Upload up to 10 files",
  maxFilesQuantity = 10,
  accept,
  onDrop,
  children,
  useable,
  ...other
}) {
  const [message, setMessage] = useState("");

  const handleDrop = useCallback(acceptedFiles => {
    onDrop(acceptedFiles);

    if (acceptedFiles.length > maxFilesQuantity) {
      setMessage("You can upload only 10 files or less at a time");
    }

  }, [onDrop, maxFilesQuantity]);

  const {getRootProps, getInputProps} = useDropzone({onDrop: handleDrop, accept})

  return (
    <div className="w-100 cursor-pointer">
      <div {...getRootProps({
        className: `form-control d-flex flex-column align-items-center h-100 bg-${useable ? "secondary" : "white"} py-5 px-10`
      })}>
        <input
          {...getInputProps()}
          {...other}
        />
        <p className="h5">{title}</p>
        <p className={`font-size-lg text-${message ? 'warning' : 'muted'}`}>{message || description}</p>
        <ul>
          {children}
        </ul>
      </div>
    </div>
  );
}

export function MappedInputFile(props) {
  const [field] = useField(props.name);

  return (
    <InputFile
      {...field}
      {...props}
    />
  )
}
