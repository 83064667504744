import config from "react-global-configuration";

export const getPaymentCyclesList = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/payment-cycles`, {
    mode: "cors",
    credentials: "include",
  });

export const addPaymentCycle = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/payment-cycles`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });

export const getPaymentCycleElementsById = (tenant_id, payment_cycle_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/elements`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getPaymentCycleElementById = (
  tenant_id,
  payment_cycle_id,
  element_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/elements/${element_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const addPaymentCycleElement = (
  tenant_id,
  payment_cycle_id,
  payment_cycle_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/elements`,
    {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payment_cycle_data),
    }
  );

export const modifyPaymentCycleElement = (
  tenant_id,
  payment_cycle_id,
  payment_cycle_data
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/elements/${
      payment_cycle_data.payment_cycle_element_id
    }`,
    {
      method: "PUT",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payment_cycle_data),
    }
  );

export const deletePaymentCycle = (tenant_id, payment_cycle_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const deletePaymentCycleElement = (
  tenant_id,
  payment_cycle_id,
  element_id
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/elements/${element_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

  export const extendPaymentCycle = (tenant_id, payment_cycle_id, data) =>
    fetch(
      `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/payment-cycles/${payment_cycle_id}/extend`,
      {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      }
    );
