/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import {
  IndeterminateCheckBox as IndeterminateCheckBoxIcon,
  AddBoxOutlined as AddBoxOutlinedIcon,
} from "@material-ui/icons";
import { makeStyles, Checkbox, Button } from "@material-ui/core";
import { getDepositById, createDepositMatch } from "../_api";
import { formatAmount, formatDate } from "../../../../ui/helpers/helpers";
import { DepositMatchFormSearchInvoices } from "./DepositMatchFormSearchInvoices";
import { handleCheckboxChange, getInvoicesValue } from "./helper";
import { light } from "../../../../helpers/toasts";
import { revertDeposit } from "../_api";
import { AmountDisplay } from "../../../../ui/components/AmountDisplay";

const useStyles = makeStyles({
  button: {
    "&:hover": {
      backgroundColor: "rgb(40, 167, 69) !important",
      color: "#ffffff !important",
    },
  },
});

export function DepositMatchForm({
  deposit_id = "",
  TENANT_ID,
  fetchDepositsData,
  handleModalClose,
}) {
  const classes = useStyles();
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [billings, setBillings] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [deposit, setDeposit] = useState({});

  function fetchMatchedBankById() {
    setLoading(true);
    request(getDepositById, TENANT_ID, deposit_id).then((data) => {
      if (data) {
        setDeposit(data);
        if (Object.keys(data.suggested_billings).length !== 0) {
          setBillings(data.suggested_billings);
        }
      }
      setLoading(false);
    });
  }

  const handleRevert = (transaction_id) => {
    request(revertDeposit, TENANT_ID, transaction_id).then((response) => {
      if (response) {
        fetchDepositsData();
      }
    });
  };

  const createMatchByTransactionId = (type, transaction_data = {}) => {
    let newData = {};
    setSubmitting(true);
    if (type === "invoices") {
      newData = {
        action: "invoices",
        invoice_ids: selectedInvoices.map(({ id }) => id),
      };
    } else if (type === "billing") {
      newData = {
        action: "billing",
        billing_id: transaction_data.billing_id,
        buyer_id: transaction_data.buyer_id,
      };
    }
    request(createDepositMatch, TENANT_ID, deposit_id, newData)
      .then((response) => {
        if (response) {
          light(
            <Button onClick={() => handleRevert(response.id)}>
              Revert Transaction
            </Button>
          );
          fetchDepositsData();
        }
      })
      .finally(() => setSubmitting(false));
    handleModalClose();
  };

  useEffect(() => {
    fetchMatchedBankById();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h3 className="text-center mb-10">Match Deposit</h3>
      <div
        className="my-10 d-flex"
        style={{
          padding: "15px 12px",
          borderRadius: 7,
          border: "1px solid #f59220",
        }}
      >
        <div className="mr-10">
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>
              Deposit Date :
            </span>
            {formatDate(deposit?.deposit_date)}
          </div>
          <div>
            <span style={{ paddingRight: 12, fontWeight: 600 }}>
              Deposit Type :
            </span>
            {deposit?.deposit_type}
          </div>
        </div>
        <div className="mr-10">
          <span style={{ paddingRight: 12, fontWeight: 600 }}>
            Deposit Value :
          </span>
          <AmountDisplay
            amount={deposit?.deposit_value}
            amount_sub={deposit?.deposit_payment_due}
            inverse_diplay
            variant="red"
          />
        </div>
        <div>
          {deposit?.bank_name ? (
            <div>
              <span style={{ paddingRight: 12, fontWeight: 600 }}>
                Bank Name :
              </span>
              {deposit?.bank_name}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>

      <div className="mb-2" style={{ fontWeight: 600 }}>
        Find invoices
      </div>
      <DepositMatchFormSearchInvoices
        TENANT_ID={TENANT_ID}
        selectedInvoices={selectedInvoices}
        setSelectedInvoices={setSelectedInvoices}
      />
      <div
        className="mt-5"
        style={{
          color: "#28a745",
        }}
      >
        {selectedInvoices.length ? (
          <>
            <div
              className="row align-items-center"
              style={{
                borderBottom: `1px solid rgb(40, 167, 69)`,
                fontWeight: 500,
                margin: 0,
              }}
            >
              <div className="col-12 d-flex justify-content-between align-items-center">
                <p className="m-0">Selected invoices</p>
                <div className="row">
                  <p className="m-0 pr-5">
                    {formatAmount(
                      Number.parseFloat(
                        selectedInvoices.reduce(function(acc, obj) {
                          return acc + obj.invoice_payment_due;
                        }, 0)
                      )
                    )}
                  </p>
                  <button
                    className={`btn btn-outline-primary btn-sm ${classes.button}`}
                    style={{
                      padding: "0 0.5rem",
                      fontSize: 11,
                      color: "rgb(40, 167, 69)",
                      borderColor: "rgb(40, 167, 69)",
                      marginBottom: 4,
                    }}
                    onClick={() => createMatchByTransactionId("invoices")}
                    type="submit"
                    disabled={submitting}
                  >
                    Match Selected Invoices
                  </button>
                </div>
              </div>
            </div>
            {selectedInvoices?.map((invoice) => (
              <div
                key={invoice.id}
                className="row align-items-center"
                style={{
                  // color: "#28a745",
                  margin: "0",
                }}
              >
                <>
                  <div className="col-12 d-flex justify-content-between align-items-center">
                    <div className="col-1">{invoice.invoice_number}</div>
                    <div
                      className="col-3"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {invoice.buyer_organisation_name}
                    </div>
                    <div
                      className="col-3"
                      style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {invoice.biller_organisation_name}
                    </div>
                    <div style={{ margin: "0 30px 0 auto" }}>
                      <AmountDisplay
                        amount={invoice.invoice_total}
                        amount_sub={invoice.invoice_payment_due}
                        inverse_diplay
                        inverse_progress
                      />
                    </div>
                    <div>
                      <Checkbox
                        className="p-1"
                        icon={<AddBoxOutlinedIcon />}
                        checkedIcon={<IndeterminateCheckBoxIcon />}
                        checked={
                          selectedInvoices.findIndex(
                            ({ id }) => id === invoice.id
                          ) !== -1
                        }
                        onChange={(e) =>
                          handleCheckboxChange(
                            e,
                            invoice,
                            selectedInvoices,
                            setSelectedInvoices
                          )
                        }
                      />
                    </div>
                  </div>
                </>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        {billings.length ? (
          <>
            <p
              className="col-12"
              style={{
                borderBottom: `1px solid rgb(40, 167, 69)`,
                fontWeight: 500,
                margin: "12px 0 0",
              }}
            >
              Matched billings
            </p>
            {billings.map((billing) => (
              <div
                key={billing.billing_id}
                className="row align-items-center"
                style={{
                  // color: "#28a745",
                  margin: "0",
                }}
              >
                <>
                  <div className="col-12 d-flex justify-content-between align-items-center py-1">
                    <div>
                      {billing.billing_data?.organisation?.shortCode}{" "}
                      {billing.billing_data?.organisation?.name}
                    </div>
                    <div
                      style={{ fontSize: 14, fontWeight: 500, minWidth: 100 }}
                    >
                      $ {billing.billing_data.total}
                    </div>
                    <div>
                      <button
                        className={`btn btn-outline-primary btn-sm ${classes.button}`}
                        style={{
                          padding: "0 0.5rem",
                          fontSize: 11,
                          color: "rgb(40, 167, 69)",
                          borderColor: "rgb(40, 167, 69)",
                        }}
                        onClick={() =>
                          createMatchByTransactionId("billing", billing)
                        }
                        type="submit"
                        disabled={submitting}
                      >
                        Match
                      </button>
                    </div>
                  </div>
                </>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
