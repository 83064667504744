import React from "react";
import {
  formatAmount,
  formatDate,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";
import config from "react-global-configuration";
import { downloadReport } from "../Reports/_api";
import { downloadFile } from "../AWS-S3";
import { Button } from "@material-ui/core";

export const renderExtendedRow = (item = {}) => {
  const { buyer_name, amount, discrepancy = 0 } = item;
  return (
    <>
      <tr className="border-bottom" style={{ background: "#f5f6fa" }}>
        <td
          className="py-3"
          colSpan={5}
        >{`Transaction - Buyer: ${buyer_name}.  Total: ${formatAmount(
          amount - discrepancy
        )}`}</td>
      </tr>

      <tr className="border-bottom" style={{ background: "#f5f6fa" }}>
        <td
          className="py-3"
          colSpan={5}
        >{`Discrepancy - Buyer: ${buyer_name}. Total: ${formatAmount(
          discrepancy
        )}`}</td>
      </tr>
    </>
  );
};

export const modifyBilling = (item = {}) => {
  return {
    ...item,
    amount: Object.values(item.content)
      .map((value) => value.total)
      .reduce((acc, value) => acc + value, 0),
  };
};

export const modifyBillings = (data = []) => {
  return data.map((item) => modifyBilling(item));
};

export const setInititalReviewData = (data = {}) => {
  return Object.keys(data.content).map((key) => ({
    buyer_name: data.content[key].organisation.name,
    amount: data.content[key].total,
    buyer_total:
      Number(data.content[key].total.toFixed(2)) -
      Number(data.content[key].fee.toFixed(2)),
    fee: Number(data.content[key].fee.toFixed(2)),
    direct_debit_enabled: data.content[key].direct_debit_enabled,
    id: key,
  }));
};

const renderABAFileButton = (item) => {
  const url = `${config.get("ApiUrl").Rest}/tenants/${
    item.tenant_id
  }/billings/${item.id}/download_aba`;
  return (
    <form target="_blank" action={url} method="post">
      <Button
        variant="contained"
        color="secondary"
        className="px-9"
        type="submit"
      >
        ABA File
      </Button>
    </form>
  );
};

const renderBuyerReportButton = (item) => {
  const downloadBuyerReport = () => {
    const data = {
      report_name: "billing_summary",
      id: item.id,
    };
    downloadFile(data, downloadReport, item.tenant_id);
  };
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className="px-5"
      onClick={downloadBuyerReport}
    >
      Report
    </Button>
  );
};

export function renderBillingRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isDate(key) ? (
        item[key] && formatDate(new Date(item[key]))
      ) : isAmount(key) ? (
        formatAmount(item[key])
      ) : key === "buyer_name" ? (
        <div>
          {item[key]}{" "}
          {item.direct_debit_enabled ? (
            <div
              className="ml-2"
              style={{
                display: "inline-block",
                background: "#3783e7",
                color: "#fff",
                // color: "#5E6973",
                // background: "#EEF0F8",
                padding: "1px 5px",
                borderRadius: "0.22rem",
                fontSize: 10,
              }}
            >
              Direct Debit
            </div>
          ) : (
            ""
          )}
        </div>
      ) : key === "aba_file" ? (
        renderABAFileButton(item)
      ) : key === "buyer_report" ? (
        renderBuyerReportButton(item)
      ) : (
        item[key]
      )}
    </td>
  ));
}
