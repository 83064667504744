import config from "react-global-configuration";

export const getLateLetters = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letters`, {
    mode: "cors",
    credentials: "include",
  });
export const createLateLetter = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letters`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyLateLetter = (tenant_id, late_letter_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letters/${late_letter_id}`, {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const deleteLateLetter = (tenant_id, late_letter_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letters/${late_letter_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getLateLetterTemplates = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letter_templates`, {
    mode: "cors",
    credentials: "include",
  });
export const createLateLetterTemplate = (tenant_id, data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letter_templates`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
    mode: "cors",
    credentials: "include",
  });

export const modifyLateLetterTemplate = (tenant_id, data, late_letter_template_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/late_letter_templates/${late_letter_template_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteLateLetterTemplate = (tenant_id, late_letter_template_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/late_letter_templates/${late_letter_template_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const generateLateLetter = (tenant_id, late_letter_id, data) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/late_letters/${late_letter_id}/generate`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
      mode: "cors",
      credentials: "include",
    }
  );

export const printLateLetter = (late_letter_template_id, tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/late_letter_templates/${late_letter_template_id}/preview`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({  }),
      mode: "cors",
      credentials: "include",
    }
  );
