import React from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { ErrorOutline as ErrorOutlineIcon } from "@material-ui/icons";
import { Add as AddIcon, Check as CheckIcon } from "@material-ui/icons";
import { Button, Grow } from "@material-ui/core";
import { InvoiceHeaderRow } from "./InvoiceRow/InvoiceHeaderRow";
import { InvoiceItemRow } from "./InvoiceRow/InvoiceItemRow";
import { setID } from "../helpers";

const INVOICEHEADINGS = [
  ["invoice_number", "Invoice No."],
  ["buyer_id", "Buyer Brand"],
  ["invoice_date", "Invoice Date"],
  ["invoice_type", "Invoice Type"],
  ["note", "Note"],
];
const INVOICEITEMHEADINGS = [
  ["line_number", "Line"],
  ["description", "Description / Item Name"],
  ["quantity", "Quantity"],
  ["unit_price", "Unit Price"],
  ["total_value", "Line Value"],
  ["tax_id", "Tax"],
  ["comment", "Comment"],
  ["reference", "Reference"],
  ["remove", "Remove"],
];

const EMPTYINVOICEITEM = {
  line_number: "",
  reference: "",
  comment: "",
  description: "",
  quantity: "",
  unit_price: "",
  total_value: "",
  tax_value: "",
  tax_id: "",
};

export function InvoiceEntryModul({
  invoice = {},
  taxList,
  taxListOptions,
  organisationsOptions,
  setData,
  invoices = [],
  handleRemoveInvoice,
  postInvoice,
  failedInvoices = [],
  invoiceLoading,
}) {
  const handleDataUpdate = (payload = {}) => {
    const index = invoices.findIndex(
      (item) => (item || {}).id === (invoice || {}).id
    );
    if (index === -1) return;
    setData((state) => [
      ...state.slice(0, index),
      payload,
      ...state.slice(index + 1),
    ]);
  };

  const handleAddNewItem = () => {
    const payload = {
      ...invoice,
      invoice_lines: [
        ...invoice.invoice_lines,
        { ...EMPTYINVOICEITEM, id: setID(10000000, invoice.invoice_lines) },
      ],
    };
    handleDataUpdate(payload);
  };

  const handleRemoveItem = (id) => () => {
    const payload = {
      ...invoice,
      invoice_lines: (invoice.invoice_lines || []).filter(
        (line) => (line || {}).id !== id
      ),
    };
    handleDataUpdate(payload);
  };

  return (
    <>
      <div className="col-12 pt-8">
        {/* <NotificationAlert
          open={alertOpen}
          handleClose={() => setAlertOpen(false)}
          message="Invoice has been created!"
        /> */}
        <div className="bg-white rounded">
          {invoice ? (
            <>
              <div className="text-right mr-5 pt-2 cursor-pointer">
                {
                  <ClearIcon
                    size="large"
                    color="secondary"
                    onClick={() => handleRemoveInvoice(invoice.id)}
                  />
                }
              </div>
              <div className="overflow-auto flex-grow-1 px-8">
                <table
                  className="table"
                  style={{ minWidth: "700px", margin: "16px 0 6px" }}
                >
                  <thead
                    style={{
                      borderBottom: "2px solid rgba(0,0,0,.1)",
                    }}
                  >
                    <InvoiceHeaderRow
                      item={invoice}
                      handleDataUpdate={handleDataUpdate}
                      organisationsOptions={organisationsOptions}
                      headings={INVOICEHEADINGS}
                    />
                  </thead>
                  <tbody>
                    <tr>
                      {INVOICEITEMHEADINGS?.map(([key, header]) => (
                        <th
                          key={key}
                          className="text-dark p-1 text-nowrap pt-4 pb-1"
                        >
                          {
                            <span
                              className={`${header === "Tax" ? "ml-3" : ""}`}
                            >
                              {header}
                            </span>
                          }
                        </th>
                      ))}
                    </tr>
                    {invoice?.invoice_lines?.map((item = {}, index) => (
                      <InvoiceItemRow
                        key={item.id}
                        handleDataUpdate={handleDataUpdate}
                        item={item}
                        headings={INVOICEITEMHEADINGS}
                        taxList={taxList}
                        taxListOptions={taxListOptions}
                        handleRemoveItem={handleRemoveItem}
                        handleAddNewItem={handleAddNewItem}
                        index={index}
                        length={(invoice.invoice_lines || []).length}
                        invoice={invoice}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
              {invoice.creation_status !== "created" && (
                <div className="d-flex justify-content-end align-items-center pb-2 px-8">
                  <Button
                    color="secondary"
                    size="large"
                    className="px-9 mr-auto w-100"
                    tabIndex="-1"
                    startIcon={<AddIcon />}
                    onClick={handleAddNewItem}
                  >
                    Add New Item
                  </Button>
                </div>
              )}

              <div className="d-flex justify-content-between align-items-center pb-5 px-8">
                {failedInvoices.includes(invoice.id) ? (
                  <span className="text-danger">
                    <ErrorOutlineIcon
                      className="text-danger mr-3"
                      fontSize="large"
                    />
                    Failed
                  </span>
                ) : (
                  <span></span>
                )}
                <Button
                  onClick={() => postInvoice(invoice)}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  disabled={
                    invoice.creation_status === "created" || invoiceLoading
                  }
                >
                  {invoice.creation_status === "created" ? (
                    <>
                      <Grow
                        in={invoice.creation_status === "created"}
                        style={{ transformOrigin: "0 0 0" }}
                        {...(invoice.creation_status === "created"
                          ? { timeout: 1000 }
                          : {})}
                      >
                        <CheckIcon className="text-success mr-1" />
                      </Grow>
                      Created
                    </>
                  ) : (
                    "Create Invoice"
                  )}
                </Button>
              </div>
            </>
          ) : (
            ""
          )}
        </div>
      </div>
    </>
  );
}
