import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../../ui/components/DataGrid";
import {
  renderHeader,
  renderTransactionRow,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { setTransactionsData } from "./_redux/actions";
import { ReportsForm } from "./ReportsForm";
import {
  getLedgerTransactions,
  getLedgerAccountTransactions,
} from "../Transactions/_api";
import { getAccountsList } from "../LedgerAccounts/_api";
import AlertBar from "../../../ui/components/AlertBar";
import { DisputeTransaction } from "./DisputeTransaction";
import { Modal } from "../../../ui/components/Modal";
import { formatDate } from "../../../ui/helpers/helpers";

const HEADINGS = [
  ["formatted_date", "Date"],
  ["account_name", "Biller Account"],
  ["other_account_name", "Buyer Account"],
  ["transaction_id", "Transaction ID"],
  ["transaction_value", "Amount"],
  ["transaction_note", "Description"],
  ["transaction_type", "Type"],
];

export function List({ match, location }) {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;
  const userCategory = user.userCategory;

  const [transactions, setTransactions] = useState([]);
  const [account, setAccount] = useState(0);
  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);
  const [accountsAllList, setAccountsAllList] = useState([]);
  const [searchDates, setSearchDates] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");

  const handleModalClose = () => setModalOpen(false);

  const data =
    useSelector(({ transactions }) => transactions.transactionsList) || [];

  function fetchLedgerTransactions(account_id, from_date, to_date) {
    setLoading(true);
    // console.log(TENANT_ID, ORGANISATION_ID, account_id, accountsList);
    if (account_id) {
      // console.log("in fetch if account_id:", account_id);
      request(
        getLedgerAccountTransactions,
        TENANT_ID,
        ORGANISATION_ID,
        account_id,
        (from_date && from_date) || undefined,
        (to_date && to_date) || undefined
      )
        .then(
          (transactions) =>
            transactions &&
            transactions.map(
              ({
                transaction_value,
                account,
                other_account,
                created_at,
                ...item
              }) => {
                return {
                  ...item,
                  transaction_value: "" + transaction_value,
                  account_name: account?.organisation?.name
                    ? account?.organisation?.name
                    : "",
                  other_account_name: other_account?.organisation?.name
                    ? other_account?.organisation?.name
                    : "",
                  formatted_date: formatDate(new Date(created_at)),
                };
              }
            )
        )
        .then((data) => {
          if (data) {
            dispatch(setTransactionsData(data));
            setTransactions(data);
          }
        })
        .finally(() => setLoading(false));
    } else {
      // console.log("in fetch else account_id:", account_id);

      request(
        getLedgerTransactions,
        TENANT_ID,
        ORGANISATION_ID,
        (account_id && account_id) || 0,
        (from_date && from_date) || undefined,
        (to_date && to_date) || undefined
      )
        .then(
          (transactions) =>
            transactions &&
            transactions.map(
              ({
                transaction_value,
                account,
                other_account,
                created_at,
                ...item
              }) => {
                return {
                  ...item,
                  transaction_value: "" + transaction_value,
                  account_name: account?.organisation?.name
                    ? account?.organisation?.name
                    : "",
                  other_account_name: other_account?.organisation?.name
                    ? other_account?.organisation?.name
                    : "",
                  formatted_date: formatDate(new Date(created_at)),
                };
              }
            )
        )
        .then((data) => {
          if (data) {
            dispatch(setTransactionsData(data));
            setTransactions(data);
          }
        })
        .finally(() => setLoading(false));
    }

    // console.log("before fetch accountsist:", accountsList.length);
    if (ORGANISATION_ID && accountsList.length === 1) {
      // console.log("in fetch accountslist:", accountsList);
      request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((data) => {
        if (data) {
          data
            .filter(({ status }) => status === "active")
            .forEach((item) => {
              setAccountsList((prevList) => [
                ...prevList,
                { ...item, value: item.id, label: item.account_name },
              ]);
            });
        }

        setLoading(false);
      });
    }
  }

  useEffect(() => {
    // console.log("useeffect Tenantid update");
    clearData();
    if (location.account_id) {
      setAccount(location.account_id);
      let date = new Date();
      setSearchDates({
        from: new Date(date.getFullYear(), date.getMonth(), 1),
        to: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      });
    }
    fetchLedgerTransactions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (account) {
      // console.log("useeffect account update");
      dispatch(setTransactionsData([]));
      setTransactions([]);
      setLoading(true);
      fetchLedgerTransactions(
        account,
        searchDates.from && searchDates.from.toISOString(),
        searchDates.to && searchDates.to.toISOString()
      );
    } else if (accountsList.length > 1 && account === 0) {
      setSearchDates({});
      setAccount(0);
      fetchLedgerTransactions();
    }
    // eslint-disable-next-line
  }, [account, setAccount]);

  useEffect(() => {
    if (searchDates.from || searchDates.to) {
      // console.log("useeffect searchDates update");
      fetchLedgerTransactions(
        account,
        searchDates.from && searchDates.from.toISOString(),
        searchDates.to && searchDates.to.toISOString()
      );
    }
    // eslint-disable-next-line
  }, [searchDates, setSearchDates]);

  const KEY_MAP = {
    "Transaction ID": data.map((item) => item.transaction_id),
    Amount: data.map((item) => item.transaction_value),
    Type: data.map((item) => item.transaction_type),
    Date: data.map((item) => item.formatted_date),
    Description: data.map((item) => item.transaction_note),
    "Biller Account": data.map((item) => item.account_name),
    "Buyer Account": data.map((item) => item.other_account_name),
  };

  const handleSearch = (search) => {
    dispatch(
      setTransactionsData(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            HEADINGS.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  const resetSearch = () => {
    dispatch(setTransactionsData(transactions));
  };

  const clearData = () => {
    dispatch(setTransactionsData([]));
    setTransactions([]);
    setAccountsList([{ value: 0, label: "Select Account" }]);
    setAccount(0);
    setSearchDates({});
  };

  const handleSubmitDispute = (values, { setSubmitting }) => {
    console.log(values);
    setSubmitting(false);
    handleModalClose();
  };

  const handleOpenDispute = (id) => () => {
    setModalOpen(true);
    setComponentId(id);
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="md"
        >
          <DisputeTransaction
            handleSubmit={handleSubmitDispute}
            handleClose={handleModalClose}
          />
        </Modal>
      )}
      <AlertBar message={"This page is under development!"} />
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Ledger Transactions</h3>
            </div>
            <ReportsForm
              searchDates={searchDates}
              setSearchDates={setSearchDates}
              accountsList={accountsList}
              setAccountsList={setAccountsList}
              account={account}
              setAccount={setAccount}
              handleSearch={handleSearch}
              resetSearch={resetSearch}
              KEY_MAP={KEY_MAP}
            />

            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderTransactionRow}
              loading={loading}
              expandable={userCategory === "Buyer"}
              handleExpand={handleOpenDispute}
              expandTitle="Dispute Transaction"
            />
          </div>
        </div>
      </div>
    </>
  );
}
