import React, { useState } from "react";
import PaymentCycleElementForm from "../../ui/structures/PaymentCycleElementForm";
import { addPaymentCycleElement } from "../modules/PaymentCycles/_api";
import { useSelector } from "react-redux";

export function AddNewPaymentCycleElement({ match }) {
  const paymentCyclesId = match.params.id;
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [data, setData] = useState({
    payment_cycles_id: paymentCyclesId,
    start_date: "",
    due_date: "",
    finish_date: "",
    note: "",
  });

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="border-bottom border-light p-7">
            <h4 className="mb-0">Add Payment Cycle</h4>
          </div>

          <PaymentCycleElementForm
            data={data}
            onSubmit={addPaymentCycleElement}
            setData={setData}
            tenantId={TENANT_ID}
            createNew={true}
          />
        </div>
      </div>
    </div>
  );
}
