import React from "react";
import { Link } from "react-router-dom";
import { TableSortLabel, Tooltip } from "@material-ui/core";
import { isLink, isValidMobile } from "../../../ui/helpers/helpers";
import ButtonMailto from "../../../ui/components/ButtonMailto";

export function renderHeaderWithSorting(
  headings,
  field,
  setField,
  direction,
  setDirection
) {
  return headings.map(([key, header]) => (
    <th
      key={key}
      className="text-dark px-5 text-nowrap"
      onClick={() => {
        setDirection(
          key !== field ? "desc" : direction === "desc" ? "asc" : "desc"
        );
        setField(key);
      }}
    >
      <span style={{ cursor: "pointer" }}>{header}</span>
      {key === field && <TableSortLabel active direction={direction} />}
    </th>
  ));
}

export function renderOrganisationRow(headings, item, organisationCycleTags) {
  return headings.map(([key]) => (
    <td
      key={key}
      className={`align-middle no-line border-0 px-5 py-1 ${
        item.status === "archived" ? "text-danger" : "text-dark"
      }`}
    >
      {isLink(key) ? (
        <Link
          className={`${
            item.status === "archived" ? "text-danger" : "text-dark"
          }`}
          to={`/contacts/organisations-list/${item.id}`}
        >
          {item[key]}
        </Link>
      ) : key === "cycle_tags" ? (
        <>
          {item[key] &&
            item[key].map((cycleTag) => (
              <Tooltip
                key={item.id}
                title={
                  (organisationCycleTags &&
                    organisationCycleTags.length &&
                    organisationCycleTags.filter(
                      (tag) => tag.name === cycleTag
                    )[0].description) ||
                  ""
                }
                arrow
              >
                <div>{cycleTag}</div>
              </Tooltip>
            ))}
        </>
      ) : key === "mobile" ? (
        isValidMobile(item)
      ) : key === "email" ? (
        <ButtonMailto
          className={`${
            item.status === "archived" ? "text-danger" : "text-dark"
          }`}
          email={item[key]}
          subject={`Hello ${item["name"] && item["name"]}`}
          body={`Dear ${item["legalName"] && item["legalName"]},`}
        >
          {item[key]}
        </ButtonMailto>
      ) : (
        item[key]
      )}
    </td>
  ));
}
