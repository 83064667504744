import React, { useState, useMemo } from "react";
import { Button } from "@material-ui/core";
import DataGrid from "../../../ui/components/DataGrid";
import { formatAmount, renderHeader } from "../../../ui/helpers/helpers";
import { ScrollableContainer } from "../DistributionsBOH/helpers";
import {
  renderExtendedRow,
  setInititalReviewData,
  renderBillingRow,
} from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { updateBilling } from "./_api";
import { Loader } from "../../../ui/components/Loader";

const HEADINGS = [
  ["buyer_name", "Buyer"],
  ["amount", "Total"],
];

export function Review({
  reviewedItem = {},
  handleNextStep,
  handlePrevStep,
  tenantId,
  setReviewedItem,
}) {
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const data = setInititalReviewData(reviewedItem);

  const renderTotal = (data = []) => {
    const total = data
      .map(({ amount }) => amount)
      .reduce((acc, value) => acc + value, 0);
    return (
      <div className="text-right mr-39 border-bottom border-top py-5">
        <strong>Total : </strong> {formatAmount(total)}
      </div>
    );
  };

  const selectedItems = Object.keys(selected).filter((key) => selected[key]);

  const submitEnabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 0,
    [selected]
  );

  const handleSubmit = () => {
    setLoading(true);
    request(updateBilling, tenantId, reviewedItem.id, {
      buyer_ids: selectedItems.map((id) => id),
    })
      .then((data) => {
        if (!data) return;
        setReviewedItem(data);
        handleNextStep();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {loading && (
        <Loader
          isOpen={loading}
          onClose={() => setLoading(false)}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ScrollableContainer>
              <h3 className="text-dark mb-5">Billing</h3>
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderBillingRow}
                selected={selected}
                setSelected={setSelected}
                selectable
                extendable
                renderExtendedRow={renderExtendedRow}
                hideSelect={true}
                paginationEnabled={false}
              />
            </ScrollableContainer>
            {renderTotal(data)}
            <div className="d-flex justify-content-end align-items-center mt-10">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handlePrevStep}
              >
                Prev
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                disabled={!submitEnabled}
              >
                Next
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
