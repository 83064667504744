export const securityTypeOptions = [
  { value: "Bank Guarantee", label: "Bank Guarantee" },
  {
    value: "Cash Advance Against Purchases",
    label: "Cash Advance Against Purchases",
  },
  {
    value: "Personal Guarantee",
    label: "Personal Guarantee",
  },
  {
    value: "Set-off Agreement",
    label: "Set-off Agreement",
  },
  {
    value: "Company guarantee",
    label: "Company guarantee",
  },
  {
    value: "PPSR register",
    label: "PPSR register",
  },
];
