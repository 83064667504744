export const getContacts = (state) => state.contacts.contactsList;

export const getContactItems = ({ contacts }) => ({
  files: contacts.files,
});

export const getContact = (contactId) => ({ contacts }) =>
  contacts.contactsList.find(({ id }) => id === contactId);

export const getShowArchived = (state) => state.contacts.showArchived;
