import { receiptingLogicTypes } from "./actions";

const initialState = {
  receiptingLogicList: [],
};

export function receiptingLogicReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case receiptingLogicTypes.SET_DATA:
      return {
        ...state,
        receiptingLogicList: payload,
      };

    case receiptingLogicTypes.ARCHIVE_DATA:
      return {
        ...state,
        receiptingLogicList: [...state.receiptingLogicList.filter(({ id }) => id !== payload)],
      };

    case receiptingLogicTypes.UPDATE_DATA:
      const index = state.receiptingLogicList.findIndex((item) => item.id === payload.id);
      const myState = [...state.receiptingLogicList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        receiptingLogicList: [...myState],
      };

    default:
      return state;
  }
}
