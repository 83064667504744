import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SearchBar } from "../../../../ui/structures/SearchBar";
import { MatchItemList } from "./MatchItemModul/MatchItemList";

export function ReconciliationModul({
  data = [],
  setData,
  organisationsList,
  accountsList,
  taxesList,
  fetchBankStatementData,
  selectedAccount,
}) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [cardData, setCardData] = useState(data);

  const KEY_MAP = {
    Status: cardData.map((item) => item.status),
    Amount: cardData.map((item) => item.transaction_value),
    Description: cardData.map((item) => item.transaction_description),
    "Transaction Type": cardData.map((item) => item.transaction_type),
  };

  useEffect(() => {
    setCardData(data);
    // eslint-disable-next-line
  }, [data]);

  const resetSearch = () => {
    setCardData(data);
  };

  const SEARCH_DATA = [
    ["status", "Status"],
    ["transaction_value", "Amount"],
    ["transaction_description", "Description"],
    ["transaction_type", "Transaction Type"],
  ];

  const handleSearch = (search) => {
    setCardData(
      data.filter((item) => {
        const _search = Object.entries(search).map((item) => [
          SEARCH_DATA.find((i) => i[1] === item[0])[0],
          item[1],
        ]);
        return (
          _search.length === 0 ||
          _search.every(([k, v]) => {
            if (Array.isArray(item[k])) {
              return item[k].includes(v);
            }
            return String(item[k]) === v;
          })
        );
      })
    );
  };

  return (
    <>
      <div className="row justify-content-center bg-white">
        <div className="col-12">
          <div className=" rounded py-2 px-10">
            <div className="row">
              <div className="col-12">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  keyMap={KEY_MAP}
                  placeholder="Search by tags..."
                  clearSearch={resetSearch}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <MatchItemList
        data={cardData}
        organisationsList={organisationsList}
        TENANT_ID={TENANT_ID}
        accountsList={accountsList}
        taxesList={taxesList}
        title="Unmatched Transactions"
        fetchBankStatementData={fetchBankStatementData}
        selectedAccount={selectedAccount}
      />
    </>
  );
}
