import React from "react";
import {
  formatAmount,
  formatDate,
  formatLongRow,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";
import { Link } from "react-router-dom";

export function hasLongContentRow(key) {
  return ["notes"].includes(key);
}

export const isAcceptAdjusted = (key) => key === "accept_adjusted";

const renderAcceptAdjustIcon = (item) => {
  return item.accept_adjusted && "Accept Adjusted";
};

export function renderDiscrepancyRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {key === "invoice_date" ? (
        formatDate(new Date(item.invoice[key]))
      ) : isDate(key) ? (
        item[key] && formatDate(new Date(item[key]))
      ) : isAmount(key) ? (
        formatAmount(item[key])
      ) : hasLongContentRow(key) ? (
        formatLongRow(item[key])
      ) : isAcceptAdjusted(key) ? (
        renderAcceptAdjustIcon(item)
      ) : key === "biller_name" ? (
        <Link to={`/contacts/organisations-list/${item.biller_id}`}>
          {item[key]}
        </Link>
      ) : key === "buyer_name" ? (
        <Link to={`/contacts/organisations-list/${item.buyer_id}`}>
          {item[key]}
        </Link>
      ) : (
        item[key]
      )}
    </td>
  ));
}
