/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import {toAbsoluteUrl} from "../../../_helpers";

const REPORTS = [
  {
    title: 'Report 1',
    link: '#'
  },
  {
    title: 'Report 2',
    link: '#'
  },
  {
    title: 'Report 3',
    link: '#'
  },
  {
    title: 'Report 4',
    link: '#'
  },
  {
    title: 'Report 5',
    link: '#'
  },
];

export function RecentReportsWidget({ className }) {
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            RECENTLY USED REPORTS
          </h3>
        </div>

        {/* Body */}
        <div className="card-body pt-2">
          {REPORTS.slice(0, 5).map((report, index) => (
            <div key={index} className="d-flex align-items-center mb-10">
              <a
                key={index}
                href={report.link}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center bg-light-warning btn w-100 py-3 px-6"
              >
                <div className="symbol symbol-20">
                  <span className="symbol-label bg-transparent">
                    <img
                      src={toAbsoluteUrl("/assets/icons/report.svg")}
                      alt="report"
                      className="h-100"
                    />
                  </span>
                </div>
                <span className="text-warning font-weight-bold ml-3">
                  {report.title}
                </span>
              </a>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
