import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setStatementsData } from "./_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../..//ui/helpers/helpers";
import { renderStatementsRow } from "./helpers";
import { getOrganisationBillings } from "../Billing/_api";

const HEADINGS = [
  ["start_date", "Start Date"],
  ["end_date", "End Date"],
  ["total", "Total"],
  ["print", "Print"],
];

export function List() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const data = useSelector(
    (state) => state.creditServiceStatements.statementsList
  );
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);

  function fetchStatements() {
    dispatch(setStatementsData([]));
    setLoading(true);
    request(getOrganisationBillings, TENANT_ID, ORGANISATION_ID)
      .then((data) => {
        if (data && Object.keys(data).length === 0) return;

        dispatch(setStatementsData(data));
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchStatements();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <h3 className="text-dark mb-5">Credit Service Statements</h3>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderStatementsRow}
              renderRowProps={[TENANT_ID, ORGANISATION_ID]}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
            />
          </div>
        </div>
      </div>
    </>
  );
}
