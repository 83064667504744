import React from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { MappedTextArea } from "../../../../ui/components/TextArea";
import { MappedInput } from "../../../../ui/components/Input";
import { lateLetterTemplateSchema } from "../../../../helpers/schemas";

export const NewLateLetter = ({
  handleAdd,
  handleEdit,
  handleClose,
  header,
  data = {},
}) => {
  const initialValues = {
    name: data.name || "",
    content: (data.content || {}).body_template || "",
  };

  return (
    <Formik
      onSubmit={data.id ? handleEdit : handleAdd}
      initialValues={initialValues}
      validationSchema={lateLetterTemplateSchema}
    >
      {({ handleSubmit, isSubmitting }) => (
        <div className="py-3">
          <h4 className="mb-10 text-center">
            <strong>{header}</strong>
          </h4>

          <div className="row mb-5">
            <div className="col-12 mr-auto">
              <MappedInput
                wrapperClassName="w-100"
                name="name"
                data-testid="name"
                placeholder="Name"
              />
            </div>
          </div>

          <div className="row mb-15">
            <div className="col-12 mr-auto">
              <MappedTextArea
                name="content"
                rows={12}
                data-testid="content"
                placeholder="Type here..."
              />
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Save
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
