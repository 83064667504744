import React from "react";
import { Chip, makeStyles } from "@material-ui/core";
import { ReactComponent as PLusIcon } from "./static/plus.svg";
import { ReactComponent as MinusIcon } from "./static/minus.svg";
import { ReactComponent as DevideIcon } from "./static/devide.svg";
import { ReactComponent as MultiplyIcon } from "./static/multiply.svg";
import { ReactComponent as OpenBracket } from "./static/openBracket.svg";
import { ReactComponent as CloseBracket } from "./static/closeBracket.svg";
import { ReactComponent as CloseBracketGrey } from "./static/closeBracketGrey.svg";
import { ReactComponent as OpenBracketGrey } from "./static/openBracketGrey.svg";
import { calculationOperations } from "./constants";

const useStyles = makeStyles({
  root: {
    backgroundColor: "#06AEE3",
    cursor: "pointer",
    color: "white",
    fontSize: "1rem",
    height: 24,
  },
});

const pointerEvents = { pointerEvents: "none" };

const firstOperations = [
  {
    name: "+",
    icon: <PLusIcon style={pointerEvents} />,
  },
  {
    name: "/",
    icon: <DevideIcon style={pointerEvents} />,
  },
  {
    name: "{",
    icon: <OpenBracket style={pointerEvents} className="mr-2" />,
    subIcon: <CloseBracketGrey style={pointerEvents} />,
  },
];

const secondOperations = [
  {
    name: "-",
    icon: <MinusIcon style={pointerEvents} />,
  },
  {
    name: "*",
    icon: <MultiplyIcon style={pointerEvents} />,
  },
  {
    name: "}",
    icon: <OpenBracketGrey style={pointerEvents} className="mr-2" />,
    subIcon: <CloseBracket style={pointerEvents} />,
  },
];

export const CalculationPanel = ({
  setCalculation,
  setValue,
  fieldValue,
  getChipValue,
}) => {
  const classes = useStyles();

  return (
    <div
      className="d-flex mb-5 pl-10 pt-3"
      onClick={setCalculation(setValue, fieldValue)}
    >
      <div>
        <div>
          {firstOperations.map(({ name, icon, subIcon }) => (
            <button value={name} key={name} className="btn">
              {icon}
              {subIcon}
            </button>
          ))}
        </div>
        <div>
          {secondOperations.map(({ name, icon, subIcon }) => (
            <button value={name} key={name} className="btn">
              {icon}
              {subIcon}
            </button>
          ))}
        </div>
      </div>
      <div className="ml-5">
        {calculationOperations.map((item) => (
          <button
            value={item}
            key={item}
            className="btn"
            style={{ padding: "6px 0 0", marginRight: 15 }}
          >
            <Chip label={item} classes={classes} style={pointerEvents} />
          </button>
        ))}
      </div>
    </div>
  );
};
