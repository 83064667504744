import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { archiveRule, setReceiptingLogicsData } from "./_redux/actions";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../..//ui/helpers/helpers";
import { modifyRules, renderReceiptingLogicRow } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { deleteRule, getRules, modifyRule } from "../Receipting/_api";
import { FilterBar } from "../../../ui/structures/FilterBar";
import { Loader } from "../../../ui/components/Loader";
import { Modal } from "../../../ui/components/Modal";

import { NewRuleForm } from "../Receipting/ReconciliationModul/MatchItemModul/ReconciliationDialogForms/NewRuleForm";
import { getOrganisationsListSimple } from "../Organisations/_api";
import { updateReceiptingLogicData } from "./_redux/actions";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
} from "@material-ui/core";

const HEADINGS = [
  ["name", "Rule Name"],
  ["field_name", "Field Name"],
  ["operator", "Operator"],
  ["field_value", "Field Value"],
  ["action", "Action"],
  ["edit", "Edit"],
];

export function List() {
  const data = useSelector(
    (state) => state.receiptingLogic.receiptingLogicList
  );
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const { request } = useFetch();

  const dispatch = useDispatch();

  const [organisationsList, setOrganisationsList] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [resetKey, setResetKey] = useState(false);
  const [archivedLoading, setArchivedLoading] = useState(false);
  const [archived, setArchived] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleModalOpen = (data) => {
    setModalContent(data);
    setModalOpen(true);
  };
  const handleModalClose = () => {
    fetchRules();
    setModalOpen(false);
  };

  function fetchRules(values = {}, actions) {
    setLoading(true);
    request(() =>
      values
        ? getRules(
            TENANT_ID,
            archived,
            values.field_name,
            values.field_value,
            values.operator,
            values.name
          )
        : getRules(TENANT_ID, archived)
    )
      .then((data) => data && dispatch(setReceiptingLogicsData(data)))
      .finally(() => {
        setLoading(false);
        setArchivedLoading(false);
        actions && actions.setSubmitting(false);
      });
  }

  useEffect(() => {
    fetchRules();

    request(getOrganisationsListSimple, TENANT_ID).then(
      (data) => data && setOrganisationsList(data)
    );
    // eslint-disable-next-line
  }, [archived]);

  const showArchived = () => {
    setArchivedLoading(true);
    setArchived(!archived);
    setResetKey(Date.now());
  };

  const filterValues = {
    name: data
      .map(({ name }) => ({
        value: name || "",
        label: name || "",
      }))
      .filter((item) => item.value),
    field_name: data.map(({ field_name }) => ({
      value: field_name,
      label: field_name,
    })),
    field_value: data.map(({ field_value }) => ({
      value: field_value,
      label: field_value,
    })),
    operator: data.map(({ operator }) => ({
      value: operator,
      label: operator,
    })),
  };

  const multipleValueFields = ["name", "field_name", "field_value", "operator"];

  const initialValues = {
    field_name: [],
    field_value: [],
    operator: [],
    name: [],
  };

  const onReset = (handleReset) => () => {
    handleReset({ values: initialValues });
    setResetKey(new Date(Date.now()));
    fetchRules();
  };

  const handleLoaderClose = (event, reason) => {
    if (reason === "backdropClick" || reason === "escapeKeyDown") return;
    setLoading(false);
  };

  const handleSwitch = (item = {}) => {
    setLoading(true);
    if (item.status === "active") {
      request(deleteRule, item.id, TENANT_ID)
        .then((data) => data && dispatch(archiveRule(item.id)))
        .finally(() => setLoading(false));
    } else {
      request(modifyRule, { status: "active" }, item.id, TENANT_ID)
        .then((data) => data && dispatch(updateReceiptingLogicData(data)))
        .finally(() => setLoading(false));
    }
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth="lg"
        >
          <NewRuleForm
            organisationsList={organisationsList}
            onClose={handleModalClose}
            rule_data={modalContent}
          />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div>
            <FilterBar
              filterValues={filterValues}
              multipleValueFields={multipleValueFields}
              onReset={onReset}
              title="Filter Rules"
              key={resetKey}
              onSubmit={fetchRules}
              initialValues={initialValues}
            />
          </div>
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex mb-5 justify-content-between align-items-center">
              <h3 className="text-dark">Receipting Logic</h3>
              <div className="d-flex">
                <FormControlLabel
                  control={
                    archivedLoading ? (
                      <CircularProgress size="1.2rem" style={{ margin: 11 }} />
                    ) : (
                      <Checkbox
                        checked={archived}
                        onChange={() => showArchived()}
                      />
                    )
                  }
                  label="Display Archived Records"
                />

                <button
                  className="px-5 ml-5 btn btn-primary"
                  onClick={handleModalOpen}
                >
                  Create New Rule
                </button>
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderReceiptingLogicRow}
              renderRowProps={[handleModalOpen]}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              switchable
              handleSwitch={handleSwitch}
            />
          </div>
        </div>
      </div>
    </>
  );
}
