import React, { useState, useCallback, useEffect } from "react";
import { Paper, Chip, TextField, Tooltip } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { getTagsList } from "../../../app/modules/Organisations/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import Can from "../../helpers/Can";

export function Tags({
  data = {},
  setData,
  tagsList = [],
  onTagDelete,
  onTagAdd,
  type,
  className = "",
}) {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [tag, setTag] = useState("");
  const [predefinedTags, setPredefinedTags] = useState([]);

  useEffect(() => {
    request(getTagsList, type, TENANT_ID).then(
      (data) => data && setPredefinedTags(data)
    );
    // eslint-disable-next-line
  }, []);

  const findDuplicate = (value) => {
    return tagsList && tagsList.find((tag) => tag === value);
  };

  const isPredefinedTag = (value) => {
    return predefinedTags.find((tag) => tag.name === value);
  };

  const handleSubmit = useCallback(
    (event) => {
      event.preventDefault();
      if (tag && !findDuplicate(tag) && isPredefinedTag(tag)) {
        if (onTagAdd) onTagAdd(tag);
        if (!data.tags) {
          setData((prevState) => ({
            ...prevState,
            tags: [tag],
          }));
        } else {
          setData((prevState) => ({
            ...prevState,
            tags: [...prevState.tags, tag],
          }));
        }
        setTag("");
      }
    },
    // eslint-disable-next-line
    [tag, onTagAdd]
  );

  const handleChange = useCallback(
    (event, value) => {
      setTag(value);
    },
    [setTag]
  );

  return (
    <div
      className={`d-flex flex-column justify-content-between p-8 h-100 ${className}`}
    >
      <Paper
        variant="outlined"
        elevation={0}
        component="ul"
        className="d-flex flex-wrap list-unstyled p-8"
        data-testid="tagsList"
      >
        {tagsList && tagsList.length ? (
          tagsList.map((tag) => (
            <li key={tag}>
              <Tooltip
                title={
                  (predefinedTags &&
                    predefinedTags.length &&
                    (
                      predefinedTags.filter(
                        (predefinedTag) => predefinedTag.name === tag
                      )[0] || {}
                    ).description) ||
                  ""
                }
                arrow
              >
                <Can
                  perform="card:tags"
                  yes={() => (
                    <Chip
                      label={tag}
                      aria-label={tag}
                      onDelete={() => onTagDelete(tag)}
                      className="mr-4 mb-4"
                      disabled={data && data.status === "archived"}
                    />
                  )}
                  no={() => (
                    <Chip
                      label={tag}
                      aria-label={tag}
                      deletable={false}
                      className="mr-4 mb-4"
                      disabled={data && data.status === "archived"}
                    />
                  )}
                />
              </Tooltip>
            </li>
          ))
        ) : (
          <p className="text-center">No tags</p>
        )}
      </Paper>

      <Can
        perform="card:tags"
        yes={() => (
          <>
            {data && data.status !== "archived" && (
              <form
                className="d-flex flex-column align-items-end"
                onSubmit={handleSubmit}
              >
                <label className="d-flex flex-column w-100">
                  <span className="h4 mb-2">New Tag</span>
                  <Autocomplete
                    freeSolo
                    disableClearable
                    options={predefinedTags.map(({ name }) => name)}
                    onChange={handleChange}
                    value={tag}
                    id="1"
                    data-testid="autocomplete"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="1"
                        label="Search by tag..."
                        margin="normal"
                        variant="outlined"
                        color="secondary"
                        InputProps={{ ...params.InputProps, type: "search" }}
                      />
                    )}
                  />
                </label>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-testid="apply"
                >
                  Apply
                </button>
              </form>
            )}
          </>
        )}
      />
    </div>
  );
}
