import React, { useState, useEffect, useMemo } from "react";
import { Formik } from "formik";
import { Input, MappedInput } from "../../components/Input";
import { MappedInputMask } from "../../components/InputMask";
import { MappedSelect, Select } from "../../components/Select";
import { MappedCheckbox } from "../../components/Checkbox";
import { verifyBSB } from "../../../app/modules/Organisations/_api";
import { getAllBsbIntegrations } from "../../../app/modules/Integrations/_api";
import { getPaymentCyclesList } from "../../../app/modules/PaymentCycles/_api";
import { Info as InfoIcon } from "@material-ui/icons/";
import { AccountSchema } from "../../../helpers/schemas";
import { IconButton, Tooltip } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";

import {
  accountTypeOptions,
  ledgerTypeOptions,
  bankOptions,
} from "./constants";

export function AccountForm({
  accountData,
  setData,
  onClose,
  onSubmit,
  organisationId,
  newAccount,
  user,
}) {
  const { request } = useFetch();
  const TENANT_ID = user.selectedTenant;
  const [bsbIntegrationId, setBsbIntegrationId] = useState();
  const [paymentCycles, setPaymentCycles] = useState([]);
  const [isInValidBSB, setIsInValidBSB] = useState(false);

  useEffect(() => {
    request(getAllBsbIntegrations, TENANT_ID).then((response) => {
      if (response && response[0]) {
        setBsbIntegrationId(response[0].id);
      }
    });

    request(getPaymentCyclesList, TENANT_ID).then((data) => {
      if (data) {
        setPaymentCycles(data);
      }
    });
    // eslint-disable-next-line
  }, [TENANT_ID]);

  const {
    account_type,
    ledger_type,
    payment_cycle_id,
    account_name,
    account_number,
    bsb,
    bank,
    id,
    billing_account,
  } = accountData;

  const initialValues = {
    account_type: account_type || "",
    ledger_type: ledger_type || "",
    payment_cycle_id: payment_cycle_id || "",
    account_name: account_name || "",
    account_number: account_number || "",
    bsb: bsb || "",
    bank: bank || "",
    billing_account: billing_account || false,
  };

  const numberCheck = (value) => {
    const arr = value.split("");
    if (arr.length < 9) {
      return Array(9 - arr.length)
        .fill(0)
        .concat(arr)
        .join("");
    }
    return arr.join("");
  };

  let paymentCyclesOptions = useMemo(
    () =>
      paymentCycles?.map(({ id, description }) => ({
        value: id,
        label: description,
      })),
    [paymentCycles]
  );

  const handleClose = () => onClose(false);

  const handleSubmit = (values) => {
    request(
      onSubmit,
      TENANT_ID,
      organisationId,
      { ...values, account_number: numberCheck(values.account_number) },
      id
    ).then((data) => {
      if (!data) return;
      onClose(false);
    });
  };

  const handleBlur = (values, setFieldValue, setTouched) => () => {
    request(verifyBSB, bsbIntegrationId, values.bsb).then((res) => {
      if (res === undefined) {
        setFieldValue("bank", "", true);
        setIsInValidBSB(true);
      }
      if (Array.isArray(res)) {
        // eslint-disable-next-line
        const [_0, bank, branch, _3, city, state] = res;
        const data = [bank, branch, city, state].join(", ");
        setFieldValue("bank", data, true);
      }
    });
    setTouched({ bsb: true }, true);
  };

  return (
    <div>
      <div className="border-bottom border-light p-7">
        {newAccount ? (
          <h4 className="mb-0">Add New Account</h4>
        ) : (
          <h4 className="mb-0">View / Edit Account</h4>
        )}
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={AccountSchema(newAccount)}
        onSubmit={handleSubmit}
      >
        {({ values, handleSubmit, setFieldValue, setTouched }) => (
          <>
            <div className="px-7 py-11">
              <div className="row mb-2">
                <div className="col-2 mt-4">
                  <p className="text-left text-muted mb-0">Account Type:</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedSelect
                    name="account_type"
                    data-testid="account_type"
                    options={accountTypeOptions}
                  />
                </div>
              </div>
              {(values.account_type === "internal" ||
                values.account_type === "investment") && (
                <div className="row mb-2">
                  <div className="col-2 mt-4">
                    <p className="text-left text-muted mb-0">Ledger Type:</p>
                  </div>
                  <div className="col-4 mr-auto">
                    <MappedSelect
                      name="ledger_type"
                      data-testid="ledger_type"
                      options={ledgerTypeOptions}
                      disabled={ledger_type && !newAccount}
                    />
                  </div>
                </div>
              )}
              {values.ledger_type === "buyer" && (
                <div className="row mb-2">
                  <div className="col-2 mt-4">
                    <p className="text-left text-muted mb-0">Payment Cycle:</p>
                  </div>
                  <div className="col-4 mr-auto">
                    <MappedSelect
                      name="payment_cycle_id"
                      data-testid="payment_cycle_id"
                      options={paymentCycles && paymentCyclesOptions}
                    />
                  </div>
                </div>
              )}
              <div className="row mb-5">
                <div className="col-2 my-auto">
                  <p className="text-left text-muted mb-0">Account Name:</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="account_name"
                    wrapperClassName="w-100"
                    data-testid="account_name"
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2 my-auto">
                  <p className="text-left text-muted mb-0">Account Number:</p>
                </div>
                <div className="col-4 mr-auto">
                  <MappedInput
                    name="account_number"
                    wrapperClassName="w-100"
                    data-testid="account_number"
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2 my-auto">
                  <p className="text-left text-muted mb-0">BSB:</p>
                </div>
                <div className="col-4">
                  {values.account_type === "internal" ||
                  values.account_type === "investment" ||
                  values.account_type === "" ||
                  !bsbIntegrationId ? (
                    <Input
                      value=""
                      wrapperClassName="w-100"
                      disabled
                      data-testid="bsb"
                    />
                  ) : (
                    <MappedInputMask
                      name="bsb"
                      wrapperClassName="w-100"
                      mask="999-999"
                      data-testid="bsb"
                      onBlur={handleBlur(values, setFieldValue, setTouched)}
                    />
                  )}
                </div>
                <div className="mr-auto pt-2">
                  {isInValidBSB && (
                    <Tooltip
                      title="If you think this is a valid BSB number, please update the BSB integration."
                      arrow
                      interactive
                      placement="right"
                    >
                      <IconButton
                        color="secondary"
                        style={{ marginLeft: "auto", padding: 6 }}
                      >
                        <InfoIcon fontSize="" />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2 my-auto">
                  <p className="text-left text-muted mb-0">Bank:</p>
                </div>
                <div className="col-4 mr-auto">
                  {values.account_type === "internal" ||
                  values.account_type === "investment" ? (
                    <Select
                      disabled
                      value=""
                      data-testid="bank"
                      options={bankOptions}
                    />
                  ) : (
                    <MappedInput
                      name="bank"
                      wrapperClassName="w-100"
                      disabled
                      data-testid="bank"
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center p-7">
              <div>
                <button
                  onClick={handleClose}
                  className="btn btn-outline-primary px-15 mr-5"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-primary px-15"
                  data-testid="submit"
                >
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
}
