import React from "react";
import ProfileUserTypeSwitcher from "../../ui/structures/ProfileUserTypeSwitcher";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export const SwapRole = () => {
  return (
    <div className="bg-white rounded py-7 px-10 d-flex justify-content-between align-items-center">
      <div>
        <h3 className="mb-10">Swap Role</h3>
        <ProfileUserTypeSwitcher />
      </div>
      <div className="mt-20 pb-5 justify-content-right">
        <img
          src={toAbsoluteUrl("/media/logos/generic/GenericLogo.svg")}
          alt="logo"
          width="70%"
        />
      </div>
    </div>
  );
};
