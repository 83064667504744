import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { useHtmlClassService } from "../../../../_metronic/layout/_core/MetronicLayout";
import { useFetch } from "../../../../hooks/fetch.hook";
import { DBWakeUp, getUser } from "../_api";
import { getDefaultTenantOrganisation } from "../../Tenants/_api";

import {
  Authenticator,
  SignIn,
  ConfirmSignUp,
  ConfirmSignIn,
  ForgotPassword,
  RequireNewPassword,
  TOTPSetup,
} from "aws-amplify-react";
import { Auth } from "@aws-amplify/auth";
import { I18n } from "@aws-amplify/core";
import { MyGreetings } from "./MyGreetings";
import "./TotpQrCodeFix.css";

import config from "react-global-configuration";
import { WarningAlertBar } from "../../../../ui/components/AlertBar";

const dict = {
  en: {
    "Forget your password? ": " ", // Blank out hint in default theme
    "Confirm TOTP Code": "2FA Login Code",
    AWSCognito: "CRSNextGen", // TOTPSetup issuer
  },
};

I18n.setLanguage("en");
I18n.putVocabularies(dict);

const errorMap = (message) => {
  if (/^Custom auth.*/i.test(message)) {
    return "Incorrect username or password.";
  }

  return message;
};

const MyTheme = {
  toast: {
    backgroundColor: "#e8cccc",
    color: "#8b0000",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    border: "1px solid",
    borderRadius: ".42rem",
    borderColor: "#e8cccc",
  },
  sectionFooterSecondaryContent: {
    textTransform: "uppercase",
    marginTop: "1rem",
    display: "block",
  },
  sectionFooter: {
    textAlign: "center",
  },
  sectionBody: {
    textAlign: "center",
  },
  sectionHeader: {
    fontWeight: "600",
    fontSize: "1.25rem",
    textAlign: "center",
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    textTransform: "uppercase",
    color: "rgb(54, 153, 255)",
  },
  container: {
    width: "100%",
    // paddingTop: "1rem",
    //'paddingBottom': '1rem',
  },
  formField: {
    width: "100%",
    paddingTop: "1rem",
    paddingBottom: "1rem",
  },
  hint: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    color: "rgba(54, 153, 255, .9)",
    textAlign: "center",
    textTransform: "uppercase",
  },
  input: {
    width: "100%",
    paddingTop: "1.25rem",
    paddingBottom: "1.25rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#f3f6f9",
    backgroundColor: "#f3f6f9",
  },
  inputLabel: { display: "none" },
  button: {
    marginTop: "0.75rem",
    marginBottom: "0.75rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    paddingLeft: "1.25rem",
    paddingRight: "1.25rem",
    fontSize: "1rem",
    lineHeight: "1.5",
    borderRadius: ".42rem",
    border: "1px solid",
    borderColor: "#3699FF",
    backgroundColor: "#3699FF",
    color: "#FFFFFF",
    width: "100%",
    textTransform: "uppercase",
  },
};

function Login(props) {
  const uiService = useHtmlClassService();
  const [status, setStatus] = useState("");
  const [expiredMessage, setExpiredMessage] = useState(false);

  const { request } = useFetch();

  useEffect(() => {
    setExpiredMessage(Boolean(localStorage.getItem("sessionExpired")));
  }, []);

  useEffect(() => {
    DBWakeUp().catch((err) => console.log(err));
    // eslint-disable-next-line
  }, []);

  function handleAuthStateChange(state) {
    console.log(state);
    setStatus(state);
    if (state === "signedIn") {
      Auth.currentUserInfo().then((userInfo) => {
        if (userInfo && userInfo.attributes) {
          // Require check as this bounces on logout
          request(getUser).then((userData) => {
            if (userData) {
              const {
                attributes: { email, name },
              } = userInfo;
              const {
                id,
                tenants,
                user_info: { "custom:selected_tenant": selectedTenant },
                roles,
                aggregated_permissions,
              } = userData;
              // console.log(userInfo);
              // console.log(userData);

              const user = {
                id: id,
                username: email,
                email: email,
                fullname: name,
                selectedTenant: parseInt(selectedTenant),
                tenants: tenants,
                roles: roles,
                permissions: aggregated_permissions,
                sessionStart: new Date(),
                userCategory: "BOH",
              };

              if (selectedTenant) {
                request(getDefaultTenantOrganisation, selectedTenant).then(
                  (defaultTenantData) => {
                    if (defaultTenantData) {
                      user.default_organisation_id = defaultTenantData.id;
                      user.default_organisation_name = defaultTenantData.name;
                    }
                    localStorage.setItem("sessionExpired", "");
                    setExpiredMessage(false);
                    props.fulfillUser(user);
                  }
                );
              } else {
                localStorage.setItem("sessionExpired", "");
                setExpiredMessage(false);
                props.fulfillUser(user);
              }
            }
          });
        }
      });
      // Auth.configure({ storage: localStorage });
    }
  }

  return (
    <div
      className="container w-100 h-100 "
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <div
        className="d-flex justify-content-center align-items-center mt-10"
        style={{ minHeight: "50%" }}
      >
        {/* <div className="col col-md-6 col-lg-4 login-form login-signin"> */}
        <div
          className={`bg-white border border-secondary ${
            status === "signIn" ? "pb-10" : "py-10"
          } px-10 rounded col-md-6 col-lg-4`}
        >
          {/* <div className="d-flex align-items-center justify-content-center mb-10 mb-lg-25">
            <img
              className="img-fluid w-100"
              src={toAbsoluteUrl(uiService.getLogo())}
              alt="Logo"
              style={{
                backgroundColor: "rgba(255, 255, 255, 1)",
                padding: "5%",
                borderRadius: ".42rem",
              }}
            />
          </div>
          <div
            style={{
              background: "rgba(255, 255, 255, 1)",
              padding: "1rem 1.3rem 2rem",
              borderRadius: "1rem",
            }}
          > */}

          {expiredMessage && (
            <div className="fixed-top">
              <WarningAlertBar message="Your session has expired. Please, reauthenticate." />
            </div>
          )}

          {status === "signIn" && (
            <div style={MyTheme.hint} className="mt-5 cursor-pointer">
              <img
                className="img-fluid"
                src={toAbsoluteUrl(uiService.getLogo())}
                alt="Logo"
                style={{
                  backgroundColor: "rgba(255, 255, 255, 1)",
                  width: "60%",
                  borderRadius: ".42rem",
                }}
              />
            </div>
          )}

          <div className="d-flex justify-content-center login-form login-signin">
            {/*begin::Form*/}
            <Authenticator
              theme={MyTheme}
              onStateChange={handleAuthStateChange}
              amplifyConfig={config.get("Auth")}
              errorMessage={errorMap}
              hideDefault={true}
            >
              <SignIn />
              <ConfirmSignUp />
              <ConfirmSignIn />
              <ForgotPassword />
              <RequireNewPassword />
              <TOTPSetup />
              <MyGreetings props={props} />
            </Authenticator>
            {/*end::Form*/}
          </div>
        </div>
      </div>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
