import { get } from "lodash";

export const modifyShareObject = (item = {}) => {
  return {
    ...item,
    beneficiary: get(item, "beneficiary.name", ""),
    shareholder: get(item, "shareholder.name", ""),
  };
};

export const modifySharesData = (data = []) => {
  return data.map((item) => modifyShareObject(item));
};
