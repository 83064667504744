export const billingCheckTypes = {
  SET_DATA: "BillingCheck/SET_DATA",
};

export function setBillingCheckData(data) {
  return {
    type: billingCheckTypes.SET_DATA,
    payload: data,
  };
}
