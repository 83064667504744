import React, { useState, useEffect } from "react";
import { InvoiceLineDetails } from "./InvoiceLineDetails";
import { Select } from "../../../ui/components/Select";
import { formatAmount, formatDate } from "../../../ui/helpers/helpers";

export const AdjustDiscrepancy = ({
  data = {},
  handleClose,
  fetchDiscrepancyData,
  organisationList = [],
}) => {
  const [key, setKey] = useState(Date.now());

  const { invoice_lines } = data?.invoice || [];
  const [invoiceLine, setInvoiceLine] = useState({});

  useEffect(() => {
    setInvoiceLine(
      invoice_lines.filter((line) => line.id === data?.invoice_line_id)[0]
        .line_number
    );
    // eslint-disable-next-line
  }, [data, invoice_lines]);

  const handleInvoiceLineChange = (event) => {
    setInvoiceLine(event.target.value);
    setKey(Date.now());
  };

  const invoiceLineOptions = invoice_lines.map(
    ({ line_number, quantity, unit_price }) => ({
      value: line_number,
      label: `${line_number}. Qty: ${quantity}. Price: ${unit_price}`,
    })
  );

  return (
    <div>
      <h3 className="text-center mb-10">Adjust Discrepancy</h3>
      <div
        className="my-10 d-flex"
        style={{
          padding: "15px 12px",
          borderRadius: 7,
          border: "1px solid #f59220",
        }}
      >
        <div className="mr-10">
          <div>
            <span
              style={{ color: "#F59220", paddingRight: 12, fontWeight: 700 }}
            >
              Biller Name :
            </span>
            {
              organisationList?.filter(
                ({ value }) => data.biller_id === value
              )[0].label
            }
          </div>
          <div>
            <span
              style={{ color: "#F59220", paddingRight: 12, fontWeight: 700 }}
            >
              Buyer Name :
            </span>
            {
              organisationList?.filter(
                ({ value }) => data.buyer_id === value
              )[0].label
            }
          </div>
        </div>
        <div>
          <span style={{ color: "#F59220", paddingRight: 12, fontWeight: 700 }}>
            Invoice Date :
          </span>
          {formatDate(data?.invoice?.invoice_date)}
          <div>
            <span
              style={{ color: "#F59220", paddingRight: 12, fontWeight: 700 }}
            >
              Amount :
            </span>
            {formatAmount(
              invoice_lines.reduce(
                (sum, { quantity, unit_price, tax_rate }) =>
                  sum +
                  (quantity * unit_price +
                    quantity * unit_price * (tax_rate / 100)),
                0
              )
            )}
          </div>
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-2 mt-3">Select Invoice Line</div>
        <div className="col-8">
          <Select
            onChange={handleInvoiceLineChange}
            value={invoiceLine}
            options={invoiceLineOptions}
          />
        </div>
      </div>
      <div>
        {invoiceLine && (
          <InvoiceLineDetails
            data={{
              ...invoice_lines.find(
                ({ line_number }) => line_number === invoiceLine
              ),
              discrepancy_reason: data?.discrepancy_reason,
              discrepancy_note: data?.discrepancy_note,
              corrected_quantity: data?.corrected_quantity,
              corrected_unit_price: data?.corrected_unit_price,
              corrected_total_value: data?.corrected_total_value,
            }}
            fetchDiscrepancyData={fetchDiscrepancyData}
            handleClose={handleClose}
            mode="adjust"
            discrepancy_id={data?.id}
            key={key}
          />
        )}
      </div>
    </div>
  );
};
