import React from "react";
import { withRouter } from "react-router-dom";
import { Skeleton } from "@material-ui/lab/";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { Info as InfoIcon } from "@material-ui/icons";
import { MappedInput } from "../../components/Input";
import { useFetch } from "../../../hooks/fetch.hook";
import { Tooltip } from "@material-ui/core";
import { TenantSettingsSchema } from "../../../helpers/schemas";

function TenantSettingsForm({
  history,
  data,
  onClose,
  onSubmit,
  setData,
  tenantId,
}) {
  const { request } = useFetch();

  const {
    ledger_connection_string,
    primary_contact,
    support_phone,
    support_email,
    bpay_biller_code,
  } = data;

  const initialValues = {
    ledger_connection_string: ledger_connection_string || "",
    primary_contact: primary_contact || "",
    support_phone: support_phone || "",
    support_email: support_email || "",
    bpay_biller_code: bpay_biller_code || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (!Object.keys(data).length) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  const handleSubmit = (values) => {
    request(onSubmit, { name: data.name, ...values }, tenantId).then((data) => {
      if (data) {
        if (onClose) return onClose(false);
        return history.push(`/tenants-list/`);
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={TenantSettingsSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="px-21 py-19" style={{ minHeight: 712.5 }}>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="d-flex text-right text-muted mb-0">
                    Ledger Connection ID
                    <div className="my-auto ml-auto">
                      <Tooltip
                        className="mb-0"
                        title={`Please add a valid Organisation ID`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="ledger_connection_string"
                    wrapperClassName="w-100"
                  />
                </div>
              </div>
              <div className="row mt-10 mb-5">
                <div className="col-2 my-auto">
                  <p className="text-right mb-0">Payment information</p>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="d-flex text-right text-muted mb-0">
                    BPAY Biller code
                    <div className="my-auto ml-auto">
                      <Tooltip
                        className="mb-0"
                        title={`When the bpay biller code is changed it triggers a background
                        process which generates CRNs for the organisations of the tenant.`}
                        arrow
                        interactive
                      >
                        <InfoIcon className="text-warning pb-1" />
                      </Tooltip>
                    </div>
                  </p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="bpay_biller_code"
                    wrapperClassName="w-100"
                    data-testid="bpay_biller_code"
                  />
                </div>
              </div>
              <div className="row mt-10 mb-5">
                <div className="col-2 my-auto">
                  <p className="text-right mb-0">Contact information</p>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Primary Contact</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="primary_contact"
                    wrapperClassName="w-100"
                    data-testid="primary_contact"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Support Phone</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="support_phone"
                    wrapperClassName="w-100"
                    data-testid="support_phone"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Support Email</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="support_email"
                    wrapperClassName="w-100"
                    data-testid="support_email"
                  />
                </div>
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  data-testid="close"
                  onClick={
                    onClose ? () => onClose(false) : () => history.go(-1)
                  }
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(TenantSettingsForm);
