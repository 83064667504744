import config from "react-global-configuration";

export const getInvoices = (tenant_id, search) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/invoices${
      search ? search : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getInvoicesWithFiltering = (tenant_id, biller_id, buyer_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/invoices?${
      biller_id ? "&biller_id=" + biller_id : ""
    }${buyer_id ? "&buyer_id=" + buyer_id : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getInvoicesWithSearch = (
  tenant_id,
  search = "",
  page = 0,
  page_size = 10,
  count_only = false,
  buyer_id = ""
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/invoices?${search &&
      "&" + search}&page=${page}&page_size=${page_size}${
      count_only ? "&count_only=y" : ""
    }${buyer_id && "&buyer_id=" + buyer_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getInvoicesAutocomplete = (
  tenant_id,
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/invoices?page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getInvoiceById = (tenant_id, invoice_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/invoices/${invoice_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createInvoice = (tenant_id, invoiceitem_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/invoices`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(invoiceitem_data),
    mode: "cors",
    credentials: "include",
  });

export const processInvoice = (tenant_id, invoice_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/invoices/${invoice_id}/process`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({}),
      mode: "cors",
      credentials: "include",
    }
  );

export const getLastUploadedInvoiceDataResult = (filename, tenant_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/invoices/preprocess-data?file_name=${filename}&tenant_id=${tenant_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getBillingCheck = (tenant_id, billing_check_type = "daily") =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/invoices/billing-check?billing_check_type=${billing_check_type}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
