import React, { useState } from "react";
import { useSelector } from "react-redux";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Tabs from "../../ui/components/Tabs";
import OrganisationDetailsForm from "../../ui/structures/OrganisationDetailsForm";
import { createOrganisation } from "../modules/Organisations/_api";

export function AddNewOrganisation() {
  const [data, setData] = useState({
    name: "",
    legalName: "",
    shortCode: "",
    phone: "",
    mobile: "",
    email: "",
    physicalAddress: "",
    physicalAddress2: "",
    physicalSuburb: "",
    physicalState: "",
    physicalPostCode: "",
    postalAddress: "",
    postalAddress2: "",
    postalSuburb: "",
    postalState: "",
    postalPostCode: "",
    ABN: "",
    ACN: "",
    tags: [],
    files: [],
  });

  const user = useSelector(({ auth: { user } }) => user);

  const tabs = [
    {
      label: "Organisation Details",
      icon: <ContactPhoneIcon />,
      tabPanel: (
        <OrganisationDetailsForm
          data={data}
          setData={setData}
          onSubmit={createOrganisation}
          user={user}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mb-9">
        <Tabs defaultActive="0" tabs={tabs} />
      </div>
    </>
  );
}
