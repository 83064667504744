import React, { useCallback, useState, useEffect } from "react";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import LocalOfferIcon from "@material-ui/icons/ContactPhone";
import get from "lodash/get";
import { useSelector } from "react-redux";
import Tabs from "../../ui/components/Tabs";
import { InputFile } from "../../ui/components/InputFile";
import { Tags } from "../../ui/structures/Tags";
import AddContactDetailsForm from "../../ui/structures/AddContactDetailsForm";
import { postContact } from "../modules/Contacts/_api";
import { useFetch } from "../../hooks/fetch.hook";
import { getOrganisationsListSimple } from "../modules/Organisations/_api";

export function AddNewContactPage({ match, history, location }) {
  const { user } = useSelector((state) => state.auth);
  const { request } = useFetch();
  const TENANT_ID = user.selectedTenant;
  const organisationId = get(history, "location.dataId.organisation_id", "");
  const organisationName = get(history, "location.dataId.name", "");

  const [data, setData] = useState({
    organisation_id: organisationId,
    firstName: "",
    lastName: "",
    phone: "",
    mobile: "",
    email: "",
    tags: [],
  });

  const [organisationsList, setOrganisationsList] = useState([]);

  useEffect(() => {
    if (organisationId) {
      setOrganisationsList([{ value: organisationId, label: organisationName }]);
      return;
    }
    request(getOrganisationsListSimple, TENANT_ID).then(
      (data) => data && setOrganisationsList(data)
    );
    // eslint-disable-next-line
  }, []);

  const handleDeleteTag = useCallback(
    (tag) => {
      const newTags = data.tags.filter((item) => item !== tag);
      setData((prevState) => ({
        ...prevState,
        tags: [...newTags],
      }));
    },
    [data.tags]
  );

  const tabs = [
    {
      label: "Contact Details",
      icon: <ContactPhoneIcon />,
      tabPanel: (
        <AddContactDetailsForm
          data={data}
          setData={setData}
          onSubmit={postContact}
          tenant_id={TENANT_ID}
          organisationsList={organisationsList}
          organisationId={organisationId}
        />
      ),
    },
    {
      label: "Tags",
      icon: <LocalOfferIcon />,
      tabPanel: (
        <Tags
          data={data}
          tagsList={data.tags}
          onTagDelete={handleDeleteTag}
          type="contact"
          setData={setData}
        />
      ),
    },
    // {
    //   label: "Files",
    //   icon: <ContactPhoneIcon />,
    //   tabPanel: <Files data={data.files} />,
    // },
    // {
    //   label: "Settings",
    //   icon: <ContactPhoneIcon />,
    //   tabPanel: <Settings data={data.settings} setData={setData} />,
    // },
  ];

  return (
    <>
      <h4 className="mb-7">Add New Contact</h4>
      <div className="mb-9">
        <Tabs defaultActive="0" tabs={tabs} />
      </div>
      <InputFile className="rounded" />
    </>
  );
}
