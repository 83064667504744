import React, { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import DataGrid from "../../components/DataGrid";
import { renderHeader, renderSharesRow } from "../../helpers/helpers";
import { ShareModal } from "./ShareModal";
import { ShareDetails } from "./ShareDetails";
import { useFetch } from "../../../hooks/fetch.hook";
import { NewShareForm } from "./NewShareForm";
import { TransferShareForm } from "./TransferSharesForm";
import { CancelShareForm } from "./CancelShareForm";
import {
  getOrganisationsListWithParams,
  getOrganisationsListSimple,
} from "../../../app/modules/Organisations/_api";
import { downloadFile } from "../../../app/modules/AWS-S3/download";
import {
  getShareRegisterListByOrgId,
  addShareRegister,
  updateShareRegister,
  exportShareCertificate,
} from "../../../app/modules/ShareRegister/_api";
import { modifySharesData } from "./helpers";

const HEADINGS = [
  ["share_number", "Share Number"],
  ["share_certificate_number", "Certificate Number"],
  ["shareholder", "Shareholder"],
  ["beneficiary", "Beneficiary"],
  ["admitted_date", "Admitted"],
  ["date_issued", "Issued"],
  ["date_cancelled", "Cancelled"],
];

export function Shares({ id, TENANT_ID, tradingName, legalName }) {
  const { request } = useFetch();

  const [data, setData] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContentId, setModalContentId] = useState("");
  const [modalComponent, setModalComponent] = useState("");
  const [submitable, setSubmitable] = useState(false);
  const [organisationsList, setOrganisationsList] = useState([]);

  function fetchShares() {
    setLoading(true);
    request(getShareRegisterListByOrgId, TENANT_ID, id).then((data) => {
      if (data) {
        setData(modifySharesData(data));
        setLoading(false);
      }
    });
  }

  useEffect(() => {
    fetchShares();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getOrganisationsListSimple, TENANT_ID).then((data) => {
      if (data) {
        setOrganisationsList((prevList) => [...prevList, ...data]);
      }
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleExpand = (id) => () => {
    setModalContentId(id);
    setModalOpen(true);
    setSubmitable(false);
    setModalComponent("view");
  };

  const handleNewShare = () => {
    setModalOpen(true);
    setSubmitable(true);
    setModalComponent("new");
  };

  const handleTransferShare = () => {
    setModalOpen(true);
    setSubmitable(true);
    setModalComponent("transfer");
  };

  const handleCancelShare = () => {
    setModalOpen(true);
    setSubmitable(true);
    setModalComponent("cancel");
  };

  const getSharebyId = () => data.find(({ id }) => id === modalContentId);

  const handleClose = () => setModalOpen(false);

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleSubmit = (values, { setSubmitting }) => {
    request(addShareRegister, TENANT_ID, { ...values }).then((data) => {
      if (data) {
        fetchShares();
      }
    });
    setSubmitting(false);
    setModalOpen(false);
  };

  const handleUpdateSubmit = (values, { setSubmitting }) => {
    request(updateShareRegister, TENANT_ID, getId(), { ...values }).then(
      (data) => {
        if (data) {
          fetchShares();
        }
      }
    );
    setSubmitting(false);
    setModalOpen(false);
    setSelected({});
  };

  const handleDownload = (id) => () =>
    downloadFile([id], exportShareCertificate, TENANT_ID);

  const componentsMap = {
    view: (
      <ShareDetails
        data={getSharebyId()}
        tradingName={tradingName}
        legalName={legalName}
      />
    ),
    new: (
      <NewShareForm
        tradingName={tradingName}
        legalName={legalName}
        id={id}
        handleClose={handleClose}
        handleSubmit={handleSubmit}
        organisationsList={organisationsList}
      />
    ),
    transfer: (
      <TransferShareForm
        tradingName={tradingName}
        data={() => data.find(({ id }) => id === getId())}
        legalName={legalName}
        id={id}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
        selected={selected}
        organisationsList={organisationsList}
      />
    ),
    cancel: (
      <CancelShareForm
        tradingName={tradingName}
        legalName={legalName}
        data={() => data.find(({ id }) => id === getId())}
        id={id}
        handleClose={handleClose}
        handleSubmit={handleUpdateSubmit}
      />
    ),
  };

  const actionDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  return (
    <>
      <div>
        <ShareModal
          isOpen={modalOpen}
          onClose={handleClose}
          submitable={submitable}
          modalContent={componentsMap[modalComponent]}
        />
        <div className="d-flex  px-9 py-7">
          <div>
            <button className="btn btn-primary mr-6" onClick={handleNewShare}>
              New
            </button>
            <button
              className="btn btn-primary mr-6"
              onClick={handleTransferShare}
              disabled={actionDisabled}
            >
              Transfer
            </button>
            <button
              className="btn btn-danger"
              onClick={handleCancelShare}
              disabled={actionDisabled}
              style={{ backgroundColor: "rgb(225, 67, 82)" }}
            >
              Cancel
            </button>
          </div>
        </div>
        <DataGrid
          controls
          headings={HEADINGS}
          data={data}
          renderHeader={renderHeader}
          renderRow={renderSharesRow}
          selectable
          hideSelect
          expandable
          selected={selected}
          setSelected={setSelected}
          loading={loading}
          handleExpand={handleExpand}
          certificate
          handleDownload={handleDownload}
        />
      </div>
    </>
  );
}
