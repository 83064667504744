import React from "react";
import { IconButton } from "@material-ui/core";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import { Autocomplete } from "../../components/Autocomplete";

export const PairForm = ({
  id,
  organisationTags = [],
  contactTags = [],
  handleRemovePair,
  data = {},
  pairs = [],
  setPairs,
}) => {

  const { organisation, contact } = data;

  const setValue = (name, value) => {
    const index = pairs.findIndex((item) => (item || {}).id === id);
    setPairs((state) => [
      ...state.slice(0, index),
      { ...data, [name]: value },
      ...state.slice(index + 1),
    ]);
  };

  return (
    <div className="d-flex justify-content-between align-items-center my-5">
      <>
        <div style={{ width: "40%" }}>
          <Autocomplete
            name="organisation"
            placeholder="Select Organisation Tag"
            setValue={setValue}
            defaultField="name"
            defaultValue={organisation}
            options={organisationTags}
          />
        </div>
        <div style={{ width: "6%" }} className="text-center">
          <ArrowRightAltIcon fontSize="large" />
        </div>
        <div style={{ width: "40%" }}>
          <Autocomplete
            name="contact"
            placeholder="Select Contact Tag"
            setValue={setValue}
            defaultValue={contact}
            defaultField="name"
            options={contactTags}
          />
        </div>
        <div>
          <IconButton onClick={handleRemovePair(id)}>
            <RemoveCircleIcon color="error" />
          </IconButton>
        </div>
      </>
    </div>
  );
};
