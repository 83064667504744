import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../../../hooks/fetch.hook";
import { ServerSearchBar } from "../../../../../../../ui/components/ServerSearchBar";
import {
  getInvoicesAutocomplete,
  getInvoicesWithSearch,
} from "../../../../../InvoiceEntry/_api";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import { Button, IconButton } from "@material-ui/core";
import { uniq } from "lodash";
import { AmountDisplay } from "../../../../../../../ui/components/AmountDisplay";
import {
  formatDate,
  getDifferenceTwoArrayOfObject,
  formatDateForBackend,
} from "../../../../../../../ui/helpers/helpers";

export const InvoiceMatchFormSearchInvoices = ({
  TENANT_ID,
  selectedInvoices,
  setSelectedInvoices,
  dueDate,
}) => {
  const { request } = useFetch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [invoicesList, setInvoicesList] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(true);

  const fetchInvoices = () => {
    setLoading(true);
    request(
      getInvoicesWithSearch,
      TENANT_ID,
      search +
        `&processed_date=_null_${
          dueDate ? `&payment_due_date=${formatDateForBackend(dueDate)}` : ""
        }`,
      page,
      pageSize
    )
      .then((invoices) => invoices && setInvoicesList(invoices))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedInvoices.length) {
      const diff = [
        // ...getDifferenceTwoArrayOfObject(selectedInvoices, invoicesList),
        ...getDifferenceTwoArrayOfObject(invoicesList, selectedInvoices),
      ];
      setIsSelectAll(diff.length ? true : false);
    }
    // eslint-disable-next-line
  }, [invoicesList, setInvoicesList, selectedInvoices, setSelectedInvoices]);

  useEffect(() => {
    fetchInvoices();
    // eslint-disable-next-line
  }, [search, page, pageSize, dueDate]);

  const setSearchKey = (data = {}, searchMap, key) => {
    if (key === "Tag") {
      return `tags.name=${data.Tag}`;
    } else if (searchMap[key]) {
      return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
    } else return "";
  };

  const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
    setSearch(
      Object.keys(data)
        .map((key) => setSearchKey(data, searchMap, key))
        .join("&")
    );
    setPage && setPage(0);
  };

  const SITE_SEARCH_HELPER = [
    ["invoice_number", "Invoice Number"],
    ["invoice_type", "Invoice Type"],
    ["invoice_date", "Invoice Date"],
    ["biller.legalName", "Biller"],
    ["buyer.legalName", "Buyer"],
    ["status", "Status"],
  ];

  const siteSearchMap = {
    "Invoice Number": "invoice_number",
    "Invoice Type": "invoice_type",
    "Invoice Date": "invoice_date",
    Biller: "biller.legalName",
    Buyer: "buyer.legalName",
    Status: "status",
  };

  const objectCheck = (data = []) => {
    return data.every((item) => typeof item !== "object");
  };

  const fetchInvoicesAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getInvoicesAutocomplete,
      TENANT_ID,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields +
        `&processed_date=_null_${
          dueDate ? `&payment_due_date=${formatDateForBackend(dueDate)}` : ""
        }`
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };

  const resetSearch = () => {
    setSearch("");
  };

  return (
    <>
      <div className="mb-3">
        <ServerSearchBar
          onSearch={(data) =>
            handleSearch(data, siteSearchMap, setSearch, setPage)
          }
          keyMap={SITE_SEARCH_HELPER}
          loading={autocompleteLoading}
          currentSearchList={autocompleteData}
          fetchAutocompleteFunction={fetchInvoicesAutocomplete}
          placeholder="Search Invoices..."
          clearSearch={resetSearch}
          focused={focused}
          setFocused={setFocused}
          searchMap={siteSearchMap}
          searchFields={search}
        />
      </div>

      {invoicesList.length ? (
        <div
          className="col-12 d-flex align-items-center"
          style={{ fontWeight: "600" }}
        >
          <div className="col-1 text-dark">Date</div>
          <div className="col-1 text-dark">Number</div>
          <div className="col-2 text-dark">Biller</div>
          <div className="col-2 text-dark">Buyer</div>
          <div className="col-1 text-dark">Due date</div>
          <div style={{ margin: "0 60px 0 auto" }}>Amount</div>

          <div>
            <Button
              onClick={() => {
                isSelectAll
                  ? setSelectedInvoices((prev) => [
                      ...[
                        ...getDifferenceTwoArrayOfObject(prev, invoicesList),
                        ...getDifferenceTwoArrayOfObject(invoicesList, prev),
                      ],
                    ])
                  : setSelectedInvoices((prev) => [
                      ...getDifferenceTwoArrayOfObject(prev, invoicesList),
                      // ...getDifferenceTwoArrayOfObject(invoicesList, prev),
                    ]);
              }}
            >
              {isSelectAll || !selectedInvoices.length
                ? "Select All"
                : "Deselect All"}
            </Button>
          </div>
        </div>
      ) : (
        ""
      )}
      {invoicesList.map((invoice) => (
        <div
          key={invoice.id}
          className="row align-items-center"
          style={{
            margin: "0",
          }}
        >
          <>
            <div className="col-12 d-flex align-items-center">
              <div className="col-1">{formatDate(invoice.invoice_date)}</div>
              <div className="col-1">{invoice.invoice_number}</div>
              <div
                className="col-2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {invoice.biller_organisation_name}
              </div>
              <div
                className="col-2"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {invoice.buyer_organisation_name}
              </div>
              <div className="col-1">
                {invoice.payment_due_date
                  ? formatDate(invoice.payment_due_date)
                  : ""}
              </div>
              <div style={{ margin: "0 30px 0 auto" }}>
                <AmountDisplay
                  amount={invoice.invoice_total}
                  amount_sub={invoice.invoice_payment_due}
                  variant="red"
                  inverse_diplay
                  inverse_progress
                />
              </div>
              <div>
                <Button
                  onClick={() =>
                    setSelectedInvoices((prev) => {
                      if (prev.findIndex(({ id }) => id === invoice.id) === -1)
                        return [...prev, invoice];
                      return prev;
                    })
                  }
                  disabled={selectedInvoices.find(
                    ({ id }) => id === invoice.id
                  )}
                >
                  Select Invoice
                </Button>
              </div>
            </div>
          </>
        </div>
      ))}
      {page === 0 && invoicesList < pageSize ? (
        ""
      ) : (
        <div className="flex row justify-content-between align-items-center mx-0 mt-1 px-2">
          <p className="text-muted">{`Showing ${pageSize * page}-${pageSize *
            page +
            pageSize}`}</p>
          <div>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page - 1)}
              disabled={!page}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page + 1)}
              disabled={invoicesList.length < pageSize}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
