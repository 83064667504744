import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../../../../hooks/fetch.hook";
import { ServerSearchBar } from "../../../../../../../ui/components/ServerSearchBar";
import {
  getDepositsAutocomplete,
  getDepositsWithSearch,
} from "../../../../../Deposits/_api";
import {
  NavigateNext as NavigateNextIcon,
  NavigateBefore as NavigateBeforeIcon,
} from "@material-ui/icons";
import { Button, IconButton, LinearProgress } from "@material-ui/core";
import { uniq } from "lodash";
import {
  formatAmount,
  formatDate,
} from "../../../../../../../ui/helpers/helpers";

export const DepositMatchFormSearchInvoices = ({
  TENANT_ID,
  transaction_data,
  setSelectedDeposits,
  createMatchByTransactionId,
}) => {
  const { request } = useFetch();

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState("");
  const [autocompleteData, setAutocompleteData] = useState([]);
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [depositsList, setDespositsList] = useState([]);

  const fetchDepositss = () => {
    setLoading(true);
    request(
      getDepositsWithSearch,
      TENANT_ID,
      "status=unprocessed" + search,
      page,
      pageSize
    )
      .then((deposits) => deposits && setDespositsList(deposits))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    fetchDepositss();
    // eslint-disable-next-line
  }, [search, page, pageSize]);

  const setSearchKey = (data = {}, searchMap, key) => {
    if (key === "Tag") {
      return `tags.name=${data.Tag}`;
    } else if (searchMap[key]) {
      return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
    } else return "";
  };
  const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
    setSearch(
      Object.keys(data)
        .map((key) => setSearchKey(data, searchMap, key))
        .join("&")
    );
    setPage && setPage(0);
  };
  const SITE_SEARCH_HELPER = [
    ["deposit_date", "Deposit Date"],
    ["deposit_type", "Deposit Type"],
    ["deposit_description", "Deposit Desscription"],
    ["status", "Status"],
  ];
  const siteSearchMap = {
    "Deposit Date": "deposit_date",
    "Deposit Type": "deposit_type",
    "Deposit Desscription": "deposit_description",
    Status: "status",
  };
  const objectCheck = (data = []) => {
    return data.every((item) => typeof item !== "object");
  };
  const fetchDepositssAutocomplete = (
    autocompleteValue,
    autocompleteField,
    searchFields
  ) => {
    if (autocompleteValue === "") return;
    setAutocompleteLoading(true);
    request(
      getDepositsAutocomplete,
      TENANT_ID,
      80,
      autocompleteField,
      autocompleteValue,
      searchFields + "status=unprocessed"
    ).then((data) => {
      if (data && Array.isArray(data) && data.length && objectCheck(data)) {
        setAutocompleteData(uniq(data));
      } else {
        setAutocompleteData(["No option found"]);
      }
      setAutocompleteLoading(false);
    });
  };
  const resetSearch = () => {
    setSearch("");
  };
  return (
    <>
      <div className="mb-3">
        <ServerSearchBar
          onSearch={(data) =>
            handleSearch(data, siteSearchMap, setSearch, setPage)
          }
          keyMap={SITE_SEARCH_HELPER}
          loading={autocompleteLoading}
          currentSearchList={autocompleteData}
          fetchAutocompleteFunction={fetchDepositssAutocomplete}
          placeholder="Search Deposits..."
          clearSearch={resetSearch}
          focused={focused}
          setFocused={setFocused}
          searchMap={siteSearchMap}
          searchFields={search}
        />
      </div>

      {depositsList.map((invoice) => (
        <div
          key={invoice.id}
          className="row align-items-center"
          style={{
            margin: "0",
          }}
        >
          <>
            <div className="col-12 d-flex justify-content-between align-items-center">
              <div style={{ minWidth: 80 }}>
                {formatDate(new Date(invoice.deposit_date))}
              </div>
              <div style={{ marginLeft: 44 }}>
                {invoice.deposit_description}
              </div>

              <div
                style={{
                  display: "inline-block",
                  background: "#3783e7",
                  color: "#fff",
                  // color: "#5E6973",
                  // background: "#EEF0F8",
                  padding: "1px 5px",
                  borderRadius: "0.22rem",
                  marginLeft: 10,
                }}
              >
                {invoice.deposit_type}
              </div>

              <div style={{ margin: "0 30px 0 auto" }}>
                <div>
                  {formatAmount(invoice?.deposit_value) +
                    " / " +
                    formatAmount(invoice?.deposit_payment_due)}
                </div>
                <LinearProgress
                  variant="determinate"
                  value={
                    (invoice?.deposit_payment_due / invoice?.deposit_value) *
                    100
                  }
                />
              </div>
              <div>
                <Button
                  onClick={() => createMatchByTransactionId(invoice, "deposit")}
                  disabled={
                    invoice.deposit_payment_due <
                    transaction_data?.transaction_value
                  }
                >
                  Match Desposit
                </Button>
              </div>
            </div>
          </>
        </div>
      ))}
      {page === 0 && depositsList < pageSize ? (
        ""
      ) : (
        <div className="flex row justify-content-between align-items-center mx-0 mt-1 px-2">
          <p className="text-muted">{`Showing ${pageSize * page}-${pageSize *
            page +
            pageSize}`}</p>
          <div>
            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page - 1)}
              disabled={!page}
            >
              <NavigateBeforeIcon />
            </IconButton>

            <IconButton
              color="secondary"
              aria-label="add an alarm"
              onClick={() => setPage(page + 1)}
              disabled={depositsList.length < pageSize}
            >
              <NavigateNextIcon />
            </IconButton>
          </div>
        </div>
      )}
    </>
  );
};
