import React, { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";

export default function TenantCustomRoleSwitcher({
  resourceId,
  permissionsByResource,
  rolePermissions,
}) {
  const [value, setValue] = useState("deny");

  useEffect(() => {
    rolePermissions.forEach((item) => {
      if (item.resource_id === resourceId) {
        setValue(item.permission_level);
      }
    });
    // eslint-disable-next-line
  }, [rolePermissions]);

  if (resourceId === null) return <></>;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="col-12">
      <FormControl component="fieldset">
        <FormLabel component="legend">
          {permissionsByResource[0].resource_id}
        </FormLabel>
        <RadioGroup
          row
          aria-label="organisation"
          name="organisation1"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="deny"
            control={<Radio />}
            label="No Access"
          />
          <FormControlLabel
            value="readonly"
            control={<Radio />}
            label="Read Only"
          />
          <FormControlLabel value="edit" control={<Radio />} label="Edit" />
        </RadioGroup>
      </FormControl>
    </div>
  );
}
