import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setLateHistoryData, updateLateHistoryData } from "./_redux/actions";
import DataGrid from "../../../ui/components/DataGrid";
import { renderHeader } from "../../..//ui/helpers/helpers";
import { renderLateHistoryRow } from "./helpers";
import { Modal } from "../../../ui/components/Modal";
import { EditNote } from "./EditNote";
import { useFetch } from "../../../hooks/fetch.hook";
import { getLateHistoryRecords, processLateHistoryRecord } from "./_api";
import { info } from "../../../helpers/toasts";

const HEADINGS = [
  ["payment_received_date", "Payment Date"],
  ["payment_due_date", "Due Date"],
  ["days_late", "Days Late"],
  ["transaction_value", "Amount"],
  ["buyer_name", "Buyer Name"],
  ["account_number", "Account Number"],
  ["note", "Note"],
  ["status", "Status"],
];

export function List() {
  const data = useSelector((state) => state.lateHistory.lateHistoryList);
  const { user } = useSelector((state) => state.auth);
  const tenantID = user.selectedTenant;

  const dispatch = useDispatch();
  const { request } = useFetch();

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");

  const handleModalClose = () => setModalOpen(false);

  function fetchLateHistory() {
    setLoading(true);
    request(getLateHistoryRecords, tenantID)
      .then((data) => data && dispatch(setLateHistoryData(data)))
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchLateHistory();
    // eslint-disable-next-line
  }, []);

  const handleEditNote = (id) => () => {
    setModalOpen(true);
    setComponentId(id);
  };

  const handleSubmitNote = (values, { setSubmitting }) => {
    const editedItem = data.find(({ id }) => id === componentId);
    if (!editedItem) return;
    const { note, requires_payment_monitoring } = values;
    request(processLateHistoryRecord, tenantID, componentId, values)
      .then((data) => {
        if (!data) return;
        dispatch(updateLateHistoryData({ ...editedItem, requires_payment_monitoring, note }));
        handleModalClose();
        info("Record has been processed!");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const getSelectedNote = data.find(({ id }) => id === componentId) || {};

  return (
    <>
      {modalOpen && (
        <Modal isOpen={modalOpen} onClose={handleModalClose} fullWidth={true} maxWidth="md">
          <EditNote
            handleSubmit={handleSubmitNote}
            handleClose={handleModalClose}
            data={getSelectedNote}
          />
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <h3 className="text-dark my-5">Late History</h3>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderLateHistoryRow}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              expandable
              handleExpand={handleEditNote}
              expandTitle="Add Note"
            />
          </div>
        </div>
      </div>
    </>
  );
}
