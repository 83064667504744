import React, { useEffect, useCallback, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Settings as SettingsIcon,
  PermContactCalendar as PermContactCalendarIcon,
  LocalOffer as LocalOfferIcon,
  AccountCircle as AccountCircleIcon,
  Description as DescriptionIcon,
  MonetizationOn as MonetizationOnIcon,
} from "@material-ui/icons";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { InputFile } from "../../../ui/components/InputFile";
import Tabs from "../../../ui/components/Tabs";
import Header from "../../../ui/structures/Header";
import { Contacts } from "../../../ui/structures/Contacts/index";
import CommentsTabs from "../../../ui/structures/CommentsStructures/CommentsTabs";
import { Settings } from "../../../ui/structures/Settings";
import { OrganisationDetails } from "../../../ui/structures/OrganisationDetails/index";
import OrganisationDetailsForm from "../../../ui/structures/OrganisationDetailsForm";
import { Securities } from "../../../ui/structures/Securities/index";
import { SecurityForm } from "../../../ui/structures/SecurityForm/index";
import { SecurityAdd } from "../../../ui/structures/SecurityAdd/index";
import { Accounts } from "../../../ui/structures/Accounts/index";
import { Shares } from "../../../ui/structures/Shares";
import { Users } from "../../../ui/structures/Users";
import { AccountForm } from "../../../ui/structures/AccountForm";
import File from "../../../ui/structures/File";
import { Tags } from "../../../ui/structures/Tags";
import { Files } from "../../../ui/structures/Files";
import { setTags } from "./_redux/actions";
import { getOrganisation } from "./_redux/selectors";
import {
  getOrganisationContacts,
  getOrganisationFiles,
  addOrganisationTags,
  deleteOrganisationTags,
  modifyOrganisation,
  getOrganisation as getOrganisationById,
  modifyOrganisationSecurity,
  modifyAccount,
  addAccount as addNewAccount,
} from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { downloadFile } from "../AWS-S3";
import { LogoUpload } from "./LogoUpload";

export function Card({ match, location }) {
  const dispatch = useDispatch();
  const { request } = useFetch();
  const defaultActiveTab = location.defaultActiveTab || "0";

  const organisationId = match.params.id;
  const data = useSelector(getOrganisation(organisationId));
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const { editMode } = location;

  const [cardData, setCardData] = useState({});
  const [loading, setLoading] = useState(true);
  const [isEditable, setIsEditable] = useState(editMode);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [viewSecurity, setViewSecurity] = useState(false);
  const [addSecurity, setAddSecurity] = useState(false);
  const [accountForm, setAccountForm] = useState(false);

  useEffect(() => {
    if (!data) {
      Promise.all([
        request(getOrganisationById, TENANT_ID, organisationId),
        request(getOrganisationContacts, TENANT_ID, organisationId),
        request(getOrganisationFiles, TENANT_ID, organisationId),
      ])
        .then(([organisation, contacts, files]) => {
          contacts &&
            files &&
            organisation &&
            setCardData({
              country: "AU",
              contacts,
              ...organisation,
              start_date:
                (organisation.start_date &&
                  new Date(organisation.start_date)) ||
                "",
              end_date:
                (organisation.end_date && new Date(organisation.end_date)) ||
                "",
              last_review_date:
                (organisation.last_review_date &&
                  new Date(organisation.last_review_date)) ||
                "",
              access_card_expiry_date:
                (organisation.access_card_expiry_date &&
                  new Date(organisation.access_card_expiry_date)) ||
                "",
              files,
            });
        })
        .then(setLoading(false));
    } else {
      Promise.all([
        request(getOrganisationContacts, TENANT_ID, organisationId),
        request(getOrganisationFiles, TENANT_ID, organisationId),
      ])
        .then(([contacts, files]) =>
          setCardData({
            ...data,
            contacts,
            files,
          })
        )
        .then(setLoading(false));
    }
    // eslint-disable-next-line
  }, []);

  const updateFiles = () => {
    request(getOrganisationFiles, TENANT_ID, organisationId).then((files) =>
      setCardData((state) => {
        return {
          ...state,
          files,
        };
      })
    );
  };

  const {
    name,
    legalName,
    email,
    country,
    tags = [],
    id,
    contacts: organisationContacts,
    files: organisationFiles,
    status,
  } = cardData || {};

  const handleTagAdd = useCallback(
    (tag) => {
      request(addOrganisationTags, TENANT_ID, organisationId, [
        ...tags,
        tag,
      ]).then((data) => data && dispatch(setTags(data, organisationId)));
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId, tags]
  );

  const handleTagDelete = useCallback(
    (tag) => {
      request(
        deleteOrganisationTags,
        TENANT_ID,
        organisationId,
        tags.filter((item) => item === tag)
      )
        .then((data) => data && dispatch(setTags(data, organisationId)))
        .then((data) =>
          setCardData((prevState) => ({
            ...prevState,
            tags: tags.filter((item) => item !== tag),
          }))
        );
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationId, tags]
  );

  // const reloadFiles = () => (
  //   request(getOrganisationFiles, organisationId)
  //     .then(files => {
  //       setCardData(prevState => ({
  //         ...prevState,
  //         files
  //       }))
  //     }
  //   )
  // );

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...prevState, ...acceptedFiles]);
  };

  const handleViewSecurity = (id) => {
    setViewSecurity(id);
  };

  const handleUpdateSecurity = (data) => {
    return request(
      modifyOrganisationSecurity,
      TENANT_ID,
      organisationId,
      viewSecurity.id,
      data
    );
  };

  const isAddNewAccountModeRun = () => accountForm === true;

  const onSubmitRequest = () => {
    return isAddNewAccountModeRun() ? addNewAccount : modifyAccount;
  };

  const handleUpdateCardData = (newData) => {
    setCardData((prevState) => ({
      ...prevState,
      ...newData,
    }));
  };

  const tabs = [
    {
      label: "Contact Details",
      icon: <LocalOfferIcon />,
      tabPanel: isEditable ? (
        <OrganisationDetailsForm
          data={cardData}
          onClose={setIsEditable}
          onSubmit={modifyOrganisation}
          setData={setCardData}
          user={user}
        />
      ) : (
        <OrganisationDetails
          data={cardData}
          tenantId={TENANT_ID}
          setIsEditable={setIsEditable}
        />
      ),
    },
    {
      label: "Tags",
      icon: <LocalOfferIcon />,
      tabPanel: (
        <Tags
          tagsList={tags}
          onTagAdd={handleTagAdd}
          onTagDelete={handleTagDelete}
          data={cardData}
          setData={setCardData}
          type="organisation"
        />
      ),
    },
    {
      label: "Contacts",
      icon: <PermContactCalendarIcon />,
      tabPanel: (
        <Contacts
          contacts={organisationContacts}
          organisationId={id}
          tenantId={TENANT_ID}
          name={name}
          loading={loading}
          status={status}
        />
      ),
    },
    {
      label: "Files",
      icon: <DescriptionIcon />,
      tabPanel: (
        <Files
          files={organisationFiles}
          onDownload={downloadFile}
          data={cardData}
        />
      ),
    },
    {
      label: "Settings",
      icon: <SettingsIcon />,
      tabPanel: (
        <Settings
          data={cardData}
          id={organisationId}
          setData={setCardData}
          isOrganisation
          handleUpdateCardData={handleUpdateCardData}
        />
      ),
    },
    {
      label: "Accounts",
      icon: <AccountCircleIcon />,
      tabPanel: accountForm ? (
        <AccountForm
          accountData={accountForm}
          setData={setAccountForm}
          onClose={setAccountForm}
          onSubmit={onSubmitRequest()}
          organisationId={organisationId}
          user={user}
          newAccount={isAddNewAccountModeRun()}
        />
      ) : (
        <Accounts
          onView={setAccountForm}
          id={organisationId}
          tenantId={TENANT_ID}
          status={status}
        />
      ),
    },
    {
      label: "Users",
      icon: <AssignmentIcon />,
      tabPanel: (
        <Users
          organisation_id={Number(organisationId)}
          TENANT_ID={TENANT_ID}
          tradingName={name}
          legalName={legalName}
        />
      ),
    },
    {
      label: "Comments",
      icon: <DescriptionIcon />,
      tabPanel: (
        <CommentsTabs
          organisationId={organisationId}
          name={name}
          tenantId={TENANT_ID}
          status={status}
        />
      ),
    },
    {
      label: "Securities",
      icon: <MonetizationOnIcon />,
      tabPanel: viewSecurity ? (
        <SecurityForm
          securityData={viewSecurity}
          onClose={setViewSecurity}
          setData={setViewSecurity}
          tenantId={TENANT_ID}
          onSubmit={handleUpdateSecurity}
        />
      ) : addSecurity ? (
        <SecurityAdd
          securityData={addSecurity}
          setData={setAddSecurity}
          onClose={setAddSecurity}
          tenantId={TENANT_ID}
          id={organisationId}
        />
      ) : (
        <Securities
          onView={handleViewSecurity}
          tenantId={TENANT_ID}
          organisationId={organisationId}
          setAddSecurity={setAddSecurity}
          status={status}
        />
      ),
    },
    {
      label: "Shares",
      icon: <AssignmentIcon />,
      tabPanel: (
        <Shares
          id={Number(organisationId)}
          TENANT_ID={TENANT_ID}
          tradingName={name}
          legalName={legalName}
        />
      ),
    },
    {
      label: "Logo",
      icon: <LocalOfferIcon />,
      tabPanel: (
        <LogoUpload
          organisation_id={Number(organisationId)}
          tenant_id={TENANT_ID}
          setCardData={setCardData}
          data={cardData}
        />
      ),
    },
  ];

  return (
    <>
      <div className="row mb-9">
        <div className="col-12">
          <Header
            name={name}
            email={email}
            geolocation={country}
            id={organisationId}
            tenantId={TENANT_ID}
            status={status}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="mb-9">
            <div className="d-flex flex-column rounded h-100">
              <Tabs
                defaultActive={defaultActiveTab}
                tabs={tabs}
                excludeTab={
                  parseInt(organisationId) === user.default_organisation_id &&
                  "Accounts"
                }
                loading={loading}
              />
            </div>
          </div>
          {status !== "archived" && (
            <InputFile
              className="rounded"
              onDrop={handleUploadFiles}
              disabled={addSecurity}
              useable={addSecurity}
            >
              {uploadedFiles.map((file) => (
                <File
                  key={file.lastModified}
                  senderId={viewSecurity ? viewSecurity.id : organisationId}
                  senderType={viewSecurity ? "security" : "organisation"}
                  file={file}
                  updateFiles={updateFiles}
                />
              ))}
            </InputFile>
          )}
        </div>
      </div>
    </>
  );
}
