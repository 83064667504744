import React, { useState, useEffect } from "react";
import TenantCustomRoleSwitcher from "./TenantCustomRoleSwitcher";
import { makeStyles } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import { getPermissionsByTenantId, getPermissionsByRoleId } from "./_api";
import { Button } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "16px 8px",
  },
}));

function groupBy(arr, prop) {
  const map = new Map(Array.from(arr, (obj) => [obj[prop], []]));
  arr.forEach((obj) => map.get(obj[prop]).push(obj));
  return Array.from(map.values());
}

export default function TenantAddRole({
  userId,
  tenantId,
  newUser,
  roleId,
  setAddCustomRole,
}) {
  const classes = useStyles();
  const [tenantPermissions, setTenantPermissions] = useState([]);
  const [rolePermissions, setRolePermissions] = useState([]);
  const { request } = useFetch();

  useEffect(() => {
    request(getPermissionsByTenantId, tenantId)
      .then((data) => {
        setTenantPermissions(groupBy(data, "resource_id"));
      })
      .then(request(getPermissionsByRoleId, roleId).then(setRolePermissions));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    request(getPermissionsByRoleId, roleId).then(setRolePermissions);
    // eslint-disable-next-line
  }, [roleId]);

  return (
    <div className={classes.root}>
      <h6 className="mb-5">You can add a Custom Role for this user</h6>
      {tenantPermissions.map((item) => {
        // item.map((byresource) => {
        //   console.log(byresource);
        // });
        // console.log("-----------------");
        return (
          <TenantCustomRoleSwitcher
            key={item[0].resource_id}
            resourceId={item[0].resource_id}
            permissionsByResource={item}
            rolePermissions={rolePermissions}
          />
        );
      })}

      <div className="d-flex justify-content-end align-items-center">
        <Button
          variant="outlined"
          color="secondary"
          size="large"
          className="px-9 mr-3"
          onClick={() => setAddCustomRole(false)}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className="px-9"
        >
          Save
        </Button>
      </div>
    </div>
  );
}
