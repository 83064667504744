import React, { useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedInput } from "../../../../../../../ui/components/Input";
import { MappedSelect } from "../../../../../../../ui/components/Select";
import { MappedDatePicker } from "../../../../../../../ui/components/DatePicker";

const fieldTypeOptions = [
  { label: "Credit", value: "credit" },
  { label: "Debit", value: "debit" },
];

const inputFields = [
  "transaction_description",
  "transaction_value",
  "bank_name",
];
const selectFields = ["transaction_type"];
const datePickerFIelds = ["transaction_date"];
const twoInputFields = ["is between"];
const multipleFields = ["contains all", "contains any"];

const renderFilterField = (fieldValue, fieldOperator) => {
  if (
    inputFields.includes(fieldValue) &&
    !twoInputFields.includes(fieldOperator) &&
    !multipleFields.includes(fieldOperator)
  ) {
    return (
      <MappedInput
        name="field_value"
        wrapperClassName="w-100 mb-5"
        label="Filter Value"
      />
    );
  } else if (
    selectFields.includes(fieldValue) &&
    !twoInputFields.includes(fieldOperator) &&
    !multipleFields.includes(fieldOperator)
  ) {
    return (
      <MappedSelect
        label="Filter Value"
        name="field_value"
        options={fieldTypeOptions}
      />
    );
  } else if (
    datePickerFIelds.includes(fieldValue) &&
    !twoInputFields.includes(fieldOperator) &&
    !multipleFields.includes(fieldOperator)
  ) {
    return (
      <>
        <div className="mt-7">
          <MappedDatePicker name="field_value" />
        </div>
      </>
    );
  } else if (
    twoInputFields.includes(fieldOperator) &&
    fieldValue === "transaction_date"
  ) {
    return (
      <>
        <div className="mb-5">
          <MappedDatePicker name="field_value" />
        </div>
        <MappedDatePicker name="field_value_2" />
      </>
    );
  } else if (twoInputFields.includes(fieldOperator)) {
    return (
      <>
        <MappedInput
          name="field_value"
          wrapperClassName="w-100 mb-5"
          label="Filter Value"
        />
        <MappedInput
          name="field_value_2"
          wrapperClassName="w-100 mb-5"
          label="Filter Value"
        />
      </>
    );
  } else if (multipleFields.includes(fieldOperator)) {
    return (
      <>
        <MappedInput
          name="field_value"
          wrapperClassName="w-100 mb-5"
          label="Filter Value"
        />
        <div>
          You can add multiple value, with separator (example:
          value1#/#value2#/#value3)
        </div>
      </>
    );
  }
};

export const FilterValueField = () => {
  const { values, setFieldValue } = useFormikContext();

  useEffect(() => {
    if (values.field_name) {
      setFieldValue("field_value_2", "");
      setFieldValue("field_value_3", "");
    }
    // eslint-disable-next-line
  }, [values.operator]);

  return (
    <div style={{ width: "27%", minHeight: "100px" }}>
      {renderFilterField(values.field_name, values.operator)}
    </div>
  );
};
