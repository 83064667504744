import config from "react-global-configuration";
import { warning } from "../../../helpers/toasts";

export const uploadFile = (
  status,
  tenant_id,
  integration_type,
  fileName,
  lastUploadId,
  getUrl = getUploadFileUrl
) => {
  if (lastUploadId) {
    return getIntegrationById(lastUploadId)
      .then((data) => data.json())
      .then(({ data }) => {
        if (data.csv_file.last_modified) {
          return getUrl(fileName, "integration", data.id);
        } else {
          warning("There is a BSB integration, but no connected file");
          return getUrl(fileName, "integration", data.id);
        }
      })
      .then((data) => data.json())
      .then(({ data }) => startUpload(fileName, data))
      .catch(console.error);
  } else {
    return getIntegrationId(status, tenant_id, integration_type)
      .then((data) => data.json())
      .then(({ data }) => getUrl(fileName, integration_type, data.id))
      .then((data) => data.json())
      .then(({ data }) => startUpload(fileName, data))
      .catch(console.error);
  }
};

const getIntegrationById = (lastUploadId) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${lastUploadId}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    credentials: "include",
  });

const getIntegrationId = (status, tenant_id, integration_type) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      status: status,
      tenant_id: tenant_id,
      integration_type: integration_type,
    }),
  });

const getUploadFileUrl = (fileName, type, resource_id) => {
  return fetch(`${config.get("ApiUrl").Rest}/files/request-upload-url`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      file_name: fileName.name,
      type: type,
      resource_id: resource_id,
    }),
  });
};

const startUpload = (fileName, data) => {
  const formData = new FormData();
  const { url, fields } = data;

  Object.keys(fields).forEach((key) => {
    const value = fields[key];

    formData.append(key, value);
  });

  formData.append("file", fileName);

  return fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: formData,
  });
};
