import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react";
import { useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Chip, Paper, makeStyles, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { MappedInput } from "../../../../ui/components/Input";
import { MappedDatePicker } from "../../../../ui/components/DatePicker";
import { useFetch } from "../../../../hooks/fetch.hook";
import { MappedSelect } from "../../../../ui/components/Select";
import { getTagsList } from "../../Organisations/_api";
import { getAccountsList } from "../../LedgerAccounts/_api";
import { getFeeById, createFee, modifyFee } from "./_api";
import { getTaxes } from "../TaxesModule/_api";
import { getFee } from "./_redux/selectors";
import { CalculationPanel } from "./CalculationPanel";
import { ReactComponent as EqualIcon } from "./static/equal.svg";
import {
  formatDateToUTC,
  removeEmptyFields,
} from "../../../../ui/helpers/helpers";
import {
  typeOptions,
  recurringFreqOptions,
  recurringDayOptions,
  recurringMonthsOptions,
  recurringMonthlyDaysOptions,
  actionHookOptions,
  dotStyle,
  specialOperations,
  filterOperations,
} from "./constants";
import { MinimumFee } from "./MinimumFee";
import { FeesCardSchema } from "../../../../helpers/schemas";
import { MappedSwitch } from "../../../../ui/components/Switch";
import { ReactComponent as PLusIcon } from "./static/plus.svg";
import { ReactComponent as MinusIcon } from "./static/minus.svg";
import { ReactComponent as DevideIcon } from "./static/devide.svg";
import { ReactComponent as MultiplyIcon } from "./static/multiply.svg";
import { ReactComponent as OpenBracket } from "./static/openBracket.svg";
import { ReactComponent as CloseBracket } from "./static/closeBracket.svg";

const calculationRegexp = /(^[-+*/}{.0-9\b]+$)/;

const useStyles = makeStyles({
  root: {
    backgroundColor: "#06AEE3",
    cursor: "pointer",
    color: "white",
    fontSize: "1rem",
    height: 24,
  },
  deleteIcon: { color: "white" },
});

const symbolsMap = {
  "+": <PLusIcon />,
  "-": <MinusIcon />,
  "*": <MultiplyIcon />,
  "/": <DevideIcon />,
  "{": <OpenBracket />,
  "}": <CloseBracket />,
  ".": <span style={dotStyle}></span>,
};

function FeeCard({ history, match, location }) {
  const feeId = match.params.id;
  const isNew = location.pathname === "/add-fee";
  const data = useSelector(getFee(feeId));

  const classes = useStyles();
  const formikRef = useRef();

  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const DEFAULT_ORGANISATION_ID = user.default_organisation_id;

  const [cardData, setCardData] = useState(data);
  const [accounts, setAccounts] = useState([]);
  const [taxes, setTaxes] = useState([]);
  const [tags, setTags] = useState([]);
  const [inputValue, setInputValue] = useState([]);

  useEffect(() => {
    Promise.all([
      request(getAccountsList, TENANT_ID, DEFAULT_ORGANISATION_ID),
      request(getTagsList, "organisation", TENANT_ID),
      request(getTaxes, TENANT_ID),
    ]).then(([accounts, tags, taxes]) => {
      if (!accounts || !tags || !taxes) return;
      setAccounts(accounts);
      setTaxes(taxes);
      setTags(tags.map((tag) => ({ ...tag, id: String(tag.id) })));
      setCaclValueOnload(data);
    });

    if (!isNew && !data) {
      request(getFeeById, TENANT_ID, feeId).then((response) => {
        setCardData(response);
        setCaclValueOnload(response);
      });
    }
    // eslint-disable-next-line
  }, [TENANT_ID]);

  const setCaclValueOnload = (data) => {
    if (data) {
      formikRef.current.setFieldValue("calculation", data.calculation);
      setInputValue([
        ...inputValue,
        { value: data.calculation, key: inputValue.length + 1 },
      ]);
    }
  };

  const {
    name,
    start_date,
    end_date,
    fee_type,
    category,
    tax_id,
    account_id,
    recurring_freq,
    recurring_day,
    recurring_month,
    action_hook,
    action_tag_filter,
    calculation,
    details,
    fee_criteria = [],
    minimum_fee_enabled,
    minimum_fee_value,
  } = cardData || {};

  const initialValues = {
    fee_type: fee_type || "",
    details: details || "",
    name: name || "",
    start_date: (start_date && new Date(start_date)) || "",
    end_date: (end_date && new Date(end_date)) || "",
    category: category || "",
    tax_id: tax_id || "",
    account_id: account_id || "",
    recurring_freq: recurring_freq || "",
    recurring_month: recurring_month || "",
    recurring_day: recurring_day || "",
    action_hook: action_hook || "",
    action_tag_filter: action_tag_filter || [],
    calculation: calculation || "",
    filter_hook: (fee_criteria && fee_criteria[0]) || "",
    filter_action: (fee_criteria && fee_criteria[1]) || "",
    filter_criteria:
      (fee_criteria && fee_criteria[0] === "BillingStartDate"
        ? new Date(fee_criteria[2])
        : fee_criteria[2]) || "",
    filter_criteria_2: (fee_criteria && fee_criteria[3]) || "",
    minimum_fee_enabled: minimum_fee_enabled || false,
    minimum_fee_value: minimum_fee_value || "",
  };

  const handleRemove = (key, value, calculation, setFieldValue) => () => {
    setInputValue(inputValue.filter((i) => i.key !== key));
    setFieldValue("calculation", calculation.replace(value, ""));
  };

  const setCalculation = (setValue, fieldValue) => ({ target: { value } }) => {
    if (!value) return;
    setValue("calculation", fieldValue + value);
    setInputValue([...inputValue, { value, key: inputValue.length + 1 }]);
  };

  const checkKeyValue = (event, calculation, setFieldValue) => {
    const lastCharacter = calculation.slice(calculation.length - 1);
    if (!calculationRegexp.test(event.key)) {
      event.preventDefault();
    }
    if (event.key === "Backspace" && calculationRegexp.test(lastCharacter)) {
      setInputValue(inputValue.slice(0, inputValue.length - 1));
      setFieldValue("calculation", calculation.slice(0, -1));
    }
  };

  const isMathOperation = (value) =>
    ["+", "-", "*", "/", "{", "}", "."].includes(value);

  const isNumber = (value) => /(^[0-9]+$)/.test(value);

  const accountOptions = useMemo(
    () =>
      accounts.map(({ id, account_name }) => ({
        value: id,
        label: account_name,
      })),
    [accounts]
  );

  const tagsOptions = useMemo(
    () => tags.map((item) => ({ value: item.name, label: item.name })),
    [tags]
  );

  const taxesOptions = useMemo(
    () =>
      taxes.map(({ id, name, rate }) => ({
        value: id,
        label: name + ` (${rate})`,
      })),
    [taxes]
  );

  const handleClose = () => history.push(`/fees`);

  const checkLength = () => Boolean(inputValue.length);

  const handleReset = useCallback(
    (setValue) => () => {
      setValue("calculation", "");
      setInputValue([]);
    },
    []
  );

  const handleFilterBlur = (values, setFieldValue) => () =>
    values.filter_action !== "between" &&
    setFieldValue("filter_criteria_2", "");

  const handleHookBlur = (values, setFieldValue) => () => {
    if (values.filter_hook === "BillingStartDate") {
      setFieldValue("filter_criteria", "");
      setFieldValue("filter_criteria_2", "");
    }
  };

  if (!DEFAULT_ORGANISATION_ID) {
    return (
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="text-center">
            <h6>Opps, missing default organization for this tenant</h6>
            <Link to={"/tenants-list/" + user.selectedTenant}>
              <button className="px-15 mr-5 btn btn-primary">
                Click here to setup
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={FeesCardSchema}
      innerRef={formikRef}
      onSubmit={(values) => {
        removeEmptyFields(values);
        const payload = {
          ...values,
          fee_criteria: [
            values.filter_hook,
            values.filter_action,
            values.filter_criteria,
            values.filter_criteria_2,
          ],
          start_date: formatDateToUTC(values.start_date),
          end_date: formatDateToUTC(values.end_date, true),
        };
        if (isNew) {
          request(createFee, TENANT_ID, payload).then((data) => {
            if (data) {
              return history.push(`/fees`);
            }
          });
        } else {
          request(modifyFee, TENANT_ID, feeId, payload).then((data) => {
            if (data) {
              return history.push(`/fees`);
            }
          });
        }
      }}
    >
      {({ setFieldValue, values, handleSubmit, ...formik }) => (
        <>
          <div className="bg-white rounded mb-10">
            <h5 className="ml-15 pt-10">{feeId ? "Edit Fee" : "Add Fee"}</h5>
            <hr />
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Fee Name : </p>
                </div>
                <div className="col-3 mr-20">
                  <MappedInput name="name" wrapperClassName="w-100" />
                </div>
                <div className="col-2 mt-3">
                  <p>Tax Rate: </p>
                </div>
                <div className="col-3">
                  <MappedSelect name="tax_id" options={taxes && taxesOptions} />
                  {/* <MappedInput name="tax_id" wrapperClassName="w-100" /> */}
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Start Date : </p>
                </div>
                <div className="col-3 mr-20">
                  <MappedDatePicker name="start_date" dateFormat="yyyy-MM-dd" />
                </div>
                <div className="col-2 mt-3">
                  <p>Category: </p>
                </div>
                <div className="col-3">
                  <MappedInput name="category" wrapperClassName="w-100" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>End Date : </p>
                </div>
                <div className="col-3 mr-20">
                  <MappedDatePicker name="end_date" dateFormat="yyyy-MM-dd" />
                </div>
                <div className="col-2 mt-3">
                  <p>Revenue G/L : </p>
                </div>
                <div className="col-3">
                  <MappedSelect
                    name="account_id"
                    options={accounts && accountOptions}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Type : </p>
                </div>
                <div className="col-3">
                  <MappedSelect name="fee_type" options={typeOptions} />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Fee Details : </p>
                </div>
                <div className="col-9">
                  <MappedInput name="details" wrapperClassName="w-100" />
                </div>
              </div>

              <hr className="my-10" />

              <div className="mb-5">
                <small>
                  <strong>Recurring Transaction Settings</strong>
                </small>
              </div>
              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Frequency : </p>
                </div>
                <div className="col-3 mr-20">
                  <MappedSelect
                    name="recurring_freq"
                    disabled={![values.fee_type].includes("recurring")}
                    options={recurringFreqOptions}
                  />
                </div>
                {values.recurring_freq !== "daily" && (
                  <>
                    <div className="col-2 mt-3">
                      <p>
                        {["weekly", "fortnightly"].includes(
                          values.recurring_freq
                        )
                          ? "Day of the week"
                          : values.recurring_freq === "monthly"
                          ? "Day of the month"
                          : values.recurring_freq === "annual"
                          ? "Day of the year"
                          : ""}
                        :{" "}
                      </p>
                    </div>
                    <div className="col-3">
                      {values.recurring_freq === "annual" && (
                        <MappedSelect
                          name="recurring_month"
                          disabled={![values.fee_type].includes("recurring")}
                          options={recurringMonthsOptions}
                        />
                      )}
                      <MappedSelect
                        name="recurring_day"
                        disabled={![values.fee_type].includes("recurring")}
                        options={
                          values.recurring_freq === "weekly"
                            ? recurringDayOptions
                            : values.recurring_freq === "annual" &&
                              values.recurring_month === "02"
                            ? recurringMonthlyDaysOptions.filter(
                                ({ value }) => value !== "31"
                              )
                            : ["monthly", "annual"].includes(
                                values.recurring_freq
                              )
                            ? recurringMonthlyDaysOptions
                            : recurringDayOptions
                        }
                      />
                    </div>
                  </>
                )}
              </div>

              <hr className="my-10" />

              <div className="mb-5">
                <small>
                  <strong>Action Transaction Settings</strong>
                </small>
              </div>
              <div className="row mb-3">
                <div className="col-2 mt-3">
                  <p>Point of action_hook : </p>
                </div>
                <div className="col-3 mr-20">
                  <MappedSelect
                    name="action_hook"
                    disabled={![values.fee_type].includes("action")}
                    options={actionHookOptions}
                  />
                </div>
                <div className="col-2 mt-3">
                  <p>Tag Filter : </p>
                </div>
                <div className="col-3">
                  <MappedSelect
                    name="action_tag_filter"
                    multiple
                    options={tags && tagsOptions}
                  />
                </div>
              </div>
              <hr className="my-10" />

              <div className="mb-5">
                <small>
                  <strong>Fee Criteria</strong>
                </small>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <MappedSelect
                    name="filter_hook"
                    options={specialOperations.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onBlur={handleHookBlur(values, setFieldValue)}
                  />
                </div>
                <div className="col-2">
                  <MappedSelect
                    name="filter_action"
                    options={filterOperations.map((item) => ({
                      value: item,
                      label: item,
                    }))}
                    onBlur={handleFilterBlur(values, setFieldValue)}
                  />
                </div>
                <div className="col-3">
                  {values.filter_hook === "BillingStartDate" ? (
                    <MappedDatePicker name="filter_criteria" />
                  ) : (
                    <MappedInput
                      name="filter_criteria"
                      wrapperClassName="w-100"
                    />
                  )}
                </div>
                {values.filter_action === "between" && (
                  <div className="col-3">
                    <MappedInput
                      name="filter_criteria_2"
                      wrapperClassName="w-100"
                    />
                  </div>
                )}
              </div>

              <hr className="my-10" />

              <div className="mb-5">
                <small>
                  <strong>Enable Minimum Fee</strong>
                </small>
              </div>
              <div className="row mb-3">
                <div className="col-3">
                  <MappedSwitch
                    name="minimum_fee_enabled"
                    checked={values.minimum_fee_enabled}
                  />
                </div>
                <MinimumFee />
              </div>

              <div className="mb-5">
                <small>
                  <strong>Fee Calculation</strong>
                </small>
              </div>
              <div className="col-11 d-flex align-items-center">
                <EqualIcon className="mr-5" />
                <Paper
                  className={`d-flex align-items-center border border-secondary rounded col-11 overflow-auto`}
                  elevation={0}
                  style={{ fontSize: "1.6rem", height: 50 }}
                >
                  <div className="flex-shrink-0">
                    {inputValue.map(({ value, key }) => {
                      return isMathOperation(value) ? (
                        <span key={key} className={value !== "." ? "mx-3" : ""}>
                          {symbolsMap[value]}
                        </span>
                      ) : isNumber(value) ? (
                        value
                      ) : (
                        <Chip
                          label={value}
                          key={key}
                          onDelete={handleRemove(
                            key,
                            value,
                            values.calculation,
                            setFieldValue
                          )}
                          className="mx-3"
                          classes={classes}
                        />
                      );
                    })}
                  </div>
                  {/* This kind of input is used for accurate display of the validation error message. If standart MappedInput component is used - error message is covered by Paper*/}
                  <input
                    id="calculation"
                    type="text"
                    {...formik.getFieldProps("calculation")}
                    value=""
                    onKeyDown={(event) =>
                      checkKeyValue(event, values.calculation, setFieldValue)
                    }
                    onChange={setCalculation(setFieldValue, values.calculation)}
                    className="form-control form-control-solid bg-white px-0 border-0 w-100 h-100"
                  />
                  {checkLength() && (
                    <IconButton
                      onClick={handleReset(setFieldValue)}
                      size="small"
                    >
                      <ClearIcon className="m-2" color="secondary" />
                    </IconButton>
                  )}
                </Paper>
              </div>
              {formik.touched.calculation && formik.errors.calculation ? (
                <div className="text-danger">{formik.errors.calculation}</div>
              ) : null}
              <CalculationPanel
                setCalculation={setCalculation}
                setValue={setFieldValue}
                fieldValue={values.calculation}
              />
            </div>
            <hr className="mt-15 mb-10" />
            <div className="d-flex justify-content-end align-items-center mr-10 pb-10">
              <Button
                onClick={handleClose}
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
              >
                Cancel
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
              >
                Save
              </Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(FeeCard);
