/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { formatAmount, objectIsEmpty } from "../../../ui/helpers/helpers";
import { makeStyles } from "@material-ui/core/";
import { useSelector } from "react-redux";
import { getNABPyByWeb, getPaymentMethods } from "./_api";
import { Skeleton } from "@material-ui/lab";

import BankTransferIcon from "./static/BankTransfer.png";
import DirectDebit from "./static/DirectDebit.png";
import VisaIcon from "./static/Visa.png";
import MasterCard from "./static/MasterCard.png";
import AmericanExpress from "./static/americanexpress.png";
import BPayIcon from "./static/BPay.png";

const useStyles = makeStyles(() => ({
  pay_button: {
    margin: "8px auto 27px auto",
    display: "block",
    background: "none",
    border: "none",
    color: "#3699FF",
    transition:
      "color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease",
    "&:hover": {
      color: "#0073e9",
    },
  },
}));

export const BuyerPayment = () => {
  const classes = useStyles();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [paymentMethodsData, setPaymentMethodsData] = useState({});
  const [selectedCardType, setSelectedCardType] = useState("");

  const fetchNABPyByWeb = (selectedCardType) => {
    setLoading(true);

    request(getNABPyByWeb, TENANT_ID, ORGANISATION_ID, selectedCardType).then(
      (data) => {
        if (data) {
          setData(data);
        }
        setLoading(false);
      }
    );
  };

  const fetchPaymentMethods = () => {
    request(getPaymentMethods, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        setPaymentMethodsData(data);
      }
    });
  };

  useEffect(() => {
    fetchPaymentMethods();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedCardType) {
      fetchNABPyByWeb(selectedCardType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCardType, setSelectedCardType]);

  return (
    <div className="bg-white">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          flex: "1 0 auto",
          width: "100%",
          backgroundColor: "#292824",
          backgroundImage:
            "linear-gradient(to right, #292824 0%, transparent 100%), url('/media/bg/cherry.jpg')",
          backgroundPosition: "50% 34%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxHeight: "200px",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Payment Methods
          </div>
        </div>
      </div>
      <div
        className="d-flex justify-content-between flex-wrap mt-10 pb-10"
        style={{ margin: "0 auto", width: "60%", fontSize: "14px" }}
      >
        <div className="w-100 d-flex mb-5 justify-content-between">
          <div
            className="p-3"
            style={{
              border: "1px solid #1f3d6e",
              width: "48%",
              color: "#1f3d6e",
            }}
          >
            <div className="mt-2 mb-8 d-flex justify-content-between align-items-center">
              <div>
                <img src={BPayIcon} alt="Logo" width="120%" />
              </div>
              <div
                className="p-2"
                style={{ border: "1px solid #1f3d6e", width: "85%" }}
              >
                <h4>
                  Biller Code:{" "}
                  {paymentMethodsData?.bpay_biller_code
                    ? paymentMethodsData?.bpay_biller_code
                    : "XXX"}
                </h4>
                <h4>
                  REF:{" "}
                  {paymentMethodsData?.customer_reference_number
                    ? paymentMethodsData?.customer_reference_number
                    : "XXX XXX XXX"}
                </h4>
              </div>
            </div>
            <h4>Telephone &amp; Internet Banking - Bpay</h4>
            <div>
              Contact your bank or financial institution to make this payment
              from your cheque, savings, debit, credit card or transaction
              account. More info : www.bpay.com.au
            </div>
          </div>
          <div
            className="p-3"
            style={{ border: "1px solid black", width: "48%" }}
          >
            <h3 className="text-center">
              <strong>EFT</strong>
            </h3>
            <p className="text-center">
              Pay your bill via Electronic Funds Transfer
            </p>
            <div className="d-flex justify-content-between">
              <div className="w-75">
                <p>
                  BSB: <strong>BSB Setting</strong>
                </p>
                <p>
                  Account Number: <strong>Account No</strong>
                </p>
                <p>
                  Reference number: <strong>Buyer Ref No</strong>
                </p>
              </div>
              <div className="w-25 text-right">
                <img src={BankTransferIcon} alt="Logo" width="100%" />
              </div>
            </div>
          </div>
        </div>

        <div className="w-100 d-flex mb-5 justify-content-between">
          <div
            className="p-3 text-center"
            style={{ border: "1px solid #1f3d6e", width: "48%" }}
          >
            <h3>
              <strong>DIRECT DEBIT</strong>
            </h3>
            <p> To pay by direct debit</p>
            <p>
              <a href="#">Click here to sign up</a>
            </p>
            <p>
              <img src={DirectDebit} alt="Logo" />
            </p>
            <p>Or contact Brismark Credit Service for details.</p>
          </div>
          <div
            className="p-3"
            style={{ border: "1px solid #1f3d6e", width: "48%" }}
          >
            <h3 className="text-center">
              <strong>CREDIT CARD</strong>
            </h3>
            <p className="text-center">
              To pay your bill select your card type:
            </p>
            <div className="d-flex justify-content-center">
              {!objectIsEmpty(paymentMethodsData?.nab_integration) &&
              (paymentMethodsData?.nab_integration[`mastercard_fixed_fee`] ||
                paymentMethodsData?.nab_integration[
                  `mastercard_percentage_fee`
                ]) ? (
                <a
                  style={{
                    width: "20%",
                    margin: "auto 8px auto 0",
                    border:
                      selectedCardType == "mastercard" ? "1px solid green" : "",
                    borderRadius: "0.42rem",
                    overflow: "hidden",
                  }}
                  onClick={() => setSelectedCardType("mastercard")}
                >
                  <img src={MasterCard} alt="Logo" width="100%" />
                </a>
              ) : (
                ""
              )}
              {!objectIsEmpty(paymentMethodsData?.nab_integration) &&
              (paymentMethodsData?.nab_integration[`visa_fixed_fee`] ||
                paymentMethodsData?.nab_integration[`visa_percentage_fee`]) ? (
                <a
                  style={{
                    width: "20%",
                    margin: "auto 8px auto 0",
                    border: selectedCardType == "visa" ? "1px solid green" : "",
                    borderRadius: "0.42rem",
                    overflow: "hidden",
                  }}
                  onClick={() => setSelectedCardType("visa")}
                >
                  <img src={VisaIcon} alt="Logo" width="100%" />
                </a>
              ) : (
                ""
              )}
              {!objectIsEmpty(paymentMethodsData?.nab_integration) &&
              (paymentMethodsData?.nab_integration[
                `americanexpress_fixed_fee`
              ] ||
                paymentMethodsData?.nab_integration[
                  `americanexpress_percentage_fee`
                ]) ? (
                <a
                  style={{
                    width: "20%",
                    margin: "auto 0",
                    border:
                      selectedCardType == "americanexpress"
                        ? "1px solid green"
                        : "",
                    borderRadius: "0.42rem",
                    overflow: "hidden",
                  }}
                  onClick={() => setSelectedCardType("americanexpress")}
                >
                  <img src={AmericanExpress} alt="Logo" width="100%" />
                </a>
              ) : (
                ""
              )}
            </div>
            {loading ? (
              <Skeleton variant="rect" width={"100%"} height={51} />
            ) : (
              <p style={{ wordBreak: "break-all" }}>
                {data && selectedCardType && (
                  <form method="post" action={data.url}>
                    {data?.parameters?.map((e) => (
                      <input
                        type="hidden"
                        name={Object.entries(e)[0][0]}
                        value={Object.entries(e)[0][1]}
                      />
                    ))}
                    <input
                      type="submit"
                      value="https://paybyweb.nab.com.au"
                      className={classes.pay_button}
                    ></input>
                  </form>
                )}
              </p>
            )}
            <div className="mt-2 d-flex justify-content-between align-items-center">
              <div className="w-75">
                <p className="mb-0">Phone or Visit Credit Service</p>
                <p className="mb-0">
                  Reference number:{" "}
                  {paymentMethodsData?.nab_integration?.settlement_account_id
                    ? paymentMethodsData?.nab_integration?.settlement_account_id
                    : "XXXXX"}
                </p>
                <p className="mb-0">
                  Surcharge applies:{" "}
                  {!objectIsEmpty(paymentMethodsData?.nab_integration) &&
                  paymentMethodsData?.nab_integration[
                    `${selectedCardType}_percentage_fee`
                  ]
                    ? paymentMethodsData?.nab_integration[
                        `${selectedCardType}_percentage_fee`
                      ] + "%"
                    : "XX%"}{" "}
                  {!objectIsEmpty(paymentMethodsData?.nab_integration) &&
                  paymentMethodsData?.nab_integration[
                    `${selectedCardType}_fixed_fee`
                  ]
                    ? "(" +
                      formatAmount(
                        +paymentMethodsData?.nab_integration[
                          `${selectedCardType}_fixed_fee`
                        ]
                      ) +
                      ")"
                    : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
