import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { downloadFile } from "../AWS-S3";
import { MappedSelect } from "../../../ui/components/Select";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { formatDate, formatDateForBackend } from "../../../ui/helpers/helpers";
import { downloadReportGET } from "../Reports/_api";

const REPORTS = [
  { label: "Securities", value: "M07_securities_report" },
  { label: "Inactive Buyers", value: "M08_inactive_buyers" },
  { label: "Profits and Loss", value: "D04_profit_and_loss" },
  { label: "Buyer List", value: "buyer_list" },
  {
    label: "Billing Entity Gross Distribution",
    value: "M11_billing_entity_gross_distribution",
  },
];

const BUYER_LIST_REPORTS = [
  { label: "Accelerated Buyer List", value: "accelerated" },
  { label: "Extended Buyer List", value: "extended" },
  { label: "NCS Buyer List (Access Card)", value: "ncs_buyer_access_card" },
  {
    label: "NCS Buyer List (No Access Card)",
    value: "ncs_buyer_no_access_card",
  },
];

export function ReportsPage() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;

  const handleSubmit = (values, { setSubmitting }) => {
    const report_data = {
      ...values,
      report_start: values.report_start
        ? formatDateForBackend(values.report_start)
        : "",
      report_end: values.report_end
        ? formatDateForBackend(values.report_end)
        : "",
    };
    downloadFile(report_data, downloadReportGET, TENANT_ID).finally(() =>
      setSubmitting(false)
    );
  };

  const initialValues = {
    report_start: "",
    report_end: "",
    report_name: "",
  };

  return (
    <>
      <div className="row justify-content-center rounded align-content-start h-100 m-0">
        <div className="col-12 bg-white">
          <div className="py-7 px-10">
            <div className="d-flex flex-column justify-content-between">
              <div className="mb-8 d-flex justify-content-between align-content-start">
                <h2 className="font-weight-bold">Reports</h2>
              </div>
              <div className="w-100 mb-5">
                <Formik
                  initialValues={initialValues}
                  // enableReinitialize
                  validationSchema={Yup.object({
                    report_name: Yup.string().required(
                      "Please select a report!"
                    ),
                    report_start: Yup.date(),
                    report_end: Yup.date().when(
                      "report_start",
                      (report_start, schema) =>
                        report_start &&
                        schema.min(
                          report_start,
                          "Can't be earlier than start date"
                        )
                    ),
                    report_type: Yup.string().when("report_name", {
                      is: "buyer_list",
                      then: Yup.string().required(
                        "Select buyer list report type"
                      ),
                      otherwise: Yup.string().strip(),
                    }),
                  })}
                  onSubmit={handleSubmit}
                >
                  {({ values, handleSubmit, isSubmitting }) => (
                    <Form>
                      <div className="row mb-5">
                        <div className="col-2">
                          <p className="text-right text-muted mt-3">
                            Select Dates
                          </p>
                        </div>
                        <div className="col-8 mr-auto">
                          <div className="row align-items-center">
                            <div className="col-2">
                              <MappedDatePicker
                                name="report_start"
                                className="border border-secnodary"
                                placeholderText={formatDate(
                                  new Date(Date.now())
                                )}
                              />
                            </div>
                            <div className="col-1">
                              <div className="text-center text-muted">to</div>
                            </div>
                            <div className="col-2">
                              <MappedDatePicker
                                name="report_end"
                                className="border border-secnodary"
                                placeholderText={formatDate(
                                  new Date(Date.now())
                                )}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-2">
                          <p className="text-right text-muted mt-3">Report</p>
                        </div>
                        <div className="col-8 mr-auto">
                          <MappedSelect
                            name="report_name"
                            style={{ borderColor: "white" }}
                            options={REPORTS}
                          />
                        </div>
                      </div>

                      {values.report_name === "buyer_list" ? (
                        <div className="row">
                          <div className="col-2">
                            <p className="text-right text-muted mt-3">
                              Select Type
                            </p>
                          </div>
                          <div className="col-8 mr-auto">
                            <MappedSelect
                              name="report_type"
                              style={{ borderColor: "white" }}
                              options={BUYER_LIST_REPORTS}
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="row my-5">
                        <button
                          className="px-15 mr-5 btn btn-primary mx-auto"
                          onClick={handleSubmit}
                          disabled={isSubmitting}
                        >
                          Generate Report
                        </button>
                      </div>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
