import React from "react";
import { TablePagination } from "@material-ui/core";

export const ServerPagination = ({
  handleChangePerPage,
  perPage,
  page,
  handleChangePage,
  loading,
  data,
  totalRecords,
}) => {
  const labelDisplayedRows = ({ from, to }) => {
    return `${from}-${to} of ${totalRecords}`;
  };

  return (
    <div className="server-pagination">
      <TablePagination
        component="div"
        count={-1}
        page={page}
        onChangePage={handleChangePage}
        rowsPerPage={perPage}
        onChangeRowsPerPage={handleChangePerPage}
        nextIconButtonProps={{ disabled: data.length < perPage || loading }}
        backIconButtonProps={{ disabled: !data.length || loading || page === 0 }}
        labelDisplayedRows={labelDisplayedRows}
      />
    </div>
  );
};
