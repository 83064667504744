import { newRuleAction } from "./actions";

const initialState = {
  rules: [],
  rows: [],
  action: {},
};

export function NewRuleFormReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case newRuleAction.ADD_RULE:
      return {
        ...state,
        rules: [...state.rules, payload],
      };

    case newRuleAction.ADD_ROW:
      return {
        ...state,
        rows: [...state.rows, { id: state.rows.length + 1 }],
      };

    case newRuleAction.REMOVE_DATA:
      return {
        ...state,
        rows: [...state.rows.filter(({ id }) => id !== payload)],
        rules: [...state.rules.filter(({ id }) => id !== payload)],
      };

    case newRuleAction.ADD_ACTION:
      return {
        ...state,
        action: payload,
      };

    case newRuleAction.RESET:
      return initialState;

    default:
      return state;
  }
}
