import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Tabs from "../../components/Tabs";
import Comments from "./Comments";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getCommentType,
  getComments,
} from "../../../app/modules/Organisations/_api";
import { formatDate } from "../../helpers/helpers";
import { CommentForm } from "./CommentForm";

const CommentsTabs = ({ organisationId, name, tenantId, status }) => {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const [newComment, setNewComment] = useState(false);
  const [itemId, setItemId] = useState({ editMode: false, id: "" });
  const [commentTypes, setCommentTypes] = useState({});
  const [commentTypeOptions, setCommentTypeOptions] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadComments = () => {
    Promise.all([
      request(getCommentType, tenantId),
      request(getComments, tenantId, organisationId),
    ]).then(([commentTypes, comments]) => {
      const types = {};
      commentTypes.forEach((type) => {
        const name = type.comment_category;
        if (!types[name]) {
          types[name] = { ids: {}, comments: [] };
        }
        types[name].ids[type.id] = type.comment_type_name;
      });
      setCommentTypeOptions([
        { value: 0, label: "All type" },
        ...commentTypes.map(({ id, comment_type_name }) => ({
          value: id,
          label: comment_type_name,
        })),
      ]);

      comments.forEach((comment) => {
        const includes = Object.keys(types).find(
          (type) => types[type].ids[comment.comment_type_id]
        );
        if (includes) {
          types[includes].comments.push({
            ...comment,
            created_at: formatDate(new Date(comment.created_at)),
            last_modified: formatDate(new Date(comment.last_modified)),
            category: (
              commentTypes.find(({ id }) => comment.comment_type_id === id) ||
              {}
            ).comment_category,
            comment_type_name: (
              commentTypes.find(({ id }) => comment.comment_type_id === id) ||
              {}
            ).comment_type_name,
            user: comment.user.name,
          });
        }
      });
      setCommentTypes(types);
      setLoading(false);
    });
    // eslint-disable-next-line
  };

  useEffect(loadComments, []);

  const tabs = Object.entries(commentTypes).map(([type, { ids, comments }]) => {
    return {
      label: type,
      tabPanel: newComment ? (
        <CommentForm
          id={organisationId}
          tenantId={tenantId}
          organisationName={name}
          commentTypes={ids}
          setNewComment={setNewComment}
          user={user.fullname}
          onAfterSubmit={loadComments}
          comment={
            itemId.editMode
              ? comments.find((comment) => comment.id === itemId.id)
              : ""
          }
        />
      ) : (
        <Comments
          data={comments}
          setNewComment={setNewComment}
          commentType={type}
          commentTypeOptions={commentTypeOptions}
          id={organisationId}
          onAfterDelete={loadComments}
          setItemId={setItemId}
          tenantId={tenantId}
          loading={loading}
          status={status}
        />
      ),
    };
  });

  return (
    <>
      <div className="m-5 pt-5">
        {tabs.length || loading ? (
          <Tabs defaultActive="0" tabs={tabs} />
        ) : (
          <div className="text-center mb-6">
            <h6 className="mb-5">
              Opps, missing comment categories for this tenant, please setup
              them in the Tenant Administration
            </h6>
            <Link to={"/tenants-list/" + user.selectedTenant}>
              <button className="px-15 mr-5 btn btn-primary">
                Click here to setup
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default CommentsTabs;
