import React from "react";
import Tabs from "../../../ui/components/Tabs";
import { ClosedList } from "./DiscrepancyClosedList";
import { ActiveList } from "./DiscrepancyList";

export const List = () => {
  const tabs = [
    {
      label: "Active Discrepancies",
      tabPanel: <ActiveList />,
    },
    {
      label: "Closed Discrepancies",
      tabPanel: <ClosedList />,
    },
  ];

  return (
    <div>
      <Tabs defaultActive="0" tabs={tabs} align="right" />
    </div>
  );
};
