export const statementTypes = {
  SET_DATA: "CREDIT_STATEMENTS/SET_DATA",
  UPDATE_DATA: "CREDIT_STATEMENTS/UPDATE_DATA",
};

export function setStatementsData(data) {
  return {
    type: statementTypes.SET_DATA,
    payload: data,
  };
}

export function updateStatementsData(data) {
  return {
    type: statementTypes.UPDATE_DATA,
    payload: data,
  };
}
