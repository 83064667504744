import React, { useEffect, useMemo, useState } from "react";
import DataGrid from "../../components/DataGrid";
import { Modal } from "../../components/Modal";
import { formatDate, renderHeader } from "../../helpers/helpers";
import { IconButton, Tooltip } from "@material-ui/core";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import { downloadFile } from "../../../app/modules/AWS-S3/download";
import {
  deleteOrganisationSecurity,
  getOrganisationSecurities,
  exportOrganisationSecurity,
} from "../../../app/modules/Organisations/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAverageWeeklySpend } from "./_api";

const HEADINGS = [
  ["name", "Name"],
  ["security_type", "Type"],
  ["required_value", "Required"],
  ["actual_value", "Actual"],
  ["date_commenced", "Date Received"],
  ["date_expiry", "Expiry"],
];

export function Securities({
  onView,
  tenantId,
  organisationId,
  setAddSecurity,
  status,
}) {
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [monthAvgSpend, setMonthAvgSpend] = useState(0);
  const [yearAvgSpend, setYearAvgSpend] = useState(0);

  const loadSecurities = () => {
    request(getOrganisationSecurities, tenantId, organisationId).then(
      (data) => {
        if (data) {
          setData(data);
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    loadSecurities();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    Promise.all([
      request(getAverageWeeklySpend, tenantId, organisationId),
      request(getAverageWeeklySpend, tenantId, organisationId, "48"),
    ]).then(([monthAvg, yearAvg]) => {
      if (!monthAvg || !yearAvg) return;
      setMonthAvgSpend(monthAvg.weekly_average);
      setYearAvgSpend(yearAvg.weekly_average);
    });

    // eslint-disable-next-line
  }, []);

  const renderSecuritiesRow = (headings, item) => {
    return headings.map(([key]) => (
      <td key={key} className="align-middle no-line border-0 px-5">
        {key === "security_type" ? (
          <p
            className={`${
              item.status === "archived" ? "text-danger" : "text-primary"
            } mb-0`}
            onClick={() => status !== "archived" && onView(item)}
          >
            {item[key]}
          </p>
        ) : key === "date_commenced" ||
          (key === "date_expiry" && item[key] !== null) ? (
          formatDate(new Date(item[key]))
        ) : (
          item[key]
        )}
      </td>
    ));
  };

  const getButton = () => {
    const totalActual = data.reduce((acc, item) => acc + item.actual_value, 0);
    const totalRequired = data.reduce(
      (acc, item) => acc + item.required_value,
      0
    );
    if (!totalActual || !totalRequired) {
      return <button className="btn btn-danger">Not Calculated</button>;
    } else if (totalActual >= totalRequired) {
      return <button className="btn btn-success">Compliant</button>;
    } else if (totalActual < totalRequired) {
      return <button className="btn btn-warning">Non-Compliant</button>;
    } else return <button className="btn btn-danger">Not Calculated</button>;
  };

  const calculate = (data, field) => {
    return data.reduce((acc, item) => acc + +item[field], 0);
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    const securityId = getId();

    request(
      deleteOrganisationSecurity,
      tenantId,
      organisationId,
      securityId
    ).then(() => {
      loadSecurities();
    });
  };

  return (
    <>
      {status !== "archived" && (
        <Modal
          isOpen={modal}
          onClose={() => setModal(false)}
          actions={
            <div className="m-auto">
              <button onClick={handleArchive} className="btn btn-primary">
                OK
              </button>
            </div>
          }
          fullWidth={true}
        >
          <p className="text-center">
            Are you sure you want to delete this item?
          </p>
        </Modal>
      )}
      <div className="px-9 py-7 d-flex">
        {status !== "archived" && (
          <>
            <button
              className="btn btn-primary mr-6"
              onClick={() => setAddSecurity(true)}
            >
              Add
            </button>
            <button
              className="btn btn-primary"
              disabled={archiveDisabled}
              onClick={() => handleArchive()}
            >
              Delete
            </button>
          </>
        )}
        <Tooltip title="Download Securities Report" arrow interactive>
          <IconButton
            color="secondary"
            onClick={() =>
              downloadFile(
                [organisationId],
                exportOrganisationSecurity,
                tenantId
              )
            }
            style={{ marginLeft: "auto", padding: 6 }}
          >
            <CloudDownloadIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </div>
      <DataGrid
        controls
        headings={HEADINGS}
        data={data}
        renderHeader={renderHeader}
        renderRow={renderSecuritiesRow}
        selectable={status !== "archived"}
        hideSelect
        selected={selected}
        setSelected={setSelected}
        loading={loading}
      />
      <div className="px-10 py-5">
        <table className="text-center w-100">
          <tbody>
            <tr>
              <td rowSpan="2">Security Snapshot</td>
              <td>Required</td>
              <td>Actual</td>
              <td>Calculated</td>
              <td>12 Month Average</td>
              <td>4 Week Average</td>
              <td rowSpan="2">{getButton("not-calculated")}</td>
            </tr>
            <tr>
              <td>{data.length && calculate(data, "required_value")}</td>
              <td>{data.length && calculate(data, "actual_value")}</td>
              <td>0</td>
              <td>{yearAvgSpend.toFixed(2)}</td>
              <td>{monthAvgSpend.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
