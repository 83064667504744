import React, { useState } from "react";
import { Alert, AlertTitle } from "@material-ui/lab";
import { IconButton, Collapse } from "@material-ui/core";
import { Info as InfoIcon, Close as CloseIcon } from "@material-ui/icons";

export default function AlertBar({ severity = "info", message }) {
  const [open, setOpen] = useState(true);
  return (
    <div>
      <Collapse in={open}>
        <Alert
          severity={severity}
          icon={<InfoIcon fontSize="large" style={{ fontSize: "2.6rem" }} />}
          variant="filled"
          action={
            <IconButton onClick={() => setOpen(false)}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          }
        >
          <AlertTitle className="ml-10 mt-3">{message}</AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
}

export function NotificationAlert({ severity = "info", message, open, handleClose }) {
  return (
    <div>
      <Collapse in={open}>
        <Alert
          severity={severity}
          icon={<InfoIcon fontSize="large" style={{ fontSize: "2.6rem" }} />}
          variant="filled"
          action={
            <IconButton onClick={handleClose}>
              <CloseIcon style={{ color: "white" }} />
            </IconButton>
          }
        >
          <AlertTitle className="ml-10 mt-3">{message}</AlertTitle>
        </Alert>
      </Collapse>
    </div>
  );
}

export const IntegrationBar = () => {
  return (
    <>
      {true ||
        window.location.hostname ===
          "integration-1.portal.crs-nonp.c0.creditservice.cloud" ||
        (window.location.hostname === "crs.nonp.creditservice.cloud" && (
          <div className="w-100">
            <Alert
              elevation={6}
              variant="filled"
              severity="error"
              style={{ backgroundColor: "#F44336", fontSize: 14 }}
            >
              Caution - Test System
            </Alert>
          </div>
        ))}
    </>
  );
};

export const WarningAlertBar = ({ message }) => {
  return (
    <div>
      <Alert
        severity="warning"
        variant="filled"
        elevation={6}
        style={{ backgroundColor: "#F44336", fontSize: 14 }}
      >
        {message}
      </Alert>
    </div>
  );
};
