import React, { useState } from "react";
import { IntegrationItem, IntegrationItemWithFooter } from "./ItemIntegration";
import { MailchimpForm } from "./MailchimpForm";
import { BSBForm } from "./BSBForm";
import { ABAFileForm } from "./ABAFile";
import { NABForm } from "./NABForm";
import { BulkSMSForm } from "./BulkSMSForm";
import { ReactComponent as MailchimpIcon } from "./assets/Mailchimp.svg";
import { ReactComponent as BSBIcon } from "./assets/BSB.svg";
import { ReactComponent as NABIcon } from "./assets/nab_logo.svg";
import { ReactComponent as BulkIcon } from "./assets/Bulk.svg";
import { ReactComponent as ConciergeIcon } from "./assets/Concierge.svg";
import ABNLogo from "./assets/australian_payments_network_logo.png";
import { AccountBalance as AccountBalanceIcon } from "@material-ui/icons";

const formsMap = {
  Mailchimp: <MailchimpForm />,
  BSB: <BSBForm />,
  "ABA File": <ABAFileForm />,
  "bulk sms": <BulkSMSForm />,
  "National Australia Bank": <NABForm />,
};

export function Integrations() {
  const [active, setActive] = useState("");

  return (
    <>
      <div className="row justify-content-center h-100">
        <div className="col-12">
          <div className="py-7 px-10 bg-white rounded mb-10">
            <div className="d-flex justify-content-between mb-3">
              <h2 className="font-weight-bold">Third Party Integrations</h2>
            </div>
            <div
              style={{
                display: "grid",
                gridGap: 10,
                gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr) )",
              }}
            >
              <IntegrationItem
                icon={<AccountBalanceIcon style={{ fontSize: 53 }} />}
                title="ABA File"
                action="Setup your ABA File integration"
                isActive={active === "ABA File"}
                setActive={setActive}
              />
              <IntegrationItemWithFooter
                icon={<BSBIcon style={{ width: 72, height: 69 }} />}
                secondIcon={<img src={ABNLogo} alt="Logo" width="123px" />}
                title="BSB"
                action="Maintain BSB Listing"
                isActive={active === "BSB"}
                setActive={setActive}
              />
              <IntegrationItemWithFooter
                icon={<BulkIcon />}
                secondIcon={<ConciergeIcon />}
                title="bulk sms"
                action="Paid Add-On"
                isActive={active === "bulk sms"}
                setActive={setActive}
              />
              <IntegrationItem
                icon={<MailchimpIcon />}
                title="Mailchimp"
                action="Sync your Contacts to Mailchimp"
                isActive={active === "Mailchimp"}
                setActive={setActive}
              />
              <IntegrationItem
                icon={<NABIcon style={{ height: 53, width: 112 }} />}
                title="National Australia Bank"
                action="Pay-by-web"
                isActive={active === "National Australia Bank"}
                setActive={setActive}
              />
            </div>
          </div>
          {active && (
            <div className="py-7 px-10 bg-white rounded">
              {formsMap[active]}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
