import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setBillingCheckData } from "./_redux/actions";
import { useFetch } from "../../../hooks/fetch.hook";
import DataGrid from "../../../ui/components/DataGrid";
import { SearchBar } from "../../../ui/structures/SearchBar";
import { renderInvoicesRow } from "./helpers";
import { onSearch, renderHeader } from "../../../ui/helpers/helpers";
import { HEADINGS } from "./constants";
import { getBillingCheck } from "../InvoiceEntry/_api";

export function BillingCheckPage() {
  const { request } = useFetch();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.billingCheck.billingCheckList);
  const user = useSelector(({ auth: { user } }) => user) || {};
  const TENANT_ID = user.selectedTenant;

  const [loading, setLoading] = useState(false);
  const [initialData, setInitialData] = useState([]);

  function fetchBillingCheckData() {
    setLoading(true);
    request(getBillingCheck, TENANT_ID)
      .then((data) => {
        if (data && Object.keys(data).length === 0) return;

        let dailyBillers = Object.entries(data).map(([k, v], i) => {
          return {
            ...v,
            name: v.organisation.legalName || v.organisation.name || "",
          };
        });

        request(getBillingCheck, TENANT_ID, "four_week_avg").then((data) => {
          let modifiedBillers = Object.entries(data).map(([k, v], i) => {
            const found =
              dailyBillers.find((x) => {
                return +x.organisation.id === +v.organisation.id;
              }) || {};
            if (found && Object.keys(found).length !== 0) {
              return {
                ...found,
                "4_week_avg": v["4_week_avg"],
                "4_week_cnt": v["4_week_cnt"],
              };
            } else {
              return {
                ...v,
                name: v.organisation.legalName || v.organisation.name || "",
              };
            }
          });
          dispatch(setBillingCheckData(modifiedBillers));
          setInitialData(modifiedBillers);
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    dispatch(setBillingCheckData([]));
    setInitialData([]);
    setLoading(true);
    fetchBillingCheckData();
    // eslint-disable-next-line
  }, []);

  const handleSearch = (search, isRemoveKey) => {
    onSearch(
      search,
      isRemoveKey ? initialData : data,
      dispatch,
      setBillingCheckData,
      HEADINGS
    );
  };

  const resetSearch = () => {
    setLoading(true);
    dispatch(setBillingCheckData(data));
    setLoading(false);
  };

  const KEY_MAP = {
    "Biller Name": data.map((item) => item.name),
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <h3 className="text-dark">Billing Check</h3>
            </div>
            <div className="row">
              <div className="col-8">
                <SearchBar
                  className="mb-5"
                  onSearch={handleSearch}
                  clearSearch={resetSearch}
                  keyMap={KEY_MAP}
                  placeholder="Filter..."
                />
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderInvoicesRow}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
