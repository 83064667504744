import config from "react-global-configuration";

export const getStaticPages = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/static_pages/`, {
    mode: "cors",
    credentials: "include",
  });

export const getStaticPage = (tenant_id, page_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/static_pages/${page_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
