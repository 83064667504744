import React from "react";
import config from "react-global-configuration";
import DescriptionIcon from "@material-ui/icons/Description";
import PrintIcon from "@material-ui/icons/Print";
import { Button, styled, Container } from "@material-ui/core";
import {
  formatAmount,
  formatDate,
  formatLongRow,
  isAmount,
  isDate,
} from "../../../ui/helpers/helpers";
import { downloadReport } from "../Reports/_api";
import { downloadFile, startDownload } from "../AWS-S3";

export const renderExtendedRow = (item = {}) => {
  const { biller_name, amount, discrepancy = 0 } = item;
  return (
    <>
      <tr className="border-bottom" style={{ background: "#f5f6fa" }}>
        <td
          className="py-3"
          colSpan={5}
        >{`Transaction - Biller: ${biller_name}.  Total: ${formatAmount(
          amount - discrepancy
        )}`}</td>
      </tr>

      <tr className="border-bottom" style={{ background: "#f5f6fa" }}>
        <td
          className="py-3"
          colSpan={5}
        >{`Discrepancy - Biller: ${biller_name}. Total: ${formatAmount(
          discrepancy
        )}`}</td>
      </tr>
    </>
  );
};

export const renderTotal = (data = []) => {
  const total = data
    .map(({ amount }) => amount)
    .reduce((acc, value) => acc + value, 0);
  return (
    <div className="text-right mr-39 border-bottom border-top py-5">
      <strong>Total : </strong> {formatAmount(total)}
    </div>
  );
};

export const modifyDistribution = (item = {}) => {
  if (item.content === null) return item;
  return {
    ...item,
    amount: Object.values(item.content.billers)
      .map((value) => value.total)
      .reduce((acc, value) => acc + value, 0),
  };
};

export const modifyDistributions = (data = []) => {
  return data.map((item) => modifyDistribution(item));
};

export const setInititalReviewData = (data = {}) => {
  if (data.content === null) return;
  return Object.keys(data.content.billers).map((key) => ({
    biller_name: data.content.billers[key].organisation.name,
    amount: data.content.billers[key].total,
    biller_total: data.content.billers[key].biller_total,
    fee: Number(data.content.billers[key].biller_fee.toFixed(2)),
    id: key,
  }));
};

export function hasLongContentRow(key) {
  return ["account_name"].includes(key);
}

const handlePrint = (id) => console.log(id);
const handleExport = (id) => console.log(id);

const isExportKey = (key) => key === "export";
const isPrintKey = (key) => key === "print";

const renderCSVButton = (item) => {
  return (
    <Button onClick={() => handleExport(item.id)}>
      <DescriptionIcon color="primary" />
    </Button>
  );
};

const renderPrintButton = (item) => {
  return (
    <Button onClick={() => handlePrint(item.id)}>
      <PrintIcon color="secondary" />
    </Button>
  );
};

const renderABAFileButton = (item) => {
  const url = `${config.get("ApiUrl").Rest}/tenants/${
    item.tenant_id
  }/distributions/${item.id}/download_aba`;
  return (
    <form target="_blank" action={url} method="post">
      <Button
        variant="contained"
        color="secondary"
        className="px-9"
        type="submit"
      >
        ABA File
      </Button>
    </form>
  );
};

const renderDistributionReportButton = (item) => {
  const downloadDistributionReport = () => {
    const data = {
      report_name: "D03_distribution_payment_summary",
      id: [item.id],
    };
    downloadFile(data, downloadReport, item.tenant_id);
  };
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className="px-5"
      onClick={downloadDistributionReport}
    >
      Report
    </Button>
  );
};

export function renderDistributionsRow(headings, item) {
  return headings.map(([key]) => (
    <td key={key} className="align-middle no-line border-0 px-5 py-1">
      {isDate(key)
        ? item[key] && formatDate(new Date(item[key]))
        : isAmount(key)
        ? formatAmount(item[key])
        : hasLongContentRow(key)
        ? formatLongRow(item[key])
        : isExportKey(key)
        ? renderCSVButton(item[key])
        : isPrintKey(key)
        ? renderPrintButton(item[key])
        : key === "aba_file"
        ? renderABAFileButton(item)
        : key === "distribution_report"
        ? renderDistributionReportButton(item)
        : item[key]}
    </td>
  ));
}

export const ScrollableContainer = styled(Container)({
  flex: "1 1 auto",
  minHeight: "1px",
  height: "56vh",
  overflowY: "auto",
  paddingLeft: 0,
  paddingRight: 0,
  marginLeft: 0,
  marginRight: 0,
  maxWidth: "100%",
  "&::-webkit-scrollbar": {
    width: "5px",
  },
  "&::-webkit-scrollbar-track": {
    boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
    webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.1)",
    //   outline: "1px solid slategrey",
  },
  scrollbarColor: "rgba(0,0,0,.1) transparent",
  scrollbarWidth: "thin",
  "&>.d-flex:last-child": {
    marginBottom: "0 !important",
  },
});
