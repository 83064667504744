import { billerInvoicesTypes } from "./actions";

const initialState = {
  billerInvoicesList: [],
};

export function billerInvoicesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case billerInvoicesTypes.SET_DATA:
      return {
        ...state,
        billerInvoicesList: payload,
      };

    case billerInvoicesTypes.UPDATE_DATA:
      const index = state.billerInvoicesList.findIndex((item) => item.id === payload.id);
      const myState = [...state.billerInvoicesList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        billerInvoicesList: [...myState],
      };

    default:
      return state;
  }
}
