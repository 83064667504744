export const paymentCyclesTypes = {
  SET_DATA: "PAYMENTCYCLES/SET_DATA",
  UPDATE_DATA: "PAYMENTCYCLES/UPDATE_DATA",
  ARCHIVE_DATA: "PAYMENTCYCLES/ARCHIVE_DATA",
  UPDATE_PAYMENTCYCLE: "PAYMENTCYCLES/UPDATE_PAYMENTCYCLE",
  TOGGLE_SHOW_ARCHIVED: "PAYMENTCYCLES/TOGGLE_SHOW_ARCHIVED",
};

export function setPaymentCyclesList(data) {
  return {
    type: paymentCyclesTypes.SET_DATA,
    payload: data,
  };
}

export function updatePaymentCycle(data) {
  return {
    type: paymentCyclesTypes.UPDATE_PAYMENTCYCLE,
    payload: data,
  };
}

export function archivePaymentCycle(id) {
  return {
    type: paymentCyclesTypes.ARCHIVE_DATA,
    payload: id,
  };
}

export function updatePaymentCyclesList(data) {
  return {
    type: paymentCyclesTypes.UPDATE_DATA,
    payload: data,
  };
}

export function toggleArchivedPaymentCycles() {
  return {
    type: paymentCyclesTypes.TOGGLE_SHOW_ARCHIVED,
  };
}
