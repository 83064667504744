import React, { useState, useEffect } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import { getStaticPage } from "./_api";
import { StaffDirectoryPage } from "./StaffDirectoryPage/StaffDirectoryPage";
import { MasterFilesPage } from "./MasterFilesPage/MasterFilesPage";
import { ContactPage } from "./ContactPage/ContactPage";
import { StaticPageLoading } from "./StaticPageLoading";
import htmr from "htmr";
import { BusinessServicesOverview } from "./BusinessServicesOverview/BusinessServicesOverview";
import { RulesAndByLaws } from "./RulesAndByLaws/RulesAndByLaws";

export function StaticPages({ history, location, match }) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const PAGE_ID = match.params.id;
  const { request } = useFetch();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  function fetchStaticPage() {
    request(getStaticPage, TENANT_ID, PAGE_ID).then((page) => {
      if (page) {
        setData([]);
        setData(page);
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    switch (match.url) {
      // case "/static/credit-service-overview":
      //   setData([]);
      //   setLoading(false);
      //   break;
      case "/static/business-services-overview":
        setData([]);
        setLoading(false);
        break;
      case "/static/empty":
        setData([]);
        setLoading(false);
        break;
      case "/static/contact":
        setData([]);
        setLoading(false);
        break;
      case "/static/master-files":
        setData([]);
        setLoading(false);
        break;
      case "/static/rules-and-by-laws":
        setData([]);
        setLoading(false);
        break;
      default:
        fetchStaticPage();
    }
    // eslint-disable-next-line
  }, [TENANT_ID, PAGE_ID]);

  return (
    <>
      <div
        className={`row ${
          match.url == "/static/contact" ? "" : "bg-white h-100"
        } rounded`}
        style={{ overflow: "hidden" }}
      >
        {loading && <StaticPageLoading />}
        {!loading && match.url === "/static/contact" && (
          <ContactPage TENANT_ID={TENANT_ID} />
        )}
        {data && data.content
          ? htmr(`${data.content}`)
          : !loading &&
            match.url === "/static/empty" && (
              <div
                className="align-self-center"
                style={{ margin: "0 auto", fontSize: 24 }}
              >
                Page Not Found
              </div>
            )}
        {data.title === "Staff Directory" && <StaffDirectoryPage />}
        {/* {location.pathname === "/static/credit-service-overview" && (
          <CreditServiceOverview />
        )} */}
        {location.pathname === "/static/business-services-overview" && (
          <BusinessServicesOverview />
        )}
        {location.pathname === "/static/master-files" && <MasterFilesPage />}
        {location.pathname === "/static/rules-and-by-laws" && (
          <RulesAndByLaws />
        )}
      </div>
    </>
  );
}
