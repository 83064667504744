import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button } from "@material-ui/core/";
import { Formik } from "formik";
import { DepositDetailSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { MappedDatePicker } from "../../components/DatePicker";
import { useFetch } from "../../../hooks/fetch.hook";
import { getAccountsList } from "../../../app/modules/LedgerAccounts/_api";
import { formatDate } from "../../../ui/helpers/helpers";

function DepositDetailsForm({
  history,
  data = {},
  onSubmit,
  setData,
  isAddNewDeposit = false,
}) {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user && user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const { request } = useFetch();
  const {
    account_id,
    deposit_value,
    deposit_type,
    deposit_description,
    deposit_date,
    bank_name,
    deposit_source,
    bank_payment_line_description,
  } = data;

  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);

  useEffect(() => {
    if (ORGANISATION_ID) {
      setAccountsList([{ value: 0, label: "Select Account" }]);
      fetchAccountsList();
    }
    // eslint-disable-next-line
  }, [ORGANISATION_ID]);

  const initialValues = {
    account_id: account_id || "0",
    deposit_value: deposit_value || "",
    deposit_type: deposit_type || "0",
    deposit_description: deposit_description || "",
    deposit_date: (deposit_date && new Date(deposit_date)) || "",
    bank_name: bank_name || "",
    deposit_source: deposit_source || "",
    bank_payment_line_description: bank_payment_line_description || "",
  };

  function fetchAccountsList() {
    request(getAccountsList, TENANT_ID, ORGANISATION_ID).then((data) => {
      if (data) {
        data
          .filter(({ status }) => status === "active")
          .forEach((item) => {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
          });
      }
    });
  }

  const handleSubmit = (values, { setSubmitting }) => {
    if (isAddNewDeposit) {
      request(onSubmit, TENANT_ID, values)
        .then((data) => {
          if (data) {
            return history.push(`/deposits/`);
          }
        })
        .finally(() => setSubmitting(false));
    } else {
      request(onSubmit, TENANT_ID, { ...data, ...values }, data.id)
        .then((data) => {
          if (data) {
            return history.push(`/deposits/`);
          }
        })
        .finally(() => setSubmitting(false));
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={DepositDetailSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <>
          <div className="d-flex justify-content-between mb-3">
            <div className="col-6">
              <div className="row mb-3">
                <div className="col-4 my-auto pt-1">
                  <p className="text-right text-muted mb-0">Account</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedSelect
                    options={accountsList}
                    name="account_id"
                    data-testid="account_id"
                    wrapperclasses="mb-1"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">Deposit Value</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput name="deposit_value" wrapperClassName="w-100" />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto pt-1">
                  <p className="text-right text-muted mb-0">Deposit Type</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedSelect
                    options={[
                      { value: 0, label: "Select Deposit Type" },
                      {
                        value: "pre-payment",
                        label: "Pre-payment",
                      },
                      {
                        value: "cheque",
                        label: "Cheque",
                      },
                    ]}
                    name="deposit_type"
                    data-testid="deposit_type"
                    wrapperclasses="mb-1"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">
                    Deposit Description
                  </p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput
                    name="deposit_description"
                    wrapperClassName="w-100"
                    data-testid="deposit_description"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">Deposit Date</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedDatePicker
                    name="deposit_date"
                    className="border border-secnodary"
                    placeholderText={formatDate(new Date(Date.now()))}
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">Bank Name</p>
                </div>
                <div className="col-6">
                  <MappedInput
                    name="bank_name"
                    wrapperClassName="w-100"
                    data-testid="bank_name"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">Deposit Source</p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput
                    name="deposit_source"
                    wrapperClassName="w-100"
                    data-testid="deposit_source"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-4 my-auto">
                  <p className="text-right text-muted mb-0">
                    Bank Payment Line Description
                  </p>
                </div>
                <div className="col-6 mr-auto">
                  <MappedInput
                    name="bank_payment_line_description"
                    wrapperClassName="w-100"
                    data-testid="bank_payment_line_description"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={() => history.go(-1)}
            >
              Cancel
            </Button>
            <Button
              onClick={props.handleSubmit}
              type="submit"
              variant="contained"
              color="secondary"
              size="large"
              className="px-9"
              data-testid="submit"
              disabled={props.isSubmitting}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(DepositDetailsForm);
