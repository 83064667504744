export const newRuleAction = {
  ADD_RULE: "NEW_RULE/ADD_RULE",
  ADD_ROW: "NEW_RULE/ADD_ROW",
  ADD_ACTION: "NEW_RULE/ADD_ACTION",
  REMOVE_DATA: "NEW_RULE/REMOVE_DATA",
  RESET: "NEW_RULE/RESET",
};

export function addRule(data) {
  return {
    type: newRuleAction.ADD_RULE,
    payload: data,
  };
}

export function addAction(data) {
  return {
    type: newRuleAction.ADD_ACTION,
    payload: data,
  };
}

export function addRow(data) {
  return {
    type: newRuleAction.ADD_ROW,
    payload: data,
  };
}

export function removeData(data) {
  return {
    type: newRuleAction.REMOVE_DATA,
    payload: data,
  };
}

export const resetState = () => {
  return {
    type: newRuleAction.RESET,
  };
};
