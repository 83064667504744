import React, { useState } from "react";
import { Button } from "@material-ui/core";
import DataGrid from "../../../ui/components/DataGrid";
import { formatAmount, renderHeader } from "../../../ui/helpers/helpers";
import { renderDistributionsRow, ScrollableContainer, setInititalReviewData } from "./helpers";
import { renderExtendedRow } from "./helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { Loader } from "../../../ui/components/Loader";
import { approveDistribution } from "./_api";
import Can from "../../../ui/helpers/Can";

const HEADINGS = [
  ["biller_name", "Biller"],
  ["biller_total", "Biller Total"],
  ["fee", "Fee"],
  ["amount", "Total"],
];

export function Approve({
  handleNextStep,
  handlePrevStep,
  reviewedItem = {},
  tenantId,
  setReviewedItem,
}) {
  const { request } = useFetch();
  const data = setInititalReviewData(reviewedItem);

  const [loading, setLoading] = useState(false);

  const renderTotalRow = (data = []) => {
    const sumValues = (key) => {
      return formatAmount(data.map((item) => item[key]).reduce((acc, value) => acc + value, 0));
    };
    return (
      <tr className="border-bottom">
        <td className="no-line border-0 align-middle px-5 pb-2 pt-10">
          <strong>Sum : </strong>
        </td>
        <td className="pt-10 pb-2 pl-5"></td>
        <td className="pt-10 pb-2 pl-5"></td>
        <td className="pt-10 pb-2 pl-5"></td>
        <td className="pt-10 pb-2 pl-5">{sumValues("amount")}</td>
        <td className="pt-10 pb-2 pl-5"></td>
        <td className="pt-10 pb-2 pl-5">{sumValues("biller_total")}</td>
      </tr>
    );
  };

  const handleSubmit = () => {
    setLoading(true);
    request(approveDistribution, tenantId, reviewedItem.id)
      .then((data) => {
        if (!data) return;
        handleNextStep();
        setReviewedItem(data);
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      {loading && (
        <Loader
          isOpen={loading}
          onClose={() => setLoading(false)}
          disableBackdropClick
          disableEscapeKeyDown
        />
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <ScrollableContainer>
              <h3 className="text-dark mb-5">Approve Distributions</h3>
              <DataGrid
                data={data}
                headings={HEADINGS}
                renderHeader={renderHeader}
                renderRow={renderDistributionsRow}
                extendable
                renderExtendedRow={renderExtendedRow}
                hideSelect={true}
                paginationEnabled={false}
                footerRow={renderTotalRow}
              />
            </ScrollableContainer>
            <div className="d-flex justify-content-end align-items-center mt-5">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={handlePrevStep}
              >
                Prev
              </Button>
              <Can
                perform="distributions:approve"
                yes={() => (
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                  >
                    Submit
                  </Button>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
