export const accountsTypes = {
  SET_DATA: "ACCOUNTS/SET_DATA",
  UPDATE_DATA: "ACCOUNTS/UPDATE_DATA",
  ARCHIVE_DATA: "ACCOUNTS/ARCHIVE_DATA",
  UPDATE_ACCOUNT: "ACCOUNTS/UPDATE_ACCOUNT",
  TOGGLE_SHOW_ARCHIVED: "ACCOUNTS/TOGGLE_SHOW_ARCHIVED",
};

export function setAccountsList(data) {
  return {
    type: accountsTypes.SET_DATA,
    payload: data,
  };
}

export function updateAccount(data) {
  return {
    type: accountsTypes.UPDATE_ACCOUNT,
    payload: data,
  };
}

export function archiveAccount(id) {
  return {
    type: accountsTypes.ARCHIVE_DATA,
    payload: id,
  };
}

export function updateAccountsList(data) {
  return {
    type: accountsTypes.UPDATE_DATA,
    payload: data,
  };
}

export function toggleArchivedAccounts() {
  return {
    type: accountsTypes.TOGGLE_SHOW_ARCHIVED,
  };
}
