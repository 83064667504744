import config from "react-global-configuration";
import { error } from "../../../helpers/toasts";

export const downloadFile = (
  fileName,
  getUrl = getDownloadFileUrl,
  tenant_id = undefined,
  setLoading
) =>
  getUrl(fileName, tenant_id)
    .then((data) => data.json())
    .then(({ data }) => startDownload(data.url))
    .catch((err) => error((err || {}).message))
    .finally(() => setLoading && setLoading(false));

const getDownloadFileUrl = (fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/files`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ file_name: fileName }),
  });
const getReportFileUrl = (fileName) =>
  fetch(`${config.get("ApiUrl").Rest}/files`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({ file_name: fileName }),
  });

export const startDownload = (url) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("target", "_blank");
  link.setAttribute("rel", "noopener noreferrer");
  link.click();
};
