import React, { useState } from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { useSelector } from "react-redux";
import { MappedInput } from "../../../../../ui/components/Input";
import { useFetch } from "../../../../../hooks/fetch.hook";
import { splitBankTransaction } from "../../_api";
import {
  Add as AddIcon,
  ArrowRightAlt as ArrowRightAltIcon,
} from "@material-ui/icons";
import { formatAmount } from "../../../../../ui/helpers/helpers";
import { makeStyles } from "@material-ui/core/styles/";

const useStyles = makeStyles({
  inputs: {
    "&:before": {
      content: '""',
      position: "absolute",
      backgroundColor: "#212121",
      width: 2,
      top: "21px",
      bottom: "20.5px",
      left: 8,
    },
    position: "relative",
  },
});
export const SplitPaymentForm = ({
  transactionData,
  onClose,
  fetchBankStatementData,
}) => {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();

  const TENANT_ID = user.selectedTenant;
  const [transactionValues, setTransactionValues] = useState([
    Number(transactionData.transaction_value),
    0,
  ]);
  const [isEqual, setIsEqual] = useState("no-touch");

  const initialValues = {
    transaction_value: Number(transactionData.transaction_value),
  };

  const handleNewTransactionField = () => {
    setTransactionValues((oldData) => [...oldData, 0]);
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const total = Object.values(values).reduce((p, c) => Number(p) + Number(c));
    let payload = {
      transaction_values: Object.values(values)
        .filter((val) => val !== "0")
        .map((num) => Number(num)),
    };

    if (total === transactionData.transaction_value) {
      setIsEqual(true);
      request(splitBankTransaction, payload, TENANT_ID, transactionData.id)
        .then((data) => {
          data && fetchBankStatementData() && onClose();
        })
        .finally(() => setSubmitting(false));
    } else {
      setIsEqual(false);
      setSubmitting(false);
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ handleSubmit, values, resetForm, isSubmitting, setFieldValue }) => (
          <>
            <div className="row" style={{ padding: "1rem 2.5rem" }}>
              <h3 className="mb-8 w-100 text-center">
                Split Transaction value
              </h3>
              <div className="mb-1 col-6 d-flex justify-content-center align-items-center">
                <p style={{ fontSize: 18, margin: 0 }}>
                  {formatAmount(Number(transactionData.transaction_value))}
                </p>
                <span
                  style={{
                    color:
                      isEqual === "no-touch"
                        ? "black"
                        : isEqual
                        ? "green"
                        : "rgb(225, 67, 82)",
                    marginLeft: "auto",
                    fontSize: 24,
                    transition: "all 0.3s cubic-bezier(.25,.8,.25,1)",
                    cursor: "context-menu",
                  }}
                >
                  =
                </span>
              </div>
              <div className={`mb-1 col-6 ${classes.inputs}`}>
                {transactionValues.map((value, i) => (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ flexDirection: "row" }}
                    key={i}
                  >
                    <ArrowRightAltIcon style={{ marginLeft: "-7px" }} />
                    {/* <p style={{ padding: "0 5px 0 0" }}>{i + 1}.</p> */}
                    <MappedInput
                      name={`transaction_value${i ? i : ""}`}
                      wrapperClassName="w-100 mb-1"
                    />
                  </div>
                ))}
              </div>
              <div className="mb-2 col-6"></div>
              <div className="mb-2 col-6 justify-content-center d-flex">
                <Button
                  onClick={handleNewTransactionField}
                  color="secondary"
                  size="large"
                  className="px-9"
                  startIcon={<AddIcon />}
                  style={{ fontSize: 11 }}
                >
                  Add Transaction Value
                </Button>
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ margin: "auto" }}
              >
                <Button
                  onClick={onClose}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9 ml-5"
                  data-testid="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
