import config from "react-global-configuration";

export const downloadReport = (report_data, tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/reports`, {
    method: "post",
    mode: "cors",
    body: JSON.stringify(report_data),
    credentials: "include",
  });

export const downloadReportGET = (report_data, tenant_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/reports?report_name=${
      report_data.report_name
    }${
      report_data.report_start
        ? "&report_start=" + report_data.report_start
        : ""
    }${report_data.report_end ? "&report_end=" + report_data.report_end : ""}${
      report_data.account_id ? "&account_id=" + report_data.account_id : ""
    }${
      report_data.report_type ? "&report_type=" + report_data.report_type : ""
    }`,
    {
      mode: "cors",
      credentials: "include",
    }
  );
