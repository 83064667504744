import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";
import DataGrid from "../../../ui/components/DataGrid";
import { useSelector } from "react-redux";
import {
  renderHeader,
  renderPaymentCyclesRow,
} from "../../../ui/helpers/helpers";
import { getPaymentCyclesList, deletePaymentCycle } from "./_api";
import { useFetch } from "../../../hooks/fetch.hook";
import ToolTip from "../../../ui/components/Tooltip";
import Can from "../../../ui/helpers/Can";

const HEADINGS = [
  ["id", "ID"],
  ["description", "Cycle Description"],
  ["start_date", "Start Date"],
  ["length", "Length"],
  ["term", "Term"],
  ["cycle_count", "Number of Cycles"],
];

export function List() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [deleted, setDeleted] = useState(false);
  const [data, setData] = useState([]);
  const { request } = useFetch();

  useEffect(() => {
    setLoading(true);
    request(getPaymentCyclesList, TENANT_ID).then((data) => {
      if (data) {
        setData(data);
        setLoading(false);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TENANT_ID, deleted, setDeleted]);

  // const arrayOfSelectedIds = useMemo(
  //   () => Object.keys(selected).filter((item) => Boolean(selected[item])),
  //   [selected]
  // );

  const getId = () => {
    for (let key in selected) {
      if (selected[key]) {
        return key;
      }
    }
  };

  const handleArchive = () => {
    // eslint-disable-next-line no-restricted-globals
    const confirmation = confirm(
      "Are you sure you want to archive this Payment Cycle? This process is not reversible"
    );
    const itemID = getId();
    if (confirmation) {
      try {
        deletePaymentCycle(TENANT_ID, itemID).then((res) => {
          if (res.status === 200 || res.status === 204) {
            setDeleted(!deleted);
            setSelected({});
          }
        });
      } catch {}
    }
  };

  const archiveDisabled = useMemo(
    () => Object.values(selected).filter(Boolean).length !== 1,
    [selected]
  );

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Parent Payment Cycles</h3>
            <div>
              <ToolTip
                icon={<PrintIcon fontSize="large" />}
                title="Print Selected Payment Cycle Data in PDF"
                disabled
              />
            </div>
          </div>
          <div>
            <Can
              perform="list:new"
              yes={() => (
                <div className="mb-5">
                  <Link to={"/payment-cycles/add-cycle"}>
                    <button className="px-15 mr-5 btn btn-primary">New</button>
                  </Link>
                  <button
                    className="px-15 mr-5 btn btn-primary"
                    onClick={handleArchive}
                    disabled={archiveDisabled}
                  >
                    Archive
                  </button>
                </div>
              )}
            />
            <Can
              perform="list:edit"
              yes={() => (
                <DataGrid
                  data={data}
                  headings={HEADINGS}
                  renderHeader={renderHeader}
                  renderRow={renderPaymentCyclesRow}
                  editable
                  selectable
                  selected={selected}
                  setSelected={setSelected}
                  link="payment-cycles"
                  loading={loading}
                />
              )}
              no={() => (
                <DataGrid
                  data={data}
                  headings={HEADINGS}
                  renderHeader={renderHeader}
                  renderRow={renderPaymentCyclesRow}
                  loading={loading}
                />
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
