import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { AuditTrailForm } from "./AuditTrailForm";
import { renderHeader, renderRow } from "../../../ui/helpers/helpers";
import { SearchBar } from "../../../ui/structures/SearchBar";
import DataGrid from "../../../ui/components/DataGrid";
import { setLogsList } from "./_redux/actions";
import { getAuditLogs } from "./_api";
import { startOfDay } from "date-fns";
import { useFetch } from "../../../hooks/fetch.hook";
import { modifyAuditsData } from "./helpers";

const HEADINGS = [
  ["timestamp", "Date"],
  ["name", "Name"],
  ["message", "Message"],
];

export function AuditTrail() {
  const dispatch = useDispatch();
  const { request } = useFetch();

  const data = useSelector((state) => state.auditLogs.auditLogs) || [];
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [key, setKey] = useState(Date.now());

  function fetchAuditTrail() {
    setLoading(true);
    setLogs([]);
    request(getAuditLogs, startOfDay(new Date()) / 1000)
      .then((response) => response && modifyAuditsData(response))
      .then((response) => {
        if (!response) return;
        dispatch(setLogsList(response));
        setLogs(response);
        setLoading(false);
      });
  }

  useEffect(() => {
    fetchAuditTrail();
    // eslint-disable-next-line
  }, []);

  const resetSearch = () => {
    dispatch(setLogsList(logs));
  };

  const KEY_MAP = {
    Date: data.map((item) => item.timestamp),
    Name: data.map((item) => item.name),
  };

  const handleSearch = (search) => {
    dispatch(
      setLogsList(
        data.filter((item) => {
          const _search = Object.entries(search).map((item) => [
            HEADINGS.find((i) => i[1] === item[0])[0],
            item[1],
          ]);
          return (
            _search.length === 0 ||
            _search.every(([k, v]) => {
              if (Array.isArray(item[k])) {
                return item[k].includes(v);
              }
              return item[k] === v;
            })
          );
        })
      )
    );
  };

  return (
    <>
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between mb-3">
              <h3 className="text-dark mb-5">Audit Trail</h3>
            </div>
            <div className="row">
              <div className="col-8">
                <AuditTrailForm
                  setLogs={setLogs}
                  setLoading={setLoading}
                  setKey={setKey}
                />
                <div className="row mb-6 mt-6">
                  <div className="col-2 align-items-center my-auto">
                    <p className="text-right text-muted">Filter in results: </p>
                  </div>
                  <div className="col-10 mr-auto">
                    <SearchBar
                      onSearch={handleSearch}
                      keyMap={KEY_MAP}
                      placeholder="Filter by actions..."
                      name="filter"
                      clearSearch={resetSearch}
                      key={key}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DataGrid
              data={data}
              headings={HEADINGS}
              renderHeader={renderHeader}
              renderRow={renderRow}
              loading={loading}
            />
          </div>
        </div>
      </div>
    </>
  );
}
