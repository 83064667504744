import React from "react";
import { StaticPageBox } from "../../../../ui/components/StaticPageBox";
// import { StaticPageBox } from "../../../../ui/components/StaticPageBox";

const businessServicesItems = [
  {
    title: "HR & Industrial Relations",
    logo: "/media/brands/brismark/wholesaler/service-logos/HRandIR.svg",
    backgroundColor: "#5091CD",
    to: "/static/7",
  },
  {
    title: "Recruitment",
    logo: "/media/brands/brismark/wholesaler/service-logos/Recruitment.svg",
    backgroundColor: "#00AEEF",
    to: "/static/18",
  },
  {
    title: "Training",
    logo: "/media/brands/brismark/wholesaler/service-logos/Training.svg",
    backgroundColor: "#00B5D1",
    to: "/static/9",
  },
  {
    title: "WHS",
    logo: "/media/brands/brismark/wholesaler/service-logos/WHS.svg",
    backgroundColor: "#5956A5",
    to: "/static/10",
  },
  {
    title: "Food Safety",
    logo: "/media/brands/brismark/wholesaler/service-logos/FoodSafety.svg",
    backgroundColor: "#569BB4",
    to: "/static/15",
  },
  {
    title: "FreshTest",
    logo: "/media/brands/brismark/wholesaler/service-logos/Freshtest.svg",
    backgroundColor: "#5091CD",
    to: "/static/5",
  },
  {
    title: "Training Centre",
    logo: "/media/brands/brismark/wholesaler/service-logos/TrainingCentre.svg",
    backgroundColor: "#008599",
    to: "/static/19",
  },
  {
    title: "Fuel Cards",
    logo: "/media/brands/brismark/wholesaler/service-logos/FuelCard.svg",
    backgroundColor: "#5091CD",
    to: "/static/6",
  },
  {
    title: "Phone Plans",
    logo: "/media/brands/brismark/wholesaler/service-logos/PhonePlan.svg",
    backgroundColor: "#5091CD",
    to: "/static/empty",
  },
];

export function BusinessServicesOverview() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          flex: "1 0 auto",
          width: "100%",
          backgroundColor: "#292824",
          backgroundImage:
            "linear-gradient(to right, #292824 0%, transparent 100%), url('/media/bg/cherry.jpg')",
          backgroundPosition: "50% 34%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxHeight: "200px",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Brismark Member Services
          </div>
        </div>
        <div
          style={{
            width: "400px",
            height: "200px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div style={{ padding: 60, fontSize: 14 }}>
        <div style={{ fontSize: 16 }}>
          <b>Brismark Member Services</b>
        </div>
        <br />
        <div>
          As a member of the Credit Service, you can take advantage of
          Brismark’s range of Business Services at a discount. You are also
          eligible to take up special offers such as the fuel card and phone
          plan. Select an option below to learn more.
        </div>
        <br />
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
            gridAutoRows: 160,
          }}
        >
          {businessServicesItems.map((item, id) => (
            <StaticPageBox key={id} item={item} />
          ))}
        </div>
      </div>
    </>
  );
}
