import React, { useState, useEffect } from "react";
// import { useSelector } from "react-redux";
import TenantAddRole from "./TenantAddRole";
import { useFetch } from "../../../hooks/fetch.hook";
import {
  getRoleByUserId,
  getRolesByTenantId,
  addRoleToUser,
  deleteRoleToUser,
} from "./_api";
import { makeStyles } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Select } from "../../../ui/components/Select";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "24px 16px",
  },
  roles: {
    textAlign: "center",
  },
}));

export function TenantRole({ userId, tenantId, newUser }) {
  //   const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const { request } = useFetch();
  const [addCustomRole, setAddCustomRole] = useState(false);
  const [selectedRole, setSelectedRole] = useState(0);
  const [loading, setLoading] = useState(true);

  const [tenantRoles, setTenantRoles] = useState([
    { value: 0, label: "Select Role" },
  ]);

  useEffect(() => {
    request(getRolesByTenantId, tenantId)
      .then((data) => {
        if (data) {
          data.forEach((item) => {
            setTenantRoles((prevList) => [
              ...prevList,
              { value: item.id, label: item.name },
            ]);
          });
        }
      })
      .then(() => request(getRoleByUserId, userId, tenantId))
      .then((data) => {
        if (data) {
          data.forEach((item) => {
            if (tenantId === item.tenant_id) {
              setSelectedRole(item.id);
            }
          });
        }
        setLoading(false);
      });

    // eslint-disable-next-line
  }, []);

  const handleOnChange = (e) => {
    request(deleteRoleToUser, userId, tenantId, selectedRole)
      .then(() => {
        request(addRoleToUser, userId, tenantId, e.target.value);
      })
      .then(setLoading(false));
    setSelectedRole(e.target.value);
  };

  const handleAddCustomRole = (e) => {
    setAddCustomRole(!addCustomRole);
    // request(deleteRoleToUser, userId, tenantId, selectedRole)
    //   .then(() => {
    //     request(addRoleToUser, userId, tenantId, e.target.value);
    //   })
    //   .then(setLoading(false));
    // setSelectedRole(e.target.value);
  };

  return (
    <div className={classes.root}>
      <h6>Roles and Permissions ({tenantId})</h6>
      {loading ? (
        <div className={classes.roles} style={{ padding: "18px 8px" }}>
          Loading...
        </div>
      ) : (
        <div className={classes.roles}>
          {tenantRoles.length === 1 ? (
            <span style={{ display: "block", padding: "16px 8px" }}>
              This tenant don't have existing role for this user
            </span>
          ) : (
            <Select
              onChange={handleOnChange}
              name="tenantRoles"
              value={selectedRole}
              options={tenantRoles}
              defaultValue="null"
              className="col-6 mb-8 mt-4"
            />
          )}

          {!addCustomRole ||
            (newUser && (
              <Button
                onClick={() => handleAddCustomRole()}
                color="secondary"
                size="large"
                className="px-9"
              >
                Add Custom Role
              </Button>
            ))}
        </div>
      )}

      {addCustomRole && (
        <TenantAddRole
          userId={userId}
          tenantId={tenantId}
          newUser={newUser}
          roleId={selectedRole}
          setAddCustomRole={setAddCustomRole}
        />
      )}
    </div>
  );
}
