const setSearchKey = (data = {}, searchMap, key) => {
  if (key === "Tag") {
    return `tags.name=${data.Tag}`;
  } else if (key === "Status") {
    return `${searchMap[key]}=${encodeURIComponent(data[key])}`;
  } else if (key === "Pack Sizes") {
    return `pack_sizes.id=_null_`;
  } else if (searchMap[key]) {
    return `${searchMap[key]}=_like(${encodeURIComponent(data[key])})_`;
  } else return "";
};

export const handleSearch = (data = {}, searchMap = {}, setSearch, setPage) => {
  setSearch(
    Object.keys(data)
      .map((key) => setSearchKey(data, searchMap, key))
      .join("&")
  );
  setPage && setPage(0);
};

export const objectCheck = (data = []) => {
  return data.every((item) => typeof item !== "object");
};
