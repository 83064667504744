import { shareTypes } from "./actions";

const initialState = {
  sharesList: [],
};

export function sharesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case shareTypes.SET_DATA:
      return {
        ...state,
        sharesList: payload,
      };

    default:
      return state;
  }
}
