import { invoicesTypes } from "./actions";

const initialState = {
  invoicesList: [],
};

export function invoicesReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case invoicesTypes.SET_DATA:
      return {
        ...state,
        invoicesList: payload,
      };

    default:
      return state;
  }
}
