import React from "react";
import { Dialog, DialogContent } from "@material-ui/core";

export function ShareModal({ onClose, isOpen, modalContent, submitable = false }) {
  return (
    <Dialog onClose={onClose} open={isOpen} fullWidth={true} maxWidth="lg">
      <DialogContent className="p-0">
        {modalContent}{" "}
        {!submitable && (
          <div className="text-right mb-10 mr-10">
            <button onClick={onClose} className="btn btn-white">
              <strong>OK</strong>
            </button>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
