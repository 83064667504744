const initialState = {
  taxList: [
    // {
    //   name: "GST",
    //   rate: "10%",
    //   account: "1234 - GST Settlement",
    //   id: 1
    // },
    // {
    //   name: "GST Free",
    //   rate: "0%",
    //   account: "1234 - GST Settlement",
    //   id: 2
    // },
  ],
};

export const taxesReducer = (state = initialState, action) => {
  if (typeof action.execute === "function" && action.type === "taxes")
    return action.execute(state);
  return state;
};
