/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import { Select } from "../../../../../ui/components/Select";
import { InvoiceItemRowItem } from "./InvoiceItemRowItem";

export function InvoiceItemRow({
  item = {},
  headings,
  handleAddNewItem,
  taxList,
  taxListOptions,
  setInvoice,
  handleRemoveItem,
  index,
  length,
  handleDataUpdate,
  invoice = {},
}) {
  const getTaxRateDefaultValue = () => {
    return (taxListOptions.find(({ rate }) => rate === item.tax_rate) || {})
      .value;
  };

  const [unitPrice, setUnitPrice] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [lineValue, setLineValue] = useState(0);
  const [taxValue, setTaxValue] = useState(
    getTaxRateDefaultValue() || taxListOptions[1]?.value || 0
  );
  const [taxListOptionsModified, setTaxListOptions] = useState(taxListOptions);

  const updateFieldChanged = (column, value) => {
    let newItem = { ...item, [column]: value };
    const index = invoice.invoice_lines.findIndex(
      (line) => line.id === item.id
    );
    const payload = {
      ...invoice,
      invoice_lines: [
        ...invoice.invoice_lines.slice(0, index),
        newItem,
        ...invoice.invoice_lines.slice(index + 1),
      ],
    };
    handleDataUpdate(payload);
  };

  useEffect(() => {
    const taxRate = (
      taxListOptions.find(({ value }) => value === taxValue) || {}
    ).rate;
    updateFieldChanged("tax_id", taxValue);
    updateFieldChanged("tax_rate", taxRate);
    // eslint-disable-next-line
  }, [taxListOptions, taxListOptionsModified]);

  useEffect(() => {
    if (taxList.length) {
      let selectedTaxRate = taxList.length
        ? taxList?.find((o) => o.id === taxValue)?.rate / 100
        : 0;
      if (selectedTaxRate > 0) {
        setTaxListOptions(
          taxListOptions.map((el) =>
            el.value === taxValue
              ? {
                  ...el,
                  label:
                    el.label +
                    "  ~  $ " +
                    Number.parseFloat(
                      unitPrice * quantity * selectedTaxRate
                    ).toFixed(2),
                }
              : el
          )
        );
      } else {
        updateFieldChanged("tax_rate", 0);
      }
    }
    setLineValue(unitPrice * quantity);
    // eslint-disable-next-line
  }, [unitPrice, setUnitPrice, quantity, setQuantity, taxValue, setTaxValue]);

  return (
    <Fragment>
      <tr>
        {headings.map(([key, id], i) => (
          <td
            key={i}
            className={`align-middle no-line border-0 p-1`}
            style={{ width: 10 }}
          >
            {key === "line_number" ? (
              index + 1
            ) : key === "total_value" ? (
              <div style={{ padding: "0 8px" }}>
                $ {Number.parseFloat(lineValue).toFixed(2)}
              </div>
            ) : key === "tax_id" ? (
              <Select
                value={taxValue}
                key={i}
                className="bg-white"
                onChange={(e) => {
                  setTaxValue(e.target.value);
                  updateFieldChanged("tax_id", e.target.value);
                }}
                style={{ borderColor: "white" }}
                options={taxList && taxListOptionsModified}
              />
            ) : key === "remove" ? (
              length > 1 ? (
                <div className="pl-5">
                  <span
                    className="cursor-pointer"
                    onClick={handleRemoveItem(item.id)}
                  >
                    <ClearIcon size="large" color="secondary" />
                  </span>
                </div>
              ) : null
            ) : (
              <InvoiceItemRowItem
                value={item[key]}
                column={key}
                updateFieldChanged={updateFieldChanged}
                setUnitPrice={setUnitPrice}
                setQuantity={setQuantity}
                setLineValue={setLineValue}
              />
            )}
          </td>
        ))}
      </tr>
    </Fragment>
  );
}
