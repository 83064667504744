export const billerInvoicesTypes = {
  SET_DATA: "BILLER_INVOICES/SET_DATA",
  UPDATE_DATA: "BILLER_INVOICES/UPDATE_DATA",
};

export function setBillerInvoicesData(data) {
  return {
    type: billerInvoicesTypes.SET_DATA,
    payload: data,
  };
}

export function updateBillerInvoicesData(data) {
  return {
    type: billerInvoicesTypes.UPDATE_DATA,
    payload: data,
  };
}
