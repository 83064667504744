import React from "react";
import { Formik } from "formik";
import {
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
} from "@material-ui/core";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { MappedDatePicker } from "../../components/DatePicker";
import { FilterBarFieldMultiple } from "../../components/FilterBarFieldMultiple";
import { formatDate } from "../../helpers/helpers";
import { uniqBy } from "lodash";
import { FilterBarFieldSingle } from "../../components/FilterBarFieldSingle";
import { DependentField } from "./DependentField";

const sortHelper = function(a, b) {
  if (a.label > b.label) return 1;
  if (a.label < b.label) return -1;
  return 0;
};

const startDateStyle = {
  flex: "0 0 200px",
  maxWidth: 200,
  margin: "0 7px 0 12px",
  minHeight: "60px",
};

const endDateStyle = {
  flex: "0 0 225px",
  maxWidth: 225,
  padding: "0 0 0 25px",
  marginRight: 10,
  minHeight: "60px",
};

const dateKeys = ["from", "to", "due_date"];

const filterOptions = createFilterOptions({
  limit: 70,
});

const modifyPlaceholder = (text = "") => {
  return text
    .charAt(0)
    .toUpperCase()
    .concat(text.slice(1).toLowerCase())
    .split("_")
    .join(" ");
};

export function FilterBar({
  onSubmit,
  onReset,
  singleValueFields = [],
  multipleValueFields = [],
  filterValues = {},
  title = "Filter",
  schema,
  hasDependentField,
  DependentFieldAPI,
  tenantID,
  parentFieldName,
  initialValues,
  dependentFieldLabel,
  dependentFieldDefaultValue,
  defaultExpanded,
  getSelectedItem,
}) {
  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema || null}>
      {({ handleSubmit, isSubmitting, resetForm, values, setFieldValue, errors, touched }) => (
        <div className="mb-10">
          <Accordion defaultExpanded={defaultExpanded}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <strong>{title}</strong>
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex-column w-100">
                <div className="row align-items-center my-3">
                  {singleValueFields.find((key) => key === "from") && (
                    <div style={startDateStyle}>
                      <MappedDatePicker
                        name="from"
                        className="border border-secnodary"
                        placeholderText={formatDate(Date.now())}
                      />
                    </div>
                  )}
                  {singleValueFields.find((key) => key === "to") && (
                    <>
                      <div style={{ width: 0 }}>
                        <div className="text-center text-muted mb-3">To</div>
                      </div>
                      <div style={endDateStyle}>
                        <MappedDatePicker
                          name="to"
                          className="border border-secnodary"
                          placeholderText={formatDate(Date.now())}
                        />
                      </div>
                    </>
                  )}

                  {singleValueFields.find((key) => key === "due_date") && (
                    <div className="row align-items-center">
                      <div className="text-muted ml-10 mr-2 ">Due Date : </div>
                      <div>
                        <MappedDatePicker
                          name="due_date"
                          className="border border-secnodary"
                          placeholderText={formatDate(Date.now())}
                        />
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-flex my-5 justify-content-between flex-wrap">
                  {singleValueFields.map(
                    (key) =>
                      !dateKeys.includes(key) && (
                        <div style={{ width: "49%", minHeight: "80px" }} className="mb-5" key={key}>
                          <FilterBarFieldSingle
                            filterOptions={filterOptions}
                            name={key}
                            placeholder={modifyPlaceholder(key)}
                            setValue={setFieldValue}
                            options={uniqBy(filterValues[key] || [], "label").sort(sortHelper)}
                            defaultValueField="value"
                            data-testid={key}
                            tip={touched[key] && errors[key] ? errors[key] : null}
                            defaultValue={initialValues[key]}
                          />
                        </div>
                      )
                  )}

                  {hasDependentField && (
                    <DependentField
                      tenantID={tenantID}
                      parentFieldName={parentFieldName}
                      filterOptions={filterOptions}
                      name="account"
                      modifyPlaceholder={modifyPlaceholder}
                      DependentFieldAPI={DependentFieldAPI}
                      label={dependentFieldLabel}
                      defaultValue={dependentFieldDefaultValue}
                      getSelectedItem={getSelectedItem}
                    />
                  )}

                  {multipleValueFields.map((key) => (
                    <div style={{ width: "49%", minHeight: "80px" }} className="mb-5" key={key}>
                      <FilterBarFieldMultiple
                        filterOptions={filterOptions}
                        name={key}
                        placeholder={modifyPlaceholder(key)}
                        setValue={setFieldValue}
                        options={uniqBy(filterValues[key] || [], "label").sort(sortHelper)}
                        defaultValueField="value"
                        data-testid={key}
                        tip={touched[key] && errors[key] ? errors[key] : null}
                      />
                    </div>
                  ))}
                </div>
                <div className="row align-items-center justify-content-end mr-1 mb-5">
                  <Button
                    onClick={onReset(resetForm)}
                    disabled={isSubmitting}
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="mr-3"
                    data-testid="cancel"
                  >
                    Reset
                  </Button>
                  <Button
                    onClick={handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    data-testid="submit"
                    disabled={isSubmitting}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      )}
    </Formik>
  );
}
