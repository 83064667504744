import React from "react";
import { TextareaAutosize } from "@material-ui/core";
import { useField } from "formik";
import { getTip } from "../helpers/helpers";

export function TextArea({
  label,
  rows = 3,
  rowsMin,
  rowsMax,
  tip,
  labelClassName,
  inputClassName,
  placeholder,
  ...other
}) {
  return (
    <>
      {label && <div className={labelClassName}>{label}</div>}
      <TextareaAutosize
        className={`form-control h-100 border-secondary ${inputClassName}`}
        placeholder={placeholder}
        rowsMin={rowsMin || rows}
        rowsMax={rowsMax || rows}
        {...other}
      />
      {tip && <div>{tip}</div>}
    </>
  );
}

export function MappedTextArea(props) {
  const [field, meta] = useField(props.name);
  return <TextArea {...field} {...props} tip={getTip(meta, props.tip)} />;
}
