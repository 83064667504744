import React from "react";
import { withRouter } from "react-router-dom";
import { Button } from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab/";
import { Formik } from "formik";
import { MappedInput } from "../../components/Input";
import { MappedInputMask } from "../../components/InputMask";
import { MappedSelect } from "../../components/Select";
import { useFetch } from "../../../hooks/fetch.hook";
import { organisationDetailsSchema } from "../../../helpers/schemas";
import { stateOptions } from "../../helpers/constants";
import { capitalizeObject, removeEmptyFields } from "../../helpers/helpers";
import { labelStyle, inputStyle } from "./constants";
import { MappedDatePicker } from "../../components/DatePicker";

function OrganisationDetailsForm({
  history,
  data,
  onClose,
  onSubmit,
  setData,
  user,
}) {
  const initialValues = {
    name: data.name || "",
    legalName: data.legalName || "",
    shortCode: data.shortCode || "",
    phone: data.phone || "",
    mobile: data.mobile || "",
    email: data.email || "",
    physicalAddress: data.physicalAddress || "",
    physicalAddress2: data.physicalAddress2 || "",
    physicalSuburb: data.physicalSuburb || "",
    physicalState: data.physicalState || "",
    physicalPostCode: data.physicalPostCode || "",
    postalAddress: data.postalAddress || "",
    postalAddress2: data.postalAddress2 || "",
    postalSuburb: data.postalSuburb || "",
    postalState: data.postalState || "",
    postalPostCode: data.postalPostCode || "",
    ABN: data.ABN || "",
    ACN: data.ACN || "",
    start_date: data.start_date || "",
    last_review_date: data.last_review_date || "",
    end_date: data.end_date || "",
    access_card_expiry_date: data.access_card_expiry_date || "",
    indicative_limit: data.indicative_limit || "",
  };

  const TENANT_ID = user.selectedTenant;

  const { request } = useFetch();

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (!Object.keys(data).length) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const titleCaseValues = capitalizeObject(values);
    const payload = {
      tenant_id: TENANT_ID,
      ...data,
      ...titleCaseValues,
    };
    removeEmptyFields(payload);
    request(onSubmit, TENANT_ID, payload, data.id)
      .then((data) => {
        if (data) {
          setData((state) => ({
            ...state,
            ...data,
            start_date: (data.start_date && new Date(data.start_date)) || "",
            end_date: (data.end_date && new Date(data.end_date)) || "",
            last_review_date:
              (data.last_review_date && new Date(data.last_review_date)) || "",
            access_card_expiry_date:
              (data.access_card_expiry_date &&
                new Date(data.access_card_expiry_date)) ||
              "",
          }));
          history.push(`/contacts/organisations-list/${data.id}`);
          if (onClose) return onClose(false);
        }
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={organisationDetailsSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded">
            <div className="px-21 py-19">
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">
                    Organisation Name
                  </p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="name"
                    wrapperClassName="w-100"
                    autoFocus
                    placeholder="Credit Service"
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Legal Name</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="legalName"
                    placeholder="Credit Service"
                    wrapperClassName="w-100"
                    data-testid="legalName"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Short Code / ID</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="shortCode"
                    placeholder="1"
                    wrapperClassName="w-100"
                    data-testid="shortCode"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Phone</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="phone"
                    icon="phone"
                    placeholder="0481074271"
                    wrapperClassName="w-100"
                    data-testid="phone"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Mobile</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="mobile"
                    icon="phone"
                    placeholder="0481074271"
                    wrapperClassName="w-100"
                    data-testid="mobile"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Email Address</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    placeholder="hello@creditservice.com.au"
                    name="email"
                    icon="alternate_email"
                    wrapperClassName="w-100"
                    data-testid="email"
                  />
                </div>
              </div>
              {/*  */}
              {/*  */}
              {/*  */}
              <div className="row mb-3">
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Trading Address
                      </p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        placeholder="98 Shirley Street"
                        name="physicalAddress"
                        wrapperClassName="w-100"
                        data-testid="physicalAddress"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Trading Address 2
                      </p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        name="physicalAddress2"
                        placeholder="98 Shirley Street"
                        wrapperClassName="w-100"
                        data-testid="physicalAddress2"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Trading Suburb
                      </p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        name="physicalSuburb"
                        placeholder="BRISBANE MARKETS"
                        wrapperClassName="w-100"
                        data-testid="physicalSuburb"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Trading State
                      </p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedSelect
                        name="physicalState"
                        options={stateOptions}
                        data-testid="physicalState"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Trading Postсode
                      </p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        placeholder="4106"
                        name="physicalPostCode"
                        wrapperClassName="w-100"
                        data-testid="physicalPostCode"
                      />
                    </div>
                  </div>
                </div>

                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Postal Address
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        placeholder="PO Box 70"
                        name="postalAddress"
                        wrapperClassName="w-100"
                        data-testid="postalAddress"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Postal Address 2
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        placeholder="PO Box 70"
                        name="postalAddress2"
                        wrapperClassName="w-100"
                        data-testid="postalAddress2"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Postal Suburb
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        placeholder="BRISBANE MARKETS"
                        name="postalSuburb"
                        wrapperClassName="w-100"
                        data-testid="postalSuburb"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">Postal State</p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedSelect
                        name="postalState"
                        options={stateOptions}
                        data-testid="postalState"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Postal Postсode
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        name="postalPostCode"
                        placeholder="4106"
                        wrapperClassName="w-100"
                        data-testid="postalPostCode"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">ABN</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInputMask
                    onBlur={handleOnBlur}
                    placeholder="96 727 681 765"
                    name="ABN"
                    mask="99 999 999 999"
                    testid="ABN"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">ACN</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInputMask
                    onBlur={handleOnBlur}
                    name="ACN"
                    placeholder="727 681 765"
                    mask="999 999 999"
                    testid="ACN"
                  />
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">Start Date</p>
                    </div>
                    <div className="col-7" style={inputStyle}>
                      <MappedDatePicker
                        name="start_date"
                        onBlur={handleOnBlur}
                        className="border border-secnodary"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">End Date</p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedDatePicker
                        name="end_date"
                        onBlur={handleOnBlur}
                        className="border border-secnodary"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Last Review Date
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedDatePicker
                        name="last_review_date"
                        onBlur={handleOnBlur}
                        className="border border-secnodary"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Access card expiry date
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedDatePicker
                        name="access_card_expiry_date"
                        onBlur={handleOnBlur}
                        className="border border-secnodary"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-5">
                  <div className="row mb-3">
                    <div className="col-5 my-auto" style={labelStyle}>
                      <p className="text-right text-muted mb-0">
                        Indicative Limit
                      </p>
                    </div>
                    <div className="col-7 mr-auto" style={inputStyle}>
                      <MappedInput
                        onBlur={handleOnBlur}
                        name="indicative_limit"
                        wrapperClassName="w-100"
                        placeholder="Add indicative limit"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={
                    onClose ? () => onClose(false) : () => history.go(-1)
                  }
                >
                  Cancel
                </Button>
                <Button
                  onClick={props.handleSubmit}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                  disabled={props.isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default withRouter(OrganisationDetailsForm);
