import React from "react";
import { Button } from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab/";
import { Formik } from "formik";
import { CommentsManagerFormSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../components/Input";

export function CommentManagerForm({
  commentManagerData = {},
  tenantId,
  onClose,
  setData,
  onSubmit,
  isNewCommentType,
}) {
  const { comment_type_name, comment_category } = commentManagerData;

  const initialValues = {
    comment_type_name: comment_type_name || "",
    comment_category: comment_category || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (!Object.keys(commentManagerData).length && !isNewCommentType) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  const handleSubmit = (values) => onSubmit(values).then(() => onClose(false));

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={CommentsManagerFormSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div className="bg-white rounded">
          <div className="bg-white rounded px-21 py-19">
            <div className="row mb-3">
              <div className="col-2 my-auto">
                <p className="text-right text-muted mb-0">Comment Category</p>
              </div>
              <div className="col-3 mr-auto">
                <MappedInput
                  onBlur={handleOnBlur}
                  name="comment_category"
                  wrapperClassName="w-100"
                  data-testid="category"
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-2 my-auto">
                <p className="text-right text-muted mb-0">Comment Type</p>
              </div>
              <div className="col-3 mr-auto">
                <MappedInput
                  onBlur={handleOnBlur}
                  name="comment_type_name"
                  wrapperClassName="w-100"
                  data-testid="type"
                />
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                variant="outlined"
                color="secondary"
                size="large"
                className="px-9 mr-3"
                onClick={() => onClose(false)}
                data-testid="cancel"
              >
                Cancel
              </Button>
              <Button
                onClick={props.handleSubmit}
                type="submit"
                variant="contained"
                color="secondary"
                size="large"
                className="px-9"
                data-testid="submit"
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      )}
    </Formik>
  );
}
