import React, { useEffect } from "react";
import { TextField } from "@material-ui/core";
import { Autocomplete as MuiAutocomplete } from "@material-ui/lab";
import { makeStyles, Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const useStyles = makeStyles({
  tag: {
    backgroundColor: "#aee2f2",
    color: "black",
  },
  root: {
    zIndex: 0,
  },
});

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export const FilterBarFieldMultiple = ({
  options = [],
  tip,
  placeholder = "Select option",
  loading,
  setValue,
  name,
  defaultValue,
  defaultValueField = "id",
  disabled,
  label = "label",
  disableCloseOnSelect = true,
  variant = "outlined",
  ...other
}) => {
  const classes = useStyles();

  const handleChange = (_, value = []) => {
    setValue(
      name,
      value.map(({ value }) => value)
    );
  };

  const setDefaultValue = () =>
    options.filter((item) => (defaultValue || []).includes(item[defaultValueField]));

  useEffect(() => {
    if (!defaultValue || !setValue) return;
    setValue(
      name,
      (setDefaultValue() || []).map(({ value }) => value)
    );
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <MuiAutocomplete
        options={options}
        id={name}
        onChange={handleChange}
        getOptionLabel={(item) => item[label]}
        defaultValue={setDefaultValue}
        disabled={disabled}
        multiple={true}
        disableCloseOnSelect={disableCloseOnSelect}
        classes={classes}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            color="secondary"
            variant={variant}
            className={classes.root}
          />
        )}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option[label]}
          </React.Fragment>
        )}
        {...other}
      />
      {tip && <div className="text-danger">{tip}</div>}
    </div>
  );
};
