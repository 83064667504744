export const contactsTypes = {
  SET_DATA: "CONTACTS/SET_DATA",
  UPDATE_DATA: "CONTACTS/UPDATE_DATA",
  ARCHIVE_DATA: "CONTACTS/ARCHIVE_DATA",
  UNARCHIVE_DATA: "ORGANISATIONS/UNARCHIVE_DATA",
  UPDATE_CONTACT: "CONTACTS/UPDATE_CONTACT",
  TOGGLE_SHOW_ARCHIVED: "CONTACTS/TOGGLE_SHOW_ARCHIVED",
};

export function setContactsList(data) {
  return {
    type: contactsTypes.SET_DATA,
    payload: data,
  };
}

export function updateContact(data) {
  return {
    type: contactsTypes.UPDATE_CONTACT,
    payload: data,
  };
}

export function archiveContact(id) {
  return {
    type: contactsTypes.ARCHIVE_DATA,
    payload: id,
  };
}

export function unArchiveData(index, data) {
  return {
    type: contactsTypes.UNARCHIVE_DATA,
    payload: data,
    index,
  };
}

export function updateContactsList(data) {
  return {
    type: contactsTypes.UPDATE_DATA,
    payload: data,
  };
}

export function toggleArchivedContacts() {
  return {
    type: contactsTypes.TOGGLE_SHOW_ARCHIVED,
  };
}
