import config from "react-global-configuration";

export const getLastUpload = (integration_id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    mode: "cors",
    credentials: "include",
  });

export const createIntegration = (integration_data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(integration_data),
    mode: "cors",
    credentials: "include",
  });

export const modifyIntegration = (integration_id, integration_data) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(integration_data),
    mode: "cors",
    credentials: "include",
  });

export const deleteIntegration = (integration_id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    method: "DELETE",
    mode: "cors",
    credentials: "include",
  });

export const getIntegrationById = (integration_id) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/${integration_id}`, {
    method: "get",
    headers: {
      "Content-Type": "application/json",
    },
    mode: "cors",
    credentials: "include",
  });

export const getIntegrationByType = (tenant_id, integration_type) =>
  fetch(
    `${config.get("ApiUrl").Rest}/integrations/${integration_type &&
      "?integration_type=" + integration_type}${tenant_id &&
      "&tenant_id=" + tenant_id}`,
    {
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      credentials: "include",
    }
  );

export const getAllBsbIntegrations = (integration_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/integrations/?integration_type=bsb&tenant_id=${integration_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

// export const getMailchimpIntegrations = (integration_id) =>
//   fetch(
//     `${
//       config.get("ApiUrl").Rest
//     }/integrations/?integration_type=mailchimp&tenant_id=${integration_id}`,
//     {
//       mode: "cors",
//       credentials: "include",
//     }
//   );

export const getMailchimpLists = (mailchimp_api_key) =>
  fetch(`${config.get("ApiUrl").Rest}/integrations/mailchimp/show-lists`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ api_key: mailchimp_api_key }),
    mode: "cors",
    credentials: "include",
  });

export const postSendBulkSms = (tenant_id, sms_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/sms/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sms_data),
    mode: "cors",
    credentials: "include",
  });

export const getBulkSMSBalance = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/sms/`, {
    mode: "cors",
    credentials: "include",
  });
