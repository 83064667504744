export const stateOptions = [
  {
    value: "QLD",
    label: "QLD",
  },
  {
    value: "NSW",
    label: "NSW",
  },
  {
    value: "TAS",
    label: "TAS",
  },
  {
    value: "VIC",
    label: "VIC",
  },
  {
    value: " ACT",
    label: " ACT",
  },
  {
    value: " SA",
    label: " SA",
  },
  {
    value: "WA",
    label: "WA",
  },
  {
    value: "NT",
    label: "NT",
  },
];
