import { all } from "redux-saga/effects";
import { combineReducers } from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import { organisationsReducer } from "../app/modules/Organisations/_redux/reducer";
import { contactsReducer } from "../app/modules/Contacts/_redux/reducer";
import { accountsReducer } from "../app/modules/LedgerAccounts/_redux/reducer";
import { auditReducer } from "../app/modules/AuditTrail/_redux/reducer";
import { usersReducer } from "../app/modules/UserManagment/_redux/reducer";
import { paymentCyclesReducer } from "../app/modules/PaymentCycles/_redux/reducer";
import { feesReducer } from "../app/modules/Fees/FeesModule/_redux/reducer";
import { taxesReducer } from "../app/modules/Fees/TaxesModule/_redux/reducer";
import { sharesReducer } from "../app/modules/ShareRegister/_redux/reducer";
import { transactionsReducer } from "../app/modules/Transactions/_redux/reducer";
import { invoiceEntryReducer } from "../app/modules/InvoiceEntry/_redux/reducer";
import { NewRuleFormReducer } from "../app/modules/Receipting/ReconciliationModul/MatchItemModul/ReconciliationDialogForms/NewRuleForm/_redux/reducer";
import { lateHistoryReducer } from "../app/modules/LateHistory/_redux/reducer";
import { discrepancyReducer } from "../app/modules/DiscrepancyManagement/_redux/reducer";
import { depositReducer } from "../app/modules/Deposits/_redux/reducer";
import { delinquentReducer } from "../app/modules/DelinquentBuyers/_redux/reducer";
import { statementsReducer } from "../app/modules/CreditServiceStatements/_redux/reducer";
import { billerInvoicesReducer } from "../app/modules/BillerInvoiceSearch/_redux/reducer";
import { receiptingLogicReducer } from "../app/modules/ReceiptingLogic/_redux/reducer";
import { invoicesReducer } from "../app/modules/Invoices/_redux/reducer";
import { billingCheckReducer } from "../app/modules/BillingCheck/_redux/reducer";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  organisations: organisationsReducer,
  contacts: contactsReducer,
  accounts: accountsReducer,
  auditLogs: auditReducer,
  users: usersReducer,
  paymentCycles: paymentCyclesReducer,
  fees: feesReducer,
  taxes: taxesReducer,
  sharesRegister: sharesReducer,
  transactions: transactionsReducer,
  invoiceEntry: invoiceEntryReducer,
  newRule: NewRuleFormReducer,
  lateHistory: lateHistoryReducer,
  discrepancy: discrepancyReducer,
  deposit: depositReducer,
  delinquent: delinquentReducer,
  creditServiceStatements: statementsReducer,
  billerInvoiceSearch: billerInvoicesReducer,
  receiptingLogic: receiptingLogicReducer,
  invoices: invoicesReducer,
  billingCheck: billingCheckReducer,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
