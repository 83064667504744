import React, { useState } from "react";
import Tabs from "../../ui/components/Tabs";
import TenantDetailsForm from "../../ui/structures/TenantDetailsForm";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import { createTenant } from "../modules/Tenants/_api";

export function AddNewTenant({ match, location, history }) {
  const [data, setData] = useState({
    name: "",
    legal_name: "",
    id: "",
    phone: "",
    general_email: "",
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postcode: "",
    web_address: "",
    ledger_connection_string: "",
    status: "active",
  });

  const tabs = [
    {
      label: "Contact Details",
      icon: <ContactMailIcon />,
      tabPanel: (
        <TenantDetailsForm
          data={data}
          history={history}
          onSubmit={createTenant}
          setData={setData}
          isAddNewTenant={true}
        />
      ),
    },
  ];

  return (
    <>
      <div className="mb-9">
        <Tabs defaultActive="0" tabs={tabs} />
      </div>
    </>
  );
}
