import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataGrid from "../../../ui/components/DataGrid";
import {
  renderHeader,
  renderTransactionRow,
} from "../../../ui/helpers/helpers";
import { useFetch } from "../../../hooks/fetch.hook";
import { setTransactionsData } from "./_redux/actions";
import { ReportsForm } from "./ReportsForm";
import { getLedgerAccountTransactions } from "../Transactions/_api";
import { getAccountsList } from "../LedgerAccounts/_api";
import { HEADINGS } from "./constants";

export function List({ match, location }) {
  // console.log("START COMPONENT");
  const dispatch = useDispatch();
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const ORGANISATION_ID = user.default_organisation_id;

  const [transactions, setTransactions] = useState([]);
  const [account, setAccount] = useState(0);
  const [accountsList, setAccountsList] = useState([
    { value: 0, label: "Select Account" },
  ]);
  const [searchDates, setSearchDates] = useState({});
  const [pageSize, setPageSize] = useState(25);
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [loading, setLoading] = useState(false);

  const data =
    useSelector(({ transactions }) => transactions.transactionsList) || [];

  function fetchLedgerTransactions(
    account_id,
    from_date,
    to_date,
    count_only = false
  ) {
    // console.log(TENANT_ID, ORGANISATION_ID, account_id, accountsList);
    if (account_id) {
      setLoading(true);
      // console.log("in fetch if account_id:", account_id);
      request(
        getLedgerAccountTransactions,
        TENANT_ID,
        ORGANISATION_ID,
        account_id,
        (from_date && from_date) || undefined,
        (to_date && to_date) || undefined,
        search,
        page,
        pageSize,
        count_only
      )
        .then((transactions) => {
          if (transactions) {
            if (count_only) {
              setTotalRecords(transactions);
            } else {
              const modifiedTransactions = transactions?.map(
                ({ transaction_value, ...item }) => ({
                  ...item,
                  transaction_value: "" + transaction_value,
                })
              );

              dispatch(setTransactionsData(modifiedTransactions));
              setTransactions(modifiedTransactions);
            }
          }
        })
        .finally(() => setLoading(false));
    }

    // console.log("before fetch accountsist:", accountsList.length);
    if (ORGANISATION_ID && accountsList.length === 1) {
      // console.log("in fetch accountslist:", accountsList);
      request(getAccountsList, TENANT_ID, ORGANISATION_ID, "status=all").then(
        (data) => {
          if (data) {
            setAccountsList([{ value: 0, label: "Select Account" }]);
            data.forEach((item) => {
              setAccountsList((prevList) => [
                ...prevList,
                {
                  ...item,
                  value: item.id,
                  label: `${item.id}  ${item.account_name} ${
                    item.status === "archived" ? "(archived)" : ""
                  }`,
                },
              ]);
            });
          }

          setLoading(false);
        }
      );
    }
  }

  useEffect(() => {
    // console.log("useeffect Tenantid update");
    clearData();
    if (location.account_id) {
      setAccount(location.account_id);
      let date = new Date();
      setSearchDates({
        from: new Date(date.getFullYear(), date.getMonth(), 1),
        to: new Date(date.getFullYear(), date.getMonth() + 1, 0),
      });
    }
    fetchLedgerTransactions();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    if (account) {
      // console.log("useeffect account update");
      dispatch(setTransactionsData([]));
      setTransactions([]);
      setLoading(true);
      fetchLedgerTransactions(
        account,
        searchDates.from && searchDates.from.toISOString(),
        searchDates.to && searchDates.to.toISOString()
      );
    } else if (accountsList.length > 1 && account === 0) {
      setSearchDates({});
      setAccount(0);
      fetchLedgerTransactions();
    }
    // eslint-disable-next-line
  }, [account, setAccount, search, page, pageSize]);

  useEffect(() => {
    if (searchDates.from || searchDates.to) {
      // console.log("useeffect searchDates update");
      fetchLedgerTransactions(
        account,
        searchDates.from && searchDates.from.toISOString(),
        searchDates.to && searchDates.to.toISOString()
      );
    }
    // eslint-disable-next-line
  }, [searchDates, setSearchDates]);

  useEffect(() => {
    if (account) {
      fetchLedgerTransactions(
        account,
        searchDates.from && searchDates.from.toISOString(),
        searchDates.to && searchDates.to.toISOString(),
        true
      );
    }
    // eslint-disable-next-line
  }, [account, search]);

  const resetSearch = () => {
    dispatch(setTransactionsData(transactions));
  };

  const clearData = () => {
    dispatch(setTransactionsData([]));
    setTransactions([]);
    setAccountsList([{ value: 0, label: "Select Account" }]);
    setAccount(0);
    setSearchDates({});
  };

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="d-flex justify-content-between mb-3">
            <h3 className="text-dark mb-5">Ledger Transactions</h3>
          </div>
          <ReportsForm
            searchDates={searchDates}
            setSearchDates={setSearchDates}
            account={account}
            accountsList={accountsList}
            setAccount={setAccount}
            resetSearch={resetSearch}
            setSearch={setSearch}
            setPage={setPage}
            search={search}
            TENANT_ID={TENANT_ID}
            ORGANISATION_ID={ORGANISATION_ID}
            pageSize={pageSize}
            loading={loading}
          />

          <DataGrid
            data={data}
            headings={HEADINGS}
            renderHeader={renderHeader}
            renderRow={renderTransactionRow}
            loading={loading}
            perPageDefault={pageSize}
            setPageSize={setPageSize}
            serverPage={page}
            setServerPage={setPage}
            pageSize={pageSize}
            isServerPagination
            totalRecords={totalRecords}
          />
        </div>
      </div>
    </div>
  );
}
