/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button, CircularProgress, Tooltip } from "@material-ui/core";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { formatDate, formatAmount } from "../../../ui/helpers/helpers";
import { downloadReport } from "../Reports/_api";
import { sendDistribution } from "./_api";
import { downloadFile } from "../AWS-S3";
import { useFetch } from "../../../hooks/fetch.hook";
import { success } from "../../../helpers/toasts";
import config from "react-global-configuration";

const initialValues = {
  payment_date: "",
};

export const Pay = ({ reviewedItem = {}, setStep, tenantId }) => {
  const { request } = useFetch();
  const [loading, setLoading] = useState(false);
  const [
    isDistributionStatementSent,
    setIsDistributionStatementSent,
  ] = useState(false);

  const downloadDistributionReport = () => {
    const data = {
      report_name: "D03_distribution_payment_summary",
      id: [reviewedItem.id],
    };
    downloadFile(data, downloadReport, tenantId);
  };

  const handleDistributionSendSubmit = () => {
    setLoading(true);
    request(sendDistribution, tenantId, reviewedItem.id)
      .then((data) => {
        if (!data) return;
        setIsDistributionStatementSent(true);
        success("Distribution Summaries succesfully sent!");
      })
      .finally(() => setLoading(false));
  };
  return (
    <div className="bg-white p-10">
      <div className="d-flex justify-content-between mb-5">
        <h6 className="mb-10">Finalise Distribution</h6>
        <Button
          variant="contained"
          color="secondary"
          className="px-9"
          onClick={downloadDistributionReport}
        >
          Download Distribution Report
        </Button>
      </div>
      <h6 className="mb-5">{`Total: ${formatAmount(
        reviewedItem?.content?.distribution_total || 0
      )}`}</h6>
      <Formik
        onSubmit={(values) => console.log(values)}
        initialValues={initialValues}
        validationSchema={Yup.object({
          email: Yup.string()
            .required("Required field")
            .email(),
          payment_date: Yup.string().required("Required field"),
        })}
      >
        {({ handleSubmit, isSubmitting }) => (
          <>
            <div className="row mb-10">
              <div className="col-2">
                <p>Payment Date</p>
              </div>
              <div className="col-5">
                <MappedDatePicker
                  name="payment_date"
                  className="border border-secnodary"
                  placeholderText={formatDate(new Date(Date.now()))}
                />
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-2">
                <p>Send Distribution Summaries</p>
              </div>
              <div className="col-3">
                <Button
                  variant="contained"
                  color="secondary"
                  className="px-11"
                  onClick={handleDistributionSendSubmit}
                  disabled={loading}
                >
                  {loading ? (
                    <CircularProgress
                      size="1.2rem"
                      style={{ color: "#ffffff", margin: "2px 4px" }}
                    />
                  ) : (
                    "Send"
                  )}
                </Button>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-2">
                <p>Create ABA File</p>
              </div>
              <div className="col-3">
                <form
                  target="_blank"
                  action={`${
                    config.get("ApiUrl").Rest
                  }/tenants/${tenantId}/distributions/${
                    reviewedItem.id
                  }/download_aba`}
                  method="post"
                >
                  <Tooltip
                    title="Please send the Buyer Statements before"
                    arrow
                    placement="right"
                    disabled={isDistributionStatementSent}
                  >
                    <div>
                      <Button
                        variant="contained"
                        color="secondary"
                        className="px-9"
                        type="submit"
                        disabled={!isDistributionStatementSent}
                      >
                        Create
                      </Button>
                    </div>
                  </Tooltip>
                </form>
              </div>
            </div>

            <div className="text-right mt-5">
              <Button
                variant="contained"
                color="secondary"
                className="px-9"
                type="submit"
                onClick={() => setStep(0)}
              >
                Back to Drafts
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};
