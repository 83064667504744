import React, { useState, useEffect } from "react";
import { useFormikContext } from "formik";
import { MappedSelect } from "../../../../../../../ui/components/Select";
import { useFetch } from "../../../../../../../hooks/fetch.hook";
import { getAccounts } from "../../../../../Organisations/_api";

export const AccountSelect = ({ tenantID }) => {
  const { values } = useFormikContext();
  const { request } = useFetch();

  const [accounts, setAccounts] = useState([]);

  useEffect(() => {
    if (!values.organisation_id) return;
    request(getAccounts, tenantID, values.organisation_id).then(
      (data) =>
        data &&
        setAccounts(
          data.map(({ account_name, id }) => ({
            value: id,
            label: account_name,
          }))
        )
    );
    // eslint-disable-next-line
  }, [values]);

  return (
    <div className="mb-3 w-50">
      <MappedSelect
        name="account_id"
        options={accounts}
        label="Account"
        disabled={Boolean(!values.organisation_id)}
      />
    </div>
  );
};
