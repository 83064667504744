import React, { useEffect, useState } from "react";
import { Button, Chip } from "@material-ui/core/";
import { Formik } from "formik";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { TagsManagerFormSchema } from "../../../helpers/schemas";
import { Skeleton } from "@material-ui/lab/";
import { MappedInput } from "../../components/Input";
import { MappedSelect } from "../../components/Select";
import { MappedTextArea } from "../../components/TextArea";
import { useFetch } from "../../../hooks/fetch.hook";
import { getTenantTags, modifyTenantBulkTag } from "../../../app/modules/Tenants/_api";
import { TagsSearch } from "./TagsSearch";
import AlertBar from "../../components/AlertBar";
import ToolTip from "../../components/Tooltip";
import { Modal } from "../../components/Modal";
import { TagsPair } from "./TagsPair";

export function TagsManagerForm({
  tagsManagerData = {},
  tenantId,
  onClose,
  setData,
  onSubmit,
  isNewTags,
}) {
  const { request } = useFetch();
  const { name, type, description, sub_tags = [], id, tag_pairs = [] } = tagsManagerData;

  const initialValues = {
    name: name || "",
    type: type || "",
    description: description || "",
    sub_tags: [],
    tag_pairs: [],
  };

  const [tags, setTags] = useState([]);
  const [optenAlert, setOpenAlert] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    request(getTenantTags, tenantId).then((data) => data && setTags(data));
    // eslint-disable-next-line
  }, []);

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (!Object.keys(tagsManagerData).length && !isNewTags) {
    return <Skeleton variant="rect" width={"100%"} height={812} />;
  }

  const handleSubmit = (values, { setSubmitting }) => {
    const subTags = values.sub_tags.map(({ name }) => name);
    const payloadTags = [...(sub_tags || []), ...subTags];
    const tagPairs = values.tag_pairs.map(({ organisation, contact }) => ({
      organisation,
      contact,
    }));
    const payloadPairs = [...(tag_pairs || []), ...tagPairs];
    onSubmit({ ...values, sub_tags: payloadTags, tag_pairs: payloadPairs })
      .then(() => onClose(false))
      .finally(() => setSubmitting(false));
  };

  const handleUpdateTags = () => {
    request(modifyTenantBulkTag, tenantId, id).then((data) => {
      if (!data && data !== 0) return;
      setOpenAlert(true);
    });
  };

  const filterTagsOptions = (type) => {
    return tags.filter(
      (tag) => !(sub_tags || []).includes(tag.name) && tag.name !== name && tag.type === type
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={TagsManagerFormSchema}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, handleSubmit, values, isSubmitting, dirty }) => (
        <>
          {modalOpen && (
            <Modal isOpen={modalOpen} onClose={handleModalClose} fullWidth={true} maxWidth="lg">
              <TagsPair
                onClose={handleModalClose}
                data={tags}
                setValue={setFieldValue}
                selectedPairs={values.tag_pairs}
              />
            </Modal>
          )}
          {optenAlert && <AlertBar message="Tags have been updated!" />}
          <div className="bg-white rounded">
            <div className="bg-white rounded px-21 py-19">
              {!isNewTags && (
                <div className="d-flex justify-content-end mb-5">
                  <ToolTip
                    icon="Apply Dynamic Tag"
                    handleClick={handleUpdateTags}
                    title={
                      dirty
                        ? `Please, save the form first if you want new values to be applied`
                        : `Re-applies dynamic tag to 
                        the entities  which have any of the tags specified in Sub Tags`
                    }
                    variant="contained"
                    size="large"
                    placement="top"
                    disabled={dirty}
                  />
                </div>
              )}
              <div className="row mb-3">
                <div className="col-2 my-auto">
                  <p className="text-right text-muted mb-0">Tag Name</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedInput
                    onBlur={handleOnBlur}
                    name="name"
                    wrapperClassName="w-100"
                    data-testid="name"
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-2">
                  <p className="text-right text-muted mt-3">Tag Type</p>
                </div>
                <div className="col-3 mr-auto">
                  <MappedSelect
                    name="type"
                    disabled={id ? true : false}
                    options={[
                      { value: "organisation", label: "Organisation" },
                      { value: "contact", label: "Contact" },
                    ]}
                    data-testid="type"
                  />
                </div>
              </div>
              <div className="row mb-5">
                <div className="col-2">
                  <p className="text-right text-muted mt-2">Description</p>
                </div>
                <div className="col-5 mr-auto">
                  <MappedTextArea name="description" rows={6} data-testid="description" />
                </div>
              </div>

              {values.type === "contact" && !isNewTags && (
                <div className="row mb-4 align-items-center">
                  <div className="col-2">
                    <p className="text-right text-muted mt-3">Selected Tag Pairs</p>
                  </div>
                  <div className="col-10 mr-auto d-flex align-items-center">
                    <div className="mb-1">
                      {(tag_pairs || []).length
                        ? tag_pairs.map(({ organisation, contact }) => (
                            <span className="mr-3" key={`${contact} + ${organisation}`}>
                              <Chip label={`${organisation} : ${contact}`} />
                            </span>
                          ))
                        : "No Tag Pairs selected"}
                    </div>
                  </div>
                </div>
              )}

              {values.type === "contact" && (
                <div className="row mb-4 align-items-center">
                  <div className="col-2">
                    <p className="text-right text-muted">Add Tag Pairs</p>
                  </div>
                  <div className="col-10 mr-auto d-flex align-items-center">
                    <div className="d-flex flex-wrap">
                      {(values.tag_pairs || []).length
                        ? values.tag_pairs.map(({ organisation, contact, id }) => (
                            <div className="mr-3 my-2" key={id}>
                              <Chip label={`${organisation} : ${contact}`} />
                            </div>
                          ))
                        : "No Tag Pairs added"}
                    </div>
                    <div className="ml-5 my-2">
                      <ToolTip
                        title="Add Tag Pair"
                        handleClick={handleModalOpen}
                        icon={<AddCircleIcon color="primary" />}
                      />
                    </div>
                  </div>
                </div>
              )}

              {!isNewTags && (
                <div className="row mb-4 align-items-center">
                  <div className="col-2">
                    <p className="text-right text-muted mt-3">Selected Sub Tags</p>
                  </div>
                  <div className="col-10 mr-auto">
                    {(sub_tags || []).length ? sub_tags.join(", ") : "No Sub Tags selected"}
                  </div>
                </div>
              )}

              <div className="row mb-2 align-items-center">
                <div className="col-2">
                  <p className="text-right text-muted mt-3">Add Sub Tags</p>
                </div>
                <div className="col-10 mr-auto">
                  <TagsSearch
                    options={filterTagsOptions(id ? type : values.type)}
                    name="sub_tags"
                    setValue={setFieldValue}
                    placeholder="Select Tags"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-end align-items-center mt-10">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={() => onClose(false)}
                  data-testid="cancel"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  data-testid="submit"
                  disabled={isSubmitting}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
