/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useFetch } from "../../hooks/fetch.hook";
import { MappedInput } from "../components/Input";
import { MappedSelect } from "../components/Select";
import { MappedTextArea } from "../components/TextArea";
import { MappedDatePicker } from "../components/DatePicker";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import {
  addImportantMessage,
  editImportantMessage,
} from "../../app/modules/DashboardFOHBiller/_api";
import { formatDateToUTC } from "../helpers/helpers";

export function ImportantMessagesForm({
  selectedMessageData = [],
  open,
  setOpen,
  tenantId,
}) {
  const { request } = useFetch();
  const { title, type, message, date_expiry } = selectedMessageData;
  const isAddNew = selectedMessageData.length === 0;
  const [loading, setLoading] = useState(false);

  const initialValues = {
    title: title || "",
    type: type || "",
    message: message || "",
    date_expiry: (date_expiry && new Date(date_expiry)) || "",
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="form-dialog-title">
          {isAddNew ? "Add New " : "Edit "} Message
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={initialValues}
            validationSchema={Yup.object({
              title: Yup.string().required("Required"),
              type: Yup.string().required("Required"),
              message: Yup.string().required("Required"),
            })}
            onSubmit={(values) => {
              setLoading(true);
              isAddNew
                ? request(addImportantMessage, tenantId, {
                    ...values,
                    date_expiry: formatDateToUTC(values.date_expiry),
                  }).then((response) => {
                    if (response) handleClose();
                    setLoading(false);
                  })
                : request(
                    editImportantMessage,
                    tenantId,
                    selectedMessageData.id,
                    {
                      ...values,
                      date_expiry: formatDateToUTC(values.date_expiry),
                    }
                  ).then((response) => {
                    if (response) handleClose();
                    setLoading(false);
                  });
            }}
          >
            {(props) => (
              <div className="bg-white rounded">
                <div className="row">
                  <div className="col-12">
                    <div className="row mb-3">
                      <div className="col-2 my-auto">
                        <p className="text-right text-muted mb-0">Title</p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedInput name="title" wrapperClassName="w-100" />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2">
                        <p className="text-right text-muted mt-3">Type</p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedSelect
                          name="type"
                          options={[
                            {
                              value: "Information",
                              label: "Information",
                            },
                            {
                              value: "Warning",
                              label: "Warning",
                            },
                            {
                              value: "Security",
                              label: "Security",
                            },
                            {
                              value: "Event",
                              label: "Event",
                            },
                          ]}
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2">
                        <p className="text-right text-muted">Expiry Date</p>
                      </div>
                      <div className="col-8 mr-auto">
                        <MappedDatePicker
                          name="date_expiry"
                          testId="date_expiry"
                        />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-2">
                        <p className="text-right text-muted mt-2">Message</p>
                      </div>
                      <div className="col-10 mr-auto">
                        <MappedTextArea name="message" rows={6} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-end align-items-center mt-4">
                  <Button
                    variant="outlined"
                    color="secondary"
                    size="large"
                    className="px-9 mr-3"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={props.handleSubmit}
                    variant="contained"
                    color="secondary"
                    size="large"
                    className="px-9"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        size="1.5rem"
                        style={{
                          margin: "0 auto",
                          // color: "#6ba3ee !important",
                        }}
                      />
                    ) : (
                      "Save"
                    )}
                  </Button>
                </div>
              </div>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
