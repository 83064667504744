import { get } from "lodash";

export const modifyUsers = (data = []) => data.map((user) => modifyUserData(user));

export const modifyUserData = (user = {}) => {
  return {
    ...user,
    firstName: get(user, "name", "").split(" ")[0],
    lastName: get(user, "name", "").split(" ")[1],
    email: get(user, "user_info.email", ""),
    mobile: get(user, "user_info.phone_number", ""),
  };
};
