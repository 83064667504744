import React from "react";
import { makeStyles, LinearProgress } from "@material-ui/core";
import { formatAmount } from "../helpers/helpers";

export const AmountDisplay = ({
  amount = 0,
  amount_sub = 0,
  inverse_diplay = false,
  inverse_progress = false,
  variant = "default",
  ...other
}) => {
  const classes = makeStyles({
    root: {},
    colorPrimary: {
      backgroundColor:
        variant === "red" ? "rgba(255, 0, 0, 0.3)" : "rgba(255, 255, 255, 0.5)",
    },
    bar: {
      backgroundColor:
        variant === "red" ? "rgba(255, 0, 0, 0.8)" : "rgba(255, 255, 255, 0.8)",
    },
  })();

  return (
    <div {...other}>
      <div style={{ margin: "0 auto" }}>
        {formatAmount(amount) +
          " / " +
          formatAmount(inverse_diplay ? amount - amount_sub : amount_sub)}
      </div>
      <LinearProgress
        classes={variant === "default" ? undefined : classes}
        className="rounded"
        variant="determinate"
        value={
          inverse_progress
            ? 100 - (amount_sub / amount) * 100
            : (amount_sub / amount) * 100
        }
      />
    </div>
  );
};
