export const fieldNames = [
  { label: "Description", value: "transaction_description" },
  { label: "Amount", value: "transaction_value" },
  { label: "Date", value: "transaction_date" },
  { label: "Type", value: "transaction_type" },
  { label: "Bank Name", value: "bank_name" },
];

export const stringOperators = [
  "is equal to",
  "is not equal to",
  "is null",
  "is not null",
  "contains all",
  "does not contain",
  "contains any",
  "begins with",
  "ends with",
];

export const numberOperators = [
  "is equal to",
  "is not equal to",
  "is less than",
  "is greater than",
  "is less than or equal to",
  "is greater than or equal to",
  "is between",
  "is null",
  "is not null",
];

export const operatorFIeldMap = {
  transaction_type: stringOperators,
  transaction_description: stringOperators,
  bank_name: stringOperators,
  transaction_value: numberOperators,
  transaction_date: numberOperators,
};
