/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { TextField, withStyles } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    // "& label.Mui-focused": {
    //   color: "white",
    // },
    // "& .MuiInput-underline:after": {
    //   borderBottomColor: "yellow",
    // },
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
        // background-color: #C9F7F5;
        // color: #1BC5BD;
      },
      // "&:hover fieldset": {
      //   borderColor: "white",
      // },
      // "&.Mui-focused fieldset": {
      //   borderColor: "yellow",
      // },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        // borderColor: "#fff",
      },
    },
  },
})(TextField);

export function InvoiceItemRowItem({
  value,
  column,
  setUnitPrice,
  setQuantity,
  setLineValue,
  updateFieldChanged,
}) {
  const [changed, setChanged] = useState(false);
  const [checked, setChecked] = useState(false);
  const [cardData, setCardData] = useState(value);

  useEffect(() => {
    if (column === "unit_price") {
      setUnitPrice(cardData);
    }
    if (column === "total_value") {
      setLineValue(cardData);
    }
    if (column === "quantity") {
      setQuantity(cardData);
    }
    // eslint-disable-next-line
  }, [cardData, setCardData]);

  const handleChange = (e) => {
    setCardData(e.target.value);
    e.target.value !== value ? setChanged(true) : setChanged(false);
    updateFieldChanged(column, e.target.value);
  };

  return (
    <>
      <CssTextField
        className={`w-100 ${changed && "changed-input"} ${checked &&
          "checked-input"}`}
        onChange={handleChange}
        onBlur={() => (!changed ? setChecked(true) : setChecked(false))}
        // defaultValue={cardData}
        value={cardData}
        label={""}
        variant="outlined"
        InputLabelProps={{ shrink: false }}
      />
    </>
  );
}
