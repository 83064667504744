import React from "react";
import { Link } from "react-router-dom";

const ButtonMailto = ({ email, subject, body, children, ...props }) => {
  return (
    // <Link
    //   to="#"
    //   onClick={(e) => {
    //     window.location = mailto;
    //     e.preventDefault();
    //   }}
    // >
    //   {label}
    // </Link>

    <a
      href={`mailto:${email}?subject=${encodeURIComponent(subject) ||
        ""}&body=${encodeURIComponent(body) || ""}`}
      {...props}
      // style={{ color: "#212121" }}
    >
      {children}
    </a>
  );
};

export default ButtonMailto;
