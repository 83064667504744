import React from "react";
import { Select as MuiSelect, MenuItem } from "@material-ui/core";
import { useField } from "formik";
import { getTip } from "../helpers/helpers";

export function Select({
  className,
  label,
  options = [],
  defaultValue = "",
  multiple,
  tip,
  ...other
}) {
  return (
    <>
      <label className="d-block mb-0">
        {label && <div className="mb-2">{label}</div>}
        <MuiSelect
          className={`bg-light form-control form-control-solid ${className}`}
          disableUnderline={true}
          defaultValue={defaultValue}
          multiple={multiple}
          data-testid="select"
          MenuProps={{ disableScrollLock: true }}
          {...other}
        >
          {options.map(({ value, label }) => (
            <MenuItem key={value} value={value} children={label} />
          ))}
        </MuiSelect>
      </label>
      {tip && <div>{tip}</div>}
    </>
  );
}

export function MappedSelect(props) {
  const [field, meta] = useField(props.name);
  return (
    <div className={`form-group ${props.wrapperclasses}`}>
      <Select {...field} {...props} tip={getTip(meta, props.tip)} />
    </div>
  );
}
