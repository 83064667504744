import React, { useState, useEffect } from "react";
import PaymentCycleElementForm from "../../ui/structures/PaymentCycleElementForm";
import { useFetch } from "../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import {
  getPaymentCycleElementById,
  modifyPaymentCycleElement,
} from "../modules/PaymentCycles/_api";

export function EditPaymentCycleElement({ match }) {
  const paymentCyclesId = match.params.id;
  const paymentCycleElementId = match.params.elementid;

  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const { request } = useFetch();

  const [data, setData] = useState({
    payment_cycles_id: paymentCyclesId,
    payment_cycle_element_id: paymentCycleElementId,
    start_date: "",
    due_date: "",
    finish_date: "",
    note: "",
  });

  useEffect(() => {
    request(
      getPaymentCycleElementById,
      TENANT_ID,
      paymentCyclesId,
      paymentCycleElementId
    ).then((response) => {
      setData((data) => ({
        ...data,
        ...response,
        start_date: new Date(response.start_date),
        due_date: new Date(response.due_date),
        finish_date: new Date(response.finish_date),
      }));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-12">
        <div className="bg-white rounded py-7 px-10">
          <div className="border-bottom border-light p-7">
            <h4 className="mb-0">Edit Payment Cycle</h4>
          </div>
          <PaymentCycleElementForm
            data={data}
            tenantId={TENANT_ID}
            onSubmit={modifyPaymentCycleElement}
            setData={setData}
          />
        </div>
      </div>
    </div>
  );
}
