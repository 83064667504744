import React, { useState, useEffect } from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { useSelector } from "react-redux";
import { getOrganisationContacts } from "../../Organisations/_api";
import DataGrid from "../../../../ui/components/DataGrid";
import {
  renderHeader,
  renderLedgerAccountsRow,
} from "../../../../ui/helpers/helpers";

const HEADINGS = [
  ["firstName", " First Name"],
  ["lastName", "Last Name"],
  ["position_title", "Title"],
  ["email", "Email"],
  ["phone", "Phone"],
];

export function StaffDirectoryPage() {
  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;

  const { request } = useFetch();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  function fetchStaffDirectoryPage() {
    request(getOrganisationContacts, TENANT_ID, ORGANISATION_ID).then(
      (contacts) => {
        if (contacts) {
          setData(contacts);
        }
        setLoading(false);
      }
    );
  }

  useEffect(() => {
    fetchStaffDirectoryPage();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  return (
    <>
      <div className="w-100 px-10 mb-10">
        <DataGrid
          data={data}
          headings={HEADINGS}
          renderHeader={renderHeader}
          renderRow={renderLedgerAccountsRow}
          loading={loading}
        />
      </div>
    </>
  );
}
