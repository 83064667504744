import config from "react-global-configuration";

export const uploadFile = (
  tenant_id,
  fileName,
  setLastFileName,
  getUrl = getUploadFileUrl
) => {
  return getUrl(fileName, "bank", tenant_id)
    .then((data) => data.json())
    .then(({ data }) => startUpload(fileName, data, setLastFileName))
    .catch(console.error);
};

const getUploadFileUrl = (fileName, type, tenant_id) => {
  return fetch(`${config.get("ApiUrl").Rest}/files/request-upload-url`, {
    method: "POST",
    mode: "cors",
    credentials: "include",
    body: JSON.stringify({
      file_name: fileName.name,
      type: type,
      tenant_id: tenant_id,
    }),
  });
};

const startUpload = (fileName, data, setLastFileName) => {
  const formData = new FormData();
  const { url, fields } = data;

  // setLastFileName(fields.key.replace("/", "%2F"));
  setLastFileName(fields.key);

  Object.keys(fields).forEach((key) => {
    const value = fields[key];

    formData.append(key, value);
  });

  formData.append("file", fileName);

  return fetch(url, {
    method: "POST",
    mode: "no-cors",
    body: formData,
  });
};
