export const HEADINGS = [
  ["invoice_date", "Invoice Date"],
  ["invoice_number", "Invoice Number"],
  ["biller_organisation_name", "Biller"],
  ["buyer_organisation_name", "Buyer"],
  ["payment_due_date", "Due Date"],
  ["payment_status", "Status"],
  ["invoice_total", "Amount"],
  ["details", "Details"],
];
