import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import DataGrid from "../../../../ui/components/DataGrid";
import { renderHeader } from "../../../../ui/helpers/helpers";
import { renderDiscrepancyRow } from "../helpers";
import { Modal } from "../../../../ui/components/Modal";
import { DiscrepanciesDetails } from "../DiscrepanciesDetails";
import { HEADINGS, BUYER_HEADINGS, BILLER_HEADINGS } from "../constants";
import { useFetch } from "../../../../hooks/fetch.hook";
import { getDiscrepancies, modifyDiscrepancy } from "../_api";
import { getOrganisationsListSimple } from "../../Organisations/_api";

export function ClosedList() {
  const { request } = useFetch();
  const user = useSelector(({ auth: { user } }) => user) || {};
  const TENANT_ID = user.selectedTenant;
  const userCategory = user.userCategory;

  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [componentId, setComponentId] = useState("");
  const [initialData, setInitialData] = useState([]);
  const [modalContent, setModalContent] = useState("DiscrepanciesDetails");
  const [organisationList, setOrganisationList] = useState([]);

  const handleModalClose = () => setModalOpen(false);

  function fetchDiscrepancyData() {
    setLoading(true);
    request(getDiscrepancies, TENANT_ID, "status=approved&status=rejected")
      .then((data) => {
        if (!data) return;

        const modifiedDiscrepencies = data.map((discrapency) => ({
          ...discrapency,
          biller_name: organisationList.filter(
            ({ value }) => discrapency.biller_id === value
          )[0].label,
          buyer_name: organisationList.filter(
            ({ value }) => discrapency.buyer_id === value
          )[0].label,
        }));
        setInitialData(modifiedDiscrepencies);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([
      request(getDiscrepancies, TENANT_ID, "status=approved&status=rejected"),
      request(getOrganisationsListSimple, TENANT_ID),
    ]).then(([discrepencies, organisations]) => {
      if (!discrepencies && !organisations) return;

      const modifiedDiscrepencies = discrepencies.map((discrapency) => ({
        ...discrapency,
        biller_name: organisations.filter(
          ({ value }) => discrapency.biller_id === value
        )[0].label,
        buyer_name: organisations.filter(
          ({ value }) => discrapency.buyer_id === value
        )[0].label,
      }));
      setOrganisationList(organisations);
      setInitialData(modifiedDiscrepencies);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleDiscrepanciesDetails = (id) => () => {
    setComponentId(id);
    setModalContent("DiscrepanciesDetails");
    setModalOpen(true);
  };

  const handleSubmitNote = (values, { setSubmitting }) => {
    const editedItem = initialData.find(({ id }) => id === componentId);

    request(
      modifyDiscrepancy,
      TENANT_ID,
      { discrepancy_note: values.discrepancy_note },
      editedItem.id
    )
      .then((data) => {
        data && fetchDiscrepancyData();
      })
      .finally(() => setSubmitting(false));
    handleModalClose();
  };

  const getSelectedDiscrepency =
    initialData.find(({ id }) => id === componentId) || {};

  const headingsMap = {
    Biller: BILLER_HEADINGS,
    Buyer: BUYER_HEADINGS,
    BOH: HEADINGS,
  };

  const modalContentMap = {
    DiscrepanciesDetails: (
      <DiscrepanciesDetails
        handleSubmit={handleSubmitNote}
        handleClose={handleModalClose}
        data={getSelectedDiscrepency}
      />
    ),
  };

  return (
    <>
      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          onClose={handleModalClose}
          fullWidth={true}
          maxWidth={
            modalContent === "reject" || modalContent === "approve"
              ? "sm"
              : "md"
          }
        >
          {modalContentMap[modalContent]}
        </Modal>
      )}
      <div className="row justify-content-center">
        <div className="col-12">
          <div className="bg-white rounded py-7 px-10">
            <div className="d-flex justify-content-between my-5">
              <h3 className="text-dark">Closed Discrepancies</h3>
            </div>
            <DataGrid
              data={initialData}
              headings={headingsMap[userCategory]}
              renderHeader={renderHeader}
              renderRow={renderDiscrepancyRow}
              loading={loading}
              selected={selected}
              setSelected={setSelected}
              selectable
              expandable
              handleExpand={handleDiscrepanciesDetails}
              expandTitle="Add Note"
            />
          </div>
        </div>
      </div>
    </>
  );
}
