import React, { useState } from "react";
import { Button } from "@material-ui/core/";
import { Input } from "./Input";

export const ConfirmDelete = ({ handleClose, handleSubmit, id, warningMessage }) => {
  const [submitting, setSubmitting] = useState(false);
  const [step, setStep] = useState(0);
  const [inputValue, setInptuValue] = useState("");

  const onClose = () => {
    handleClose();
    setStep(0);
    setInptuValue("");
  };

  const handleChange = (event) => {
    setInptuValue(event.target.value);
  };

  return (
    <>
      {step === 0 && (
        <div className="p-5">
          <div className="text-center">
            <h3 className="text-danger">{warningMessage}</h3>
          </div>
          <div className="mt-10 d-flex justify-content-end">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setStep(1)}
              variant="contained"
              color="secondary"
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Delete
            </Button>
          </div>
        </div>
      )}
      {step === 1 && (
        <div className="p-5">
          <div className="text-center">
            <h3>Please type the word "DELETE" and press OK</h3>
          </div>
          <div className="my-10">
            <Input value={inputValue} onChange={handleChange} wrapperClassName="w-100" />
          </div>
          <div className="mt-10 d-flex justify-content-end">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleSubmit(id, setSubmitting)}
              disabled={submitting || inputValue !== "DELETE"}
              variant="contained"
              color="secondary"
              size="large"
              className="px-9"
              data-testid="submit"
            >
              OK
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
