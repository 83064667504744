import React from "react";
import { withRouter } from "react-router-dom";
import { Formik } from "formik";
import { MappedInput } from "../../components/Input";
import { MappedDatePicker } from "../../components/DatePicker";
import { useFetch } from "../../../hooks/fetch.hook";
import { PaymentCyclesSchema } from "../../../helpers/schemas";
import { formatDateToUTC } from "../../helpers/helpers";

function PaymentCyclesForm({ history, data, setData, onSubmit }) {
  const { request } = useFetch();
  const {
    tenant_id,
    description,
    start_date,
    length,
    term,
    cycle_count,
  } = data;

  // {"name": "PaymentCycle X", "tenant_id": 62, "start_date": "2020-10-05", "length": 7, "cycle_count": 3, "term": 10}

  // created_at: "2020-10-15T10:38:53.316191+00:00"​​
  // cycle_count: 2​​
  // description: null​​
  // id: 1​​
  // last_modified: "2020-10-15T10:38:53.316191+00:00"​​
  // length: 7​​
  // name: "PaymentCycle X"​​
  // start_date: "2020-10-15T00:00:00+00:00"​​
  // status: "active"​​
  // tenant_id: 204​​
  // term: 10

  const initialValues = {
    tenant_id: tenant_id || "",
    description: description || "",
    start_date: start_date || "",
    length: length || "",
    term: term || "",
    cycle_count: cycle_count || "",
  };

  const handleOnBlur = (e) => {
    setData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  if (!Object.keys(data).length) {
    return <p className="text-center">Loading...</p>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PaymentCyclesSchema}
      onSubmit={(values) => {
        request(onSubmit, tenant_id, {
          ...values,
          start_date: formatDateToUTC(values.start_date),
        }).then((data) => {
          if (data) {
            return history.push(`/payment-cycles`);
          }
        });
      }}
    >
      {({ values, handleSubmit }) => (
        <>
          <div className="px-7 py-11">
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Cycle Description</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="description"
                  onBlur={handleOnBlur}
                  wrapperClassName="w-100"
                  data-testid="description"
                />
              </div>
            </div>

            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Start Date</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedDatePicker
                  name="start_date"
                  onBlur={handleOnBlur}
                  className="border border-secondary"
                  style={{ width: "100%" }}
                  testId="start_date"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Length (Days)</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="length"
                  onBlur={handleOnBlur}
                  wrapperClassName="w-100"
                  data-testid="length"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Term (Days)</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="term"
                  onBlur={handleOnBlur}
                  wrapperClassName="w-100"
                  data-testid="term"
                />
              </div>
            </div>
            <div className="row mb-5">
              <div className="col-2 my-auto">
                <p className="text-left text-muted mb-0">Number of Cycles</p>
              </div>
              <div className="col-4 mr-auto">
                <MappedInput
                  name="cycle_count"
                  onBlur={handleOnBlur}
                  wrapperClassName="w-100"
                  data-testid="cycle_count"
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between align-items-center p-7">
            <div>
              <button
                onClick={handleSubmit}
                type="submit"
                className="btn btn-primary px-15 mr-5"
                data-testid="submit"
              >
                Save
              </button>
              <button onClick={() => history.go(-1)} className="btn btn-outline-primary px-15">
                Cancel
              </button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default withRouter(PaymentCyclesForm);
