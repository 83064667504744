import React from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import { extendFormSchema } from "../../../helpers/schemas";
import { MappedInput } from "../../../ui/components/Input";

const initialValues = {
  added_cycle_count: "",
};

export const ExtendForm = ({ handleSubmit, handleClose }) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={extendFormSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <div className="p-5">
          <h3>
            <strong>Extend amount of cycles</strong>
          </h3>
          <div className="row mt-15 mb-5">
            <div className="col-4 my-auto">
              <p className="text-muted">Number of Cycles</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput wrapperClassName="w-100" name="added_cycle_count" data-testid="added_cycle_count" />
            </div>
          </div>
          <div className="d-flex justify-content-end mt-10">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className="px-9 ml-3"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
