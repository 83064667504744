import config from "react-global-configuration";

export const getDeposits = (tenant_id) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits`, {
    mode: "cors",
    credentials: "include",
  });

export const getDepositsWithSearch = (
  tenant_id,
  search = "",
  page = 0,
  page_size = 10
) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits?${search &&
      "&" + search}&page=${page}&page_size=${page_size}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const getDepositsAutocomplete = (
  tenant_id,
  page_size = 80,
  autocompleteField,
  autocompleteText = "",
  search
) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/deposits?page_size=${page_size}${(autocompleteField &&
      "&distinct=" +
        autocompleteField +
        "&" +
        autocompleteField +
        "=_like(" +
        autocompleteText +
        ")_") ||
      ""}${search ? "&" + search : ""}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const createDeposit = (tenant_id, deposit_data) =>
  fetch(`${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(deposit_data),
    mode: "cors",
    credentials: "include",
  });

export const getDepositById = (tenant_id, deposit_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits/${deposit_id}`,
    {
      mode: "cors",
      credentials: "include",
    }
  );

export const modifyDeposit = (tenant_id, deposit_data, deposit_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits/${deposit_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(deposit_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const deleteDeposit = (tenant_id, deposit_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits/${deposit_id}`,
    {
      method: "DELETE",
      mode: "cors",
      credentials: "include",
    }
  );

export const createDepositMatch = (tenant_id, deposit_id, match_data) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/deposits/${deposit_id}/match`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(match_data),
      mode: "cors",
      credentials: "include",
    }
  );

export const revertDeposit = (tenant_id, deposit_id) =>
  fetch(
    `${
      config.get("ApiUrl").Rest
    }/tenants/${tenant_id}/deposits/${deposit_id}/revert`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      mode: "cors",
      credentials: "include",
    }
  );
export const importNABStatement = (tenant_id, filename, account_id) =>
  fetch(
    `${config.get("ApiUrl").Rest}/tenants/${tenant_id}/deposits/bpay-import`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        file_name: filename,
        account_id: account_id,
        force: true,
      }),
      mode: "cors",
      credentials: "include",
    }
  );
