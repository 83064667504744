/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { Fragment, useState, useEffect } from "react";
import { InvoiceHeaderRowItem } from "./InvoiceHeaderRowItem";
import { AutocompleteGroupBy } from "../../../../../ui/components/AutocompleteGroupBy";

export function InvoiceHeaderRow({
  item = {},
  handleDataUpdate,
  organisationsOptions,
  headings,
}) {
  const getDefaultValue = () => {
    return organisationsOptions.find(
      ({ buyer_shortcode }) => buyer_shortcode === item.buyer_shortcode
    );
  };
  const [buyerId, setBuyerId] = useState(getDefaultValue() || null);

  useEffect(() => {
    if (buyerId) {
      const payload = {
        ...item,
        buyer_id: (buyerId || {}).value,
      };
      handleDataUpdate(payload);
    }
    // eslint-disable-next-line
  }, [buyerId]);

  return (
    <Fragment>
      <tr className="pb-5">
        {headings?.map(([key, header]) => (
          <th
            key={key}
            className="text-dark px-1 text-nowrap"
            style={{ border: "none", paddingBottom: 0, paddingTop: 0 }}
          >
            {header}
          </th>
        ))}
      </tr>
      <tr>
        {headings.map(([key, header], id) => {
          return (
            <td key={id} className={`align-middle no-line border-0 px-1 pb-4`}>
              {key === "buyer_id" ? (
                <AutocompleteGroupBy
                  value={buyerId}
                  setValue={setBuyerId}
                  valuesOptions={organisationsOptions}
                />
              ) : (
                item[key] !== undefined && (
                  <InvoiceHeaderRowItem
                    value={item[key]}
                    handleDataUpdate={handleDataUpdate}
                    column={key}
                    item={item}
                  />
                )
              )}
            </td>
          );
        })}
      </tr>
    </Fragment>
  );
}
