import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { Button } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { MappedInput } from "../../../ui/components/Input";
import { MappedSelect } from "../../../ui/components/Select";
import { MappedDatePicker } from "../../../ui/components/DatePicker";
import { getOrganisationContacts } from "../Organisations/_api";
import { useFetch } from "../../../hooks/fetch.hook";
import { formatDate, formatDateForBackend } from "../../../ui/helpers/helpers";
import { LateLettersSchema } from "../../../helpers/schemas";
import {
  createLateLetter,
  generateLateLetter,
  getLateLetterTemplates,
} from "../Tenants/TenantLateLetters/_api";
import { LetterContentField } from "./LetterContentField";
import { startDownload } from "../AWS-S3";

const sendOptions = [
  { value: "pdf", label: "Print" },
  { value: "email", label: "Email" },
];

export const IssueLateLetter = ({ handleClose, data = {}, user = {} }) => {
  const { request } = useFetch();

  const [letterTemplates, setLetterTamplates] = useState([]);
  const [letterTemplatesLoading, setLetterTamplatesLoading] = useState(false);
  const [contactsLoading, setContactsLoading] = useState(false);
  const [contactOptions, setContactOptions] = useState([]);

  const organisation_id = data.id;
  const organisation_name = data.name;
  const { fullname, selectedTenant } = user;

  const initialValues = {
    late_letter_template_id: "",
    method: "",
    organisation_id: organisation_name || "",
    contact_id: "",
    sender: fullname || "",
    content: "",
    payment_due_date: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    const selectedTemplate = letterTemplates.find(
      ({ id }) => id === values.late_letter_template_id
    );
    if (!selectedTemplate) return;
    request(createLateLetter, user.selectedTenant, {
      ...values,
      organisation_id,
      content: {
        values: {
          content: values.content,
          payment_due_date:
            formatDate(values.payment_due_date) ||
            formatDate(new Date() - 1 * (1000 * 3600 * 24)),
        },
      },
    }).then((data) => {
      if (!data) return;
      request(generateLateLetter, selectedTenant, data.id, {
        format: values.method,
      })
        .then((data) => {
          if (values.method === "pdf") {
            startDownload(data.url);
          }
        })
        .finally(() => {
          setSubmitting(false);
          handleClose();
        });
    });
  };

  useEffect(() => {
    setContactsLoading(true);
    request(getOrganisationContacts, selectedTenant, organisation_id)
      .then(
        (data) =>
          data &&
          setContactOptions(
            data.map(({ firstName, lastName, id }) => ({
              value: id,
              label: [firstName, lastName].join(" "),
            }))
          )
      )
      .finally(() => setContactsLoading(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLetterTamplatesLoading(true);
    request(getLateLetterTemplates, selectedTenant)
      .then((data) => data && setLetterTamplates(data))
      .finally(() => setLetterTamplatesLoading(false));
    // eslint-disable-next-line
  }, []);

  const letterTemplateOptions = letterTemplates.map(({ name, id }) => ({
    value: id,
    label: name,
  }));

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={LateLettersSchema}
    >
      {({ handleSubmit, isSubmitting, values }) => (
        <div className="py-10">
          <h4 className="mb-10 text-center">
            <strong>Late Letters</strong>
          </h4>

          <div className="row mt-15 mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted mb-0">Recipient Org</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput
                wrapperClassName="w-100"
                name="organisation_id"
                data-testid="organisation_id"
                disabled
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted">Select Contact</p>
            </div>
            <div className="col-8 mr-auto">
              {contactsLoading ? (
                <div className="mb-6">
                  <Skeleton variant="rect" width={"100%"} height={40} />
                </div>
              ) : (
                <MappedSelect
                  name="contact_id"
                  options={contactOptions}
                  data-testid="contact_id"
                />
              )}
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted">Select Letter</p>
            </div>
            <div className="col-8 mr-auto">
              {letterTemplatesLoading ? (
                <div className="mb-6">
                  <Skeleton variant="rect" width={"100%"} height={40} />
                </div>
              ) : (
                <MappedSelect
                  name="late_letter_template_id"
                  options={letterTemplateOptions}
                  data-testid="letter"
                />
              )}
            </div>
          </div>

          <LetterContentField letterTemplates={letterTemplates} />

          <div className="row mt-15 mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted">Payment Due Date</p>
            </div>
            <div className="col-2">
              <MappedDatePicker
                name="payment_due_date"
                className="border border-secnodary"
                placeholderText={formatDate(
                  new Date() - 1 * (1000 * 3600 * 24)
                )}
              />
            </div>
          </div>

          <div className="row mt-15 mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted">Sender</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedInput
                wrapperClassName="w-100"
                name="sender"
                data-testid="sender"
                disabled
              />
            </div>
          </div>

          <div className="row mb-5">
            <div className="col-2 my-auto">
              <p className="text-right text-muted">Sending Method</p>
            </div>
            <div className="col-8 mr-auto">
              <MappedSelect
                name="method"
                options={sendOptions}
                data-testid="method"
              />
            </div>
          </div>

          <div className="d-flex justify-content-end align-items-center">
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              className="px-9 mr-3"
              onClick={handleClose}
            >
              Cancel
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              color="secondary"
              disabled={isSubmitting}
              size="large"
              className="px-9"
              data-testid="submit"
            >
              Send
            </Button>
          </div>
        </div>
      )}
    </Formik>
  );
};
