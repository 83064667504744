import { delinquentTypes } from "./actions";

const initialState = {
  delinquentList: [],
};

export function delinquentReducer(state = initialState, actions) {
  const { type, payload } = actions;

  switch (type) {
    case delinquentTypes.SET_DATA:
      return {
        ...state,
        delinquentList: payload,
      };

    case delinquentTypes.UPDATE_DATA:
      const index = state.delinquentList.findIndex((item) => item.id === payload.id);
      const myState = [...state.delinquentList];
      myState.splice(index, 1, payload);
      return {
        ...state,
        delinquentList: [...myState],
      };

    default:
      return state;
  }
}
