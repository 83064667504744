import React from "react";
import { useFetch } from "../../../../hooks/fetch.hook";
import { downloadFile } from "../../AWS-S3";
import { exportOrganisationsReportsPdf } from "../../Organisations/_api";
import { useSelector } from "react-redux";
import { Link } from "@material-ui/core/";

export function MasterFilesPage() {
  const { request } = useFetch();
  const { user } = useSelector((state) => state.auth);
  const ORGANISATION_ID = user.default_organisation_id;
  const TENANT_ID = user.selectedTenant;

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "20px 60px",
          flex: "1 0 auto",
          width: "100%",
          backgroundColor: "#292824",
          backgroundImage:
          "linear-gradient(to right, #292824 0%, transparent 100%), url('/media/bg/orange.jpg')",
          backgroundPosition: "50% 34%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          maxHeight: "200px",
        }}
      >
        <div style={{ paddingBottom: "40px" }}>
          <div
            style={{
              color: "#f59220",
              fontSize: "40px",
              fontWeight: "600",
              marginBottom: "15px",
            }}
          >
            Master Files
          </div>
        </div>
        <div
          style={{
            width: "400px",
            height: "200px",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
      </div>
      <div style={{ padding: 60, fontSize: 14 }}>
        <div style={{ fontSize: 16 }}>
          <b>Master Files</b>
        </div>
        <br />
        <div>
          Access important files here to assist with your business requirements.
          Each file is a downloadable PDF for your convenience.
        </div>
        <br />
        <ul>
          <li>
            <Link
              onClick={() =>
                downloadFile(
                  "blue_report",
                  exportOrganisationsReportsPdf,
                  TENANT_ID
                )
              }
              style={{ color: "#3699FF" }}
            >
              Blue List
            </Link>
          </li>
          <li>Credit Service Buyers -Accelerated Cycle</li>
          <li>Credit Service Buyers -Extended Cycle</li>
          <li>Credit Service Wholesalers & Billing Entities</li>
          <li>
            Non Credit Service Buyers -With a Current Brisbane Market Access
            Card
          </li>
          <li>
            Non Credit Service Buyers - Without a Current Brisbane Market Access
            Card
          </li>
          <li>
            <Link
              onClick={() =>
                downloadFile(
                  "red_report",
                  exportOrganisationsReportsPdf,
                  TENANT_ID
                )
              }
              style={{ color: "#3699FF" }}
            >
              Red List
            </Link>
          </li>
          <li>
          <a
              href="/media/brands/brismark/wholesaler/files/Direct_Debit_Request.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Credit Service - Direct Debit Request
            </a>
          </li>
        </ul>
      </div>
    </>
  );
}
