import React, { useState } from "react";
import { Button, Tooltip } from "@material-ui/core";
import { useFetch } from "../../../hooks/fetch.hook";
import { Edit as EditIcon, Textsms as TextsmsIcon } from "@material-ui/icons/";
import { ShareModal } from "../../structures/ShareModal";
import { SMSForm } from "../../structures/SMSForm";
import { postSendBulkSms } from "../../../app/modules/Integrations/_api";
import Can from "../../helpers/Can";
import ButtonMailto from "../../components/ButtonMailto";

export function ContactDetails({ data, setIsEditable, tenantId }) {
  const {
    firstName,
    lastName,
    aka,
    phone,
    mobile,
    email,
    name,
    position_title,
  } = data || {};
  const { request } = useFetch();
  const [modalOpen, setModalOpen] = useState(false);

  const handleClose = () => setModalOpen(false);

  const handleOpen = () => setModalOpen(true);

  const handleSubmit = (values, { setSubmitting }) => {
    request(postSendBulkSms, tenantId, {
      contact_ids: [data.id],
      ...values,
    }).then((response) => {
      if (response) {
        console.log(response);
      }
      handleClose();
    });
    setSubmitting(false);
  };

  return (
    <div className="bg-white rounded">
      <ShareModal
        isOpen={modalOpen}
        submitable={true}
        maxWidth="xs"
        modalContent={
          <SMSForm
            handleClose={handleClose}
            handleSubmit={handleSubmit}
            title={`Send SMS to ${mobile}`}
          />
        }
      />
      <Can
        perform="card:edit"
        yes={() => (
          <Button
            variant="contained"
            color="secondary"
            data-testid="edit"
            onClick={() => setIsEditable(true)}
            style={{ position: "absolute", right: 50, top: 90 }}
          >
            <EditIcon />
          </Button>
        )}
      />
      <div className="pt-19 pb-25">
        <div className="row" data-testid="firstname">
          <div className="col-2">
            <p className="text-right text-muted">First Name</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{firstName}</span>
          </div>
        </div>
        <div className="row" data-testid="lastname">
          <div className="col-2">
            <p className="text-right text-muted">Last Name</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{lastName}</span>
          </div>
        </div>
        <div className="row" data-testid="aka">
          <div className="col-2">
            <p className="text-right text-muted">AKA</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{aka}</span>
          </div>
        </div>

        <div className="row" data-testid="position_title">
          <div className="col-2">
            <p className="text-right text-muted">Position Title</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{position_title}</span>
          </div>
        </div>

        <div className="row" data-testid="orgname">
          <div className="col-2">
            <p className="text-right text-muted">Organisation Name</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{name}</span>
          </div>
        </div>
        <div className="row" data-testid="phone">
          <div className="col-2">
            <p className="text-right text-muted">Phone</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark">{phone}</span>
          </div>
        </div>
        <div className="row" data-testid="mobile">
          <div className="col-2">
            <p className="text-right text-muted">Mobile</p>
          </div>
          <div className="col-4 mr-auto">
            <span className="text-dark mr-4">{mobile}</span>
            {mobile && (
              <span>
                <Tooltip
                  title={`Send SMS to ${firstName} ${lastName}`}
                  arrow
                  interactive
                >
                  <TextsmsIcon onClick={handleOpen} />
                </Tooltip>
              </span>
            )}
          </div>
        </div>
        <div className="row" data-testid="email">
          <div className="col-2">
            <p className="text-right text-muted">Email Address</p>
          </div>
          <div className="col-4 mr-auto">
            <ButtonMailto
              email={email}
              subject={`Hello ${firstName && firstName} ${lastName &&
                lastName}`}
              body={`Dear ${firstName && firstName} ${lastName && lastName},`}
            >
              {email}
            </ButtonMailto>
          </div>
        </div>
      </div>
    </div>
  );
}
