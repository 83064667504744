import React, { useState, useEffect, useMemo } from "react";
import { useFetch } from "../../../hooks/fetch.hook";
import { createInvoice, getLastUploadedInvoiceDataResult } from "./_api";
import { useSelector } from "react-redux";
import { InvoiceEntryModul } from "./InvoiceEntryModul/InvoiceEntryModul";
import { Add as AddIcon } from "@material-ui/icons";
import { getTaxes } from "../Fees/TaxesModule/_api";
// import { success } from "../../../helpers/toasts";
import { getAccountsList } from "../LedgerAccounts/_api";
import { getOrganisationsListSimple } from "../Organisations/_api";
import File from "./File";
import { BrowseFile } from "../../../ui/components/BrowseFile";
import { TextField, withStyles, Button } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { setID, setIDMultiple } from "./helpers";
import { error } from "../../../helpers/toasts";
import { isValidDate, removeEmptyFields } from "../../../ui/helpers/helpers";
import { ConfirmCreateAll } from "./ConfirmCreateAll";
import { Modal } from "../../../ui/components/Modal";

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-input": {
      padding: "8.5px 14px",
    },
    "&.changed-input .MuiOutlinedInput-root": {
      "& .MuiOutlinedInput-input": {
        color: "#1BC5BD",
      },
      "& fieldset": {
        borderColor: "#C9F7F5",
      },
    },
    "&.checked-input .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#fff",
      },
    },
  },
})(TextField);

const EMPTYINVOICE = {
  id: 0,
  buyer_id: "",
  buyer_account_id: "",
  biller_id: "",
  biller_account_id: "",
  invoice_date: "",
  payment_due_date: "",
  processed_date: "",
  invoice_number: "",
  invoice_type: "",
  batch_id: "",
  note: "",
  creation_status: "new",
  invoice_lines: [
    {
      line_number: "",
      reference: "",
      comment: "",
      description: "",
      quantity: "",
      unit_price: "",
      total_value: "",
      tax_value: "",
      tax_rate: "",
      tax_id: "",
      id: 0,
    },
  ],
};

export function InvoiceEntry() {
  const { user } = useSelector((state) => state.auth);
  const TENANT_ID = user.selectedTenant;
  const DEFAULT_ORGANISATION = user.default_organisation_id;
  const USER_CATEGORY = user.userCategory;
  const { request } = useFetch();
  const [data, setData] = useState([]);
  const [taxList, setTaxList] = useState([]);
  const [billerAccount, setBillerAccount] = useState(0);
  const [billerOrganisation, setBillerOrganisation] = useState(0);

  const [organisationsList, setOrganisationsList] = useState([]);
  const [accountsList, setAccountsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const [lastFileName, setLastFileName] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [updateLastUpload, setUpdateLastUpload] = useState(false);
  const [failedInvoices, setFailedInvoices] = useState([]);
  const [createdInvoices, setCreatedInvoices] = useState([]);
  const [invoiceLoading, setInvoiceLoading] = useState(false);
  const [createAllLoading, setCreateAllLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);

  useEffect(() => {
    setAccountsList([]);
    fetchTaxList();
    fetchOrganisationList();
    // eslint-disable-next-line
  }, [TENANT_ID]);

  useEffect(() => {
    if (lastFileName && updateLastUpload) {
      const usedNumbers = [];
      setLoading(true);
      setData([]);
      setUpdateLastUpload(false);
      request(getLastUploadedInvoiceDataResult, lastFileName, TENANT_ID).then(
        (response) => {
          if (response) {
            setData(
              response.map((item) => {
                const itemID = setIDMultiple(10000000, usedNumbers);
                usedNumbers.push(itemID);
                return {
                  ...item,
                  id: itemID,
                  invoice_lines: item.invoice_lines.map((line) => {
                    const itemID = setIDMultiple(10000000, usedNumbers);
                    usedNumbers.push(itemID);
                    return {
                      ...line,
                      id: itemID,
                    };
                  }),
                };
              })
            );
          }
          setLoading(false);
        }
      );
    }
    // eslint-disable-next-line
  }, [lastFileName, setLastFileName, updateLastUpload, setUpdateLastUpload]);

  useEffect(() => {
    if (billerOrganisation?.value) {
      fetchAccountsList();
    } else {
      setAccountsList([]);
    }
    // eslint-disable-next-line
  }, [billerOrganisation, setBillerOrganisation]);

  const handleUploadFiles = (acceptedFiles) => {
    setUploadedFiles((prevState) => [...acceptedFiles]);
  };

  function fetchAccountsList() {
    setAccountsList([]);
    request(
      getAccountsList,
      TENANT_ID,
      billerOrganisation.value,
      "status=active"
    ).then((data) => {
      if (data) {
        data.forEach((item) => {
          if (item.default_account === true) setBillerAccount(item.id);
          if (
            item.account_type === "internal" &&
            item.ledger_type === "biller"
          ) {
            setAccountsList((prevList) => [
              ...prevList,
              { ...item, value: item.id, label: item.account_name },
            ]);
            setBillerAccount(item.id);
          }
        });
      }
    });
  }

  function fetchTaxList() {
    request(getTaxes, TENANT_ID).then((taxList) => {
      if (taxList) {
        setTaxList(taxList);
      }
      setLoading(false);
    });
  }

  let taxListOptions = useMemo(
    () =>
      taxList.map(({ id, name, rate }) => ({
        value: id,
        label: name + ` (${rate}%)`,
        rate: rate,
      })),
    [taxList]
  );

  function fetchOrganisationList() {
    setOrganisationsList([]);
    request(getOrganisationsListSimple, TENANT_ID).then((data) => {
      if (data) {
        setOrganisationsList(data);
        setBillerOrganisation(
          data.find((org) => org.value === DEFAULT_ORGANISATION)
        );
      }

      setLoading(false);
    });
  }

  const organisationsOptions = organisationsList.map((option) => {
    const firstLetter = option.label[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
      value: option.value + "",
      buyer_shortcode: option.label.split(" ")[0],
    };
  });

  const handleClear = () => {
    setData([]);
    setFailedInvoices([]);
    setCreatedInvoices([]);
  };

  const handleNewInvoice = () => {
    setData((oldData) => [
      ...oldData,
      { ...EMPTYINVOICE, id: setID(10000000, oldData) },
    ]);
  };

  const handleRemoveInvoice = (id) => {
    setData((state) => state.filter((invoice) => (invoice || {}).id !== id));
    setFailedInvoices((state) => state.filter((invoiceID) => invoiceID !== id));
  };

  const handleStatusChange = (cardData = {}) => {
    const payload = {
      ...cardData,
      creation_status: "created",
    };
    setData((state) => {
      const index = state.findIndex(
        (item) => (item || {}).id === (cardData || {}).id
      );
      if (index === -1) return;
      return [...state.slice(0, index), payload, ...state.slice(index + 1)];
    });
  };

  const modifyInvoiceLines = (invoice_lines = []) => {
    // eslint-disable-next-line array-callback-return
    return invoice_lines.map((element, index) => {
      if (element.description || element.quantity || element.unit_price) {
        let invoice_line = {
          ...element,
          line_number: index + 1,
          total_value: undefined,
        };
        removeEmptyFields(invoice_line);
        return invoice_line;
      }
    });
  };

  const getPayload = (req_invoice_lines = [], cardData = {}) => {
    let date = cardData.invoice_date.split("/");
    var dateObj =
      date.length > 1
        ? new Date(parseInt(date[2]), parseInt(date[1]) - 1, parseInt(date[0]))
        : new Date();
    const payload = {
      ...cardData,
      biller_id: DEFAULT_ORGANISATION,
      biller_account_id: billerAccount?.id === 0 ? "" : billerAccount?.id,
      invoice_date: isValidDate(dateObj) ? dateObj.toISOString() : "",
      invoice_lines: req_invoice_lines,
    };
    return payload;
  };

  const handleFailedInvoices = (cardData = {}, requestData = {}) => {
    setFailedInvoices((state) => {
      if (!state.find((invoice) => (invoice || {}).id === cardData.id)) {
        return [...state, requestData.id];
      } else return;
    });
  };

  const handleCreatedInvoices = (cardData = {}, requestData = {}) => {
    setCreatedInvoices((state) => {
      if (!state.find((invoice) => (invoice || {}).id === cardData.id)) {
        return [...state, requestData.id];
      } else return;
    });
  };

  function postInvoice(cardData = {}) {
    setInvoiceLoading(true);
    let req_invoice_lines = modifyInvoiceLines(cardData.invoice_lines);
    const requestData = getPayload(req_invoice_lines, cardData);
    if (req_invoice_lines.some((line) => !line)) {
      error("Not a valid Invoice Line");
      handleFailedInvoices(cardData, requestData);
      setInvoiceLoading(false);
      return;
    } else {
      setCreateAllLoading(true);
      setLoading(true);
      removeEmptyFields(requestData);
      return request(createInvoice, TENANT_ID, requestData)
        .then((response) => {
          if (response) {
            handleCreatedInvoices(cardData, requestData);
            setFailedInvoices((state) =>
              state.filter((invoiceID) => invoiceID !== cardData.id)
            );
            handleStatusChange(cardData);
          } else {
            setLoading(false);
            handleFailedInvoices(cardData, requestData);
          }
        })
        .finally(() => {
          setInvoiceLoading(false);
          setCreateAllLoading(false);
        });
    }
  }

  const handlePostAllInvoices = () => {
    const filteredData = data.filter(
      (invoice) => !createdInvoices.includes((invoice || {}).id)
    );
    setData((state) =>
      state.filter((invoice) => !createdInvoices.includes((invoice || {}).id))
    );
    handleModalClose();
    if (!filteredData.length) return;
    const promises = filteredData.map((invoice) => postInvoice(invoice));
    Promise.all(promises);
  };

  const getInvoicesValue = (data = []) => {
    const invoiceLines = data
      .map((item) => item.invoice_lines)
      .reduce((acc, value) => [...acc, ...value], [])
      .filter((item) => item.unit_price && item.quantity && item.description);
    const totalValue = Number.parseFloat(
      invoiceLines.reduce((acc, value) => {
        const tax_value =
          value.quantity * value.unit_price * ((value.tax_rate || 0) / 100);
        return acc + (value.quantity * value.unit_price + tax_value);
      }, 0)
    ).toFixed(2);
    return totalValue;
  };

  return (
    <>
      <Modal isOpen={modalOpen} onClose={handleModalClose} maxWidth="md">
        <ConfirmCreateAll
          handleSubmit={handlePostAllInvoices}
          handleClose={handleModalClose}
        />
      </Modal>
      <div className="row justify-content-center rounded">
        <div className="col-12">
          <div
            className="py-7 bg-white px-10"
            style={{
              boxShadow:
                "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px",
            }}
          >
            <div className="d-flex justify-content-between">
              <h2 className="font-weight-bold">Invoice Entry</h2>
              {USER_CATEGORY === "BOH" ? (
                <div className="col-2 ml-auto">
                  <Autocomplete
                    id="grouped-demo"
                    className="w-100"
                    value={billerOrganisation}
                    onChange={(event, newValue) => {
                      setBillerOrganisation(newValue);
                    }}
                    size="small"
                    options={organisationsList && organisationsList}
                    getOptionLabel={(option) => option.label || ""}
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    // disabled={!ORG || props.isSubmitting}
                    renderInput={(params) => (
                      <CssTextField
                        {...params}
                        label="Biller Organisation"
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              ) : (
                ""
              )}
              <div
                className={`col-2 ${USER_CATEGORY === "BOH" ? "" : "ml-auto"}`}
              >
                <Autocomplete
                  id="grouped-demo"
                  className="w-100"
                  value={billerAccount.value}
                  onChange={(event, newValue) => {
                    setBillerAccount(newValue);
                  }}
                  size="small"
                  options={accountsList && accountsList}
                  getOptionLabel={(option) => option.label || ""}
                  getOptionSelected={(option, value) =>
                    // option.value === value.value
                    true
                  }
                  // disabled={!ORG || props.isSubmitting}
                  renderInput={(params) => (
                    <CssTextField
                      {...params}
                      label="Biller Account"
                      variant="outlined"
                    />
                  )}
                />
              </div>
              <div className="d-flex justify-content-end align-items-center">
                <BrowseFile
                  onDrop={handleUploadFiles}
                  width="1"
                  visibleFile={false}
                  buttonVariant="outlined"
                  title="Upload"
                >
                  {/* {uploadedFiles.map((file) => (
                    <File
                      key={file.lastModified}
                      tenant_id={TENANT_ID}
                      file={file}
                      setLastFileName={setLastFileName}
                      updateLastUpload={updateLastUpload}
                      updateLastUploadState={setUpdateLastUpload}
                    />
                  ))} */}
                </BrowseFile>

                <Button
                  variant="outlined"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  onClick={handleClear}
                  disabled={createAllLoading}
                >
                  Delete All
                </Button>
                <Button
                  onClick={handleNewInvoice}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9 mr-3"
                  disabled={createAllLoading}
                >
                  Add New Invoice
                </Button>
                <Button
                  onClick={handleModalOpen}
                  variant="contained"
                  color="secondary"
                  size="large"
                  className="px-9"
                  disabled={createAllLoading || !data.length}
                >
                  Create All
                </Button>
              </div>
            </div>

            {uploadedFiles.length ? (
              <div
                className="d-flex flex-column mt-3 ml-auto"
                style={{ maxWidth: "553.7px" }}
              >
                <div
                  className="bg-secondary w-100"
                  style={{
                    minHeight: "38.4px",
                    padding: "0 16px",
                  }}
                >
                  {uploadedFiles.map((file) => (
                    <File
                      key={file.lastModified}
                      tenant_id={TENANT_ID}
                      file={file}
                      setLastFileName={setLastFileName}
                      updateLastUpload={updateLastUpload}
                      updateLastUploadState={setUpdateLastUpload}
                    />
                  ))}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          {data.length ? (
            <div className="d-flex justify-content-end">
              <div
                style={{
                  background: "#fff",
                  display: "inline-block",
                  padding: "0 20px 4px",
                  borderRadius: "0 0 0.42rem 0.42rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px",
                }}
              >
                <span style={{ fontWeight: "600" }}>{data.length}</span>{" "}
                {data.length > 1 ? "invoices" : "invoice"}
              </div>
              <div
                style={{
                  background: "#fff",
                  display: "inline-block",
                  padding: "0 20px 4px",
                  marginLeft: 20,
                  borderRadius: "0 0 0.42rem 0.42rem",
                  boxShadow:
                    "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px",
                }}
              >
                Total value:{" "}
                <span style={{ fontWeight: "600" }}>
                  $ {getInvoicesValue(data)}
                </span>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="col-12">
          <div className="row justify-content-center rounded h-100">
            {data.map((invoice = {}) => (
              <InvoiceEntryModul
                key={invoice.id}
                invoice={invoice}
                taxList={taxList}
                taxListOptions={taxListOptions}
                organisationsOptions={organisationsOptions}
                loading={loading}
                setData={setData}
                invoices={data}
                handleRemoveInvoice={handleRemoveInvoice}
                postInvoice={postInvoice}
                failedInvoices={failedInvoices}
                invoiceLoading={invoiceLoading}
              />
            ))}
            <Button
              onClick={handleNewInvoice}
              color="secondary"
              size="large"
              className="px-9"
              startIcon={<AddIcon />}
              style={{ fontSize: 18, marginTop: 36 }}
            >
              Add New Invoice
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
